
CookieFactory.$inject = ['$rootScope'];
function CookieFactory($rootScope) {

        return {
                get: function (name) {
                    var cname = name + "=";
                    var ca = document.cookie.split(';');
                    for (var i = 0; i < ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0) == ' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(cname) == 0) {
                            return c.substring(cname.length, c.length);
                        }
                    }
                    return "";
                },

                //추가
                set: function (name, value, lifeDay) {
                        var expire = new Date();
                        expire.setDate(expire.getDate() + lifeDay);
                        var cookies = name + '=' + escape(value) + '; path=/ '; // 한글 깨짐을 막기위해 escape(value)를 합니다.
                        if (typeof lifeDay != 'undefined')
                                cookies += ';expires=' + expire.toGMTString() + ';';
                        cookies += 'domain='+ DOMAIN +';';
                        document.cookie = cookies;
                },
                // 삭제
                remove: function (name) {
                        this.set(name, '', -1);
                },

        }
};

module.exports = CookieFactory;