
/**
 * 하위버전 안드로이드 단말에서 ng-click을 사용할경우 
 * 두번 클릭됨  touchstart 로 대치함
 */
function ngTouchstart() {
        return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                        element.bind('touchstart', function (evt) {
                                evt.stopPropagation();
                                scope.$apply(function () {
                                        scope.$eval(attrs.ngTouchstart);
                                });
                        });
                }
        }
}

module.exports = ngTouchstart;