tagInput.$inject = ['gtext'];
function tagInput(gtext) {
        return {
                restrict: 'A',
                scope: {
                        value: '=tagInput',
                        newTag: '&',
                        deleteTag: '&',
                        onInput: '&'
                },
                link: function (scope, element, attrs) {
                        $(element).width(30);
                        scope.prevValue = '';

                        element.on('keydown', function (e) {
                                if (e.which == 9) {
                                        e.preventDefault();
                                }

                                if (e.which == 8) {
                                        scope.$apply(scope.deleteTag);
                                }
                        });
                        element.on('keyup', function (e) {
                                //일부단말에서  input에 maxsize가 안먹음 ㅜㅜ
                                if (scope.value.length > 12) {
                                        e.preventDefault();
                                        alert(gtext("아이디는 12자 이내로 입력해 주세요."));
                                        scope.value = '';
                                        scope.$apply();
                                        return false;
                                }

                                var key = e.which;
                                // Tab or Enter, Space pressed
                                if (key == 9 || key == 13 || key == 32) {
                                        e.preventDefault();
                                        scope.$apply(scope.newTag);
                                        $(element).width(30);
                                }
                        });
                        element.on('change input', function(e)       {
                                var tempEl = $('<span>').text(scope.value).appendTo('body');
                                $(element).width(scope.value ? (tempEl.width() + 30) : 30);
                                tempEl.remove();
                        });
                        element.on('focus', function (e) {
                                $(element).width(30);
                        });
                        element.on('blur', function (e) {
                                if (!scope.value || !scope.value.trim()) {
                                        return;
                                }
                                scope.value = '';
                                scope.$apply();
                                $(element).width(1);
                        });

                        element.on('change input keydown keyup blur focus', function()
                        {
                                var szValue = $(this).val();

                                if (szValue === scope.prevValue) {
                                        return;
                                }

                                scope.prevValue = szValue;
                                scope.value = szValue;
                                scope.$apply();
                                scope.$apply(scope.onInput);
                        });

                }
        }
}

module.exports = tagInput;