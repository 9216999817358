IteminfoGiftTicketController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$http', '$timeout', 'Util', 'LoginManager', 'ItemService', 'gtext'];

function IteminfoGiftTicketController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, ItemService, gtext) {

	var change_cnt = 6;
	var ticket_cnt = 0;
	$scope.ids = [];
	$scope.disabled = true;

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$window.history.back();
	}

	/**
	 * ID 추가
	 */
	$scope.addId = function () {
		if (!$scope.disabled) {
			return false;
		}
		$scope.disabled = false;
		$('.box_w p').hide();
		if ($scope.ids.length >= change_cnt) {
			alert(gtext("이미 %s명이 등록되어, 더 이상 추가할 수 없습니다.", change_cnt));
			$scope.tagText = '';
			$('#tag_input').focus();
			$scope.disabled = true;
			return false;
		}
		if ($scope.ids.length >= ticket_cnt) {
			if (confirm('보유 티켓이 부족합니다. 티켓 교환하기로 이동하시겠습니까?')) {
				$state.go("iteminfo.exchangeticket", {
					ticket_type: $stateParams.ticket_type
				});
			}
			$scope.tagText = '';
			$scope.disabled = true;
			return false;
		}
		var regType1 = /^[A-Za-z0-9+]+$/;
		if (!regType1.test($scope.tagText)) {
			alert(gtext("영문과 숫자만 입력할 수 있습니다."));
			$scope.tagText = '';
			$('#tag_input').focus();
			$scope.disabled = true;
			return false;
		}
		if ($scope.tagText === undefined || !$scope.tagText || $scope.tagText.length == 0) {
			$('#textError').show();
			$('#tag_input').focus();
			$scope.disabled = true;
			return false;
		}

		var cnt = $scope.ids.length;
		for (var i = 0; i < cnt; i++) {
			if ($scope.ids[i].receive_id.toLowerCase() == $scope.tagText.toLowerCase()) {
				alert(gtext("이미 추가된 아이디입니다."));
				$scope.tagText = '';
				$('#tag_input').focus();
				$scope.disabled = true;
				return false;
			}
		}

		var receive_id = $scope.tagText;

		ItemService.fanLoveItem({
				szWork: 'getCheckUserId',
				szReceiverId: receive_id,
				szItemType: $stateParams.ticket_type
			}).then(function (response) {
				var result = response.RESULT;
				var msg = response.MSG;
				if (result < 0) {
					alert(msg);
					$scope.disabled = true;
					$scope.$digest();
					return;
				} else {
					$scope.tagText = '';
					ItemService.nickname({
						user_id: receive_id
					}).then(function (response) {
						$scope.ids.push({
							receive_id: receive_id,
							receive_nickname: response.data.nickname
						});
						$scope.disabled = true;
						$scope.$digest();
					}, function (response) {
						alert(gtext("채널 정보가 없습니다."));
						$('#tag_input').focus();
						$scope.disabled = true;
						$scope.$digest();
					})
				}
			})
			.catch(function (error) {
				$scope.disabled = true;
				$scope.$digest();
			});
	}

	/**
	 * ID 제거 
	 */
	$scope.deleteId = function (key) {
		if ($scope.ids.length > 0 &&
			$scope.tagText.length == 0 &&
			key === undefined) {
			$scope.ids.pop();
		} else if (key != undefined) {
			$scope.ids.splice(key, 1);
		}
	}

	/**
	 * 티켓 선물
	 */
	$scope.giftTicket = function (key) {
		$('.box_w p').hide();
		var values = new Array();
		var cnt = $scope.ids.length;
		if (cnt == 0) {
			$('#textError').show();
			$('#tag_input').focus();
			return false;
		}

		$.each($scope.ids, function (index, value) {
			values.push(value.receive_id);
		});

		if (confirm("아이디 확인 된 총 " + cnt + "명에게 선물하시겠습니까?")) {

			ItemService.fanLoveItem({
				szWork: 'setTicketGift',
				szItemType: $stateParams.ticket_type,
				szReceiverIds: values
			}).then(function (response) {

				var result = response.RESULT;
				var msg = response.MSG;

				if (result < 0) {
					alert(msg);
					return;
				} else {
					$state.go("iteminfo.giftticketend", {
						ticket_type: $stateParams.ticket_type
					});
				}
			});
		}
	}

	/**
	 * 티켓 선물하기 페이지 띄우기
	 */
	ItemService.stickerGiftTicketView({
		ticket_type: $stateParams.ticket_type
	}).then(function (response) {
		$scope.total = 1;
		$scope.ticket = response.data;
		ticket_cnt = $scope.ticket.buy_cnt;
		$('.box_w p').hide();
		$scope.$digest();
	}).catch(function (error) {
		$scope.total = 0;
		$scope.$digest();
	});
}
module.exports = IteminfoGiftTicketController;