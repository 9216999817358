

RecommanderService.$inject = ['$http'];
function RecommanderService($http) {
        this.encode = function (data) {
                var newData = angular.merge({}, {
                        szWork: 'createCode',
                        szFileType: 'json'
                }, data);
                return new Promise(function (resolve, reject) {
                        $http.post(LIVE_AFREECATV + '/api/ok_api.php', $.param(newData)).then(function (response) {
                                resolve(response.data)
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }


        this.recomand = function (data) {
                var newData = angular.merge({}, {
                        szWork: 'sendOk',
                        szFileType: 'json'
                }, data);
                return new Promise(function (resolve, reject) {
                        $http.post(LIVE_AFREECATV + '/api/ok_api.php', $.param(newData)).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
}


module.exports = RecommanderService;