function customSelect() {
    return {
        restrict: 'A',
        scope: {
            value: '=selectValue',
            scroll: '&',
            scrollEnd: '&',
            onSelect: '&'
        },
        link: function (scope, element) {
            var $element = $(element);
            $element.on('scroll', function()
            {
                var nTop = $element.scrollTop()
                    , nPrevTop = $element.data('scroll-top') || 0;

                if (nTop === nPrevTop)  {
                    return true;
                }

                var nNHeight = nTop + $element.height();
                if (nNHeight >= $element.prop('scrollHeight'))  {
                    scope.$apply(scope.scrollEnd);
                }

                scope.$apply(scope.scroll);
                $element.data('scroll-top', nTop);
            });

            $element.on('mousedown', function(event)    {
                event.preventDefault();
            }).on('click', '[select-item]', function()   {
                var szValue = $(this).data('value');
                scope.value = szValue;
                scope.$apply();
                scope.$apply(scope.onSelect);
            });
        }
    }
}

module.exports = customSelect;