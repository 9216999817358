NotificationPushsettingController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', 'Util', 'LoginManager', 'NotificationService', 'gettextCatalog', 'gtext']
function NotificationPushsettingController($scope, $rootScope, $state, $http, $timeout, Util, LoginManager, NotificationService, gettextCatalog, gtext) {

    //리스트 페이지 이동
    $scope.back = function (link) {
        location.replace(M_AFREECATV + "/#/notification/list");
    }

    //PUSH설정 페이지이동
    $scope.setting_noti = function () {
        location.replace(M_AFREECATV + "/#/notification/notisetting");
    }

    //NOTI설정 페이지이동
    $scope.setting_push = function () {
        location.replace(M_AFREECATV + "/#/notification/pushsetting");
    }

    //APP설치 페이지이동 (AOS,IOS)
    $scope.setup_link = function () {
        if( /Android/i.test(navigator.userAgent)) {
            window.open("https://play.google.com/store/apps/details?id=kr.co.nowcom.mobile.afreeca", "", ""); 
        } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            window.open("https://itunes.apple.com/kr/app/afreecatv-%EC%95%84%ED%94%84%EB%A6%AC%EC%B9%B4tv/id334185830?mt=8", "", ""); 
        }       
    }    
}

module.exports = NotificationPushsettingController;