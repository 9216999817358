module.exports = {
	'홈': 'ホーム',
	'핫이슈': 'HOT ISSUE',
	'나중에보기': '後で見る',
	'전체': '全体',
	'로그인': 'ログイン',
	'최신순': '最新順',
	'참여인원순': '視聴者数順',
	'즐겨찾기 추가': 'お気に入り追加',
	'방송국 보러가기': 'コミュニティへ',
	'나중에보기 등록': '後で見るリスト登録',
	'목록편집': 'リスト編集',
	'전체삭제': '全体削除',
	'재생한 VOD 삭제': '再生した動画削除',
	'시청한 VOD': '視聴履歴',
	'다시보기': '過去の放送',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': '[後で見る]に登録した動画は<br>ログイン後視聴できます。',
	'나중에 보기에 등록한 VOD가 없습니다.': '動画がありません。',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': '動画を視聴できない状況の場合には、<br>[後で見る]リストに登録しておくと<br>いつでも簡単に視聴できます！',
	'나중에보기 삭제': '[後で見る]削除',
	'나중에 보기 서비스란?': '[後で見る]サービスとは？',
	'나중에 보기에 등록되었습니다.': '[後で見る]に登録しました。',
	'나중에 보기가 삭제되었습니다.': '[後で見る]が削除されました。',
	'라이브 방송을 시청할 수 없는 상황일 때': 'LIVE放送を視聴できない場合',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': 'リストへ登録するといつでも視聴することができます。',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': '<span class="blue">ダイジェストの「後で見る」メニュー</span>から<br>登録した動画の確認ができます。',
	'보고 싶은 VOD나, 아직 보지 않은': '視聴したい動画やまだ視聴していない,',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': '動画をリストに登録していつでも視聴することができます。,',
	'다시 보지 않기': '非表示にする',
	'닫기': '閉じる',
	'나중에 보기를 지원하지 않는 영상입니다.': '[後で見る]機能を支援しない映像です。',
	'쪽지함': 'マイDM',
	'새 쪽지 <span>%s</span>개': '新着DM <span>%s</span>通',
	'새 쪽지가 없습니다': '新着DMはありません。',
	'쪽지 보내기': 'DM送信',
	'쪽지설정': 'DM受信設定',
	'받은 쪽지함': '受信Box',
	'보낸 쪽지함': '送信Box',
	'쪽지 보관함': 'マイDM',
	'전체 쪽지': 'すべて',
	'안읽은 쪽지': '未読DM',
	'보관한 쪽지':'保存中のDM',
	'보관한 쪽지가 없습니다.': '保存中のDMがありません。',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': '保存したDMは永久保存されます。(最大1000通)',
	'이미지 첨부는 3개까지 가능합니다.': '画像の添付は3つまで可能です。',
	'이미지 첨부는 8MB까지 가능합니다.': '画像の添付は8MBまで可能です。',
	'이미지가 저장되었습니다.': 'Image saved. ',
	'확인': '確認',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(受信した日から<em>90</em>日保管)',
	'이미지 첨부': '画像を添付',
	'첨부 이미지': '添付された画像',
	'이미지 저장 기간': '画像の保存期間',
	'이미지 저장 기간 만료': '画像の保存期間満了',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(送信した日から<em>90</em>日間保存)',
	'이미지 파일은 1개씩 첨부 가능합니다.': '画像ファイルは1つずつ添付してください。',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': 'JPG, JPEG, GIF, PNG, BMP 画像のみ可能です。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': '保存情報に移動されたDMは永久保存できます。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)':'保存情報に移動されたDMは永久保存できます。(最大1000通)',
	'이미지 첨부 중입니다.': '画像を添付中です。',
	'해당 쪽지가 삭제되었습니다.' : 'このDMが削除されました。',
	'해당 쪽지가 신고되었습니다.' : 'このDMを通報しました。',
	'수신거부가 완료되었습니다.' : '受信拒否を完了しました。',
	'해당 쪽지가 발송 취소되었습니다.' : 'このDMの送信をキャンセルしました。',
	'이미 신고된 쪽지입니다.':'すでに通報したDM です。',
	'수신거부 ID 추가' : '受信拒否ID追加',


	'보관': '保存',
	'신고': '通報',
	'삭제': '削除',
	'받은 쪽지가 없습니다.': 'メッセージがありません。',
	'읽지않음': '未確認',
	'읽음': '既読',
	'더 보기': 'もっとみる',
	'받은 쪽지': '受信Box',
	'보낸 사람': '送信先',
	'받은 시각': '受信日時',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': '確認したDMは90日後自動削除されます。',
	'답장': '返信',
	'수신거부': 'ブロック',
	'보낸 쪽지가 없습니다.': 'メッセージがありません。',
	'보냄': '送信完了',
	'보낸 쪽지': '送信Box',
	'받는 사람': '受信先',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': '送信されたDMは90日後自動削除されます。',
	'쪽지 수신 설정': 'DM受信設定',
	'전체 수신거부': '全体受信拒否',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': '全ユーザーのDM受信を拒否します。<br>(運営者アカウント除外)',
	'수신거부 ID 목록': 'DMブロックIDリスト',
	'총 %s명': '総%s人',
	'아이디': 'ID',
	'추가 날짜': '追加日付',
	'수신거부 목록이 없습니다.': '本人にはメッセージ送信ができません。',
	'수신거부 해제': 'ブロック解除',
	'추가': '追加',
	'수신거부 추가': 'DMブロックリスト追加',
	'쪽지 보내기': 'DM送信',
	'보내기': '送信',
	'받는 아이디': '受信ID',
	'다중 전송은 엔터키로 구분해 주세요.': '一括送信はEnterキーで 区分してください。',
	'최대 5,000자까지 작성 가능합니다.': '最大5,000文字まで作成可能です。',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': '禁止語使用で機能使用ができません。詳しい内容はお問い合わせください。',
	'신고가 접수되었습니다.': '通報が完了しました。',
	'선택한 쪽지를\n신고하시겠습니까?': '選択したメッセージを通報しますか。',
	'쪽지를 선택해 주세요.': 'メッセージを選択してください。',
	'선택한 쪽지를\n삭제하시겠습니까?': '選択したメッセージを削除しますか。',
	'쪽지를 선택해 주세요.': 'メッセージを選択してください。',
	'삭제하시겠습니까?': '削除しますか。',
	'신고하시겠습니까?': '通報しますか。',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': '受信拒否リストに追加しました。受信拒否設定から解除できます。',
	'아이디를 입력해 주세요.': 'IDを入力してください。',
	'아이디는 영문,숫자만 입력 가능합니다.': 'IDは英数字のみ入力可能です。',
	'아이디를 선택해 주세요': 'IDを選択してください。',
	'해제되었습니다.': '解除しました。',
	'한 번에 최대 30명까지만 전송 가능합니다.': '送信可能人数は一度に最大30人までです。',
	'본인에게는 쪽지를 보낼 수 없습니다.': '本人のIDには送信できません。',
	'이미 받는사람 목록에 포함 되어 있습니다.': '既に送信先リストに含まれています。',
	'%s은(는) 존재하지 않는 닉네임/아이디입니다.' : '%sは間違っているか登録されていないニックネーム/IDです。',
	'받는 아이디/닉네임을 입력해 주세요.': '送信先のID/ニックネームを入力してください。',
	'쪽지 내용을 입력해 주세요.': '内容を入力してください。',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': '送信可能人数は一度に最大30人までです。',
	'전송이 완료되었습니다.': '送信が完了しました。',
	'로그인이 되어 있지 않습니다.': 'ログインされていません。',
	'아이디는 12자 이내로 입력해 주세요.': 'IDは12文字まで入力してください。',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': '通報を完了しました。(運営ID除外)\n通報したメッセージを削除しますか。',
	'잠시 후 이용해 주세요.': 'しばらくしてからお試しください。',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': '受信拒否が完了しました。(運営者IDを除く)\n受信拒否設定から確認しますか？',

	'즐겨찾기': 'お気に入り',
	'즐찾': 'お気に入り',
	'방송중': '放送中',
	'방송 시작 전': '放送準備中',
	'BJ 랭킹순': 'BJ ランキング順',
	'방송 중인 즐겨찾기 BJ가 없습니다.': '生放送中のお気に入りBJがいません。',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': '放送開始前のお気に入りBJがいません。',
	'즐겨찾기한 BJ가 없거나': 'お気に入りBJがいないか',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': '生放送中のお気に入りBJがいません。',
	'상세정보': '詳細情報',
	'추천 받지 않기': 'おすすめ非表示',
	'즐겨찾기 삭제': 'お気に入り解除',
	'명 시청중': '人視聴中',

	'BJ랭킹순': 'BJ ランキング順',
	'회원가입': 'アカウント作成',
	'고객센터': 'ヘルプ',
	'최근 본 방송': '視聴履歴',
	'지상파/케이블': '地上波/ケーブル',
	'보이는라디오': '見えるラジオ',
	'스포츠': 'スポーツ',
	'게임': 'ゲーム',
	'애니': 'アニメ',
	'콘텐츠발전소': 'コンテンツ発電所',
	'별풍선': '星風船',
	'장애/버그신고': '不具合通報',
	'공지사항': 'お知らせ',
	'게임센터': 'ゲームセンター',
	'더보기': 'もっとみる',
	'신인': '新人',
	'시청자순': '視聴者順',
	'참여자순': '視聴者順',
	'1일': '1日',
	'1주': '1週',
	'1개월': '1ヶ月',
	'즐겨찾기할만한방송': 'オススメの放送',
	'아프리카TV앱': 'アフリカTVアプリ',
	'바로가기': 'ダウンロード',
	'정보': '情報',
	'댓글': 'コメント',
	'승인': '承認',
	'인기방송': '人気放送',
	'내가 보유한 별풍선': '保有星風船',
	'확인': '確認',
	'취소': 'キャンセル',
	'스티커': 'ステッカー',
	'구매하기': '購入する',
	'직접입력': '直接入力',
	'초콜릿': 'チョコレート',
	'로그인상태유지': 'ログイン状態維持',
	'개': '個',
	'비밀번호': 'パスワード',
	'최근 본 방송 내역 전체 삭제하기': '視聴履歴をすべて削除',
	'전체방송': '全体リスト',
	'모바일게임': 'モバイルゲーム',
	'애니메이션': 'アニメ',
	'먹방/쿡방': 'フードファイト',
	'음악': '音楽',
	'시사/현장': 'ニュース',
	'주식/금융': '株/金融',
	'금융': '金融',
	'생활/정보': 'ライフスタイル',
	'공부방': '学習',
	'보이는쇼핑': 'ショッピング',
	'케이블': 'ケーブル',
	'1:1문의': 'お問い合わせ',
	'엔터테인먼트': 'エンター',
	'창업방': '',
	'VOD': '動画',
	'개인방송국': 'コミュニティ',
	'핫이슈동영상': 'HOT ISSUE',
	'로그아웃': 'ログアウト',
	'방송시작전': '放送開始前',
	'아이디찾기': 'IDをお忘れの方はこちら',
	'방송국보러가기': 'コミュニティへ',
	'연령제한': '年齢制限',
	'청소년유해신고': '青少年有害通報',
	'국내야구': '韓国野球',
	'일본야구': '日本野球',
	'프로농구': 'バスケットボール',
	'프로배구': 'バレーボール',
	'UFC/일반': '格闘機/一般',
	'프로골프': 'ゴルフ',
	'특집VOD': '特集VOD',
	'스포츠특집': 'スポーツ特集',
	'게임특집': 'ゲーム特集',
	'명시청중': '人視聴中',
	'내가 보유한 골드': '保有ゴールド',
	'내가 보유한 초콜릿': '保有チョコレート',
	'아프리카TV': 'アフリカTV',
	'아프리카TV,': 'アフリカTV',
	'랭킹순': 'ランキング順',
	'님이': 'さんが',
	'비밀번호찾기': 'パスワード探し',
	'최근본방송 삭제': '視聴履歴から削除',
	'최근본방송추가': '最近の視聴リストに追加',
	'시청한 방송이 없습니다': '視聴履歴がありません。',
	'지금 바로 아프리카TV의 재미있고': 'いつでも誰でも生放送アフリカTV',
	'다양한 방송을 만나보세요!': 'みんなで楽しむ新しいコミュニティ！',
	'[생]': '[Live]',
	'[재]': '[Replay]',
	'[중계방]': '[サーブ会場]',
	'아이디비번찾기': 'ID/パスワードをお忘れの方はこちら',
	'명시청': '人視聴',
	'레이디스배틀': 'Ladies Battle',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': '登録されていないIDまたはID/パスワードが正しくありません。',
	'아이디/비밀번호찾기': 'ID/パスワードをお忘れの方はこちら',
	'님이빠져들만한방송': 'おすすめ放送',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': 'メンテナンス中です。終了までしばらくお待ちだくさい。',
	'구매': '購入',
	'즐겨찾기추가': 'お気に入り追加',
	'베스트BJ방송': 'ベストBJ放送',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': '視聴履歴を削除しますか？\n(放送別に個別削除するには放送リストの「もっとみる(3本線)」をタップしてください)',
	'자신에게는선물하실수없습니다.': '自分にはプレゼントできません。',
	'현재시청자수': '現在の視聴者数',
	'모바일': 'モバイル',
	'누적 시청자 수': '累積視聴者数',
	'유머/패러디': 'お笑い',
	'베스트BJ': 'ベストBJ',
	'신인BJ': '新人BJ',
	'파트너BJ': 'パートナーBJ',
	'뷰티/패션': 'コスメ',
	'샵프리카': 'ショップリカ',
	'MY쇼핑': 'My ショップ',
	'마인크래프트': 'Minecraft',
	'히어로즈': 'Heroes',
	'메이플스토리2': 'MapleStory',
	'피파온라인3': 'FIFAONLINE3',
	'검은사막': '黒い砂漠',
	'블랙스쿼드': 'ブラックスクワッド',
	'하스스톤': 'Hearthstone',
	'문명': 'シドマイヤーズ シヴィライゼーション ',
	'버블파이터': 'Bubble Fighter',
	'마비노기': 'マビノギ',
	'사이퍼즈': 'Cyphers',
	'메이플스토리': 'Maplestory',
	'테일즈런너': 'TalesRunner',
	'서든어택': 'SuddenAttack',
	'마영전': 'マビノギ',
	'리니지': 'Lineage',
	'스타': 'StarCraft',
	'스타2': 'StarCraft II',
	'아키에이지': 'アーキエイジ',
	'카트라이더': 'カートライダー ',
	'모두의마블': 'モドゥエ マブル',
	'디아블로3': 'Diablo III',
	'월드오브탱크': 'World of Tanks',
	'워크래프트': 'Warcraft',
	'아이온': 'Aion',
	'던파': 'アラド戦記',
	'스페셜포스': 'スペシャルフォース ',
	'고전게임': '古典ゲーム',
	'비디오게임': 'Steamゲーム',
	'기타': 'その他',
	'LOL': 'LOL',
	'BladeNSoul': 'BladeNSoul',
	'WOW': 'WOW',
	'종교': '宗教',
	'요리/건강': '料理/健康',
	'자습': '自習',
	'강좌': '講座',
	'멘토': 'メンター',
	'프로야구': 'プロー野球',
	'농구': 'バスケットボール',
	'골프': 'ゴルフ',
	'국내축구': '韓国サッカー',
	'해외축구': '海外サッカー',
	'스타리그': 'StarLeague',
	'어학': '語学',
	'지식': '知識',
	'취미': '趣味',
	'토크/캠방송': '雑談',
	'나루토': 'ナルト',
	'명탐정코난': '名探偵コナン',
	'교육': '教育',
	'토크/캠방': '雑談',
	'별풍선 &#183; 아이템': '星風船アイテム',
	'별풍선ㆍ아이템': '星風船アイテム',
	'보.라': '見えるラジオ',
	'엔터': 'エンタメ',
	'파트너BJ방송': 'パートナーBJ放送',
	'게임전체': '全てのゲーム',
	'스포츠전체': '全てのスポーツ',
	'보이는라디오전체': '全ての見えるラジオ',
	'엔터전체': '全てのエンタメ',
	'농구/NBA': 'バスケットボール/NBA',
	'시청자가밀어주는방송': '視聴者一押しLIVE',
	'클랜이밀어주는방송': 'クラン一押しLIVE',
	'PC게임': 'PCゲーム',
	'멸망전': '滅亡戦',
	'라이프': 'ライフスタイル',
	'라이프전체': '全体ライフスタイル',
	'더빙/리뷰': 'アフレコ/レビュー',
	'격투기/일반': '格闘技/一般',
	'샵프리카Beta': 'ショップリカBeta',
	'라이브': 'LIVE',
	'배구': 'バリボール',
	'농구/배구': 'バスケット/バリボール',
	'별풍선·아이템': '星風船/アイテム',
	'실시간핫이슈': 'Hot Issue',
	'오버워치': 'OW',
	'리니지LFC': 'Lineage LFC',
	'테라': 'Tera',
	'철권': 'TEKKEN',
	'스파5': 'Street Fighter 5',
	'스팀게임': 'Steamゲーム',
	'뽐': 'FFOM',
	'가우스X아프리카TV': 'Gaus X アフリカTV',
	'유로2016': 'EURO2016',
	'LoL공식리그': 'LoL Leagues',
	'e스포츠': 'e-Sports',
	'세계정복2': 'World Conquest II',
	'클래시로얄': 'Clash Royale',
	'세븐나이츠': 'セブンナイツ',
	'레이븐': 'レイヴン',
	'쿠키런': 'Cookierun',
	'기타게임': 'その他',
	'두산': 'Doosan',
	'한화': 'Hanwa',
	'롯데': 'Lotte',
	'넥센': 'Nexon',
	'삼성': 'Samsang',
	'중립': '中立',
	'채널A': 'Channel A',
	'TV조선': 'TV Chosun',
	'연합뉴스': 'Yonhapnews',
	'몬길': 'タッチモンスター',
	'서든어택2': 'SuddenAttack II',
	'BJ리그': 'BJリーグ',
	'포켓몬GO': 'Pokemon GO',
	'2016BJ대상': '2016 BJ大賞',
	'학습': '学習',
	'애니메이션관': 'アニメ館',
	'콘텐츠제작지원센터': 'コンテンツ支援センター',
	'알려드립니다': 'お知らせ',
	'개인정보처리방침': '個人情報処理方針',
	'개인정보 수정': 'マイ情報修正',
	'로그인 기기관리': 'ログイン端末管理',
	'내 방송국': 'コミュニティ',
	'내 정보': 'マイ情報',
	'본방': 'メイン会場',
	'MY뽐': 'My FFOM',
	'2차비밀번호입력': '2次パスワード入力',
	'2차비밀번호': '2次パスワード',
	'입력내용삭제': '入力内容削除',
	'2차비밀번호찾기': '2次パスワード照会',
	'MOBILE': 'モバイル',
	'나중에설정': '後で設定',
	'닉네임': 'Nickname',
	'고화질로시청': '高画質視聴',
	'멀티방송': 'マルチ放送',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'Kakao Talk',
	'참여중인 중계방': '参加中のサブ会場',
	'멀티방송화면리스트': 'マルチ画面リスト',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': '放送中の商品は[商品を見る]ボダンをクリックして購入できます。',
	'앱에서시청하기': 'アプリで視聴',
	'상품보기': '商品を見る',
	'선물하기': 'プレゼント',
	'전송': '送信',
	'비밀번호 변경': 'パスワード変更',
	'2차 비밀번호 설정': '2次パスワード変更',
	'건강': '健康',
	'이슈방송스케줄': 'Issue 放送スケジュール',
	'PC버전으로보기': 'PCバージョン',
	'LoL챌린저스': 'LoL challengers',
	'오버워치BJ리그': 'OW BJリーグ',
	'격투게임': '格闘ゲーム',
	'별풍선/아이템 정보': '星風船/アイテム情報',

	'VOD가 없습니다.': '動画がありません。',
	'UP순': 'UP順',
	'댓글순': 'コメント順',
	'조회순': '再生順',
	'1주일': '1週間',
	'방송국': 'コミュニティ',
	'인기순' : '人気順',

	'사용 중인 퀵뷰가 없습니다.': '使用中のクイックビューがありません。',
	'새로고침': '更新',
	'사용중': '使用中',
	'무료사용중': '無料使用中',
	'자동결제': '月定額',
	'사용기간이': '有効期間は残り',
	'일': '日',
	'시간': '時間',
    '남았습니다.': 'です。',
	'다음 결제일': '次の決済日',
	'보유 중인 퀵뷰': '保有中のクイックビュー',
	'보유중인 퀵뷰가 없습니다.': '所持中のクイックビューはありません。',
	'보유 중인 퀵뷰 선물권': 'クイックビュー プレゼント券',
	'전체 방송': '全ての放送',
	'아이템효과 닫기': '閉じる',
	'채팅토글': 'チャットON/OFF',
	'앱에서 참여하기': 'アプリで視聴',
	'아프리카TV 앱에서 참여하기': 'アフリカTVアプリで視聴',
	'이모티콘': '絵文字',

	'광고 Skip, 본방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': '広告Skip、本放送入場、タイムマシン機能など<br>様々な特典の<strong>クイックビューアイテム</strong>をお使いください。',
	'아프리카TV 상품권 등록 하세요!': 'アフリカTV 商品券を登録 してください!',
	'즐겨찾기에 추가됐습니다.': 'お気に入りに追加しました。',
	'바람의나라': '',
	'닉네임 변경': 'ニックネーム変更',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': 'ニックネームは全角6文字(半角12字)で設定できます。',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': 'ニックネーム変更後、24時間は変更できません。',
	'닉네임 적용하기': 'ニックネーム適用',
	'중복확인': '重複確認',
	'내정보': 'マイ情報',
	'퀵뷰 정보': 'クイックビュー情報',
	'아이템': 'アイテム',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': 'お気に入りに登録するにはログインが必要です。ログインしますか。',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': 'このBJをお気に入りから削除しますか？',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': 'このBJをお気に入りから削除しますか？',

	'사용하기': '使用する',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': 'ブラインド状態で放送から退場した場合、自動的に強制退場扱いとなり、放送に再入場できません。',
	'고객센터 문의하기': '咨询客服',
	'비밀번호 설정 방송': 'パスワード設定放送',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'プライベート放送です。<br>アフリカTVアプリから視聴できます。',
	'연령제한 방송': '年齢制限放送',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'この放送は成人(+18)視聴者に限定されています。<br>アフリカTVアプリから視聴してください。',
	'연령제한 / 비밀번호 설정 방송': '年齢制限 /プライベート放送',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '18歳未満視聴不可またはパスワード’設定放送です<br>アフリカTVアプリをご利用ください。',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': '現在、放送中ではありません。<br>アフリカTVアプリで他の放送を見てみよう！',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 참여가 가능합니다.': 'Android2.3.7以下のバージョンでは<br>アフリカTVアプリからのみ視聴できます。',

	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': 'BJさんが放送を一時停止しました。',
	'다시 시작될 때까지 잠시만 기다려주세요.': 'しばらくお待ちください。',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': '強制退場および利用停止に関するお問い合わせは ヘルプ ＞ お問い合わせ をご利用ください。',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]<br>를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 <br>있습니다.': '保有中のクイックビューアイテムと有効期間を確認後、 [使用する]<br>をクリックするとクイックビューアイテムを<br>ご利用できます。',
	'즐겨찾기가 삭제되었습니다.': 'お気に入りリストから削除しました。',
	'알람': 'アラーム',
	'유료': '有料',
	'알람 닫기': 'アラーム閉じる',
	'알림 예약': 'アラーム予約',
	'알림 취소': 'アラームキャンセル',
	'현재 진행 중인 방송이 없습니다.': '生放送中のBJがいません。',
	'곧,  BJ들의 재미있고 다양한 방송이': '様々なBJたちの放送が',
	'시작됩니다.': '始まります。',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>生放送中のBJがいません。</strong><br>様々なBJたちの放送が<br>始まります。',
	'방송이 시작되면': '放送が開始したら',
	'알려 드릴까요?': 'お知らせしますか。',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>アラームを予約済み<br>です。',

	'운영자 강제퇴장 안내': '運営者強制退場案内',
	'강제퇴장': '強制退場',
	'회차': '回次',
	'(채팅금지 24시간)': '(チャット禁止24時間)',
	'강제퇴장 및 3일 이용정지': '強制退場および3日間利用停止',
	'강제퇴장 및 7일 이용정지': '強制退場および7日間利用停止',
	'강제퇴장 되어 방송을 보실 수 없습니다.': '強制退場になり視聴ができません。',
	'앱을 설치하시면 구매가 가능합니다.': 'アプリから購入できます。',
	'[안내]': '[案内]',
	'방송에 입장하였습니다.': '放送に入場しました。',
	'채널 퇴장하셨습니다.': '会場から退場しました。',
	'로그아웃 하시겠습니까?': 'ログアウトしますか。',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'アラーム予約をするにはログインが必要です。\nログインしますか。',
	'알림 예약이 완료되었습니다.': 'アラーム予約を完了しました。',
	'알림 예약이 취소되었습니다.': 'アラーム予約をキャンセルしました。',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': '18歳未満視聴不可またはパスワード設定放送です。アフリカTVアプリをご利用ください。\n\nアフリカTVアプリから視聴しますか？',
	'유효기간': '有効期間',
	'등록되었습니다.': '登録しました。',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': 'クイックビュー利用中には登録できません。',
	'오류가 발생하여 이용하실수 없습니다.': 'エラーのため利用できません。',

	'검색결과': '検索結果',
	'검색결과가 없습니다.': '検索結果がありません。',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': 'ニックネーム変更を完了しました。24時間経過後に再変更が可能です。',
	'사용할 수 없는 닉네임입니다.': '使用できないニックネームです。',
	'닉네임 중복 확인을 해주세요.': 'ニックネームの重複確認をしてください。',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': 'チャット入力に対応していないブラウザです。アフリカTVアプリをご利用ください。',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'プライベート放送です。アフリカTVアプリから視聴できます。',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'この放送は成人(+18)視聴者に限定されています。アフリカTVアプリから視聴してください。',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': '本放送は著作権の関係上、韓国国内のみ視聴可能です。',
	'강제퇴장 되어 방송을 볼 수 없습니다.': '強制退場になり視聴ができません。',
	'로그인 되었습니다.': 'ログインしました。',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': 'エラーが発生しました。リトライしますか？',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': 'エラーが発生しました。\n\nリトライしますか？',
	'채팅 메세지를 입력하세요.': 'チャットボックスでチャットを楽しみましょう！',
	'1개 이상 선물이 가능합니다.': '保有星風船が足りません。',
	'보유하신 별풍선이 부족합니다.': '保有星風船が足りません。',
	'보유하신 골드가 부족합니다.': '保有ゴールドが足りません。。',
	'보유하신 초콜릿이 부족합니다.': '保有チョコレートが足りません。',
	'선물하기 서버 문제로 이용하실 수 없습니다.': 'エラーが発生しました。',
	'로그인 하셔야 선물 하실 수 있습니다.': 'ログインが必要です。',

	'즐겨찾기 바로가기': 'お気に入り',
	'멀티방송 화면리스트': 'マルチ放送画面リスト',
	'현재 유저수': '現在の視聴者数',
	'중계방': 'サブ会場',
	'PC버전으로 보기': 'PCバージョンで見る',
	'앱에서 보기': 'アプリから見る',
	'명': '人',

	'본방입장': 'メイン会場',
	'방송보기': '視聴する',
	'잠시 쉬어갑니다.': 'メンテナンス中…',
	'점검시간': '実施時間',
	'점검영향': '影響範囲',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': 'サービス安定化のため<br>以下の時間帯にてメンテナンス作業を実施いたします。',
	'이용에 불편을 드려 죄송합니다.': ' ご利用にご不便をおかけいたしますが<br>ご理解ご協力のほどよろしくお願いいたします。',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': 'ご理解ご協力のほどよろしくお願いいたします。',

	'채팅안보기 해제': 'チャット非表示解除',
	'채팅안보기': 'チャット非表示',

	'즐겨찾기에 추가됐습니다.': 'お気に入りに追加しました。',
	'[아프리카TV 안내]': '[アフリカTV案内]',
	'운영자 안내': '運営案内',
	'운영자': '運営',
	'BJ': 'BJ',
	'매니저': 'マネージャー',
	'클린아티': 'クリーンアティ',
	'%s에 의해 강제퇴장 되었습니다.': '%s により強制退場になりました。',
	'%s님은 %s에 의해 채팅금지 %s회가 되셨습니다.': '%s さんは%sによりチャット禁止( %s回目)になりました。',

	'로그인 서버오류가 발생하였습니다.': 'ログインエラーが発生しました。',
	'지원하지 않는 OS버전입니다.': 'ご利用いただけないOSバージョンです。',
	'아프리카TV 앱으로 시청해주세요.': 'アフリカTVアプリをご利用ください。',
	'버퍼링 중': 'ローディング中',
	'애청자': '愛聴者',
	'팬클럽': 'ファンクラブ',
	'통합검색': '総合検索',
	'생방송': '生放送',
	'정확도순': '正確度順',
	'방송시간순': '放送時間順',
	'누적참여자순': '累積視聴者数',
	'최신방송순': '最新放送順',
	'적용 되었습니다.': '適用しました。',
	'채팅창을 얼렸습니다.': 'チャットを禁止しました。',
	'채팅 참여 등급이 변경되었습니다.': 'チャットに参加できるレベルが変更されました。',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': 'BJとマネージャーのみチャットに参加できます。',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': 'BJとマネージャー、ファンクラブのみチャットに参加できます。',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': 'BJとマネージャー、サポーターのみチャットに参加できます。',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': 'BJとマネージャー、ファンクラブ、サポーターのみチャットに参加できます。',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': 'チャット禁止を解除しました。チャットに参加できます。',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "入力した\'%s\'は検索制限された禁止語が含まれており、ご利用いただけません。",
	'검색시스템에 문제가 발생했습니다.': '検索システムエラーです。',
	'검색어를 입력하세요.': '検索語を入力してください。',
	'|중계방송|': '|サブ会場|',
	'LIVE/VOD 광고 NO' : 'LIVE及びVOD広告NO',

	'BJ가 방송을 종료하였습니다.': 'BJが放送を終了しました。',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": "\'%s\'さんの放送からUP受信を拒否しますか。",
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': 'メイン会場への入場にはクイックビューアイテムが必要です。クイックビューを購入をしますか？',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': 'この放送はモバイルライブカメラ放送です。BJが放送を一時停止する際に放送が停止したり黒い画面が表示されたりする場合がございます。(一時停止が解除されると放送が再開します)',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'UPはアフリカTVアプリから利用できます。\n\nアプリから視聴しますか？',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '高画質視聴にはアフリカTVアプリをご利用ください。\n\nアプリから視聴しますか？',
	' 이상~': '以上～',


	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%s さんは%sによりチャット禁止になりました。30秒間、チャットができません。',
	'%s님이 %s에 의해 채팅금지 %s회가 되셨습니다.': '%s さんは%sによりチャット禁止( %s回目)になりました。',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%s さんは%sによりチャット禁止になりました。60秒間、チャットができません。',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리되었습니다.': '%s さんは%sチャット禁止回数の累積によりブラインドになりました。2分間、映像の視聴とチャット入力ができません。',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%s さんは%sチャット禁止回数累積によりブラインドになりました。ブラインド状態では映像とチャットを見ることができません。また、ブラインド状態で放送から退場した場合、自動的に強制退場扱いとなり放送に再入場することはできません。',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제퇴장 처리되었습니다.': '%s(%s) さんがブラインド状態で放送から退場し強制退場になりました。',
	'%s(%s)님이 강제퇴장 처리되었습니다.': '%s(%s) さんが強制退場になりました。',
	'%s(%s)님이 강제퇴장 취소 처리되었습니다.': '%s(%s) さんの強制退場が解除されました。',
    '%s님이 별풍선 %s개를 선물했습니다!': '%sさんが星風船 %s個をプレゼントしました!',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': '%sさんがサブ会場から星風船 %s個をプレゼントしました!',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': '%sさんが過去の動画から星風船 %s個をプレゼントしました!',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%s様がビデオ風船 %s個をプレゼントしました。',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': 'サブ会場で %s様がビデオ風船 %s個をプレゼントしました！',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%s さんのファンクラブNoはNo %sです。',
	'%s님이 스티커 %s개를 선물했습니다!': '%s さんがステッカーを%s個プレゼントしました！',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%s さんのサポーターNoはNo %sです。',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': '%s さんがサブ会場からステッカーを%s個プレゼントしました！',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%s さんがチョコレートを%s個プレゼントしました！',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': '%s さんがサプ会場からチョコレートを%s個プレゼントしました！',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s さんが%sへクイックビュー%s日券をプレゼントしました！',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': 'モバイルWebからは視聴できません。\nPCサイトはアフリカTVアプリをご利用ください。',


	'운영자에 의해 강제퇴장 되었습니다.': '運営により強制退場になりました。',
	'운영자에 의해': '運営により',
	'되었습니다': 'になりました。',

	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                                                                                        \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                                                                                        \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': '今視聴している放送は、青少年有害媒体物としての情報通信網利用促進及び情報保護などに関する法律や青少年保護法の規定により、\n\
                                                                                                                                                                                                                        \n\満18歳未満の青少年が利用できないように、放送情報が変更されました。\n\
                                                                                                                                                                                                                        \ n\視聴を続けるには、アプリを利用して本人認証が必要です。',

	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': 'アフリカTTVで%sを一緒に見ませんか？',
	'채팅입력이 지원되지 않는 브라우져입니다.': 'チャット入力に対応していないブラウザです。',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>アフリカTV <strong>商品券を登録</strong>してください！</span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': 'ご利用できないOSバージョンです。<br><span>アフリカTVアプリ</span>をご利用ください。',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': '%sさんのチャットを<br>表示します。',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': '%sさんのチャットを<br>非表示します。',


	'랭킹 홈': 'ランキングホーム',
	'최고 유저 평균': '最高視聴者数',
	'애청자 증가수': '愛聴者増加数',
	'UP 수': 'UP数',
	'펫방': 'ペット',
	'더빙/라디오': 'ダビング/ラジオ',
	'VOD BJ': '動画BJ',
	'VOD FAN': '動画FAN',
	'누적 애청자 수': '累積愛聴者数',
	'누적 팬클럽 수': '累積ファンクラブ数',
	'랭킹 소통방 바로가기': 'ランキングコミュニティへ',
	'BJ랭킹 산정 방식 안내': 'BJランキング算定方式案内',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': 'BJランキングは先週の月曜日～日曜日までの放送活動を下記の比率で算出し選定しています。',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '(愛聴者の場合、累積愛聴者数として反映されます)',
	'VOD BJ 랭킹 산정 방식 안내': '動画BJランキング算定方式案内',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': '動画BJランキングは過去の放送動画とコミュニティへアップロードした動画による順位です。',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': '先週月曜日～日曜日までの動画ヒット増加数合計',
	'VOD FAN 랭킹 산정 방식 안내': '動画FANランキング算定方式案内',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': '動画FANランキングは好きなBJの放送を編集しアップロードしたファンの動画順位です。',

	'변경': '変更',
	'아프리카TV 앱 실행하기': 'アプリで視聴',
	'최고 유저': '最高視聴者数',
	'BJ랭킹': 'BJランキング',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': '<span>アフリカTV</span>アプリを利用すると<br>チャットと共に<span>高画質でご参加</span>いただけます。',
	'고화질로 시청하기': '高画質で視聴',

	'년 전': '年前',
	'개월 전': 'ヶ月前',
	'주 전': '週前',
	'일 전': '日前',
	'시간 전': '時間前',
	'분 전': '分前',
	'초 전': '秒前',
	'보낸 시각': '送信日時',
	'공유하기': 'SNSシェア',
	'검색': '検索',
	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '<strong>アプリダウンロード</strong><span></span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': '「後で見る」のご利用にはログインが必要です。\nログインしますか？',
	'BJ 랭킹': '',
	'즐겨찾기 전체': 'すべて',
	'최신등록순': '最新登録順',
	'참여인원순': '視聴者数順',
	'닉네임순': 'ニックネーム順',
	'아이디순': 'ID順',
	'최신방송순': '最新放送順',
	'LIVE 시청': 'LIVEを見る',
	'목록 상단 고정 완료': 'ピン留め完了',
	'라이브&amp;VOD': 'LIVE&amp;動画',
	'방송국 개설일': 'コミュニティ開設日',
	'최근방송': '直近放送',
	'LIVE 시청하기': 'LIVEを見る',
	'방송국가기': 'コミュニティへ',
	'목록 상단 고정': 'リストピン留め',
	'즐겨찾기한 BJ가 없습니다.': 'お気に入りしたBJがいません。',
	'목록 상단 고정 해제': 'ピン留めを外す',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': '現在放送中のお気に入りしたBJがいません。',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': 'お気に入りBJの動画がありません。　',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': '気に入ったBJを見つけてお気に入り 登録しましょうお気に入り登録したBJのLIVEや動画をいつでも楽しむことができます。',
	'상단고정 완료되었습니다': 'ピン留め完了',
	'상단고정 해제 되었습니다': '解除置頂設置',
	'지원하지 않는 메뉴입니다.': 'サービス提供準備中です。',
	'수신거부 해제되었습니다.' : '受信拒否が解除されました。',
	'필터': 'フィルター',
	'정렬기준': 'ソート基準',
	'구분': '区分',
	'날짜': '日付',
	'취소': 'キャンセル',
	'적용': '適用',
	'생방송': '放送中',
	'업로드VOD': '一般動画',
	'유저클립': 'ユーザークリップ',
	'하이라이트': 'ハイライト',
	'구독전용': '購読専用',
	'1년': '1年',
	'고화질순': '高画質順',
	'고해상도순': '高画質順',
	'애청자순': '高解像度順',
	'팬클럽순': 'ファンクラブ順',
	'전체알림': 'すべてを通知',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': '全てのBJの通知を受け取ります。',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': '全てのBJの通知を受け取りません。',
	'%s님에게 알림을 받습니다.': '%sさんから通知を受け取ります。',
	'%s님에게 알림을 받지 않습니다.': '%sさんから通知を受け取りません。',

	'방송제목 변경이력': '放送タイトル変更履歴',
	'조회순': '再生人数順',
	'관련된 VOD': '関連動画',
	'검색어와 연관된 추천 영상입니다.': '検索語と連携したおすすめ動画です。',
	'게시글': '投稿',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': '直近1か月間の投稿検索結果がありません。<br> 検索期間を1か月から1年に変更すると<br> さらに多くの投稿が表示されます。',
	'조회순': '閲覧数',

	'최근 검색어': '最近',
	'실시간 인기 검색어': 'リアルタイム人気',
	'개인화 추천검색어': 'パーソナル',
	'자동저장 <span>끄기</span>': '自動保存<span>オフ</span>',
	'자동저장 <span>켜기</span>': '自動保存<span>オン</span>',
	'최근 검색어가 없습니다.': '最近の検索語がありません。',
	'최근 검색어 자동저장이 꺼져 있습니다.': '最近の検索語の自動保存がオフ状態です。',
	'자동저장 켜기': '自動保存オン',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': 'ログインをすると<strong>パーソナル検索語サービス</strong>を<br>ご利用いただけます。',
	'최근검색어를\n모두 삭제하시겠습니까?': '最近の検索語を\n全て削除しますか？',
	'최근검색어 자동저장을 사용합니다.': '最近の検索語の自動保存を使用します。',
	'최근검색어 자동저장 사용을 중지합니다.': '最近の検索語の自動保存を使用しません。',
	'개인화 추천 검색어가 없습니다.': 'パーソナル検索語がありません。',
	'검색한 검색결과입니다.': 'で検索した検索結果です。',
	'검색결과 보기': '検索結果を見る',

	'나중에 보기' : '後で見る',
	'즐겨찾기한 BJ의 VOD' : 'お気に入りしたBJの動画',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : 'お気に入りしたBJの動画がありません。',
	'즐겨찾기한 BJ의 방송이 없습니다.' : 'お気に入りしたBJの配信がありません。',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : '動画視聴履歴をすべて削除しますか？',
	'재생한 VOD를 모두 삭제하시겠습니까?' : '再生した動画を全て削除しますか？',
	'UP 한 VOD가 없습니다.' : 'UPした動画がありません。',
	'즐찾 BJ': 'お気に入りBJ',
	'구독 BJ': '購読BJ',
	'구독중인 BJ' : '購読中BJ',
	'VOD 홈' : '動画ホーム',
	'인기 VOD' : '人気動画',
	'인기' : '人気動画',
	'핫이슈 VOD' : 'HOTISSUE動画',
	'TV클립' : 'TVクリップ',
	'인기 하이라이트' : '人気ハイライト',
	'드라마 인기클립' : 'ドラマ人気クリップ',
	'예능 인기클립' : 'バラエティ人気クリップ',
	'스포츠 인기클립' : 'スポーツ人気クリップ',
	'아프리카TV X TV클립' : 'アフリカTV X TVクリップ',
	'예능' : 'バラエティ',
	'교양' : '教養',
	'시사' : '時事',
	'드라마' : 'ドラマ',
	'스포츠' : 'スポーツ',
	'패션/뷰티' : 'ファッション/美容',
	'어린이' : '子供向け',
	'스페셜' : 'スペシャル',
	'디지털' : 'デジタル',
	'뮤직' : 'ミュージック',
	'라디오' : 'ラジオ',
	'키즈' : 'キッズ',
	'연예' : '恋愛',
	'시사보도' : '時事報道',
	'라이프' : 'ライフ',
	'홈페이지' : 'ホームページ',
	'주목할만한 클립' : '注目のクリップ',
	'인기 클립' : '人気クリップ',
	'클립 전체' : 'クリップ 全体',
	'프로그램 전체' : 'プログラム 全体',
	'TV클립 목록이 없습니다.' : 'TVクリップリストがありません。',
	'인기 프로그램' : '人気プログラム',

	'방송국 가기': 'コミュニティへ',
	'기록' : 'ライブラリ',
	'카테고리' : 'カテゴリー',
	'추천받지 않기' : 'おすすめを受け取らない',
	'이어서 보기' : '続けて見る',
	'최근 본 VOD' : '最近見た動画',
	'UP 한 VOD' : 'UPした動画',
	'나중에 보기 삭제' : '後で見る削除',
	'최근 본 VOD 삭제' : '最近見た動画削除',
	'UP 취소' : 'UPキャンセル',
	'나중에 보기 목록에서 삭제 되었습니다.' : '後で見るリストから削除されました。',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : '最近見た動画リストから削除されました。',
	'선택하신 VOD UP이 취소 되었습니다.' : '選択した動画 UPがキャンセルされました。',
	'추천 받지 않기가 반영되었습니다.' : 'ホームから非表示にします。',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : '既にお気に入りに追加されています。',
	'이미 나중에 보기 등록된 VOD 입니다.' : '既に後で見るに登録されている動画です。',
	'전체 삭제' : '全体削除',
	'나중에 보기 한 VOD가 없습니다.' : '後で見るに選択した動画がありません。',
	'최근 본 VOD가 없습니다.' : '最近視聴したVODがありません。',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : '動画ホームに移動すると<br>おすすめ動画が表示されます。',
	'VOD 홈으로 이동' : '動画ホームに移動',
	'구독' : '購読',
	'카테고리 선택' : 'カテゴリー選択',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : 'ログイン後ライブラリの<br>動画を見ることができます。',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : 'ログイン後お気に入りした<br>BJの動画を見ることができます。',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : '後で見るリストを全て削除しますか？',
	'UP이 취소되었습니다.' : '選択した動画 UPがキャンセルされました。',
	'추천 받지 않기에 등록 되었습니다.' : 'ホームから非表示にします。',
	'즐겨찾기에서 삭제됐습니다.': 'お気に入りリストから削除しました。',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?' : 'お気に入りに登録するにはログインが必要です。\nログインしますか。',
	'팬' : 'ファン',
	'구독한 BJ가 없습니다.' : '購読したBJがいません。',
	'구독한 BJ의 VOD가 없습니다.' : '購読したBJのVODがありません。',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : '%sさんをお気に入りから削除しますか?？',

	'추천' : 'おすすめ',
	'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : '過去動画おすすめメニューに移動すると<br>おすすめ動画を受け取ることができます。',
	'VOD 추천받기' : '過去動画おすすめ受信',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : 'ログインしてください。\nログインしますか。',
	'주소가 복사되었습니다.' : 'URLがコピーされました。',
	'본인은 즐겨찾기 할 수 없습니다.' : 'ご自分のIDは登録できません。',

	'TAG_VOD_LIST_COUNT' : '<span class="count">総<em class="point" text="@count"></em>個</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">さんがよく視聴するBJの動画</span>',
	'TAG_VOD_RECENT_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">さんがよく見るBJ</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name" text="@nickname"></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span>さんの動画 <span text="@count"></span>個</span>',

	'알림': '通知',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : '受信した通知がありません。<br><a ng-click="setting()"><span>通知設定</span></a>から受信する通知を選択してください。',
	'모두 읽음' : '全て既読にする',
	'모두 삭제' : '全て削除',
	'읽은 알림 삭제' : '既読済通知削除',
	'알림 설정' : '通知設定',
	'설정' : '設定',
	'%d분 전' : '%d分前',
	'%d시간 전' : '%d時間前',
	'삭제' : '削除',
	'알림이 삭제되었습니다.' : '通知が削除されました。',
	'숨기기' : '隠す',
	'알림 콘텐츠 설정' : '通知コンテンツ設定',
	'초기화' : '初期化',
	'저장' : '保存',
	'푸시 설정' : 'プッシュ通知設定',
	'기록 설정' : '記録設定',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : 'プッシュ通知は<a ng-click="setup_link()"><span>AfreecaTVアプリ-設定-通知設定</span></a>から変更できます。',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : '通知設定を初期化します。 \n変更したすべての設定詳細を初期の状態に戻します。 初期化した通知設定は保存時、適応されます。',
	'설정이 저장되었습니다.' : '設定が保存されました。',

	'발송취소' : '発送取消',
	'해당 쪽지를 발송 취소하시겠습니까?' : 'DMの送信を取り消しますか？',
	'보관한 보낸 쪽지가 없습니다.' : '保存中の送信DMがありません。',
	'보관한 받은 쪽지가 없습니다.' : '保存中の受信DMがありません。',
	'읽지 않음' : '未読',
	'방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : 'コミュニティで, %sさんがAD風船%s個をプレゼントしました.',
	'유효기간 만료순' : '有効期限満了順',

	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : 'このDMを通報しますか？\n（通報したDMは迷惑Boxに移動します。）',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : 'このDMの迷惑設定を解除しますか？\n（迷惑設定を解除したDMは受信Boxに移動します。）',
	'자동스팸분류':'自動迷惑DM分類',
	'스팸해제' : '迷惑解除',
	'스팸신고': '迷惑通報',
	'보관함':'マイDM',
	'스팸함':'迷惑Box',
	'스팸 쪽지가 없습니다.':'迷惑DMがありません。',
	'스팸 쪽지':'迷惑Box DM',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.':'迷惑BoxのDMは読んでから90日後自動削除します。',

	'"%s" 검색 결과' : '"%s" 検索結果',
	'검색된 쪽지가 없습니다.' : '検索したDMがありません。',
	'검색어를 입력해 주세요.' : '検索後を入力してください。',
	'내용' : '内容',
	'검색' : '検索',
	'더 알아보기' : '詳細を見る',
	'재생목록':'プレイリスト',
	'재생목록에 담기':'プレイリストに保存',
	'재생목록 퍼가기':'再生リストコピー',
	'생성한 재생목록':'作成したプレイリスト',
	'재생목록 보관함':'再生リストライブラリ',
	'재생목록이 없습니다.':'プレイリストがありません。',
	'새 재생목록 만들기':'新しいプレイリスト作成',
	'추가':'追加',
	'새 재생목록':'新しいプレイリスト',
	'확인' : '確認',
	'VOD 추가순': '動画追加順',
	'오래된순': '過去動画順',
	'수정하기': '修正する',
	'삭제하기': '削除する',
	'업데이트' : 'アップデート',
	'공개': '公開',
	'비공개' : '非公開',
	'전체보기' : '全体を見る',
	'목록 공개': 'リスト公開',
	'목록 비공개': 'リスト非公開',
	'목록 퍼가기 허용': 'リストのコピー許可',
	'목록 퍼가기 비허용': 'リストのコピー非許可',
	'제목을 입력해주세요.': 'タイトルを入力してください。',
	'VOD가 없습니다.' : '動画がありません。',
	'재생목록을 삭제하시겠습니까?' : 'プレイリストを削除しますか？',
    '저화질' : '低画質',
    '일반화질' : '一般画質',
	'원본화질' : '原本画質',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : 'アプリで映像を動かし360°を楽しんでください!',
    '유료 광고 포함' : '有料広告を含む',
    '참여를 위해<br>인코딩 중입니다.': '視聴のためエンコード中です。',
    '잠깐만 기다려주세요!': '少々お待ちください!',
    '<span><em>초</em> 남았습니다.</span>': '<span><em>초</em> 残り- です。</span>',
	'보유 중인 선물 받은 구독권' : '保有中のプレゼントされた購読券',
    '보유 중인 구독 선물권' : '保有中の購読プレゼント券',
    '아이템 정보' : 'アイテム情報',
    '광고 Skip, Full방 입장, 고화질 방송참여가 가능한<br>퀵뷰아이템을 만나보세요.' : 'クイックビューを使用すると<br>広告スキップ、Full放送入場、高画質視聴ができます。',
    '선물/후원 정보' : 'プレゼント・支援情報',
	'교환' : '交換',

	'사용하러 바로가기':'今すぐ使用する',
	'구독 구매하기':'購読購入する',
	'구매하러 바로가기':'今すぐ購入する',
    '연속선물' : '自動+1',
    '연속선물이란?' : '自動+1?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : '自動+1 ONの状態で送るとプレゼント欄が維持され、<br>送る個数はプレゼント後に+1個で自動入力されます。',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : 'クイックビュー+を利用中には登録できません。',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : 'クイックビューを利用中には登録できません。',
	'플러스로 전환' : 'Plusに切り替え',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : '有料クイックビュー、有料クイックビュープレゼントのみ\nクイックビュー+に切り替え可能です。',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : 'クイックビューを使用します。\nクイックビュー+に切り替えのチャンスです。\nクイックビュー+に切り替えますか？',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "無料クイックビューを使用しました。<br>無料クイックビューはクイックビュー+に切り替えられません。",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "無料クイックビュー+を使用しました。",
	"퀵뷰가 사용되었습니다.": "クイックビューを使用しました。",
	"퀵뷰 플러스가 사용되었습니다.": "クイックビュー+を使用しました。",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : '<strong>クイックビュー+に</strong><br>切り替えのチャンス！',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : 'クイックビュー利用券を クイックビュー+に切り替えることができます。',
	'이용기간' : '利用期間',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : '切り替え時点から利用期間が再算定され 利用期間が延長されます。',
	'퀵뷰 플러스의 더 커진 혜택' : 'クイックビュー+のレベルアップした特典',
	'VOD 광고 NO!' : '画像広告NO!',
	'FULL방 입장' : 'Full放送入場',
	'방송 광고 NO!' : '放送広告NO!',
	'멀티뷰' : 'マルチビュー',
	'방송 미리보기(모바일)' : '放送プレビュー(モバイル)',
	'퀵뷰 사용 기간 별 전환 금액' : 'クイックビュー使用期間別切り替え金額',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : '使用期間による切り替え金額支払い時、利用期間が再算定され<strong>利用期間が延長される特典を提供します！</strong>',
	'사용' : '使用',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'플러스로 전환하기' : 'Plusに 切り替える',
	'기존 퀵뷰로 사용하기' : '既存クイックビューを 使用する',
	'※부가세 미포함' : '※税抜',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : '使用期間 %s日に<span>%s</span> 決済時<strong>クイックビュー+%s日券</strong> に切り替えることができます。',
	'더 저렴하고! 기간도 길게!<br>(최초 1회 제공)' : 'より安く！期間も長く！<br>(最低1回提供)',
	'선물 ・ 후원 정보' : 'プレゼント・支援情報',
	'무료체험중' : '無料体験中',
	'(iOS자동결제)' : '(iOS自動決済)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Apple長期購読(自動決済)にて\n現在のデバイスでは\n決済手段変更ができません。\niPhone(またはiPad)の「App Store>Apple ID>購読」をご利用ください。',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Apple長期購読(自動決済)にて\n現在のデバイスでは\n解約できません。iPhone(またはiPad)の「App Store>Apple ID>購読」をご利用ください。',
	'해지 신청중' : '解約申請中',
	'2개의 방송을 동시에! 모바일 멀티뷰' : '2つの放送を同時に！モバイルマルチビュー',

	'구매한 스티커' : '購入したステッカー',
	'선물받은 스티커' : 'もらったステッカー',
	'스티커 아이템' : 'ステッカーアイテム',
	'스티커 교환하기' : 'ステッカー交換する',
	'아이템 정보' : 'アイテム情報',

	'내가 보유한 스티커' : '保有ステッカー',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : '100個以上のシグネチャー風船プレゼントでクリップを残す',
	'소개글' : '紹介文',
	'공지' : 'お知らせ',
	'나이' : '年齢',
	'경력/수상' : '経歴/受賞',
	'수상 메달' : '受賞メダル',
	'앗 혹시 찾으시는 분이...' : "もしかして探している方が...",
	'지금 이 콘텐츠에 주목!' : '今このコンテンツに注目！',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '\'{{ query }}\' <span class="normal">を検索したユーザーのためのおすすめコンテンツ</span>',
	'누적 참여자' : '累積視聴者',
	'방송 시간' : '放送時間',

	'쉬는시간 후 방송이 이어집니다.' : 'ブレイクタイム後、放送が続きます。',
	'방송에 후원되는 광고입니다.' : '放送に後援される広告です。',
	
	'URL 복사' : 'リンク コピー',
	'구독하러 바로가기':'今すぐ購入する',

	'빠른 답장' : '早い返信',
	'기본 메시지로 저장' : '基本メッセージに保存',
	'보내기' : '返信',
	'후원 쪽지' : '支援DM',

	'선물 메세지' : 'プレゼントメッセージ',
	'목소리 선택' : 'ボイス選択',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : '通知音ボイスを使用できるよう<br>BJにステッカーをプレゼント',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : 'BJの放送設定に電子秘書がないか、別途で個数設定を している場合、通知音再生ができません。',

	'영상 광고 없는 VOD 재생!' : '動画広告なしで動画再生！',
	'캐치 광고 제외' : 'キャッチ広告除外',
	'방송 입장 영상광고 NO!' : '放送入場動画広告NO',
	'배너형, 로딩광고, LIVE 중간광고 제외' : 'バナー型、ローディング広告、LIVE中間広告除外',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':"青少年ユーザーを保護するため<br><strong>19歳以上のユーザーのみ</strong>ご利用いただけるよう<br>年齢制限に設定されたコンテンツです。<br>アフリカTVアプリからのみ参加できます。",
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':"青少年ユーザーを保護するため19歳以上のユーザーのみご利用いただけるよう\n年齢制限に設定されたコンテンツです。\n\nアフリカTVアプリから参加しますか？",
	'대결미션' : 'バトルミッション', 
	'대결미션이란?': 'バトルミッションとは？',
	'대결단계에서만 후원 가능합니다.': 'バトル段階でのみ支援可能ですl.',
	'보유 별풍선이 즉시 차감됩니다.': '保有星風船はすぐに差し引かれます。',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': 'BJがチームを作り、バトルコンテンツを行い支援された星風船をバトル結果に応じて配布される機能です。',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': '現在放送BJに星風船支援するのではなく、バトルミッションに星風船を寄付します。',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': '星風船を支援すると該当放送のファンクラブ加入及び熱狂的ファン順位に集計されます。',
	
	'도전미션' : '挑戦ミッション',
	'보유 별풍선 :' : '保有星風船 :',
	'도전미션이란?' : '挑戦ミッションとは？',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : 'BJに挑戦ミッションを要請し<br />星風船で賞金支援をしてください！',
	'새로운 미션 요청' : '新しいリクエスト',
	'후원할 별풍선' : '支援する星風船',
	'후원하기' : '支援する',
	'미션 내용을 입력해 주세요. (최대 30자)' : 'ミッション内容を入力してください。(最大30字)',
	'미션 제한 시간' : 'ミッション制限時間',
	'도전미션 일': '日',
	'도전미션 시간': '時間',
	'도전미션 분' : '分',
	'미션 상금' : 'ミッション賞金',
	'미션 요청' : 'リクエスト',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : 'BJがミッションを進め、成功の可否によってユーザーが募金した賞金を獲得する支援サービスです。',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : '挑戦ミッションはユーザーがミッションを要請したり、BJが直接ミッションを登録することができます。',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : 'ミッション結果はBJが決定し、ミッション要請したユーザーは「成功」のみ決定できます。',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : '挑戦ミッションはBJとユーザーの信頼を前提に行われるため、いかなる状況でもミッション結果を覆すことはできません。',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : 'ミッション制限時間が経過した場合、自動的にミッション失敗となります。',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : 'ミッション成功時、BJに募金された賞金が渡されます。',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : 'ミッションキャンセル、ミッション失敗時の賞金はユーザーに返されます。',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : 'BJはミッション制限時間を1回延長することができます。 (最大10日)',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : '待機/進行中のミッションに賞金を追加支援することができます。',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : 'ファンクラブ加入/熱血ファン集計はミッション成功後、賞金がBJに渡される時点で行われます。',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : 'ユーザーは要請したミッションが最近1日間累積5回キャンセルされた場合、24時間すべてのBJにミッション要請ができません。 慎重にミッションを要請してください。',
	
	'아이템은 여기서 구매하세요!' : 'アイテムはここで購入してください！',
	'1:1 문의 바로가기' : 'お問い合わせ',
	'내 애드벌룬 확인하고 선물하기' : 'マイAD風船を確認してプレゼント',

	'규칙에 동의합니다!' : 'ルールに同意します！',
	
	'내 별풍선' : 'マイ星風船',
	'내 별풍선ㆍ아이템' : 'マイ星風船・アイテム',
	'드롭스 이벤트 진행 중' : 'Dropsイベント進行中',

	'퀵뷰': 'クイックビュー',
	'퀵뷰 플러스': 'クイックビュー+',
	'만료': '満了',
	'예약': '予約',
	'사용 예약': '予約',
	'예약 취소': 'キャンセル',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': '%s使用予約をしました。\n\n現在使用中のクイックビューアイテムの使用期間が満了となる場合、予約したアイテムを引き続き使用します。',
	'%s 사용을 예약 취소하시겠습니까?': '%sの使用予約をキャンセルしますか？',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': '%s使用予約をキャンセルしました。\n\n使用予約を求める場合には「使用予約」を押してください。',

	'공식방송국':	'コミュニティ',
	'공식 방송국':'コミュニティ',
	'관련BJ':	'関連BJ',
	'관련 BJ':	'関連BJ',
	'이벤트':	'イベント',
	'시리즈게임':	'シリーズ',
	'시리즈 게임':	'シリーズ',
	'전체':	'全体',
	'명':	'名',
	'관련BJ<br>더보기':	'管理BJ<br>もっと見る',
	'LIVE':	'LIVE',
	'다시보기':	'再度みる',
	'경기없음':	'中継なし',
	'경기취소':	'キャンセル',
	'경기예정':	'競技予定',
	'결과<br>보기':	'結果を<br>見る',
	'참여인원순':	'参加人数',
	'시청인원순':	'視聴人数',
	'최근방송':	'最新放送',
	'LIVE 참여하기':	'LIVE参加',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'最近放送したBJがいません。<br>該当カテゴリで放送を初めてください！',
	'예정된 경기가 없습니다.':	'予定された競技がありません。',
	'경기종료':	'競技終了',
	'최신방송순':	'最新放送順',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '\'{{ query }}\' <span class="normal">を検索したユーザーのためのおすすめBJ</span>',

	// 모웹 메인 footer에서 사용
	'이용약관': '利用規約',
	'개인정보 처리방침': '個人情報処理方針',
	'대표이사': '代表取締役',
	'사업자번호': '事業者番号',
	'사업자 정보 확인': '事業者情報確認',
	'호스팅 제공자': 'ホスティングプロバイダ',
	'주식회사 숲': 'ⓒ SOOP Corp.',
	'주소': '住所',
	'경기도 성남시 분당구 판교로 228번길 15 판교세븐밴처밸리 1단지 2동 주식회사 숲(삼평동)': '京畿道城南市盆唐区板橋路228番キル15板橋セブンベンチャーバレー1団地2棟ⓒ SOOP Corp.(三坪洞)',
	'대표 번호': '代表番号',
	'이메일': 'Eメール',
	'사업자 정보': '事業者情報',
	'통신판매번호': '通信販売業届出番号',
	'FAX': 'ファックス',
	'\'{{ query }}\' 검색한 유저를 위한 추천 Catch' : '「\'{{ query }}\'」を検索したユーザーのためのおすすめCatch',
	'계정연결': 'アカウント連携',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'본방 입장': '本放送入場',
	'남자': '男性',
	'여자': '女性',
	'캐릭터': 'キャラクター',

	'종료된 방송입니다.': '終了した放送です。',
	'지금 바로 아프리카TV의 다양한 생방송과<br>재미있는 VOD를 만나보세요!': '今すぐアフリカTVの多様なLIVEと楽しい動画をご視聴ください！',
	'VOD 보러가기': '動画を見に行く'
};
