IteminfoInsertAddrController.$inject = ["$scope", "$state", "$stateParams", "$window", "ItemService"];

function IteminfoInsertAddrController($scope, $state, $stateParams, $window, ItemService) {
	$scope.check_list = [];
	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function() {
		$window.history.back();
	};

	// 주소찾기
	$scope.openZip = function() {
		var link = MEMBER_AFREECATV + "/app/zipcode.php?nDeviceType=1";
		afreeca.link.inapp(link);
		return;
	};

	// 주소삭제
	$scope.delAddress = function() {
		if (
			$("[name=address]").val() == "" &&
			$("[name=roadAddress]").val() == "" &&
			$("[name=detailAddress]").val() == "" &&
			$("[name=postNo]").val() == ""
		) {
			alert("삭제할 주소가 없습니다.");
			return;
		}
		if (confirm("등록된 주소를 삭제하시겠습니까?")) {
			$scope.postNo = "";
			$scope.address = "";
			$scope.roadAddress = "";
			$scope.detailAddress = "";
			$("[name=postNo]").val("");
			$("[name=address]").val("");
			$("[name=roadAddress]").val("");
			$("[name=detailAddress]").val("");
		}
	};

	// 입력완료
	$scope.formSubmit = function() {
		if (!validCheck()) {
			return;
		}

		// 약관 체크
		var agreeInfo = $("#ag1").is(":checked") === true ? 1 : 0;
		var agreeInfo2 = $("#ag2").is(":checked") === true ? 1 : 0;
		var agreeInfo3 = $("#ag3").is(":checked") === true ? 1 : 0;
		if (agreeInfo !== 1 || agreeInfo2 !== 1 || agreeInfo3 !== 1) {
			alert("약관 내역에 동의해 주세요.");
			return;
		}

		var sendData = {};

		var ticketIdx = $.trim($("[name=ticketIdx]").val());
		var itemCount = $.trim($("[name=itemCount]").val());
		var userId = $.trim($("[name=userId]").val());
		var userName = $.trim($("[name=userName]").val());
		var mobile = $.trim($("[name=mobile]").val());
		var postNo = $.trim($("[name=postNo]").val());
		var address = $.trim($("[name=address]").val());
		var roadAddress = $.trim($("[name=roadAddress]").val());
		var detailAddress = $.trim($("[name=detailAddress]").val());
		var memo = $.trim($("[name=memo]").val());

		sendData = {
			szWork: "INSERT_ADDRESS",
			ticketIdx: ticketIdx,
			itemCount: itemCount,
			userId: userId,
			userName: userName,
			mobile: mobile,
			postNo: postNo,
			address: address,
			roadAddress: roadAddress,
			detailAddress: detailAddress,
			memo: memo,
		};

		if (!confirm("입력하신 배송지로 주문하시겠습니까?")) {
			return;
		}

		ItemService.fanLoveItem(sendData)
			.then(function(response) {
				var result = response.result;

				if (response.result > 0) {
					// 배송 완료 페이지
					$state.go("iteminfo.confirmaddr", {
						order_num: response.order_num,
					});
				} else {
					alert("일시적인 오류로 인해 배송지 입력을 실패하였습니다.\r잠시 후 다시 시도해주시기 바랍니다. : " + response.result);
				}
			})
			.catch(function(error) {
				$scope.disabled = true;
				$scope.$digest();
			});
	};

	// 유효성 체크
	function validCheck() {
		var bIsValid = true;
		$(".addr_area p").hide();

		//이름 체크
		var userName = $.trim($("[name=userName]").val());
		if (userName == "" || userName.length < 1 || !chkFormat(userName, "name")) {
			$scope.userName = "";
			$("[name=userName]").val("");
			$("#userNameError").show();
			bIsValid = false;
		}

		//휴대폰 번호 체크
		var mobile = $.trim($("[name=mobile]").val());
		if (mobile == "" || mobile.length < 1 || !chkMobile(mobile)) {
			$scope.mobile = "";
			$("[name=mobile]").val("");
			$("#mobileError").show();
			bIsValid = false;
		}

		//주소 체크
		var postNo = $.trim($("[name=postNo]").val());
		var address = $.trim($("[name=address]").val());
		var roadAddress = $.trim($("[name=roadAddress]").val());
		var detailAddress = $.trim($("[name=detailAddress]").val());

		if (postNo == "" || postNo.length < 1 || ((address == "" || address.length < 1) && (roadAddress == "" || roadAddress.length < 1))) {
			$scope.postNo, $scope.address, ($scope.roadAddress = "");
			$("[name=postNo], [name=address], [name=roadAddress]").val("");
			$("#addrError").show();
			bIsValid = false;
		}
		if (detailAddress == "" || detailAddress.length < 1) {
			$scope.detailAddress = "";
			$("[name=detailAddress]").val("");
			$("#detailError").show();
			bIsValid = false;
		}

		return bIsValid;
	}

	//입력 포맷 체크
	function chkFormat(szText, szType) {
		var szRegData;
		switch (szType) {
			case "name": //이름은 한글,영문만 허용
				szRegData = "[^a-zA-Zᄀ-ᇿ㄰-㆏ꥠ-꥿가-힯ힰ-퟿]";
				szText = szText.replace(/\s/gi, "");
				break;
			case "mobile": //휴대폰번호는 숫자만 허용
				szRegData = "[^0-9]";
				break;
		}

		var oReg = new RegExp(szRegData, "gi");
		if (oReg.test(szText)) {
			return false;
		}
		return true;
	}

	//휴대폰 번호 체크
	function chkMobile(szPhone) {
		if (!chkFormat(szPhone, "mobile")) {
			return false;
		}

		//숫자 외에 제거
		szPhone = szPhone.replace(/[^0-9]/gi, "");

		var aszToken = [];
		var matches =
			szPhone.length == 10
				? szPhone.match(/^([0-9]{3})?([0-9]{3})?([0-9]{4})$/)
				: szPhone.match(/^([0-9]{3})?([0-9]{4})?([0-9]{4})$/);
		if (matches) aszToken = [matches[1], matches[2], matches[3]];

		//번호 자리수 체크
		if (aszToken.length != 3) {
			return false;
		}

		if (aszToken[0] == "" || aszToken[1] == "" || aszToken[2] == "") {
			return false;
		}

		//국번 체크
		var aszValidFirstNum = new Array("010", "011", "016", "017", "018", "019");
		var bIsValidFirstNum = false;
		for (var nIdx = 0; nIdx < aszValidFirstNum.length; nIdx++) {
			if (aszToken[0] == aszValidFirstNum[nIdx]) {
				bIsValidFirstNum = true;
				break;
			}
		}
		if (!bIsValidFirstNum) {
			return false;
		}

		//뒷 번호 체크
		var szTmp = aszToken[1] + aszToken[2];
		if (szTmp.length != 7 && szTmp.length != 8) {
			return false;
		}

		return true;
	}

	//입력 길이 체크
	function chkLength(oTextArea, nLimit, checkType) {
		var text = $(oTextArea).val();
		var chars;
		var passed = true;

		if (checkType == "bytes") {
			chars = text.getBytes();
			if (chars > nLimit) {
				$(oTextArea).val(text.cut(nLimit));
				chars = text.getBytes();
				passed = false;
			}
			$(oTextArea)
				.parent()
				.find(".max")
				.text(chars + "/" + nLimit + "bytes");
		} else {
			chars = $(oTextArea).val().length;
			if (chars > nLimit) {
				$(oTextArea).val(text.substring(0, nLimit));
				chars = $(oTextArea).val().length;
				passed = false;
			}
			$(oTextArea)
				.parent()
				.find(".max")
				.text(chars + "/" + nLimit + "자");
		}
		return passed;
	}

	function setAddressCallback(data) {
		data = jQuery.parseJSON(data);
		setAddress(data.post_no, data.road_addr, data.addr, data.detail_addr);
	}

	window.setAddress = function(zipno, road_address, address, detail_address) {
		$("[name=postNo]")
			.val(zipno)
			.trigger("change");
		$("[name=address]")
			.val(address)
			.trigger("change");
		$("[name=roadAddress]")
			.val(road_address)
			.trigger("change");
		$("[name=detailAddress]")
			.val(detail_address)
			.trigger("change");
	};

	/**
	 * 배송지 정보 불러오기 (배송지 입력 페이지)
	 */
	ItemService.stickerItemInsertAddr({
		ticket_type: $stateParams.ticket_type,
		order_type: $stateParams.order_type,
	}).then(function(response) {
		$(window).scrollTop(0);
		$scope.userName = response.userName;
		$scope.postNo = response.postNo;
		$scope.address = response.address;
		$scope.roadAddress = response.roadAddress;
		$scope.detailAddress = response.detailAddress;
		$scope.mobile = response.mobile;
		$scope.userid = response.userid;
		$scope.ticket = response.data;
		$scope.check_list = response.check_list;
		$scope.$digest();
		if ($stateParams.order_type == "ALL") {
			if ($scope.check_list.length == 0) {
				alert("배송 가능한 보유 상품이 없습니다.");
				$state.go("iteminfo.mysticker");
			}
		} else {
			if ($scope.check_list.length > 0) {
				alert("배송 가능한 보유 상품이 없습니다.");
				$state.go("iteminfo.mysticker");
			}
		}
	});

	$scope.$on("$viewContentLoaded", function(event) {
		/***
		 * 되도록이면 Angularjs 문법을 이용해 작성바랍니다.
		 */
		document.domain = DOMAIN;
		// '이름' 길이 제한
		$("#userName").on("keyup keypress focusin focusout", function(e) {
			if (!chkLength(this, 12, "bytes")) {
				e.preventDefault();
				return false;
			}
		});

		// '배송 메모' 길이 제한
		$("#memo").on("keyup keypress focusin focusout", function(e) {
			if (!chkLength(this, 50, "length")) {
				e.preventDefault();
				return false;
			}
		});

		// 약관 동의
		$("#checkall").click(function() {
			if ($("#checkall").prop("checked")) {
				$("input[name=adr]").prop("checked", true);
			} else {
				$("input[name=adr]").prop("checked", false);
			}
		});

		$("#ag1, #ag2, #ag3").on("change", function(e) {
			if ($("#ag1").is(":checked") && $("#ag2").is(":checked") && $("#ag3").is(":checked")) {
				$("#checkall").prop("checked", true);
			} else {
				$("#checkall").prop("checked", false);
			}
		});

		// 약관 레이어
		$(".agree_layer").hide();
		$(".agree_area").on("click", ".link_v", function() {
			$(".agree_layer").show();
			return false;
		});
		$(".agree_layer").on("click", ".confirm, .close", function() {
			$(".agree_layer").hide();
			return false;
		});

		$(".agree_layer").on("show", function() {
			$(window).on("navigate", function(event, data) {
				var direction = data.state.direction;
				if (direction == "back") {
					alert(1111);
				}
			});
			//$(".agree_layer").hide();
			//return false;
		});
	});
}
module.exports = IteminfoInsertAddrController;
