var shifty = require('shifty');

function SearchSwiper() {
    // detect supported CSS property
    function detectTransformProperty() {
        var transformProperty = 'transform',
            safariPropertyHack = 'webkitTransform';
        if (typeof document.body.style[transformProperty] !== 'undefined') {

            ['webkit', 'moz', 'o', 'ms'].every(function (prefix) {
                var e = '-' + prefix + '-transform';
                if (typeof document.body.style[e] !== 'undefined') {
                    transformProperty = e;
                    return false;
                }
                return true;
            });
        } else if (typeof document.body.style[safariPropertyHack] !== 'undefined') {
            transformProperty = '-webkit-transform';
        } else {
            transformProperty = undefined;
        }
        return transformProperty;
    }

    //Detect support of translate3d
    function detect3dSupport() {
        var el = document.createElement('p'),
            has3d,
            transforms = {
                'webkitTransform': '-webkit-transform',
                'msTransform': '-ms-transform',
                'transform': 'transform'
            };

        // Add it to the body to get the computed style
        document.body.insertBefore(el, null);
        for (var t in transforms) {
            if (el.style[t] !== undefined) {
                el.style[t] = 'translate3d(1px,1px,1px)';
                has3d = window.getComputedStyle(el).getPropertyValue(transforms[t]);
            }
        }
        document.body.removeChild(el);
        return (has3d !== undefined && has3d.length > 0 && has3d !== "none");
    }

    function CarouselSlideStyle(offset) {
        var style = {};

        if (!detectTransformProperty()) {
            style['margin-left'] = offset + '%';
        } else {
            style[detectTransformProperty()] = detect3dSupport() ? 'translate3d(' + offset + 'px, 0, 0)' : 'translate3d(' + offset + 'px, 0)';
        }
        return style;
    }

    /**
     * 현재위치
     * @param {type} obj
     * @returns {Number}
     */
    function getTransformX(obj) {
        var transformMatrix = obj.css("-webkit-transform") ||
            obj.css("-moz-transform") ||
            obj.css("-ms-transform") ||
            obj.css("-o-transform") ||
            obj.css("transform");
        var matrix = transformMatrix.replace(/translate3d|px|\(|\)/gi, '').split(',');
        return parseInt(matrix[0]) | 0;//translate x
    }
    return {
        restrict: 'A',
        scope: {
            paginationClass: "@", //페이징 클래스명
        },
        link: function (scope, element, $attrs) {
            var elX, pressed = false, startX = 0;
            var containerWidth = $(element).parent().parent().width();
            var _index = 0;
            var _count = 0;
            var touchStartTime;
            var clickTimer;
            var clickIsValid;
            var domNode;

            // DOM을 다그린후 배너를 셋팅하기위함. (재 생성시 문제가 있어서.)
            var nCnt = 0;
            var timer = setInterval(function () {
                nCnt++;
                if (nCnt > 1) {
                    clearInterval(timer);
                    reSize();
                }
            }, 100);

            // DOM 변화 이벤트가 없을때만 이벤트 발생 시킴.
            if (!domNode) {
                domNode = $('body').on('DOMNodeInserted', element, function (e) {
                    nCnt = 0;
                });
            }

            //사이즈 변경시
            $(window, 'body').on("resize", function (event) {
                reSize();
            });

            function reSize() {
                angular.forEach($(element).children(), function (value) {
                    $(value).width(containerWidth);
                });
                $(element).width($(element).children().length * containerWidth);
                $(element).parent().width(containerWidth);
                goToDirect(_index);
            }

            /**
             * 데이터 로드되고나서
             */
            scope.$watch('$viewContentLoaded', function () {
                setTimeout(function () {
                    if ($(element).children().length <= 1) {
                        angular.forEach($(element).children(), function (value) {
                            $(value).width(containerWidth)
                        });
                        $(element).width(containerWidth);
                    } else {
                        var first = $($(element).children()[0]).clone();
                        var last = $($(element).children()[$(element).children().length]).clone();
                        $(element).prepend(last);
                        $(element).append(first);

                        angular.forEach($(element).children(), function (value) {
                            $(value).width(containerWidth)
                        });
                        
                        _count = $(element).children().length;
                        $(element).width(_count * containerWidth);
                        $(element).parent().width(containerWidth);

                        var widthPercentage = 1 / (_count-1) * 100 + '%';
                        $(element).parent().parent().find('.pagination span').width(widthPercentage)
                        goToDirect(0);

                        //스와이프 이벤트
                        $(element).bind('mousedown touchstart', swipeStart);
                        $(element).bind('mousemove touchmove', swipeMove);
                        $(element).bind('mouseup touchend', swipeEnd);
                        $(element).bind('mouseout mouseleave', swipeLeave);
                    }
                }, 1);
            });

            function swipeStart(event) {
                var coords;
                pressed = true;
                touchStartTime = Date.now();
                startX = event.pageX || event.originalEvent.changedTouches[0].pageX;
                clickIsValid = true;
                clickTimer = setTimeout(function () {
                    clickIsValid = false;
                }, 500);
                event.stopPropagation();
                event.preventDefault();
                return true;
            }

            function swipeMove(event) {
                if (pressed) {
                    var objectX = getTransformX(element);
                    var offset = (event.pageX || event.originalEvent.changedTouches[0].pageX) - startX;
                    event.stopPropagation();
                    event.preventDefault();
                    var swipeX = (_index * -1 * containerWidth);
                    updateSlidesPosition(swipeX + offset);
                }
                return true;
            }

            function swipeEnd(event) {
                var moveX = (event.pageX || event.originalEvent.changedTouches[0].pageX) - startX;
                var increase;
                pressed = false;
                clickIsValid = true;

                clearTimeout(clickTimer);

                //클릭
                if (clickIsValid && Math.abs(moveX) < 10) {
                    $(event.target).trigger("click");
                    clickIsValid = true;
                    goToSlide(_index);
                    return false;
                }
                // Time diff
                var touchEndTime = Date.now();
                var timeDiff = touchEndTime - touchStartTime;
                if (timeDiff < 500) {	//짧은 Swpie를 했을 경우(0.3초 내 Swpie)
                    increase = (moveX > 0 ? 1 : -1) * -1;
                    goToSlide(_index + increase);
                } else {
                    var increase = Math.round(moveX / containerWidth) * -1;
                    goToSlide(_index + increase);
                }
                event.stopPropagation();
                event.preventDefault();
                return true;
            }

            function swipeLeave(veent) {
                pressed = false;
                clickIsValid = true;
                goToSlide(_index);
                return true;
            }

            /**
             * 좌표 변경
             * @param {type} offset
             * @returns {undefined}
             */
            function updateSlidesPosition(offset) {
                angular.forEach(CarouselSlideStyle(offset), function (value, key) {
                    $(element).css(key, value);
                });
            }

            /**
             * 효과없이 바로 이동
             * @param {type} index
             * @returns {undefined}
             */
            function goToDirect(index) {
                _index = index;
                updateSlidesPosition(-1 * index * containerWidth);
            }

            function goToSlide(index) {
                var idx = Math.min(Math.max(0, index), (_count - 1));
                var fromX = getTransformX(element);
                var toX = -1 * idx * containerWidth;
                var duration = Math.abs(fromX - toX) / 2
                var tweenable = new shifty.Tweenable();
                tweenable.tween({
                    from: {
                        'x': fromX
                    },
                    to: {
                        'x': toX
                    },
                    duration: duration,
                    step: function (state) {
                        updateSlidesPosition(state.x);
                    },
                    finish: function () {
                    }
                }).then(function () {
                    //롤링기능
                    _index = idx;
                    if (_index == 0) {
                        _index = _count - 1;
                        goToDirect(_index);
                    } else if (_index == (_count - 1)) {
                        _index = 0;
                        goToDirect(_index);
                    }
                });

                var divLength = $(element).children().length - 1
                var widthPercentage = 0;
                if (index <= 0 || index > divLength - 1) {
                    widthPercentage = 1 / divLength * 100 + '%'
                    $(element).parent().parent().find('.pagination span').width(widthPercentage)
                    $(element).parent().parent().find('.page_cnt em').eq(0).text('1');
                } else {
                    widthPercentage = (index + 1) / divLength * 100 + '%'
                    $(element).parent().parent().find('.pagination span').width(widthPercentage)
                    $(element).parent().parent().find('.page_cnt em').eq(0).text(index+1);
                }
            }
        }
    };
}

module.exports = SearchSwiper;