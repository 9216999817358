ToastMessage.$inject = [];

function ToastMessage(){
	var timeout = null;	
	var nAnimationT = 100;
	var nDelay = 3000;

	var footerElement = document.getElementById("footer_area");

	var toastElement =  document.createElement("div");
	toastElement.className = "toast_message";
	toastElement.style.display = "none";

	var toastTextElement = document.createElement("p");
	toastTextElement.className = "text";
	toastElement.appendChild(toastTextElement);

	return function(message, color){		
		toastTextElement.textContent = message;
		toastTextElement.innerHTML = toastTextElement.innerHTML.replace(/\n/g, '<br />');
		
		// MANTIS:0018965
		// if(color){
		// 	toastTextElement.parentElement.classList.add(color);
		// } else {
		// 	toastTextElement.parentElement.classList = ["toast_message"];
		// }
	
		footerElement.insertBefore(toastElement, footerElement.firstChild);
		$(toastElement).fadeIn(nAnimationT);
		
		clearTimeout(timeout);
		timeout = setTimeout(function(){
			$(toastElement).fadeOut(nAnimationT, function(){
				toastElement.remove();
			});
		}, nDelay)
	}
}
module.exports = ToastMessage;