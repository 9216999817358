

GiftService.$inject = ['$http'];
function GiftService($http) {

        var BALLOON_GIFT = ITEM_M_AFREECATV + '/api/starballoon/a/gift';
        var STICKER_GIFT = ITEM_M_AFREECATV + '/api/sticker/a/gift';
        var CHOCOLET_GIFT = ITEM_M_AFREECATV + '/api/chocolate/a/gift';

        this.gift = function (data) {

                var url = BALLOON_GIFT;
                switch (data.type) {
                        case 'starballoon':
                                url = BALLOON_GIFT;
                                break;
                        case 'sticker':
                                url = STICKER_GIFT;
                                break;
                        case 'chocolate':
                                url = CHOCOLET_GIFT;
                                break;
                }
                console.log(22);
                return new Promise(function (resolve, reject) {
                        $http.post(url, $.param(data)).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });


        }

        this.getCategory = function () {
                return new Promise(function (resolve, reject) {
                        $http.post(STATIC_M_AFREECATV + '/json/application/category/live.json').then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
}


module.exports = GiftService;
