MyTokenStatusController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState', '$filter']

function MyTokenStatusController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState, $filter) {

    // 기본 조회날자 세팅 (3개월<->오늘자)
    var nLimitMonth = 3;
    var oDate = new Date()
    $scope.endDate = getDateStr(oDate);
    var nMonth = oDate.getMonth()
    oDate.setMonth(nMonth - nLimitMonth)
    $scope.startDate = getDateStr(oDate);
    $scope.tokenHistory = [];
    $scope.nextPageNo = 1;
    $scope.totalCount = 0;
    $scope.perPageLimit = 20;
    $scope.hasMore = false;
    $scope.bPageLoading = true;
    $scope.pageNo = 1;
    $scope.startDateModel = new Date($scope.startDate);
    $scope.endDateModel = new Date($scope.endDate);
    $scope.defaultStartDate = $scope.startDate;
    $scope.defaultEndDate = $scope.endDate;
    $scope.callType = 'token';
     
    // 히스토리 내역 조회
    $scope.history = function (nPageNo) {
        $scope.bPageLoading = true;
        $scope.hasMore = false;
        $scope.pageNo = nPageNo;

        // 조회값 가능 범위를 벗어난 경우 (기본값으로 변경)
        if ($scope.startDate < $scope.defaultStartDate) {
            $scope.startDate = $scope.defaultStartDate;
        }

        if ($scope.endDate > $scope.defaultEndDate) {
            $scope.endDate = $scope.defaultEndDate;
        }

        var nRandNo = '';
        if (!$rootScope.cacheFlag) {
            randNo = new Date().getTime();
        }

        MypointService.history(LoginManager.getUserId(),$scope.startDate,$scope.endDate,$scope.callType,nPageNo,nRandNo)
        .then(function (response) {

            if (nPageNo == 1) {
                $scope.tokenHistory = response.DATA;
            } else {
                $.merge($scope.tokenHistory, response.DATA);
            }

            $scope.totalCount = response.TOTAL_COUNT;
            $scope.bPageLoading = false;

            // 전체 페이지 수
            var nTotalPageCount = Math.ceil($scope.totalCount/$scope.perPageLimit);

            // 추가 페이지 여부
            if (nPageNo < nTotalPageCount) {
                $scope.hasMore = true;
                $scope.nextPageNo = nPageNo+1;
            }

            $scope.$digest();

        }).catch(function (response) {
            $scope.bPageLoading = false;
            $scope.$digest();
        });
    }

    // 페이지 더보기
    Util.lastItem(function () {
        console.log('Util.lastItem', $scope.bPageLoading, $scope.hasMore)
        if ($scope.bPageLoading == false && $scope.hasMore) {
            $scope.amount();
            $scope.history($scope.nextPageNo);
        }
    });

    // 검색날자 변환
    function getDateStr(date) {
        var nMonth = date.getMonth() + 1;
        var nDay = date.getDate();
        if (nMonth < 10) {
            nMonth = "0" + nMonth;
        }
        if (nDay < 10) {
            nDay = "0" + nDay;
        }

        return (date.getFullYear() + '-' + nMonth + '-' + nDay)
    }

    // 내역조회
    $scope.goSearch = function () {
        $scope.startDate = $("#oStartPicker").val();
        $scope.endDate = $("#oEndPicker").val();

        if ($scope.startDate > $scope.endDate) {
            alert('조회 범위가 잘못되었습니다. 선택한 날짜를 확인해주세요.');
            return false;
        } else if ($scope.startDate < $scope.defaultStartDate) {
            alert('최근 3개월 이내 기록만 조회 가능합니다.');
            return false;
        }

        $scope.amount();
        $scope.history(1);
    }

    // 내역 날자형식 변환
    $scope.setDateConvert = function (date) {
        date = date == null ? date = '' : date.substr(0,16).replace(/-/g,".");

        return date;
    }
    
   if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $scope.history(1);
            } else {
                if (afreeca.link.isWebview()) {
                    location.href = SERVICE_APP_SCHEME + "login/requireLogin";
                } else {
                    toastr.error("로그인이 되어 있지 않습니다.");
                    setTimeout(function () {
                        $state.go("home");
                    }, 500);
                }
            }
        });
        LoginManager.check();
    } else {
        $scope.history(1);
    }
}

module.exports = MyTokenStatusController;
