

VideoListService.$inject = ['$http', "Util"];
function VideoListService($http, Util) {
        // 베스트 동영상 리스트
        this.getBestVideo = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/a/best', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // 핫이슈 동영상 || TOP30
        this.getVideoSection = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/section/a/items2', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // 스페셜 동영상 리스트
        this.getVideoSpecial = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/special/a/list', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // 카테고리별 리스트
        this.getCategoryList = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(SCH_AFREECATV + '/api.php', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // 카테고리 리스트
        this.getCategory = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.get(API_M_AFREECATV + '/application/video/a/vodRealCategory', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        
        // VOD리스트 공통
        this.getVodList = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(STBBS_AFREECATV + '/api/get_vod_list.php', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        
        // 신규 VOD리스트 공통
        this.getVodControllList = function(data){
                return new Promise(function (resolve, reject) {
                        $http.post(STBBS_AFREECATV + '/api/vod_list_controller.php', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // VOD 액션 공통
        this.setVodList = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(STBBS_AFREECATV + '/api/set_vod_list.php', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // UP취소
        this.deleteUp = function (data) {
                
                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/recommend/a/recommendcancel', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
        // 광고 조회.
        this.getAdvertisement = function (data) {
                return new Promise(function (resolve, reject) {
                        $http.get(Util.printf(PA_AFREECATV + '/RealMedia/ads/adstream_sx.ads/mobile.%s.web/list@Top3?%s', data.os, $.param(data || '')), '').then(function (response) {
                                if(response.data.Result == 1){
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                }
                        }).catch(function (error) {
                                reject(error);
                        }) 
                }); 
        }
        // 인기VOD
        this.getPopularList = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/station/video/popular/a/list', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }

}


module.exports = VideoListService;