NoteSpamListController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'NoteService', 'HistoryState', 'gtext', '$location']

function NoteSpamListController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, NoteService, HistoryState, gtext, $location) {
    $scope.notes= [];
    $scope.isDisabled = false;
    $scope.all = false; // 전체 선택 여부
    $scope.szPage = "";
    $scope.nSearchType = 0; // 검색 타입 1 닉네임 2 아이디 3 내용 
    $scope.searchWord = ''; // 검색 창 초기화
    $scope.szLastSearchWord = ''; // 마지막 검색 키워드
    $scope.searchSummary = ''; // 검색 요약   
    $scope.nListSize = 0; //검색 총 갯수
    $scope.nPageNo = 1; // 페이징 번호
    $scope.bSearch = $stateParams.searchWord !== null ? true : false;
    $rootScope.note_cate = (["0", "3"].indexOf($rootScope.note_cate) == -1) ? '0' : $rootScope.note_cate;

    $scope.checkAll = function () {
        $timeout(function () {
            for (var i = 0; i < $scope.notes.length; i++) {
                $scope.notes[i].checked = $scope.all;
            }
            $scope.$digest();
        });

    }

    function _checkAllSelected() {
        for (var i = 0; i < $scope.notes.length; i++) {
            if (!$scope.notes[i].checked) {
                return false;
            }
        }
        return true;
    }

    $scope.checkOne = function () {
        $timeout(function () {
            $scope.all = _checkAllSelected();
            $scope.$digest();
        });
    }

    function _removeSelectedItems(bAllRemove) {
        $timeout(function () {
            var cnt = $scope.notes.length - 1;
            for (var i = cnt; i >= 0; i--) {
                if ($scope.notes[i].checked) {
                    $scope.notes.splice(i, 1);
                    $scope.nListSize = $scope.nListSize - 1;
                }
            }
            
            if ($scope.nListSize <= 0) {
                $scope.nListSize = 0;                        
            }
            
            //검색 했을 경우만, 리카운트 
            if ($scope.searchWord != '') {
                _searchWordSize();
            }            
        });

        // 15개 이하이면 리로딩
        if ($scope.notes.length < 16 || bAllRemove) {
            $scope.nLastNo = 0;
            $scope.nPageNo = 1;
            $scope.nListSize = 0;
            $scope.notes = [];
            $scope.more();
        }
    }

    function _getSelectedItems() {
        var aDeleteNo = [];
        for (var i = 0; i < $scope.notes.length; i++) {
            if ($scope.notes[i].checked) {
                aDeleteNo.push($scope.notes[i].no);
            }
        }
        return aDeleteNo;
    }

    function _removeItems(no) {
		var cnt = $scope.notes.length - 1;
		for (var i = cnt; i >= 0; i--) {
			if ($scope.notes[i].no == no) {
				$scope.notes.splice(i, 1);
			}
		}
	}
    /**
     * 읽음표시
     * @param {type} no
     * @returns {undefined}
     */
    function _readItems(no) {
        $timeout(function () {
            var cnt = $scope.notes.length - 1;
            for (var i = cnt; i >= 0; i--) {
                if ($scope.notes[i].no == no) {
                    $scope.notes[i].is_read = true;
                }
            }
            $scope.$digest();
        });
    }

    // 리로딩 및 초기화
    function _resetState() {
        $scope.notes = [];
        $scope.nLastNo = 0;
        $scope.search_type = [];
        $scope.search_type['all'] = true;
        $scope.nSearchType = 4; // 검색 타입 1 닉네임 2 아이디 3 내용 
        $scope.searchWord = ''; // 검색 창 초기화
        $scope.szLastSearchWord = '';
        $scope.searchSummary = ''; // 검색 요약
        $scope.nListSize = 0;
        $scope.nPageNo = 1;
        $scope.all = false; // 전체 체크박스 해제
        $scope.$parent.bSearchResult = false;
        $scope.$parent.bSearchShow = false;
        $scope.$parent.searchOnOffSetting();
        $scope.$parent.searchPaddingTop();
        $scope.more();
    }

    function _searchWordSize() {
        var searchSummary = $scope.szLastSearchWord;
        var szNoteSize = $scope.nListSize.numberFormat();
        
        if (searchSummary.length >= 10) {  
            searchSummary = searchSummary.substr(0, 10) + '...';
        }

        searchSummary = '<em>' + Util.getHtmlEntity(searchSummary) + '</em>';
        $scope.searchSummary = gtext("\"%s\" 검색 결과", searchSummary) + ' (<em>' + szNoteSize + '</em>)';
        $scope.$digest(); 
    }

    $scope.makeNickId = function (note) {
        var oReg = new RegExp(RegExp.escape(Util.getHtmlEntity($scope.szLastSearchWord)), 'gi');
        var szUserNick = Util.getHtmlEntity(note.send_nick);
        var szUserId = Util.getHtmlEntity(note.send_id);
        var szResult = '';
        
        // 닉네임, ID로 검색한 경우
        if($scope.szLastSearchWord !== '') {
            if ($scope.nSearchType == 1) {
                szUserNick = szUserNick.replace(oReg, '<b class="hl">$&</b>');
            } else if ($scope.nSearchType == 2) {
                szUserId = szUserId.replace(oReg, '<b class="hl">$&</b>');
            } else if($scope.nSearchType == 4) {
                szUserNick = szUserNick.replace(oReg, '<b class="hl">$&</b>');
                szUserId = szUserId.replace(oReg, '<b class="hl">$&</b>');
            }
        }

        szResult = szUserNick;

        if (!note.bAdmin) {
            szResult += " (" + szUserId + ")";
        }

        if(note.file_list && note.file_list.length > 0) {
            szResult += " <i class=\"img\"></i>";
        }

        return szResult;
    }

    $scope.makeContent = function (note) {
        var oReg = new RegExp(RegExp.escape(Util.getHtmlEntity($scope.szLastSearchWord)), 'gi');
        var szContent = Util.getHtmlEntity(note.content);
        
        szContent = Util.eacapeBackslash(szContent); //' \ 특수문자 앞에 \ 추가로 붙는 부분 제거
        szContent = Util.tagAchange('list', szContent, note.bAdmin , note.note_type, note.nSubType);
        // 내용으로 검색한 경우
        if($scope.szLastSearchWord !== '') {
            if ($scope.nSearchType == 3 || $scope.nSearchType == 4) {
                szContent = szContent.replace(oReg, '<b class="hl">$&</b>');
                szContent = szContent.replace(new RegExp(/(&)(#[\w]{0,3}|[\w]{0,3})(<(b)([^>]*)>)(\w)(<\/b>)/, 'gi'),'$1$2$6'); // htmlentity &amp;, &quot;, &#039;, &lt;, &gt;  등 엔티티 사이에 문자 하이라이트 처리되는 부분 제거
            } 
        }
        return szContent;
    }

    $scope.view = function (note) {
        HistoryState.reset();
        HistoryState.init($scope, 'receive', ['searchWord', 'nSearchType']);
        $state.go('note.spamview', {
            no: note.no
        });
    }

    /**
     * 스팸 해제
     */
    $scope.spamRaise = function () {
        var aRaiseNo = _getSelectedItems();
        if(aRaiseNo.length == 0) {
            alert(gtext('쪽지를 선택해 주세요.'));
            return false;
        }
        if(!confirm(gtext('해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)'))) {
            return;
        }

        NoteService.spamRaise({
            recv_no : aRaiseNo
        }).then(function (response) {
            if(response.result == 1) {
                $scope.isDisabled = false;
                _removeSelectedItems($scope.all);
                $scope.all = false;
                $scope.$digest();
            }

        }).catch(function (response) {
            $scope.isDisabled = false;
            $scope.$digest();
        });
    }

    $scope.delete = function (szBeforeAction) {
        var selectedNotes = _getSelectedItems();
        if (selectedNotes.length == 0) {
            alert(gtext('쪽지를 선택해 주세요.'));
            return false;
        }
        if (szBeforeAction != 'report') {
            if (!confirm(gtext('선택한 쪽지를\n삭제하시겠습니까?'))) {
                return;
            }
            $scope.isDisabled = true;
        }
        NoteService.receviceDelete({
            no: selectedNotes
        }).then(function (response) {
            $scope.isDisabled = false;
            _removeSelectedItems($scope.all);
            $scope.all = false;
            //쪽지가 없고 쪽지가 더있을경우
            if ($scope.notes.length == 0 && $scope.bHasMore) {
                $scope.more();
            }
        }).catch(function (response) {
            $scope.isDisabled = false;
            $scope.$digest();
        });
    }

    $scope.newIcon = function () {
        NoteService.newIcon({ 
            note_type : 'spam',
            note_cate : $rootScope.note_cate
        }).then(function(response){
            $scope.recv_new_icon = response.data.normal_cnt;
            $scope.store_new_icon = response.data.store_cnt;
            $scope.spam_new_icon = response.data.spam_cnt;
            $scope.$digest();
        }).catch(function (response) { });
    }

    $scope.more = function () {
        $scope.szLastSearchWord = $scope.szLastSearchWord.substr(0,50);
        $scope.searchWord = $scope.searchWord.substr(0,50);

        if($scope.bSearch) {
            $scope.$parent.bSearchShow = true;
            $scope.searchWord = $stateParams.searchWord;
            $scope.szLastSearchWord = $stateParams.searchWord;
            $scope.nSearchType = $stateParams.searchType;
            $scope.search_type = [];
            $scope.search_type['id'] = true;
            $scope.$parent.searchOnOffSetting();
            $scope.$parent.searchPaddingTop();
        }

        NoteService.recevice({
            last_no: $scope.nLastNo,
            note_type: 1,
            search_type: $scope.nSearchType,
            search_word: $scope.szLastSearchWord,
            page_no: $scope.nPageNo
        }).then(function(response) {
            if(response.result == 1) {
                if (!response.data.list.length && $scope.szLastSearchWord.length == 0) {
                    $scope.szPage = gtext("스팸 쪽지가 없습니다.");
                } else if (!response.data.list.length && $scope.szLastSearchWord.length !== 0) {
                    $scope.szPage = gtext("검색된 쪽지가 없습니다.");
                }

                $.each(response.data.list, function (idx, oData) {                
                    if ($("#note_" + oData.no).length == 0) {
                         $scope.notes.push(oData);
                     } 
                 }); 
                
                $scope.nLastNo = response.data.last_no;
                $scope.bHasMore= response.data.has_more_list == 'Y' ? true : false;

                // 페이징 설정
                if ($scope.bHasMore) {
                    $scope.nPageNo = response.data.page_no;
                }

                if ($scope.szLastSearchWord != '' && response.data.list.length > 0) {
                    if ($scope.nListSize == 0 ) {
                        $scope.nListSize = response.data.all_cnt;
                    }
                    
                    if ($scope.notes.length > 0) {
                        $scope.$parent.bSearchResult = true;
                    } else {
                        $scope.szPage = gtext("검색 된 쪽지가 없습니다.");
                    }

                    $scope.$parent.searchPaddingTop();
                    _searchWordSize();
                }                
                $scope.$digest();
            }
            
        }).catch(function(response) {
            if(response.xhrStatus == 'timeout' && $scope.szLastSearchWord != '') {
                $scope.szPage = "현재 서비스 이용자가 많아 지연되고 있습니다.<br>잠시 후 다시 이용해 주세요.";
            }
            $scope.$digest();
        });
    }

        /**
     * 검색 창에서 엔터를 누른 경우
     */
    $scope.onKeypress = function ($event) {
        if($event.keyCode == 13) {
            $scope.nSearchType = $scope.nSearchType > 0 ? $scope.nSearchType : 1;
            $scope.changeNoteSearch($scope.nSearchType);
        }
    }

    /**
     * 검색 x 버튼 눌른 경우
     * 검색한 리스트 유지
     */
    $scope.wordDelete = function () {
        $scope.searchWord = '';
    }
    
    /**
     * 검색 창에서 검색을 누른 경우
     * 빈 검색 창에 검색을 누를 때 얼럿 띄우고 검색한 리스트 유지 
     */
    $scope.wordSearch = function () {
        $scope.nSearchType = $scope.nSearchType > 0 ? $scope.nSearchType : 1;
        $scope.changeNoteSearch($scope.nSearchType);
    } 

    /**
     * 닉네임 / 아이디 / 내용 등 검색 타입 창 change 이벤트
     */
    $scope.changeNoteSearch = function (nSearchType) {
        $scope.notes = [];
        $scope.nLastNo = 0;
        $scope.searchWord = $scope.searchWord || '';
        $scope.szLastSearchWord = $scope.searchWord;
        $scope.all = false; // 전체 체크박스 해제
        $scope.bHasMore = false;
        $scope.searchSummary = '';
        $scope.nListSize = 0;
        $scope.nSearchType = nSearchType > 0 ? nSearchType : 1;
        $scope.search_type = [];
        $scope.nPageNo = 1;
        $scope.$parent.bSearchResult = false;
        $scope.$parent.searchOnOffSetting();
        $scope.$parent.searchPaddingTop();
        $scope.bSearch = false;

        switch (nSearchType) {
            case 1:
                $scope.search_type['nick'] = true;
                break;
            case 2:
                $scope.search_type['id'] = true;
                break;
            case 3:
                $scope.search_type['content'] = true;
                break;
            case 4:
                $scope.search_type['all'] = true;
                break;
        } 

        if ($scope.searchWord.length == 0) {
            $scope.szPage = gtext("검색어를 입력해 주세요.");
            return false;
        }

        $scope.more();
    }

    /******************************************
     *      API 호출부
     ******************************************/
    $scope.newIcon();
    $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
        // 리로딩 상태변경
        if (from.url.indexOf('\/spam\/*no') >= 0 && fromParams.no) {
            if (history.state) {
                $scope.searchWord = history.state.searchWord || '';
                $scope.nSearchType = history.state.nSearchType || 1;
            } else {
                $scope.searchWord = '';
                $scope.nSearchType = 1;
            }

            // view -> search list로 이동 슬라이드/뒤로가기
            if ($location.$$url.indexOf('#search') !== -1 && history.state.searchWord !== '') {
                $scope.changeNoteSearch($scope.nSearchType);
            } else {
                // view -> list로 이동
                $state.go('note.spam', {}, { reload: 'note.spam' });
            }
             
            _readItems(fromParams.no);
        } else {
            //읽음표시용
            _resetState();
        }
    });

    //URL 변경    
    $scope.$on("$locationChangeSuccess", function (angularEvent, newUrl, oldUrl, newState, oldState) {
        // search list -> list 이동 앱내 슬라이드 및 atl + ← 처리
       if (oldUrl.split("#")[2] == "search" && newUrl.split("#")[2] !== "search") {
           _resetState();
       } 
   });

    _removeItems($stateParams.deleteNo);
}
module.exports = NoteSpamListController;