


module.exports = function () {
        return function (item, field, reverse) {
                var filtered = [];
                angular.forEach(item, function (value) {
                        filtered.push(value);
                });
                filtered.sort(function (a, b) {
                        return (a[field] > b[field] ? 1 : -1);
                });
                if (reverse)
                        filtered.reverse();
                return filtered;
        }
}