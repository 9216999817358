/**
 * 하위버전 안드로이드 단말에서 ng-click을 사용할경우 
 * 두번 클릭됨  touchstart 로 대치함
 */
function ngContextMenu() {
        return {
                restrict: 'A',
                link: function ($scope, $element, $attrs) {
                        $element.bind('click', function (event) {
                                //나머지 숨긴처리
                                var eThis = $($element).next('.info_layer');

                                $('.info_layer').not(eThis).addClass('hide');

                                //해당 Context Menu 활성화
                                eThis.hasClass('hide') ? eThis.removeClass('hide') : eThis.addClass('hide');

                        });

                        $element.next('.info_layer').bind('click', function () {
                                $(this).addClass('hide');
                        });
                }
        };
}

module.exports = ngContextMenu;