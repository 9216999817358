
var toastr = require('toastr');
emoticonEncode.$inject = ['$filter', '$sce', 'EmoticonService'];
function emoticonEncode($filter, $sce, EmoticonService) {
        return function (input, userOptions) {
                if (input === undefined || input === null) {
                        return;
                }
                if (typeof input === "object") {
                        return input;
                }


                input = EmoticonService.encodeStr(input);

                //이모티콘 3개 이상 제한
                var aEmoticon = input.match(EmoticonService.getEmoticonRegex());
                //console.log(aEmoticon);
                //input = input.replace(oRegExp, '\/NULL\/');
                var bIsShowErrorMsg = false;
                // 이모티콘 임시로 NULL로 변경하고 3개이하이면 이모티콘 / 이상이면 빈값으로 셋팅 

                var count = 0;
                input = input.replace(EmoticonService.getEmoticonRegex(), function (match, text) {
                        return count++ < 3 ? match : '';
                });

                if (count > 3) {
                        toastr.error('이모티콘은 한번에 3개까지만 사용 가능 합니다.');
                }

                return input;
        }
}

module.exports = emoticonEncode;