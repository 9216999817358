KeywordController.$inject = [ '$scope', '$rootScope', '$state', '$stateParams', 'LoginManager', 'KeywordService', 'SearchService', 'Util',
    'AfreecaStorageFactory', 'gtext', '$sce', 'VideoPlaylistService', '$timeout', '$compile']

function KeywordController($scope, $rootScope, $state, $stateParams, LoginManager, KeywordService, SearchService, Util, AfreecaStorageFactory, gtext, $sce, VideoPlaylistService, $timeout, $compile,) {
    
    $('body').removeClass('menu_on'); // menu off
    $('#search-input').focus();

    // 초기 설정
    $scope.keyword = ($rootScope.previousState == 'search') ? $scope.$parent.query : '';
    $scope.pkCnt = 0;
    $scope.hasHint = false;
    $scope.suggestBj = [];
    $scope.suggestContents = {};
    $scope.history = {};
    $scope.realtime = {};
    $scope.recommend = {};
    $scope.page = {
        'history' : 1,
        'realtime' : 1
    }
    
	/**
	 * 로그인 상태 변경 시
	 */
    $scope.$on('$loginChange', function (event, value) {
        if ($rootScope.member) {
            $scope.searchHistory('list', 0, '', $scope.keyword, '').then(function() { return false; });
        }
    });

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		window.history.back();
	}

	/**
	 * 키워드 초기화
	 */
    $scope.setKeyword = function (keyword) {
        $scope.keyword = keyword;
    }
    
	/**
	 * 개인화 추천 검색어 개수 세팅
	 */
    $scope.setPkCnt = function (pkCnt) {
        $scope.pkCnt = pkCnt;
    } 

    /**
	 * 개인화 추천 검색어 항목당 힌트 존재 유무 세팅
	 */
    $scope.setHasHint = function (hintCnt) {
        if(hintCnt > 0){
            $scope.hasHint = true;
        }else{
            $scope.hasHint = false;
        }
    }
    
	/**
	 * 키워드 삭제
	 */
	$scope.deleteKeyword = function () {
		$scope.keyword = '';
        $scope.searchHistory('list', 0, '', $scope.keyword, '');
	};

	/**
	 * 레이어 이벤트
	 */
    $scope.confirmLayer = function(act) {
        if (act == 'show') {
            $('.search_layer').fadeIn('fast');
        } else {
            $('.search_layer , .dim').fadeOut();
        }
    }

	/**
	 * 메뉴 이벤트
	 */
    $scope.contextOption = function () {
        var option = $(document).find('.history-opt');
        if (option.hasClass('hide')) option.removeClass('hide');
        else option.addClass('hide');
    }

	/**
	 * 자동저장 이벤트
	 */
    $scope.contextAutoSave = function() {
        var status = $scope.history.isActive == 1 ? 'off' : 'on';
        $scope.searchHistory('activate', 0, status, '', '');
        $scope.searchHistory('list', 0, '', '', '');
        status = $scope.history.isActive == 1 ? 'false' : 'true';
        $scope.sendClickLog('autosave_change', 'rc', status);

        if ($scope.history.isActive == 0) toastr.success(gtext('최근검색어 자동저장을 사용합니다.'));
        else toastr.warning(gtext('최근검색어 자동저장 사용을 중지합니다.'));
    };

	/**
	 * 최근/실시간 검색어 페이징 ***
	 */
    $scope.setPage = function (section, act, num) {
        if (section == 'history') {
            if (act == 'prev' && $scope.page.history > 1) {
                $scope.page.history--;
            } else if (act == 'next' && $scope.page.history < Math.ceil($scope.history.list.length/4)) {
                $scope.page.history++;
            }
            
            if (num != 0) $scope.page.history = num;
        }
        else {
            if (act == 'prev' && $scope.page.realtime == 2) {
                $scope.page.realtime--;
            } else if (act == 'next' && $scope.page.realtime == 1) {
                $scope.page.realtime++;
            }

            if (num != 0) $scope.page.realtime = num;
        }
    };
    
    $scope.setPagingHtml = function (nowPage, num) {
        var html = '';
        if (nowPage == num) html = '<strong>' + num + '</strong>';
        else html = num.toString();

        return $sce.trustAsHtml(html);
    };

	/**
	 * 키워드 하이라이트
	 */
    $scope.highlightKeyword = function (words) {
        var lowerWords = words.toLowerCase();
        var lowerKeyword = $scope.keyword.toLowerCase();

        var idx = lowerWords.indexOf(lowerKeyword);
        var length = lowerKeyword.length;

        var highlightKeyword = words;
        if (idx > -1) {
            highlightKeyword = words.substr(0, idx) + '<strong>' + words.substr(idx, length) + '</strong>' + words.substr(idx + length);
        }
        return highlightKeyword;
    };

    /*
    * 아래의 케이스일때 하필 썸네일 클릭 시 다른데로 이동시켜달라는 요청이 있어서 이벤트 버블링 방지법
    * <a ng-click="setKeyword(historyKeyword); search('lc')" ng-bind-html="setKeywordHtml('history', historyKeyword)"></a>
    * */
    $scope.stopPropagation = function(event) {
        if (event) {
            event.stopPropagation(); // 이벤트 버블링 차단
        }
    };

	 /**
	  * 일치하는 검색 키워드 ***
	 */
    $scope.setKeywordHtml = function (section, row) {
        var html = '';
        if (section == 'history') {
            html += '<em class="history"></em>';
            html += $scope.highlightKeyword(row);
        } else if (section === 'bj') {
            html += '<span class="profile dynamic-html">';
            html += '<button ng-click="stopPropagation($event); goBjStation(\'' + ( row.user_id ) + '\')">';
            html += '    <img src="' + row.station_logo + '" title="SOOP"  onerror="this.src=\''+ RES_AFREECATV +'/images/common/img_search_profle.jpg\'">';
            html += '</button>';
            html += '</span>';
            html += $scope.highlightKeyword(row.user_nick);

            if(row.medal == true){
                html += '<span class="certify"></span>';
            }

        } else if (section == 'contents') {
            html += '<em class="same"></em>';
            html += $scope.highlightKeyword(row);
        }
        return $sce.trustAsHtml(html);
    };

    $scope.getFormattedViewCount = function (viewCount) {
        if (!viewCount || isNaN(Number(viewCount))) {
            return '0';
        }
        return Number(viewCount).toLocaleString('ko-KR');
    };

    // trustAsHtml에 의해 추가된 html요소에 ng-click같은 angular 기능을 바인딩 하기 위함
    $scope.$watch('suggestBj', function (newValue) {
        if (newValue && Array.isArray(newValue) && newValue.length > 0) {
            $timeout(function () {
                var dynamicHtmlElements = document.querySelectorAll('.dynamic-html:not(.compiled)');
                angular.forEach(dynamicHtmlElements, function (element) {
                    $compile(element)($scope); // 컴파일하여 이벤트 바인딩
                    element.classList.add('compiled');
                });
            });
        }
    });


	/**
	 * 로컬 스토리지 최근 검색어 조회
	 */
    $scope.getLocalStorageItem = function () {
        var history = localStorage.getItem('history');
        $scope.history.isActive = 1;
        $scope.history.list = history ? JSON.parse(history) : [];
    }

	/**
	 * 로컬 스토리지 최근 검색어 삭제
	 */
    $scope.removeLocalStorageItem = function (historyKeyword) {
        var history = localStorage.getItem('history');
        var values = JSON.parse(history) ? JSON.parse(history) : [];
        angular.forEach(values, function (row, idx) {
            if (historyKeyword == row) {
                values.splice(idx, 1);
            }
        });
        if (values.length < 1) localStorage.removeItem('history');
        else localStorage.setItem('history', JSON.stringify(values));
    };
    
	/**
	 * 최근 검색어 삭제 버튼 클릭 시
	 */
    $scope.removeHistoryKeyword = function(historyKeyword) {
        if ($scope.keyword) {
            if ($rootScope.member) {
                $scope.searchHistory('remove', 0, '', historyKeyword, $scope.keyword);
            } else {
                $scope.removeLocalStorageItem(historyKeyword);
                $scope.searchHistory('list', 0, '', $scope.keyword, '');
            }
        } else {
            if ($rootScope.member) {
                $scope.searchHistory('remove', 0, '', historyKeyword, '');
            } else {
                $scope.removeLocalStorageItem(historyKeyword);
                $scope.getLocalStorageItem();
            }
        }
        $scope.sendClickLog('remove', 'rc');
    };
    
	/**
	 * 최근 검색어 전체 삭제
	 */
    $scope.removeAllHistoryKeyword = function() {
        if ($rootScope.member) {
            $scope.contextOption();
            $scope.searchHistory('remove', 1, '', '', '');
            $scope.searchHistory('list', 0, '', '', '');
        }
        localStorage.removeItem('history');
        $scope.getLocalStorageItem();
        $scope.sendClickLog('remove_all', 'rc');
    };

	/**
	 * 검색어 입력 이벤트
	 */
    $scope.getSearchHistoryAboutKeyword = function (event) {
        if (event.which == 13) $scope.search('di');
        else $scope.searchHistory('list', 0, '', $scope.keyword, '').then(function () { return false; });
    };


    // 채널 가기
    $scope.goBjStation = function (szUserId){
        $rootScope.bNickClick = false;
        $scope.szStationUrl = BJ_AFREECATV + '/'+szUserId;
        if(Util.getOS() == 'android' && afreeca.link.isWebview() && Util.getOSVersion("6.3.0")['compare']) {
            goAosMove($scope.szStationUrl);
            return;
        }
        if(Util.getOS() == 'android' && afreeca.link.isWebview()) { // android go Station 함수 사용할 경우 본창으로 열리는 이슈가 있어 open 함수 추가
            window.open(SERVICE_APP_SCHEME + "browser/station?url="+$scope.szStationUrl, "_blank");
        } else {
            afreeca.link.goStationLink($scope.szStationUrl);
        }
    }

	/**
	 * 검색 페이지로 이동
	 */
  $scope.search = function (stype, ac_type, user_id) {
      var prevPage = $rootScope.previousState;
      var tmpPkCnt = 0;
      var tmpHasHint = false;

      if (stype == 'pk') {
          tmpPkCnt = $scope.pkCnt;
          tmpHasHint = $scope.hasHint;
      }

      $rootScope.searchTab = 'all';
      if (prevPage == 'search') $rootScope.searchTab = $stateParams.search_tab;
      if (prevPage == 'video') $rootScope.searchTab = 'vod';

      if ($scope.keyword) {
          $state.go('search', {
              query: $scope.keyword,
              stype: stype,
              ac_type: ac_type,
              pk_cnt: tmpPkCnt,
              has_hint: tmpHasHint
          });

      } else {
          toastr.info(gtext('검색어를 입력하세요.'));
      }
  }

    /**
     * 데이터 조회
     */
    $scope.searchHistory = function (service, all, value, d, keyword) {
        var param =  {
            service: service
            , all: all
            , value: value
            , d: encodeURIComponent(d)
            , keyword: encodeURIComponent(keyword)
        };

        return new Promise(function (resolve, reject) {
            KeywordService.searchHistory(param).then(function (result) {
                if (result.suggest_bj) {
                    $scope.suggestBj = result.suggest_bj;
                }
                
                if (result.suggest_contents) {
                    $scope.suggestContents = result.suggest_contents;
                }

                if ($rootScope.member) {
                    if (result.history) {
                        $scope.history = result.history;

                        if (!$scope.keyword) {
                            localStorage.removeItem('history');
                            var values = $scope.history.list;
                            if (values.length > 0) {
                                localStorage.setItem('history', JSON.stringify(values));
                            }
                        }
                    }
                } else {
                    $scope.getLocalStorageItem();
                    
                    var decodedTerm = decodeURIComponent(d);
                    if (decodedTerm) {
                        var history = [];
                        angular.forEach($scope.history.list, function(row) {
                            if (row.indexOf(decodedTerm) > -1) history.push(row);
                        })
                        $scope.history.list = history;
                    }
                }

                if (result.realtime) {
                    angular.forEach(result.realtime, function (row) {
                        if (row.show_text == '-') {
                            row.updown = 'even';
                        }
                        if (row.show_text == 'new') {
                            row.updown = 'new';
                            row.show_text = row.show_text.toUpperCase();
                        }
                    })
                    $scope.realtime = result.realtime;
                }
                
                if (result.recommend) {
                    angular.forEach(result.recommend, function (row) {
                        row.hintList = [];
                        row.hintLastIndex = 0;
                        if(row.description != ''){
                            row.hintList = row.description.split(", ");
                            row.hintLastIndex = row.hintList.length - 1;
                        }
                    })                    
                    $scope.recommend = result.recommend;
                }
                $scope.$digest();
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    }
    
	/**
	 * CLICK 로그 전송
	 * @returns {undefined}
	 */
	$scope.sendClickLog = function (action, tab_type, on_autosave) {
        var param = {
            code_type: 'search_input_field',
            action: action,
            tab_type: tab_type
        };

        if (action == 'autosave_change') {
            param.on_autosave = on_autosave;
        }
        
        window.collector.click(param);
	};

    $scope.searchHistory('list', 0, '', encodeURIComponent($scope.keyword), ''); // 최초 데이터 조회

    $scope.actionLog = function (item, acttype, actcode, stype, logic) {
        var szActCode = (typeof actcode === 'undefined' || actcode === '') ? 'all' : actcode;
        var params = {
            d : encodeURIComponent($scope.keyword)
            , stype: stype
            , acttype : acttype
            , actcode : actcode
            , l : logic
            , bjid : item.user_id
            , bno : item.broad_no
        };

        if(typeof item.is_password === "undefined"){
            item.is_password = "0";
        }

        if(typeof item.grade === "undefined"){
            item.grade = 0;
        }        

        return new Promise(function (resolve, reject) {
            SearchService.actionLog(params).then(function (result) {
                resolve();
            }).catch(function (error) {
                resolve();
            });

            if (acttype == 'live' || acttype == 'input_field') {
                $rootScope.liveAction(item);
            }
        });

    };


}
module.exports = KeywordController;