IteminfoMyStickerController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$http', '$timeout', 'Util', 'LoginManager', 'ItemService', 'gtext'];

function IteminfoMyStickerController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, ItemService, gtext) {

	$scope.nPage = 1;
	$scope.tickethistorylist = [];

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		if (afreeca.link.isWebview()) {
			afreeca.link.close();
		} else {
			$state.go("iteminfo");
		}
	}

	/**
	 * 스티커 페이지로 이동
	 */
	$scope.goStickerPage = function () {
		$state.go("iteminfo.sticker");
	}

	/**
	 * 티켓 정보(프로모션, 이벤트 등) 페이지로 이동
	 */
	$scope.goTicketInfoLink = function (ticket_url) {
		afreeca.link.browser(ticket_url);
	}

	/**
	 * 배송지 입력 페이지로 이동
	 */
	$scope.insertAddress = function (ticket_type, idx, order_cnt) {
		var order_type = idx;
		if (order_cnt > 1) {
			if (confirm("일괄 주문 가능한 동일 상품이 있습니다.\n포함하여 전체 주문하시겠습니까?")) {
				order_type = 'ALL'
			}
		}
		$state.go("iteminfo.insertaddr", {
			ticket_type: ticket_type,
			order_type: order_type
		});
	}

	/**
	 * 배송지 완료 페이지로 이동
	 */
	$scope.confirmAddress = function (order_num) {
		$state.go("iteminfo.confirmaddr", {
			order_num: order_num,
			reload: 'done'
		});
	}

	/**
	 * 내 스티커 아이템
	 */
	$scope.loadMyStickerItem = function () {

		ItemService.fanLoveItem({
			szWork: 'MYITEM'
		}).then(function (response) {
			eval(response);
			//$scope.activeItem = oActiveItem; //방송 아이템
			$scope.ticketItem = oTicketItem; //티켓 아이템
			$scope.myItem = []; // 보유중인 아이템(티켓만)
			console.log(oMyItem.ITEM)
			$.each(oMyItem.ITEM, function (key, value) {
				console.log(key, value)
				if (value.TICKET == 'Y') {
					$scope.myItem.push(value);
				}
			});
			$scope.$digest();
		})


	}

	/**
	 * 만료된 티켓 삭제
	 */
	$scope.deleteItem = function (ticket) {
		if (confirm("선물기간이 만료되어 사용이 불가능합니다. 삭제하시겠습니까?")) {

			ItemService.fanLoveItem({
				szWork: 'DEL_TICKET',
				nIdx: ticket.IDX,
				szItemType: ticket.ITEMTYPE,
				nPeriod: ticket.PERIOD
			}).then(function (response) {
				eval(response);

				var result = oDelResult.CHANNEL.RESULT;

				if (result == '-100') {
					alert("삭제에 실패하였습니다.");
					return;
				} else {
					alert("삭제되었습니다.");
					location.reload();
				}
				$scope.$digest();
			})

		}
	}

	/**
	 * 티켓 선물하기 페이지로 이동
	 */
	$scope.giftTicket = function (ticket) {
		$state.go("iteminfo.giftticket", {
			ticket_type: ticket.ITEMTYPE
		});
	}

	/**
	 * 티켓 선물 내역
	 */
	$scope.loadMyTicketChangeHistory = function (nPage) {

		ItemService.fanLoveItem({
			szWork: 'getTicketChangeHistory',
			offset: '10',
			page_no: nPage
		}).then(function (response) {
			$scope.tickethistory = response;
			$.merge($scope.tickethistorylist, response.DATA);
			$scope.bHasMore = response.TOTAL_PAGE > response.PAGE_NO ? true : false;
			$scope.nPage += 1;
			$scope.$digest();
		})
	}

	$scope.loadMyStickerItem();
	$scope.loadMyTicketChangeHistory(1);

}
module.exports = IteminfoMyStickerController;