NotificationService.$inject = ['$http'];

function NotificationService($http) {
  this.checkNewBadge = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/noti/a/badge", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.contents = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/noti/a/list", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.updateNoti = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/noti/a/updateNoti", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }; 

  this.settingInfo = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/push/setting/a/getFlagStatus", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.resetInfo = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/push/setting/a/getResetFlagStatus", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  this.saveInfo = function(data) {
    return new Promise(function(resolve, reject) {
      $http
        .post(API_M_AFREECATV + "/push/setting/a/updateFlag", $.param(data || ""))
        .then(function(response) {
          if (response.data.result == 1) {
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };  
}

module.exports = NotificationService;