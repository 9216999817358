NoteWriteController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$http', '$timeout', 'Util', 'LoginManager', 'NoteService', 'gtext']
function NoteWriteController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, NoteService, gtext) {

    $scope.ids = [];
    $scope.isDisabled = false; // a tag는 isDisabled 안됨 추후 button으로 UI 교체 요청을 해야함
    $scope.isWrite = 0;
    $scope.tagText = '';

    $scope.searchList = [];
    $scope.selectUser = null;
    $scope.searchLoading = false;
    $scope.files = [];
    var nSearchLimit = 15,
        nSearchPageNo = 1,
        bSearchMore = false;

    $scope.byteConvert = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        bytes = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        return bytes;
    }

    $rootScope.headerClassRemove();

    /**
     * 태그추가(ID 추가)
     */
    $scope.addTag = function () {
        var szReceiveId = $scope.tagText.trim().toLowerCase();
        $scope.tagText = '';

        // 검색 유저 세팅
        if ($scope.selectUser && $scope.selectUser.length > 0) {
            // 검색 선택한 유저 있을 경우
            szReceiveId = $scope.selectUser;
            $scope.selectUser = null;
        } else if ($scope.searchList[0] && $scope.searchList[0].user_id && $scope.searchList[0].user_id.length > 0) {
            // 선택한 유저 없으며, 검색결과가 한건이라도 존재할 경우
            szReceiveId = $scope.searchList[0].user_id;
        }

        if ($scope.ids.length >= 30) {
            alert(gtext("한 번에 최대 30명까지만 전송 가능합니다."));
            $('#tag_input').focus();
            return false;
        }
        if (!szReceiveId || szReceiveId.length === 0) {
            $('#tag_input').focus();
            return false;
        }
        if (LoginManager.getUserId() === szReceiveId) {
            alert(gtext('본인에게는 쪽지를 보낼 수 없습니다.'));
            $('#tag_input').focus();
            return false;
        }

        var cnt = $scope.ids.length;
        for (var i = 0; i < cnt; i++) {
            if ($scope.ids[i].receive_id === szReceiveId) {
                alert(gtext("이미 받는사람 목록에 포함 되어 있습니다."));
                $('#tag_input').focus();
                return false;
            }
        }

        NoteService.receiveIdAndSenderRejectIdCheck({
            user_id: szReceiveId
        }).then(function (response) {
            // 블랙리스트 > 수신거부 우선순위
            if(response.data.sender_black) {
                alert(response.data.message);
            } else {
                // 메시지가 존재한다는 것은 전체 수신 거부 거나 해당 받는 사람을 수신거부했거나
                if (response.data.all_reject || response.data.some_reject) {
                    if (confirm(response.data.message)) {
                        NoteService.rejectDeleteAble({
                            all_reject: response.data.all_reject,
                            some_reject: response.data.some_reject,
                            receive_id: szReceiveId,
                        }).then(function (response) {
                            alert(gtext("수신거부 해제되었습니다."));
                        }).catch(function (response) {
                            alert(response.data.message);
                        });
                    }
                }
            }
            $scope.ids.push({ receive_id: szReceiveId, receive_nickname: response.data.nickname });
            $scope.$digest();
        }).catch(function (response) {
            alert(response.data.message);
        });
    }

    /**
     * 태그제거
     * @param {type} key
     */
    $scope.deleteTag = function (key) {
        if ($scope.ids.length > 0 &&
            $scope.tagText.length === 0 &&
            key === undefined) {
            $scope.ids.pop();
        } else if (key !== undefined) {
            $scope.ids.splice(key, 1);
        }
    }

    $scope.focus = function () {
        $('#tag_input').focus();
    }

    /**
     * back 버튼
     * @param {type} : station(채널) or ''(쪽지 페이지)
     */
    $scope.backHandler = function (type) {
        if (type === 'station') {
            $window.close();
        } else {
            $window.history.back();
        }
    }
    /**
     * 보내기 전송
     * @param {type} : station(채널) or ''(쪽지 페이지) 
     */
    $scope.sendHandler = function (type) { // 추가할 부분
        $scope.isWrite ++;
        if($scope.isWrite == 1 && !$scope.isDisabled) { // 보내기 버튼 한번만 클릭
            if ($scope.ids.length === 0) {
                alert(gtext('받는 아이디/닉네임을 입력해 주세요.'));
                $scope.isWrite = 0;
                $scope.focus();
                return false;
            }
            var content = $('.memo_write').get(0).innerText;
            content = content.trim(); // 빈값 제거
            if($scope.loading) {
                alert(gtext("이미지 첨부 중입니다."));
                $scope.isWrite = 0;
                return false;
            }
            if (!content) {
                alert(gtext('쪽지 내용을 입력해 주세요.'));
                $('.memo_write').get(0).innerText = "";
                $scope.isWrite = 0;
                return false;
            }
            if ($scope.ids.length > 30) {
                alert(gtext('받는 사람은 최대 30명까지 입력하실 수 있습니다.'));
                $scope.isWrite = 0;
                return false;
            }
            $scope.isDisabled = true;
    
            NoteService.write({
                'receive_id': $scope.ids.map(function (val) {
                    return val.receive_id;
                }),
                'receive_nickname': $scope.ids.map(function (val) {
                    return val.receive_nickname;
                }),
                'content': content,
                'file_info': $scope.files
            }).then(function (response) {
                $scope.isDisabled = false;
                alert(gtext('전송이 완료되었습니다.'));
                if (type === 'station') {
                    $scope.isWrite = 0;
                    $window.self.close();
                } else {
                    $state.go('note.send', { disableHistoryState: false });
                }
            }).catch(function (response) {
                $scope.isDisabled = false;
                $scope.isWrite = 0;
                if (response.data.code === -7709) {
                    alert(response.data.message);
                } else {
                    alert(response.data.message);
                }
            });
        }
    }

    $scope.$watchCollection('tagText', function () {
        $scope.searchUser(true);
    });

    // 유저 검색
    $scope.searchUser = function (reset) {
        // 검색어가 없을 경우 검색리스트 제거
        if ($scope.tagText.trim() === '') {
            $scope.searchList = [];
            nSearchPageNo = 1;
            return;
        }

        if (reset) {
            nSearchPageNo = 1;
        } else if (bSearchMore) {
            nSearchPageNo++;
        } else {
            return;
        }

        (function (value) {
            $scope.searchLoading = true;
            NoteService.searchUser({
                keyword: value,
                page_no: nSearchPageNo,
                limit: nSearchLimit
            }).then(function (response) {
                if (value !== $scope.tagText) {
                    return;
                }

                var data = response.data
                    , groups = data.groups[0] || [];

                $scope.searchList = $.merge(reset ? [] : $scope.searchList, groups.contents || []);

                var oReg = new RegExp('(' + RegExp.escape($scope.tagText) + ')', 'gi');
                $.each($scope.searchList, function () {
                    var szUserNick = (this.user_nick || '').replace(oReg, '<i>$1</i>')
                        , szUserId = (this.user_id || '').replace(oReg, '<i>$1</i>');
                    this.user_text = szUserNick + ' (' + szUserId + ')';
                });
                bSearchMore = data.has_more_list;
            }).catch(function (response) {
                if (response.data && response.data.message) {
                    alert(response.data.message);
                }
            }).finally(function () {
                $scope.searchLoading = false;
                $scope.$digest();
            })
        })($scope.tagText.trim());
    };
    // !유저 ID/닉네임 검색

    //파일 업로드
    $scope.fileUpload = function () {
        var oInput = $("input[type=file]")
            , aFiles = oInput[0].files
            , data = aFiles[0];

        if (aFiles.length > 1) {
            alert(gtext("이미지 파일은 1개씩 첨부 가능합니다."));
            $scope.loading = false;
            return false;
        }

        if($scope.files.length > 2) {
            alert(gtext("이미지 첨부는 3개까지 가능합니다."));
            $scope.loading = false;
            return false;
        }


        $scope.loading = true;
        
        NoteService.fileUpload({
            file: data,
            fileCount : $scope.files.length
        }).then(function (response) {
            try {
                if ($scope.getTotalFileSize() + response.data.file_size > 8388608) {
                    throw new Error(gtext("이미지 첨부는 8MB까지 가능합니다."));
                }
                $scope.files.push(response.data);
                oInput.val('');
            } catch (oException)   {
                alert(oException.message);
            } finally   {
                $scope.loading = false;
                $scope.$digest();
                return false;
            }

        }).catch(function (response) {
            if (response.data && response.data.message) {
                alert(response.data.message);
            }
            oInput.val('');
            $scope.loading = false;
            $scope.$digest();
            return false;
        });

    };

    $scope.fileDelete = function (index, $event) {
        if ($scope.files[index]) {
            NoteService.fileDelete({
                'file_key': $scope.files[index].file_key
            }).then(function (response) {
                if (response.result == 1) {
                    $scope.files.splice(index, 1);
                    $scope.$digest();
                }

            }).catch(function (reponse) {
                if (reponse.data && reponse.data.message) {
                    alert(reponse.data.message);
                }
            });

        }
    };

    $scope.getTotalFileSize = function () {
        var nTotalSize = 0;
        $scope.files.forEach(function (oInfo) {
            nTotalSize += oInfo.file_size;
        });
        return nTotalSize
    };

    if ($stateParams.id) {
        $scope.tagText = $stateParams.id;
        $scope.addTag();
        $('.memo_id').focus();
    } else {
        $('#tag_input').focus();
    }
}
module.exports = NoteWriteController;