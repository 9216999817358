var toastr = require('toastr');
NoteController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', '$location', 'Util', 'LoginManager', 'NoteService', 'gettextCatalog', 'gtext']
function NoteController($scope, $rootScope, $state, $http, $timeout, $location, Util, LoginManager, NoteService, gettextCatalog, gtext) {
    var page = 0;
    $scope.bBlock = false;
    $scope.szBlockMsg = gtext("금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.");
    $scope.note_cate; // 전체쪽지:0, 받은쪽지:1, 보낸쪽지:2, 안읽은쪽지:3
    $scope.bSearchResult = false;
    $rootScope.bNickClick = false;

    $scope.back = function () {
        // 검색을 한 번이라도 한 경우 검색리스트 -> 리스트 이동
        if ($scope.bSearchResult || $location.hash() === "search" ) {
            history.back();
           
        } else if (afreeca.link.isWebview()) {
            afreeca.link.close();
        } else {
            $state.go('myinfo');
        }
    }

    $scope.write = function () {
        if ($scope.bBlock) {
            alert($scope.szBlockMsg);
            return false;
        }
        $state.go('note.write');
    }

    $rootScope.setting = function () {
        $state.go('note.setting');
    }

    $scope.searchPaddingTop = function () {
        var szPaddingVal = '';

        if ($scope.bSearchShow) {
            if($scope.bSearchResult) {
                //검색 단어가 있는 경우 238px: 검색 입력창 표시 + '검색문자' 검색결과(n) + 리스트 유지
                szPaddingVal = '238px';
            } else {
                //검색 단어가 없는 경우 208px: 검색 입력창 표시 + 리스트 출력
                szPaddingVal = '208px';
            }
        } else {
            if($scope.bSearchResult) {
                //검색 단어가 있는 경우: 검색 입력창 제거 + '검색문자' 검색결과(n) + 리스트 유지
                szPaddingVal = '163px';
            } else {
                //검색 단어가 없는 경우: 검색 입력창 제거 + 검색 결과 X 
                szPaddingVal = '133px';
            }
        }

        $("#memo .memo_wrap").css("padding-top" , szPaddingVal);
    }

    $scope.searchOnOffSetting = function() {
        var $memoSearch = $('#memo-search');
        if ($scope.bSearchShow) {
            // ON
            $memoSearch.stop().slideDown(130);
            $location.hash('search');
        
        } else {
            // OFF
            $memoSearch.stop().slideUp(130);
        }
    }

    $scope.noteSearch = function () {
        // padding 값 세팅은 검색 아이콘일 때는 toggle 되어 반대로 적용해야 하므로
        $scope.bSearchShow = !$scope.bSearchShow;
        $scope.searchOnOffSetting(); 
        $scope.searchPaddingTop();
    }

    if (LoginManager.isLogin()) {
        /**
         * 블록체크
         */
        NoteService.block({ last_no: $scope.nLastNo }).then(function (response) {
            $scope.bBlock = !response.result;
            if (response && response.data && response.data.message) {
                $scope.szBlockMsg = response.data.message;
            }
        }).catch(function (response) {
            $scope.bBlock = true;
            if (response && response.data && response.data.message) {
                $scope.szBlockMsg = response.data.message;
            }
        });
    } else {
        toastr.error(gtext("로그인이 되어 있지 않습니다."));
        setTimeout(function () {
            $state.go("home");
        }, 500);
    }



}
module.exports = NoteController;

