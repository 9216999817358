require("jquery");
var angular = require("angular");
angular
	.module("afreecatv")
	.service("MainService", require("./main.service"))
	.service("CategoryService", require("./category.service"))
	.service("VideoListService", require("./video.list.service"))
	.service("TokenService", require("./token.service"))
	.service("FavoriteService", require("./favorite.service"))
	.service("NicknameService", require("./nickname.service"))
	.service("ItemService", require("./item.service"))
	.service("GiftService", require("./gift.service"))
	.service("EmoticonService", require("./emoticon.service"))
	.service("LiveService", require("./live.service"))
	.service("LaterviewService", require("./laterview.service"))
	.service("AlarmService", require("./alarm.service"))
	.service("PmService", require("./pm.service"))
	.service("SearchService", require("./search.service"))
	.service("KeywordService", require("./keyword.service"))
	.service("RecommanderService", require("./recommander.service"))
	.service("RecentService", require("./recent.service"))
	.service("RecentVodService", require("./recent.vod.service"))
	.service("NoteService", require("./note.service"))
	.service("NotificationService", require("./notification.service"))		
	.service("RankService", require("./rank.service"))
	.service("CountryService", require("./country.service"))
	.service("MypointService", require("./mypoint.service"))
	.service("VideoPlaylistService", require("./video.playlist.service"));
