SearchController.$inject = ['$scope', '$rootScope', '$location', '$state', '$stateParams', 'SearchService', 'KeywordService', 'LoginManager', 'Util', 'AfreecaStorageFactory', 'gtext', '$sce', 'VideoPlaylistService', 'gettextCatalog', 'CookieFactory']

function SearchController($scope, $rootScope, $location, $state, $stateParams, SearchService, KeywordService, LoginManager, Util, AfreecaStorageFactory, gtext, $sce, VideoPlaylistService, gettextCatalog, CookieFactory) {
    $('body').removeClass('liveArea fullscreen');
    $scope.$parent.query = $stateParams.query;
    $scope.stype = $stateParams.stype ? $stateParams.stype : 'di';
    $scope.ac_type = $stateParams.ac_type;
    $scope.pk_cnt = $stateParams.pk_cnt;
    $scope.has_hint = $stateParams.has_hint;
    $scope.original = $stateParams.original ? $stateParams.original : '';
    $scope.modified = '';
    $scope.postPosition = '';

    $('#searchBox').removeClass('hide');

    $rootScope.title = '검색';
    $scope.aGroups = {};
    $scope.aVodGroups = {};
    $scope.toggle = false; // 필터 옵션 css 제어 변수
    
    $scope.firstVodLoadCallFlag = true; // 페이지 최초 로드 체크 변수
    $scope.szOrder = '';
    $scope.szTerm = '';
    $scope.szFileType = '';
    $scope.language = '';
    $scope.sessionKey = '';

    SearchService.szUsers = '';
    var szOrderByColumn;
    var szLately;
    var szFileType;
    var nRowPerPageNo = 5; // 통합검색 페이지에서 로드할 타입별 리스트 갯수
    var nCurrentPage = 1; // 현재 페이지 넘버
    var nCategoryNo = '';
    $scope.bCompleted = false;

    var originSelectTab = {}; // 직전 필터 옵션 값 히스토리 정보 객체
    /**
     * 검색 탭
     */
    $scope.aTabs = [{
        id: 'total',
        name: '통합검색',
        order: [],
        filetype: [{
            id: 'ALL',
            name: '전체'
        }, {
            id: 'NORMAL',
            name: '업로드VOD'
        }, {
            id: 'REVIEW',
            name: '다시보기'
        }, {
            id: 'HIGHLIGHT',
            name: '하이라이트'
        }, {
            id: 'CLIP',
            name: '유저클립'
        },  {
            id: 'CATCH',
            name: 'Catch'
        }, {
            id: 'PLAYLIST',
            name: '재생목록'
        }],
        period: []
    }, {
        id: 'live',
        name: '생방송',
        order: [{
            id: 'score',
            name: '정확도순'
        }, {
            id: 'pc_total_view_cnt',
            name: '참여인원순'
        }, {
            id: 'broad_start',
            name: '최신순'
        }, {
            id: 'broad_bps',
            name: '고화질순'
        }, {
            id: 'broad_resolution',
            name: '고해상도순'
        }],
        filetype: [],
        period: []
    }, {
        id: 'vod',
        name: 'VOD',
        order: [{
            id: 'score',
            name: '정확도순'
        }, {
            id: 'view_cnt',
            name: '조회순'
        }, {
            id: 'reg_datetime',
            name: '최신순'
        }],
        filetype: [{
            id: 'ALL',
            name: '전체'
        }, {
            id: 'NORMAL',
            name: '업로드VOD'
        }, {
            id: 'REVIEW',
            name: '다시보기'
        }, {
            id: 'HIGHLIGHT',
            name: '하이라이트'
        }, {
            id: 'CLIP',
            name: '유저클립'            
        },  {
            id: 'CATCH',
            name: 'Catch'            
        },  {
            id: 'PLAYLIST',
            name: '재생목록'            
        }],
        period: [{
            lately: 'all',
            name: '전체'
        }, {
            lately: '1day',
            name: '1일'
        }, {
            lately: '1week',
            name: '1주'
        }, {
            lately: '1month',
            name: '1개월'
        }, {
            lately: '1year',
            name: '1년'
        }]
    },  {
        id: 'post',
        name: '게시글',
        order: [{
            id: 'reg_date',
            name: '최신순'
        }, {
            id: 'view_cnt',
            name: '조회순'
        }, {
            id: 'memo_cnt',
            name: '댓글순'
        }],
        filetype: [],
        period: [{
            lately: 'all',
            name: '전체'
        }, {
            lately: '1day',
            name: '1일'
        }, {
            lately: '1week',
            name: '1주'
        }, {
            lately: '1month',
            name: '1개월'
        }, {
            lately: '1year',
            name: '1년'
        }]
    },{
        id: 'bj',
        name: '스트리머',
        order: [{
            id: 'accur',
            name: '정확도순'
        }, {
            id: 'total_view_cnt',
            name: '누적참여자순'
        }, {
            id: 'total_broad_time',
            name: '방송시간순'
        }, {
            id: 'favorite_cnt',
            name: '애청자순'
        }, {
            id: 'fanclub_cnt',
            name: '팬클럽순'
        }],
        filetype: [],
        period: []
    }
    ];

    $scope.filetype = { 'ALL': 0, 'NORMAL': 1, 'REVIEW': 2, 'HIGHLIGHT': 3, 'CLIP': 4,  'CATCH': 5, 'PLAYLIST': 6 };

    $scope.setModifiedSearch = function (item, stype, keyword = "") {
        //동명이인 재검색시, 검색 키워드 변경 정책
        //1. 검색키워드와 재검색하는 닉네임이 동일한 경우 -> '아이디' 키워드로 검색
		//2. 검색 키워드와 재검색하는 닉네임이 동일하지 않은 경우 -> '닉네임' 키워드로 검색
        const { user_nick, user_id } = item;
        const researchKeyword = keyword === user_nick ? user_id : user_nick
        if(typeof stype == 'undefined'){
            stype = 'mk';
        }

		if ($scope.$parent.query) {
            $state.go('search', {
                query: researchKeyword,
                stype: stype,
                original: $scope.$parent.query,
            });
		}
    }

    $scope.setLocalStrorageItem = function (keyword) {
        if (keyword) {
            var history = localStorage.getItem('history');
            var values = history ? JSON.parse(history) : [];
            var idx = values.indexOf(keyword)
    
            if (idx > -1) {
                values.splice(idx, 1);
                values.unshift(keyword);
            } else {
                values.unshift(keyword);
                if (values.length > 12) values.pop();
            }
            localStorage.setItem('history', JSON.stringify(values));
        }
    }

    /**
     * 탭선택
     * @param {string} szType
     * @returns {undefined}
     */
    $scope.setTab = function (id) {
        
        //id값이 전체로 들어오면 total로 바꿔줌
        if(id === 'all'){
            id = 'total';
        }
        // 검색버튼 누를 경우 현재 통합검색, 생방송, vod, bj 탭중 어디에있는지 알기 위해 저장해 놓은 rootscope 변수
        $rootScope.searchTab = id;

        // 기존에 active(show) 되어있는 필터 레이어 style off 위해 셋팅
        $scope.toggle = false;

        // VOD 영역에서 normal 동영상이 없으면 전체 동영상 로드를 해야함
        // 통합검색, VOD탭을 누를 경우 최초 로드라고 셋팅을 해줘야함
        if (['all', 'vod'].indexOf(id) != -1) {
            $scope.firstVodLoadCallFlag = true;
        }

        // 필터 옵션 초기화
        $scope.initFilterOption(id);

        // 페이징 초기화
        if (id == 'total') {
            nCurrentPage = 1;
            nRowPerPageNo = 6;
        } else {
            nCurrentPage = 1;
            nRowPerPageNo = 20;
        }
        $scope.aGroups = {};

        var query = $scope.$parent.query;
        var params = {
            d: encodeURIComponent(query)
            , uid: LoginManager.isLogin() ? LoginManager.getUserId() : ''
            , stype: $scope.stype
            , acttype : $rootScope.searchTab 
            , location : 'total_search'
            , tab : $rootScope.searchTab
        };

        if ($scope.stype == 'ac') {
            params.ac_type = $scope.ac_type;
        }
        
        if ($scope.stype == 'mk') {
            params.modified = query;
            params.original = $scope.original;
        }

        if ($scope.stype == 'pk') {
            params.pk_cnt = $scope.pk_cnt;
            params.has_hint = $scope.has_hint.toString();
        }        

        if (query) {
            // 금칙어 확인
            SearchService.checkWord(params).then(function (data) {
                if (data.isstop === '1') {
                    toastr.error(gtext("입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다.", data.stopword));
                } else {
                    $scope.language = gettextCatalog.currentLanguage;
                    $scope.setLocalStrorageItem(query);
                    $scope.modified = data.modified;
                    $scope.postPosition = data.postpos;
                    $scope.search();
                }
            }).catch(function (errors) {
                toastr.error(gtext('검색시스템에 문제가 발생했습니다.'));
            });
        } else {
			toastr.info(gtext('검색어를 입력하세요.'));
        }
        return;
    };

    /**
     * VOD 탭선택
     * @param {string} szType
     * @returns {undefined}
     */
    $scope.setVodTab = function (szFileType) {
        // VOD 탭으로 세팅
        $rootScope.searchTab = 'vod';

        // 기존에 active(show) 되어있는 필터 레이어 style off 위해 셋팅
        $scope.toggle = false;

        // VOD 영역에서 normal 동영상이 없으면 전체 동영상 로드를 해야함
        // 통합검색, VOD탭을 누를 경우 최초 로드라고 셋팅을 해줘야함
        $scope.firstVodLoadCallFlag = true;

        // VOD 탭 필터 옵션 초기화
        $scope.initFilterOption('vod');

        // VOD 파일 타입 세팅
        $scope.selectTab.selectFileType = $scope.selectTab.filetype[$scope.filetype[szFileType]];
        originSelectTab.selectFileType = angular.copy($scope.selectTab.selectFileType);

        // 페이징 초기화
        nCurrentPage = 1;
        nRowPerPageNo = 20;

        $scope.aGroups = {};
        $scope.search();
        return;
    };

    /**
     * 필터 옵션 초기화
     */
    $scope.initFilterOption = function (id) {
        // 기존에 active(show) 되어있는 필터 레이어 style off 위해 셋팅
        $scope.toggle = false;

        for (var i in $scope.aTabs) {
            var oTab = $scope.aTabs[i];
            if (oTab.id == id) {
                $scope.selectTab = oTab;
            }
        }

        // 정렬 기본값 셋팅
        
        if ($scope.selectTab.order.length > 0) {
            $scope.selectTab.selectOrder = $scope.selectTab.order[0];
            $scope.szOrder = $scope.selectTab.selectOrder.name;
            originSelectTab.selectOrder = angular.copy($scope.selectTab.selectOrder);
        }

        // 파일타입 셋팅
        if ($scope.selectTab.filetype.length > 0) {
            $scope.selectTab.selectFileType = $scope.selectTab.filetype[0];
            $scope.szFileType = $scope.selectTab.selectFileType.name;
            originSelectTab.selectFileType = angular.copy($scope.selectTab.selectFileType);
        }

        // 날짜 기본값 셋팅
        if ($scope.selectTab.period.length > 0) {
            $scope.selectTab.selectPeriod = (id == 'post') ? $scope.selectTab.period[3] : $scope.selectTab.period[4];
            $scope.szTerm = $scope.selectTab.selectPeriod.name;
            originSelectTab.selectPeriod = angular.copy($scope.selectTab.selectPeriod);
        }
    };

    /**
     * 필터 옵션 선택
     * @param {string} szOptionType
     * @param {object} oOption
     * @returns {undefined}
     */
    $scope.setFilterOption = function (szOptionType, oOption) {
        switch (szOptionType) {
            // 정렬
            case 'order':
                $scope.selectTab.selectOrder = oOption;
                $scope.szOrder = oOption.name;
                break;
            // 구분
            case 'filetype':
                $scope.selectTab.selectFileType = oOption;
                $scope.szFileType = oOption.name;
                break;
            // 날짜
            case 'period':
                $scope.selectTab.selectPeriod = oOption;
                $scope.szTerm = oOption.name;
                break;
        }
        return;
    };

    /**
     * 필터 적용하기
     */
    $scope.applyFilter = function () {
        $scope.aGroups = {};
        $scope.toggle = false;
        $scope.firstVodLoadCallFlag = false;
        nCurrentPage = 1;
        originSelectTab.selectOrder = angular.copy($scope.selectTab.selectOrder);
        originSelectTab.selectFileType = angular.copy($scope.selectTab.selectFileType);
        originSelectTab.selectPeriod = angular.copy($scope.selectTab.selectPeriod);
        $scope.search();
    };

    /**
     * 필터 취소하기
     */
    $scope.cancleFilter = function (e) {
        $('.theme_background, #cancle_button, #filter_button').on('touchstart mousedown click', function (e) {
            var szAttrId = $(this).attr('id');
            e.stopPropagation();
            e.preventDefault();
            $scope.$apply(function () {
                if (!$.isEmptyObject(originSelectTab.selectOrder)) {
                    $scope.selectTab.selectOrder = originSelectTab.selectOrder;
                    $scope.szOrder = originSelectTab.selectOrder.name;
                }

                if (!$.isEmptyObject(originSelectTab.selectFileType)) {
                    $scope.selectTab.selectFileType = originSelectTab.selectFileType;
                    $scope.szFileType = originSelectTab.selectFileType.name;
                }

                if (!$.isEmptyObject(originSelectTab.selectPeriod)) {
                    $scope.selectTab.selectPeriod = originSelectTab.selectPeriod;
                    $scope.szTerm = originSelectTab.selectPeriod.name;
                }

                // 필터버튼클릭시에는 show and hide 떄문에 !값으로 처리
                $scope.toggle = szAttrId == 'filter_button' ? !$scope.toggle : false;
            });
        });
    };

    /**
     * 검색옵션
     * @returns {undefined}
     */
    $scope.search = function () {
        
        // 정렬 옵션 세팅
        szOrderByColumn = $scope.selectTab.selectOrder ? $scope.selectTab.selectOrder.id : 'score';
        szLately = $scope.selectTab.selectPeriod ? $scope.selectTab.selectPeriod.lately : '1year';
        szFileType = $scope.selectTab.selectFileType ? $scope.selectTab.selectFileType.id : 'ALL';
        query = $scope.$parent.query;
        switch ($scope.selectTab.id) {
            case 'total':
                // 로드 순서변경함(호출 횟수 줄이려고 순서변경)
                profile().then(function () {
                    videoSearch().then(function () {
                        liveSearch().then(function () {
                            bjSearch().then(function () {
                                postSearch();
                                $scope.bCompleted = true;
                            });
                        });
                    });
                });
                break;
            case 'live':
                liveSearch().then(function () {
                    $scope.bCompleted = true;
                });
                break;
            case 'vod':
                videoSearch().then(function () {
                    $scope.bCompleted = true;
                });
                break;
            case 'bj':
                bjSearch().then(function () {
                    $scope.bCompleted = true;
                });
                break;

            case 'post':
                postSearch().then(function () {
                    $scope.bCompleted = true;
                });
                break;
        }
        $scope.bCompleted = false;

    };

    // 초로된 시간 Time format으로 변경
    $scope.secondsToTime = function (nSeconds) {
        return Util.secondsToTime(nSeconds);
    };

    /**
     * BjProfile
     */
    function profile(){
        return new Promise(function (resolve, reject) {
        
            SearchService.profile({
                d: encodeURIComponent(query),
                location : 'total_search',
                tab : $rootScope.searchTab
            }).then(function (result) {
                $scope.is_profile = false;
                if(result.PROFILE[0]){
                    var data = result.PROFILE[0];
                    $scope.aCareerData = [];
                    $scope.aMedalData = [];
                    $scope.is_profile = true;
                    $scope.aCareerData = data.career_award;
                    
                    // 즐겨찾기 토글 기능시 Object 형태 구조 활용
                    $scope.aProfileFavoriteData = [];
                    $scope.aProfileFavoriteData.favorite_flag = data.favorite_flag;

                    $scope.profile_user_id = data.user_id;
                    $scope.profile_user_nick = data.user_nick;
                    $scope.profile_fan_count = data.fan_count;
                    $scope.profile_img_file = data.img_file;
                    $scope.profile_notice = data.notice;
                    $scope.profile_subs_flag = data.subs_flag;
                    $scope.profile_fan_flag = data.fan_flag;
                    $scope.profile_station_title = data.station_title;
                    $scope.profile_station_url = BJ_AFREECATV + "/" + data.user_id;

                    $scope.profile_born_year = data.born_year;
                    $scope.profile_user_age = data.user_age;
                    $scope.profile_view_count = data.view_count;
                    $scope.profile_total_broad_time = 0;
                    if(data.total_broad_time > 0){
					    var nBroadTime = parseInt(data.total_broad_time);
					    nBroadTime = nBroadTime/3600;
					    nBroadTime = Math.floor( nBroadTime );
                        $scope.profile_total_broad_time = nBroadTime.toString();
                    }
                    
                    // LOG 남기기용
                    $scope.aProfileData = {"user_id" : data.user_id, "title_no": data.title_no}

                    // FAV LOG 남기기용
                    $scope.aProfileFavoriteLogData = [];

                    $scope.aProfileFavoriteLogData = {'location' : 'total_search', 'sub_location' : 'bjp'};

                    if(data.medal){
                        angular.forEach(data.medal, function (szVal) {
                            $scope.aMedalData.push(szVal);
                        });
                    }                    
                }

                // 최신 컨텐츠
                if(result.LATEST_VOD && result.LATEST_VOD.contents && result.LATEST_VOD.contents[0]){
                    $scope.aLatestLiveContent = [];
                    $scope.aLatestVodContent = [];
                    $scope.aLatestMoreData = [];
                    var data = result.LATEST_VOD.contents;
                    angular.forEach(data, function (aGroup) {
                        // LIVE 컨텐츠
                        if(aGroup.broad_no){
                            if(aGroup.grade == 19){
                                aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_adult.jpg';
                                if(aGroup.is_password == 'Y' || aGroup.is_password == '1' ){
                                    aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_passadult.jpg';
                                }
                            }else if(aGroup.is_password == 'Y'  || aGroup.is_password == '1' ){
                                aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_pass.jpg';
                            }

                            $scope.aLatestLiveContent.push({"scheme":aGroup.scheme, "thumbnail":aGroup.thumbnail, "view_cnt": aGroup.view_cnt, "title": aGroup.title, "user_id": aGroup.user_id,"user_nick": aGroup.user_nick, "broad_no": aGroup.broad_no, "section_name" : "latest_contents"});
                        // VOD 컨텐츠
                        }else{
                            // if(aGroup.grade == 19){
                            //     aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_adult.jpg';
                            // }

                            if(aGroup.file_type){
                                aGroup.file_type = aGroup.file_type.toLowerCase();
                            }

                            $scope.aLatestVodContent.push({"scheme":aGroup.scheme, "type":aGroup.type , "title_no": aGroup.title_no, "thumbnail":aGroup.thumbnail, "view_cnt": aGroup.view_cnt, "duration":aGroup.duration, "title": aGroup.title, "user_id": aGroup.user_id, "user_nick": aGroup.user_nick, "reg_date": aGroup.reg_date, "timestamp": aGroup.timestamp, "section_name" : "latest_contents", "is_hot" : aGroup.is_hot, "file_type" : aGroup.file_type});
                        }
                    });

                    $scope.aLatestMoreData.push({"more_type":"latest_contents", "user_id":result.LATEST_VOD.user_id});
                }
                if (result.GAME_PROFILE[0]) {
                    $scope.aGameProfileData =  result.GAME_PROFILE[0];
                    $scope.aStationData = [];
                    $scope.aRelatedBj = [];
                    $scope.aEventData = [];
                    $scope.aSeriesGameData = [];
                    $scope.aMoreRelatedBj = [];
                    $scope.aMoreRelatedBjCSV = [];
                    $scope.aMoreRelatedBjBS = [];

                    $scope.oGameProfileInfo = {
                        "viewer": String($scope.aGameProfileData.viewer.toLocaleString('ko-KR')),
                        "title": $scope.aGameProfileData.title,
                        "genre": $scope.aGameProfileData.genre, 
                        "image": $scope.aGameProfileData.mobileImage
                    };

                    $scope.szActiveRelatedBjTab = 'current_sum_viewer';
                    $scope.szRelatedBjIsMore = $scope.aGameProfileData.isMore;

                    $scope.aGameProfileData.stations.forEach(station => {
                        $scope.aStationData.push(station);
                    });

                    $scope.aGameProfileData.bj.forEach(bj => {
                        $scope.aRelatedBj.push({
                            "stationImage": bj.stationImage, 
                            "userId": bj.userId,
                            "userNick": bj.userNick,
                            "liveBroadNo": (bj.liveBroadNo !== '') ? "live" : ''
                        });
                    });

                    $scope.aGameProfileData.events.forEach(event => {
                        let szStartDate = event.startDate.replaceAll('-','.');
						let szEndDate = event.endDate.replaceAll('-','.');
						if (typeof(szStartDate) === "string" && szStartDate.length > 9) {
							szStartDate = szStartDate.substr(2,8);
						} 
						if (typeof(szEndDate) === "string" && szEndDate.length > 9) {
							szEndDate = szEndDate.substr(2,8);
						}
                        $scope.aEventData.push({
                            "link": event.link,
                            "date": `${szStartDate}~${szEndDate}`,
                            "title": event.title
                        });
                    });

                    $scope.aGameProfileData.series.forEach(seriesGame => {
                        $scope.aSeriesGameData.push(seriesGame);
                    });
                }

                if (result.ESPORT[0]) {
                    $scope.currentLanguage = navigator.language;
                    drawEsportSchedule(result.ESPORT[0]);
                }

                // 동명 이인
                if(result.HOMONYM && result.HOMONYM[0]){
                    $scope.aSameNameData = [];
                    var data = result.HOMONYM;
                    angular.forEach(data, function (aGroup) {
                        $scope.aSameNameData.push({"scheme":aGroup.scheme, "favorite_cnt": aGroup.favorite_cnt, "station_logo": aGroup.station_logo, "keyword": aGroup.keyword, "user_id": aGroup.user_id, "user_nick": aGroup.user_nick});
                    });
                }

                //추천 컨텐츠
                if(result.RECOMMEND_CONTENTS && result.RECOMMEND_CONTENTS[0]){
                    $scope.aRecommendData = [];
                    var data = result.RECOMMEND_CONTENTS;
                    angular.forEach(data, function (aGroup) {
                        if(aGroup.recommend_type == 'LIVE'){
                            if(aGroup.grade == 19){
                                aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_adult.jpg';
                                if(aGroup.is_password != 0){
                                    aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_passadult.jpg';
                                }
                            }else if(aGroup.is_password != 0){
                                aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_pass.jpg';
                            }

                            $scope.aRecommendData.push({"scheme":aGroup.scheme, "user_id":aGroup.user_id, "broad_no":aGroup.broad_no, "thumbnail":aGroup.thumbnail, "view_cnt": aGroup.view_cnt, "title": aGroup.title, "user_nick": aGroup.user_nick, "recommend_type": aGroup.recommend_type, "contents_type":"live", "contents_value": aGroup.broad_no, "is_profile": $scope.is_profile});
                        }else if(aGroup.recommend_type == 'VOD'){
                            // if(aGroup.grade == 19){
                            //     aGroup.thumbnail = RES_AFREECATV + '/images/mobile/common/img_adult.jpg';
                            // }
                            
                            $scope.aRecommendData.push({"scheme":aGroup.scheme, "user_id":aGroup.user_id, "file_type":aGroup.file_type, "thumbnail": aGroup.thumbnail, "view_cnt": aGroup.view_cnt, "duration":aGroup.duration, "title": aGroup.title, "user_nick": aGroup.user_nick, "timestamp":aGroup.timestamp, "reg_date":aGroup.reg_date, "recommend_type": aGroup.recommend_type, "contents_type":"vod", "contents_value": aGroup.title_no, "is_profile": $scope.is_profile});
                        }else if(aGroup.recommend_type == 'BJ'){
                            $scope.aRecommendData.push({"scheme":aGroup.scheme, "user_id":aGroup.user_id, "thumbnail":aGroup.thumbnail, "favorite_cnt": aGroup.favorite_cnt, "user_nick": aGroup.user_nick, "recommend_type": aGroup.recommend_type, "contents_type":"bj", "contents_value": aGroup.user_id, "favorite_flag": aGroup.favorite_flag, "is_profile": $scope.is_profile});
                        }
                    });
                }

                // 추천 스트리머
                if(result.RECOMMEND_BJ && result.RECOMMEND_BJ[0]){
                    $scope.aRecommendBjData = [];
                    var data = result.RECOMMEND_BJ;
                    angular.forEach(data, function (aGroup) {
                        $scope.aRecommendBjData.push({"scheme":aGroup.scheme, "broad_no": aGroup.broad_no, "thumbnail":aGroup.thumbnail, "favorite_cnt":aGroup.favorite_cnt, "user_nick": aGroup.user_nick, "favorite_flag":aGroup.favorite_flag, "user_id": aGroup.user_id, "contents_type":"bj", "contents_value": aGroup.user_id, "is_profile": $scope.is_profile});
                    });
                }

                $scope.$digest();
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });        
    }

    /**
     * LIVE
     */
    function liveSearch() {
        return new Promise(function (resolve, reject) {
            SearchService.live({
                szKeyword: encodeURIComponent(query),
                szOrder: szOrderByColumn,
                nListCnt: nRowPerPageNo,
                nPageNo: nCurrentPage,
                location : 'total_search',
                tab : $rootScope.searchTab
            }).then(function (result) {
                var data = result.data;
                angular.forEach(data.groups, function (aGroup) {
                    aGroup.contents = aGroup.contents.filter(function (item, index) {
                        let thumbnail = item.thumbnail;
                        if(item.grade == 19){
                            thumbnail = RES_AFREECATV + '/images/mobile/common/img_adult.jpg';
                            if(item.is_password == '1' || item.is_password == 'Y'){
                                thumbnail = RES_AFREECATV + '/images/mobile/common/img_passadult.jpg';
                            }
                        }else if(item.is_password == '1' || item.is_password == 'Y'){
                            thumbnail = RES_AFREECATV + '/images/mobile/common/img_pass.jpg';
                        }
                        item['thumbnail'] = thumbnail;
                        return item;
                    });
                    if ($scope.aGroups[aGroup.title]) {
                        $.merge($scope.aGroups[aGroup.title].contents, aGroup.contents);
                    } else {
                        aGroup['order_no'] = 1;
                        $scope.aGroups[aGroup.title] = aGroup;
                    }
                    $scope.aGroups[aGroup.title].listUi = 'list_live';
                    $scope.aGroups[aGroup.title].bHasMore = data.has_more_list;
                    $scope.aGroups[aGroup.title].bRemoveLineClass = false;
                    if($("#profile_on").css("display") == 'block'){
                        $scope.aGroups[aGroup.title].bRemoveLineClass = true;
                    }else if($("#profile_off").css("display") == 'block'){
                        $scope.aGroups[aGroup.title].bRemoveLineClass = true;
                    }

                });
                $scope.sessionKey = data.groups[0].sessionKey;
                $scope.bHasMore = data.has_more_list;
                
                $scope.$digest();
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    }

    /**
     * 동영상
     */
    function videoSearch() {
        $scope.tabType = $scope.selectTab.id == 'vod' ? 'VOD' : 'TOTAL';
        var nVodList = $scope.tabType == 'TOTAL' ? 2 : nRowPerPageNo;
        $scope.aVodGroups = {
            'REVIEW': {
                name: '다시보기',
                data: []
            },
            'NORMAL': {
                name: '업로드VOD',
                data: []
            },
            'HIGHLIGHT': {
                name: '하이라이트',
                data: []
            },
            'CLIP': {
                name: '유저클립',
                data: []
            },
            'CATCH': {
                name: 'Catch',
                data: []
            },
            'PLAYLIST': {
                name: '재생목록',
                data: []
            }
        }

        return new Promise(function (resolve, reject) {
            SearchService.video({
                szKeyword: encodeURIComponent(query),
                szOrder: szOrderByColumn,
                szFileType: szFileType,
                nListCnt: nVodList,
                nPageNo: nCurrentPage,
                szTerm: szLately,
                nCateNo: '',
                szTabType: $scope.tabType,// 통합검색에서 호출했는지 VOD 탭에서 호출했는지,
                location : 'total_search',
                tab : $rootScope.searchTab
            }).then(function (result) {
                //aRecommendDataVodTab 데이터가 존재하는상태에서 videoSearch 실행시 데이터 초기화
                if($scope.aRecommendDataVodTab){
                    $scope.aRecommendDataVodTab = null;
                }
                var data = result.data;
                angular.forEach(data.groups, function (aGroup) {
                    // VOD탭 VOD 추천 컨텐츠 추가
                    if(aGroup['title'] == 'CATCH' && szFileType == "ALL"){
                        if(aGroup['contents'].length > 0){
                            $scope.aRecommendDataVodTab = [];
                            var recommendData = aGroup['contents'];
                            angular.forEach(recommendData, function (aRecommendGroup) {
                                if(aRecommendGroup.file_type == 'CATCH'){
                                    if(aRecommendGroup.grade === 19 || aRecommendGroup.auth === "HIDDEN"){
                                        aRecommendGroup.use_vertical_thumbnail = true;
                                    }
                                    $scope.aRecommendDataVodTab.push({"scheme":aRecommendGroup.scheme, "user_id":aRecommendGroup.user_id, "file_type":aRecommendGroup.file_type, "thumbnail": aRecommendGroup.thumbnail, "view_cnt": aRecommendGroup.view_cnt, "duration":aRecommendGroup.duration, "title": aRecommendGroup.title, "title_no": aRecommendGroup.title_no, "user_nick": aRecommendGroup.user_nick, "timestamp":aRecommendGroup.timestamp, "reg_date":aRecommendGroup.reg_date, "recommend_type": aRecommendGroup.recommend_type, "contents_type":"vod", "contents_value": aRecommendGroup.title_no, "is_profile": $scope.is_profile,"vertical_thumbnail": aRecommendGroup.vertical_thumbnail , "use_vertical_thumbnail" : aRecommendGroup.use_vertical_thumbnail});
                                }
                            });
                            
                            $scope.aRecommendDataVodTab.display_cnt = 30;   
                        }
                    } else {

                        // 더보기 로딩 할 경우
                        if ($scope.aGroups[aGroup.title]) {
                            $scope.firstVodLoadCallFlag = false;
                            $.merge($scope.aGroups[aGroup.title].contents, aGroup.contents)
                            angular.forEach(aGroup.contents, function (oData) {
                                var szFileType = oData.file_type == 'EDITOR' ? 'NORMAL' : oData.file_type;
                                if(oData.file_type == 'CATCH' && (oData.grade === 19 || oData.auth === "HIDDEN")){
                                    oData.use_vertical_thumbnail = true;
                                }
                                $scope.aVodGroups[szFileType].data.push(oData)
                            });
                        } else {
                            // 더 보기 로딩이 아닌 첫 로드인 경우
                            angular.forEach(aGroup.contents, function (oData) {
                                var szFileType = oData.file_type == 'EDITOR' ? 'NORMAL' : oData.file_type;
                                if(oData.file_type == 'CATCH' && (oData.grade === 19 || oData.auth === "HIDDEN")){
                                    oData.use_vertical_thumbnail = true;
                                }
                                $scope.aVodGroups[szFileType].data.push(oData)
                            });
    
                            if ($scope.tabType == 'TOTAL') {
                                if (aGroup.related_contents) {
                                    $scope.aRelatedVod = aGroup.related_contents;
                                    $scope.aRelatedVod.display_cnt = 4;
                                }
                                if (aGroup.catch_contents){
                                    angular.forEach(aGroup.catch_contents, function (oData) {
                                        if(oData.grade === 19 || oData.auth === "HIDDEN"){
                                            oData.use_vertical_thumbnail = true;
                                        }
                                    });
                                    $scope.aCatchVod = aGroup.catch_contents;
                                    $scope.aCatchVod.display_cnt = 30;   
                                }
                            }
                            aGroup['order_no'] = 2;
                            $scope.aGroups[aGroup.title] = aGroup;
                        }
                        $scope.aGroups[aGroup.title].listUi = 'search_vod history_title_seach';
                        $scope.aGroups[aGroup.title].bHasMore = data.has_more_list;
                        $scope.aGroups[aGroup.title].fileType = szFileType;
                    }
                });
               
                $scope.bHasMore = data.has_more_list;
                $scope.sessionKey = data.groups[0].sessionKey;
                $scope.$digest();
                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    }

   
    /**
     * 채널 게시글
     */
    function postSearch() {
        szLately = $scope.selectTab.selectPeriod ? $scope.selectTab.selectPeriod.lately : '1month';

        return new Promise(function (resolve, reject) {
            SearchService.post({
                szKeyword: encodeURIComponent(query),
                szOrder: szOrderByColumn,
                nListCnt: nRowPerPageNo,
                nPageNo: nCurrentPage,
                szTerm: szLately,
                location : 'total_search',
                tab : $rootScope.searchTab,
                szWhereCall: $scope.selectTab.id, // 통합검색에서 호출했는지 게시글 탭에서 호출했는지
            }).then(function (result) {
                var data = result.data;
                angular.forEach(data.groups, function (aGroup) {
                    if ($scope.aGroups[aGroup.title]) {
                        $.merge($scope.aGroups[aGroup.title].contents, aGroup.contents);
                    } else {
                        aGroup['order_no'] = 3;
                        $scope.aGroups[aGroup.title] = aGroup;
                    }
                    $scope.aGroups[aGroup.title].listUi = 'bj_post';
                    $scope.aGroups[aGroup.title].bHasMore = data.has_more_list;

                    if (aGroup.contents.length < 1 && szLately == '1month') {
                        aGroup.html = '최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.';
                    }
                });
                
                $scope.sessionKey = data.groups[0].sessionKey;
                $scope.bHasMore = data.has_more_list;
                $scope.$digest();

                resolve();
            }).catch(function (error) {
                resolve();
            });
        });
    }

    /**
     * 스트리머
     */
    function bjSearch() {
        return new Promise(function (resolve, reject) {
            SearchService.bj({
                szKeyword: encodeURIComponent(query),
                szOrder: szOrderByColumn,
                nListCnt: nRowPerPageNo,
                nPageNo: nCurrentPage,
                location : 'total_search',
                tab : $rootScope.searchTab
            }).then(function (result) {
                // Timeout에 걸렸을때 result -1 일떄
                if (result.result === -1) {
                    if (confirm(gtext('네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?'))) {
                        setTimeout(function () {
                            SearchService.getList();
                        }, 10);
                    }
                } else {
                    var data = result.data;
                    angular.forEach(data.groups, function (aGroup) {   
                        if ($scope.aGroups[aGroup.title]) {
                            $.merge($scope.aGroups[aGroup.title].contents, aGroup.contents);
                        } else {
                            aGroup['order_no'] = 3;
                            $scope.aGroups[aGroup.title] = aGroup;
                        }
                        $scope.aGroups[aGroup.title].listUi = 'bj_list';
                        $scope.aGroups[aGroup.title].bHasMore = data.has_more_list;
                    });
                    
                    $scope.sessionKey = data.groups[0].sessionKey;
                    $scope.bHasMore = data.has_more_list;
                    $scope.$digest();
                }
                resolve();
            }).catch(function (error) {
                resolve();
            });

        });
    }

    /**
     * 더보기
     */
    Util.lastItem(function () {
        if (($scope.selectTab.id != 'total') && $scope.bCompleted && $scope.bHasMore) {
            nCurrentPage++;
            bCompleted = false;
            $scope.search();
        }
    });
    // 현재기준 몇일전 인지 값을 구한다.
    $scope.regDateConvert = function (nTimeStamp, reg) {
        return Util.regDateConvert(nTimeStamp, reg);
    };

    // 통합 검색의 유입경로 처리
    $scope.searchVodInflowPath = function (aItem, szDirectCode, skey) {
        var bjId = aItem.user_id;
        var contentNo = aItem.title_no;
        var fileType = aItem.file_type.toLowerCase();
        var arrInflowPath = ['etc'];
        // 위의 유입경로 값의 default 형이 지정되있어 switch문에 default 케이스 필요 없음
        switch(fileType){
            case 'review':
                arrInflowPath = ['sch', 'sch', 'vod', 'review'];
            break;
            case 'normal':
                arrInflowPath = ['sch', 'sch', 'vod', 'upload_vod'];
            break;
            case 'clip':
                arrInflowPath = ['sch', 'sch', 'vod', 'clip'];
            break;
            case 'catch':
                arrInflowPath = ['sch', 'sch', 'vod', 'catch'];
            break;            
            case 'playlist':
                arrInflowPath = ['sch', 'sch', 'vod', 'playlist'];
            break;
        }

        // VOD탭이라면의 유입경로
        if($rootScope.searchTab == 'vod'){
            // 파일 타입 탭
            var vodTabFileType = $scope.selectTab.selectFileType.id.toLowerCase();
            switch(vodTabFileType){
                case 'all':
                    arrInflowPath = ['sch', 'vod', 'total'];
                break;
                case 'normal':
                    arrInflowPath = ['sch', 'vod', 'upload_vod'];
                break;
                case 'review':
                    arrInflowPath = ['sch', 'vod', 'review'];
                break;
                case 'highlight':
                    arrInflowPath = ['sch', 'vod', 'highlight'];
                break;
                case 'clip':
                    arrInflowPath = ['sch', 'vod', 'clip'];
                break;
                case 'catch':
                    arrInflowPath = ['sch', 'vod', 'catch'];
                break;
                case 'playlist':
                    arrInflowPath = ['sch', 'vod', 'playlist'];
                break;                
            }

        }

        // aItem 특성에 유입경로 특성이 매핑되지 않을떄 사용
        if(szDirectCode){
            // 위의 유입경로 값의 default 형이 지정되있어 switch문에 default 케이스 필요 없음
            switch(szDirectCode){
                case 'total_related':
                    arrInflowPath = ['sch', 'sch', 'vod', 'related'];
                    break;
                case 'smart_recommend_total':
                    arrInflowPath = ['sch', 'sch', 'total', 'smart_recommend'];
                    break;
                case 'smart_recommend_vod':
                    arrInflowPath = ['sch', 'vod', 'smart_recommend'];
                    break;
                
            }

        }

        if(skey){
            // directVodInflowPath 속 convertInflowPath 함수가 배열로 있어야함
            let i=1;
            arrInflowPath.forEach(function(element){
                arrInflowPath['path'+i] = element;
                i++;
            })

            // 키워드 추가
            arrInflowPath['skey'] = skey.query;

            Util.directVodInflowPath(bjId, contentNo, arrInflowPath);
        } else {
            // 유입경로 함수로 전달
            $rootScope.sendInflowLog('VOD', bjId, contentNo, arrInflowPath);
        }
    };

    //최초검색
    $scope.setTab($rootScope.searchTab == undefined ? 'total' :$rootScope.searchTab);

    // 페이지 이동 없이 ActionLog 전송
    $scope.noMovingActionLog = function (item, acttype, actcode){
        var szActCode = (typeof actcode === 'undefined' || actcode === '') ? 'total' : actcode;
        var st_array = {
            'accur' : 'score',
            'pc_total_view_cnt' : 'view_count',
            'broad_start' : 'broad_time',
            'view_cnt' : 'view_count',
            'favorite_cnt' : 'favorite',
            'fanclub_cnt' : 'fanclub',
            'reg_date' : 'latest',
        };        
        var params = {
            d : encodeURIComponent(query)
            , stype: $scope.stype
            , acttype : acttype
            , bj : item.user_id
            , st : st_array[szOrderByColumn]
            , actcode: szActCode
        };


        SearchService.actionLog(params).then(function (result) {
            // Timeout에 걸렸을때 result -1 일떄
            if (result.result === -1) {
                if (confirm(gtext('네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?'))) {
                    setTimeout(function () {
                        SearchService.getList();
                    }, 10);
                }
            }
            resolve();
        }).catch(function (error) {
            resolve();
        });        

    }

    $scope.actionLog = function (item, acttype, actcode, mlsearch_type, listCnt, skey) {
        var szActCode = (typeof actcode === 'undefined' || actcode === '') ? 'all' : actcode;
        var st_array = {
            'accur' : 'score',
            'pc_total_view_cnt' : 'view_count',
            'broad_start' : 'broad_time',
            'view_cnt' : 'view_count',
            'favorite_cnt' : 'favorite',
            'fanclub_cnt' : 'fanclub',
            'reg_date' : 'latest',
        };

        var params = {
            d : encodeURIComponent(query)
            , stype: $scope.stype
            , acttype : acttype
            , bj : item.user_id
            , st : st_array[szOrderByColumn]
            , actcode : szActCode
            , tab : $rootScope.searchTab
        };

        params.sck_session_key = $scope.sessionKey;
        
        szFileType = "";
        if($scope.selectTab.selectPeriod !== undefined){
            szFileType = $scope.selectTab.selectPeriod.lately;
        }
        if(szFileType !== "" && acttype == 'vod'){
            szFileType = szFileType +'|'+ $scope.selectTab.selectFileType.id;
            params.ft = szFileType;
        }else if(szFileType !== "" && acttype == 'post'){
            params.ft = szFileType;
        }//통합검색탭에서 들어올때
        else if(acttype =='vod'){
            //vod일때 section_name의 종류는
            //normal_vod,review_vod,highlight_vod,clip_vod,playlist_vod,related_vod,smr_vod이렇게 전달
            params.section_name = item.type.toLowerCase()+'_vod';
        }
        
        params.file_type = item.type;
        params.list_idx = (listCnt%20 == 0) ? 20 : (listCnt%20);
        params.page = nCurrentPage;
        
        if ($scope.stype == 'ac') {
            params.ac_type = $scope.ac_type;
        }

        if ($scope.stype == 'mk') {
            params.modified = query;
            params.original = $scope.original;
        }

        if (acttype == 'vod') {
            params.vno = item.title_no;
            params.contents_value = item.title_no;
            params.contents_type = 'vod'
            
            if (typeof mlsearch_type !== 'undefined' && mlsearch_type !== '') params.mlsearch_type = mlsearch_type;
            if (item.type == 'PLAYLIST') {
                params.acttype = 'vod';
                params.playlist_idx = item.list_no;
                delete params.vno;
            }
            if(item.file_type =="CATCH") CookieFactory.set('listPreviousUrl', window.location.href, 1);
        } else if (acttype == 'live') {
            params.bno = item.broad_no;
            params.contents_type =  'live'; 
            params.contents_value =  item.broad_no; 
        } else if (acttype == 'post') {
            params.bj = item.station_user_id;
            params.contents_type =  'post'; 
            params.contents_value =  item.title_no; 
            params.userid = item.user_id;
        }else if(acttype == 'bjp'){
            if(szActCode == 'nick'){
                params.contents_type = 'station';
                params.contents_value = item.user_id;
            }else if(szActCode == 'nt'){
                params.bj = item.user_id;
                params.contents_type = 'post';
                params.contents_value = item.title_no;
                // 스킴 생성
                item.scheme = SERVICE_APP_SCHEME + 'browser/station?url=' + BJ_AFREECATV + '/' + item.user_id + '/post/' + item.title_no;
            // Live
            }else if(item.broad_no){
                params.bno = item.broad_no;
                params.contents_type = 'live';
                params.contents_value = item.broad_no;
            // VOD
            }else if(item.title_no){
                params.vno = item.title_no;
                params.contents_type = 'vod';
                params.contents_value = item.title_no;
            }

            if(item.section_name){
                params.section_name = item.section_name;
            }

            if(item.section_name == 'latest_contents'){
                params.file_type = item.file_type;
                params.is_hot = item.is_hot;
            }            

            if(item.more_type){
                params.more_type = item.more_type;
                params.stype = '';
                params.bj = '';
                params.st = ''; 
            }
        }else if(acttype == 'bjp_more' && szActCode == 'nt'){
            params.bj = item.user_id;
            params.contents_type = 'post';
            params.contents_value = item.title_no;
            // 스킴 생성
            item.scheme = SERVICE_APP_SCHEME + 'browser/station?url=' + BJ_AFREECATV + '/' + item.user_id + '/post/' + item.title_no;                    
        }else if(acttype == 'recommend'){
            params.is_profile = item.is_profile;
            params.contents_type = item.contents_type;
            params.contents_value = item.contents_value;
            if(!item.contents_type && item.type === "CATCH"){
                params.contents_type = 'vod';
            }
        }else if(acttype == 'bj'){
            params.contents_type = 'bj';
            params.contents_value = item.user_id;
        }

        return new Promise(function (resolve, reject) {
            SearchService.actionLog(params).then(function (result) {
                // Timeout에 걸렸을때 result -1 일떄
                if (result.result === -1) {
                    if (confirm(gtext('네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?'))) {
                        setTimeout(function () {
                            SearchService.getList();
                        }, 10);
                    }
                }
                resolve();
            }).catch(function (error) {
                resolve();
            });

            if (acttype == 'live') {
                $rootScope.liveAction(item);
            } else if (acttype == 'post') {
                if (szActCode == 'all') {
                    $rootScope.action(item.scheme);
                } else {
                    var szUserId = (szActCode == 'nick') ? item.user_id : item.station_user_id;
                    Util.goStation(szUserId);
                }
            } else if((acttype == 'bjp' && actcode == 'nick') || (acttype == 'bjp_more' && actcode == 'st_icon') ){
                Util.goStation(item.user_id);

            }else {
                if (actcode == 'oth') {
                    return false;
                }
                if(skey && item.file_type.toLowerCase() == "catch"){
                    item.scheme = item.scheme + "&szSearchKeyword=" + encodeURIComponent(skey.query);
                }
                $rootScope.action(item.scheme);
            }
        });

    };

    
    $scope.highlight = function (text) {
        if (!query) {
            return $sce.trustAsHtml(text);
        }

        return $sce.trustAsHtml(text.replace(new RegExp(query, 'gi'), '<strong class="mark">$&</strong>'));
    };

    $scope.eventTitleHistory = function (event, aItem) {
        if (event.target.className == 'on') {
            event.target.className = '';
            if (event.target.parentNode.getElementsByClassName('pagination')[0]) {
                event.target.parentNode.getElementsByClassName('pagination')[0].style.display = 'none';
            }
        } else {
            var x = document.getElementsByClassName('history_title_result');
            for (var i = 0; i < x.length; i++) {
                if (x[i].getElementsByClassName('on')[0]) {
                    x[i].getElementsByClassName('on')[0].className = '';
                }
                if (x[i].getElementsByClassName('pagination')[0]) {
                    x[i].getElementsByClassName('pagination')[0].style.display = 'none';
                }
            }
            event.target.className = 'on';
            if (event.target.parentNode.getElementsByClassName('pagination')[0]) {
                event.target.parentNode.getElementsByClassName('pagination')[0].style.display = 'block';
            }
            this.actionLog(aItem, 'vod', 'oth');
        }
    };

    $scope.moreRelatedVod = function (e) {
        var nRelatedVodCnt = $scope.aRelatedVod.VOD.length;
        if (nRelatedVodCnt < 5) {
            $('.more_vod a').hide();
            return false;
        }

        var nDisplayCnt = $scope.aRelatedVod.display_cnt;
        if (nDisplayCnt == 4) {
            $scope.aRelatedVod.display_cnt = 8;
        } else if (nDisplayCnt == 8) {
            $scope.aRelatedVod.display_cnt = 4;
        }
    }

    $scope.changeTextLanguage = function (msg) {
        return $sce.trustAsHtml(gtext(msg));
    }

    $scope.profileMore = function () {
		$('body').addClass('action_sheet');
		$('.profile_layer').addClass('on');
		$('.dim').fadeIn('fast');        
    };

    $scope.clickProfileMoreDim = function () {
		$('body').removeClass('action_sheet');
		$('.profile_layer').removeClass('on')
		$('.dim').fadeOut('fast');
    };

    $scope.moreLocation = function(aData){
        var szMoreType = aData.more_type;
        switch(szMoreType){
            case 'latest_contents':
                location.href = BJ_AFREECATV + "/" + aData.user_id + "/vods";
                break;
            default:
                break;
        }
    }
    $scope.changeGameProfileTab = function (szClassName)  {
        document.querySelectorAll(".profile_tab button").forEach(tab => {
            if (tab.classList.contains("on")) {
                tab.classList.remove("on");
                return false;
            }
        });
        document.querySelector(`.${szClassName}_button`).classList.add("on");

        //szClassName으로 class명 받은거만 scrollTo 값 조정하면 먹히질 않음..
        //그래서 탭 전환할때마다 모든 scroll 다 0,0으로 초기화
        const aScroll = ['.station_scroll', '.releted_bj_scroll', '.event_scroll', '.series_game_scroll']
        aScroll.forEach(scroll => {
            if (document.querySelector(scroll) !== null) {
                document.querySelector(scroll).scrollTo(0,0);
            }
        });
        //document.querySelector(`.${szClassName}_scroll`).scrollTo(0,0);
        
        //$("#game_profile .details .tab_conts > .list_item").removeClass("on");
        document.querySelectorAll("#game_profile .details .tab_conts > .list_item").forEach(tab => {
            if (tab.classList.contains("on")) {
                tab.classList.remove("on");
                return false;
            }
        });
        document.querySelector(`.${szClassName}_list`).classList.add("on");
    }
    $scope.getEsportSchedule = function(nDateFlag) {
        //const szDate = new Date($scope.aEsportScheduleDate);
        const szDate = new Date($scope.aEsportScheduleDate.replaceAll(".","/"));
        const date = getDateYYYYMMDD(new Date(szDate.setDate(szDate.getDate() + nDateFlag)));
        const oDataOption = {"pttype" : " esport", date};

        getGameContentAndEsportSchedule(oDataOption, "esport");
    }
    $scope.toggleMoreRelatedBjLayer = function() {
        const oReleBjLayer = document.querySelector(".relevance_bj_layer");
        oReleBjLayer.classList.toggle("active");

        if (oReleBjLayer.classList.contains("active")) {
            document.querySelector(".gb_list").scrollTo(0,0);

           //참여인원순탭 default로 설정
            document.querySelector(".current_sum_viewer_tab").classList.add("on");
            document.querySelector(".broad_start_tab").classList.remove("on");
            $scope.szActiveRelatedBjTab = 'current_sum_viewer';

            //팝업 뒤 스크립트 막기(js로 안막히네..)
            $('html, body').css({'overflow': 'hidden', 'height': '100%'});
            if ($scope.aMoreRelatedBjCSV.length === 0) {
                const oDataOption = {"size": 100, order: $scope.szActiveRelatedBjTab, "pttype": "content_profile"};
                getGameContentAndEsportSchedule(oDataOption, "related_bj");
            } else {
                $scope.aMoreRelatedBj = $scope.aMoreRelatedBjCSV;
            }

            let bBlockFirstLayerClose = false;
            // 외부영역 클릭 시 관련bj 팝업 닫기
            $(document).click(function (e){
                if ($(".game_bj_list").has(e.target).length === 0) {
                    //클릭 후, click 이벤트가 등록되는게 아니라 클릭하자마자 click이벤트가 실행되서
                    //바로 layer가 닫히게됨. 그래서 바로 닫힘 방지용 bBlockFirstLayerClose 활용  
                    if (bBlockFirstLayerClose) {
                        oReleBjLayer.classList.remove("active");
                        // 이벤트 없애지 않으면 bBlockFirstLayerClose == true가 유지되서 bj더보기 누르자마자 layer가 바로 닫힘
                        $(document).off("click"); 
                        $('html, body').css({'overflow': 'auto', 'height': 'auto'});
                    }
                    bBlockFirstLayerClose = true;
                }
            });
        } else { //닫기
            oReleBjLayer.classList.remove("active");
            $(document).off("click");//active될때, 외부영역 click 메소드 등록되니깐 제거, 제거 안하면 관련bj버튼 2번 눌러야 active됨
            $('html, body').css({'overflow': 'auto', 'height': 'auto'});
        }
    }

    $scope.changeRelatedBjTab = function(order) {
        if ($scope.szActiveRelatedBjTab === order) return; //같은 탭 누르면 return

        //탭 바꿀 때 마다 상단 고정. 아이폰은 스크롤 중 탭 변경 시, 빈 화면 노출되서 hidden 처리 후 스크롤
        //이거 안되면 -webkit-overflow-scrolling : touch; 또는 모멘텀 스크롤 봐야할듯
        const oRelBjLayer = document.querySelector(".gb_list");
        oRelBjLayer.style.overflowY = 'hidden';
        oRelBjLayer.scrollTo(0,0);
        oRelBjLayer.style.overflowY = 'scroll';

        if (order === "broad_start") {
            document.querySelector(".broad_start_tab").classList.add("on");
            document.querySelector(".current_sum_viewer_tab").classList.remove("on");
        } else if (order === "current_sum_viewer") {
            document.querySelector(".current_sum_viewer_tab").classList.add("on");
            document.querySelector(".broad_start_tab").classList.remove("on");
        }
        $scope.szActiveRelatedBjTab = order;
        
        const aMoreRelatedBjOrderList = (order === "broad_start") ? $scope.aMoreRelatedBjBS : $scope.aMoreRelatedBjCSV;
        if (aMoreRelatedBjOrderList.length === 0) {
            const oDataOption = {"size": 100, order, "pttype": "content_profile"};
            getGameContentAndEsportSchedule(oDataOption, "related_bj");
        } else {
            $scope.aMoreRelatedBj = aMoreRelatedBjOrderList;
        }
    }

    getGameContentAndEsportSchedule = (oDataOption, szType = '') => {
        const oData = {
            d: encodeURIComponent(query),
            location : 'total_search',
            tab: $rootScope.searchTab
        };

        for (let key in oDataOption) {
			oData[key] = oDataOption[key];
		}
        SearchService.profile(oData).then(data => {
            if (szType === "esport") {
                drawEsportSchedule(data.ESPORT[0]);
            } else if (szType === "related_bj") {
                $scope.aMoreRelatedBj = (oDataOption["order"] === "broad_start") ? $scope.aMoreRelatedBjBS : $scope.aMoreRelatedBjCSV;

                data.GAME_PROFILE[0].bj.forEach(bj => {
                    $scope.aMoreRelatedBj.push(bj);
                });
                if (oDataOption["order"] === "current_sum_viewer") {
                    $scope.aMoreRelatedBjCSV = $scope.aMoreRelatedBj;
                } else if (oDataOption["order"] === "broad_start") {
                    $scope.aMoreRelatedBjBS = $scope.aMoreRelatedBj;
                }

                $scope.$apply();
            }
        });
    }
    getDateYYYYMMDD = (szDate) => {
		const year = szDate.getFullYear();
		const month = ('0' + (szDate.getMonth() + 1)).slice(-2);
		const day = ('0' + szDate.getDate()).slice(-2);
		return `${year}-${month}-${day}`;
	}
    drawEsportSchedule = (oEsportScheduleData) => {
        $scope.aEsportSchedule = oEsportScheduleData;
        $scope.aEsportScheduleListTemp = oEsportScheduleData.list; //category filter에 사용
		$scope.aEsportScheduleDate = $scope.aEsportSchedule.date.replaceAll('-','.');

        $scope.$apply();
    }
    $scope.fadeOutScoreResultBtn = (event) => {
        $(event.target).fadeOut('fast');
    }
    $scope.sortEsportCateTab = (szMenu, event) => {
        document.querySelector(".gameSchedule_list ").scrollTo(0,0);
        $(".game_tab_list > div > div > button").removeClass("on");
        $(event.target).addClass("on");

        if (szMenu === "all") {
            $scope.aEsportSchedule.list = $scope.aEsportScheduleListTemp
        } else {
            $scope.aEsportSchedule.list = $scope.aEsportScheduleListTemp.filter(list => {
                // 특정 브라우저에서 ko-KR이 있음
                if (["ko", "ko-KR"].includes($scope.currentLanguage)) {
                    return list.code_name === szMenu
                } else {
                    return list.code_name_eng === szMenu
                }
            });
        }
    }
    $scope.changeEsportDate = (szDate) => {
        const date = getDateYYYYMMDD(szDate);
        const oDataOption = {"pttype" : " esport", date};
        $scope.aEsportScheduleDate = date.replaceAll('-','.');
        getGameContentAndEsportSchedule(oDataOption, "esport");
    }
    // ActionLog 전송
    $scope.actionLogGameProfileAndEsportSchedule = function (oOption = {}){
        
        let params = {
            
            d : encodeURIComponent(query),
            "acttype": "ctsp",
            "sck_session_key": $scope.sessionKey,
            "stype" : $scope.stype,
            "tab": "total",
        }

        for (let key in oOption) {
			params[key] = oOption[key];
		}
        console.log(params);

        SearchService.actionLog(params).then(function (result) {
            console.log(result);
            // Timeout에 걸렸을때 result -1 일떄
            if (result.result === -1) {
                if (confirm(gtext('네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?'))) {
                    setTimeout(function () {
                        SearchService.getList();
                    }, 10);
                }
            }

        }).catch(function (error) {});        
    }

    // aGroups에 데이터가 있는지 확인
    $scope.hasContent = function(aGroups) {
        return Object.values(aGroups).some(function(group) {
            return group.contents && group.contents.length > 0;
        });
    };
}
module.exports = SearchController;