var Swiper = require("swiper/dist/js/swiper.min");
NoteReceiveViewController.$inject = ['$scope', '$window', '$rootScope', '$state', '$stateParams', '$http', '$timeout', '$location','Util', 'LoginManager', 'NoteService', '$sce', 'gtext']
function NoteReceiveViewController($scope, $window, $rootScope, $state, $stateParams, $http, $timeout, $location, Util, LoginManager, NoteService, $sce, gtext) {
	//초기화
	$scope.note = {
		read_date: 0
	};

	var oDate = new Date();
	$scope.date = oDate.getFullYear() + '-' + (oDate.getMonth()+1).addZero() + '-' + oDate.getDate().addZero() + ' ' + oDate.getHours().addZero() + ":" + oDate.getMinutes().addZero() + ":" + oDate.getSeconds().addZero();

	$scope.byteConvert = function (bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		bytes = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
		return bytes;
	};
	var mySwiper = null;
	var oFastReplyTextArea = '';
	var oSaveFastReplyCheckboxDiv = '';
	var oFastReplySaveCheckbox = '';

	$scope.bLoginAdmin = false;
	$rootScope.bNickClick = false;
	$scope.szStationUrl = "";
	$scope.subscribeType = false;
	
	$rootScope.headerClassRemove();

	$scope.layers = function (target, index) {
		if (mySwiper == null) {
			mySwiper = new Swiper('.swiper-container', {
				navigation: {
					nextEl: '.viewer-next',
					prevEl: '.viewer-prev',
				},
				pagination: {
					el: '.swiper-pagination',
				}
			});
		}

		$scope.layerOpen = true;
		mySwiper.slideTo(index);
	};

	$scope.layersClose = function (target) {
		$scope.layerOpen = false;
	};

	$scope.prev = function () {
		if(afreeca.link.isWebview()) {
			if($rootScope.prevNoti) {
				history.back();
			} else if($rootScope.prevNoti == undefined) {
				afreeca.link.close();
			} else {
				history.back();
			}
		} else {
			history.back();
		}
		//$state.go('note.receive')
	};

	/**
	 * 삭제하기
	 */
	$scope.delete = function (szBeforeAction) {
		if (szBeforeAction != 'report') {
			if (!confirm(gtext('삭제하시겠습니까?'))) {
				return;
			}
			$scope.isDisabled = true;
		}
		NoteService.receviceDelete({
			no: $scope.note.no
		}).then(function (response) {
			$scope.isDisabled = false;

			$scope.prev();

		}).catch(function (response) {
			$scope.isDisabled = false;
			$scope.$digest();
		});
	}

	$scope.reply = function () {
		if ($rootScope.bBlock) {
			alert($rootScope.szBlockMsg);
			return false;
		}
		$rootScope.bNickClick = false;
		$state.go('note.write', {
			id: $scope.note.send_id
		});
	}

	/**
	 * 신고하기
	 */
	$scope.report = function () {
		aSpamNo = [];
		if (!confirm(gtext('해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)'))) {
			return;
		}
		$scope.isDisabled = true;
		aSpamNo.push($scope.note.no);
		NoteService.receviceReport({
			no: $scope.note.no
		}).then(function (response) {
			$scope.isDisabled = false;
			$scope.$digest();

			$scope.prev();
		}).catch(function (response) {
			$scope.isDisabled = false;
			alert(response.data.message);
			$scope.$digest();
		});

	}

	/**
	 * 수신거부
	 */
	$scope.reject = function () {
		$scope.isDisabled = true;
		NoteService.receviceReject({
			no: $scope.note.no
		}).then(function (response) {
            if (!confirm(gtext("수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?"))) {
                $scope.isDisabled = false;
                $scope.$digest();
                return;
            } else {
				// 알림 기록 페이지 및 다른 페이지에서 다이렉트로 view로 접근시 기존 parent인 note.controller에서의 $rootScope를 못읽어 와 $state.go 바로 호출로 변경
				$state.go('note.setting');
                $scope.isDisabled = false;
                $scope.$digest();
            }
		}).catch(function (response) {
			$scope.isDisabled = false;
			alert(response.data.message);
			$scope.$digest();
		});
	}

	/**
	 * 수신거부한 아이디가 보낸 쪽지 삭제
	 */
	$scope.rejectIdNoteDelete = function () {
		NoteService.receviceRejectIdNoteDelete({
			no: $scope.note.no
		}).then(function (response) {
			$scope.isDisabled = false;
			if (response.result == 1) {
				$state.go('note.receive', {}, { reload: 'note.receive' });
			}
		}).catch(function (response) {
			$scope.isDisabled = false;
			alert(response.data.message);
			$scope.$digest();
		});
	}

	/**
	 * 보관하기
	 */
	$scope.store = function () {
		$scope.isDisabled = true;
		NoteService.noteStore({
			no: $scope.note.no,
			note_type: 'recv'
		}).then(function (response) {
			alert(response.data.message);	
			$scope.prev();
		}).catch(function (response) {
			alert(response.data.message);
		});
	}

	$scope.getPhrases = function (n) {
		return $sce.trustAsHtml(gtext('(받은 날짜로부터 <em>90</em>일 보관)'));
	}

	NoteService.receviceView({
		no: $stateParams.no
	}).then(function (response) {
		$scope.note = response.data;
		$scope.bLoginAdmin = $scope.note.bAdmin ? true : false;
		$scope.note.content = Util.eacapeBackslash($scope.note.content);
		$scope.$digest();
	}).catch(function (response) {
		alert(response.data.message);

		if(afreeca.link.isWebview()) {
			if(response.data.code == -7710) {
				if($rootScope.prevNoti) {
					history.back();
				} else if($rootScope.prevNoti == undefined) {
					afreeca.link.close();
				} else {
					history.back();
				}
			}
		} else {
			if($rootScope.prevNoti == undefined) {
				history.back();
			} else if(!$rootScope.prevNoti) {
				$state.go('note.receive', {
					deleteNo: $stateParams.no
				});
			} else {
				history.back();
			}
		}
		
	});

	goAosMove = function (url) {
        afreeca.link.browser(url);
	}
	
	goItemMove = function (url, scheme) {
        // 앱내의 웹뷰 일때
        if(afreeca.link.isWebview()) {
			var schemeUrl = scheme == '' ? (SERVICE_APP_SCHEME + 'browser/station?url=' + encodeURIComponent(url)) : scheme;
			document.location.href = schemeUrl;
            return false;
		} else { // 모웹
			window.open(url, "_blank");
            return false;
        }
    }

	$scope.renderContent = function (szContent, szNoteType, nSubType) {
		if (szNoteType == 'DONATION') {
			document.querySelector('.memo_view').innerHTML = Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
		} else {
			return Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
		}
	}

	$scope.nickClick = function ($event, bNickClick) {
		$event.stopPropagation();
		$event.preventDefault();
		$rootScope.bNickClick = !bNickClick;
		return $rootScope.bNickClick;
	}
	
	$scope.showIct = function () {
		return $rootScope.bNickClick ? '' : 'none';
	}

	// 채널 가기
	$scope.goBjStation = function(szUserId){
		$rootScope.bNickClick = false;
		$scope.szStationUrl = BJ_AFREECATV + '/'+szUserId;
		if(Util.getOS() == 'android' && afreeca.link.isWebview() && Util.getOSVersion("6.3.0")['compare']) {
			goAosMove($scope.szStationUrl);
			return;
		}
		if(Util.getOS() == 'android' && afreeca.link.isWebview()) { // android go Station 함수 사용할 경우 본창으로 열리는 이슈가 있어 open 함수 추가
			window.open(SERVICE_APP_SCHEME + "browser/station?url="+$scope.szStationUrl, "_blank");
		} else {
			afreeca.link.goStationLink($scope.szStationUrl);
		}
	}

	$scope.searchUser = function() {
		$state.go('note.receive', {deleteNo: null,searchWord: $scope.note.send_id, searchType: 2}, {reload: 'note.receive'});
	}

	$scope.ictClose = function () {
        $rootScope.bNickClick = false;
    }
	//빠른 답장 UI 팝업창 띄우기
	$scope.openFastReply = function () {

		if ($scope.note.oFastReplyPopUi == undefined) {
			$scope.note.oFastReplyPopUi = document.querySelector('#ui-pop-m_fast_reply');
			$scope.note.oFastReplyDimmed = document.querySelector('#dimmed_fast_reply');
			$scope.note.oFastReplyTextLength = $('#fast_reply_text_length');
			oFastReplyTextArea = document.getElementById("fast_reply_text");//$scope로 잡으니깐 textarea 입력창 초기화가 안됨
			oSaveFastReplyCheckboxDiv = document.getElementById("save_fast_reply_checkbox_div");
			oFastReplySaveCheckbox = document.getElementById("save_checkbox");
		}
		oFastReplySaveCheckbox.checked = true;
		oFastReplySaveCheckbox.disabled = true;
		oSaveFastReplyCheckboxDiv.classList.add('off');
		$scope.note.oFastReplyDimmed.style.display = 'block';
		$scope.note.oFastReplyPopUi.style.display = 'block';
		getSaveFastReply();
    }

	$scope.closeFastReplyPop = function () {
		oFastReplyTextArea.value=''; //이 처리 안하면 빠른 답장 불러오기 전에 이전에 입력했던 텍스트창이 보임
		$scope.note.oFastReplyDimmed.style.display = 'none';
		$scope.note.oFastReplyPopUi.style.display = 'none';
	}

	$scope.sendReplyFast = function (szRecvId, szRecvNick) {
		if (!szRecvId) {
			alert(gtext('받는 아이디/닉네임을 입력해 주세요.'));
			return false;
		}

		if (oFastReplyTextArea.value.length > 500) {
			alert("최대 500자 까지 작성 가능합니다.");
			return false;
		}

		if (!oFastReplyTextArea.value) {
			alert(gtext('쪽지 내용을 입력해 주세요.'));
			return false;
		}

		NoteService.write({
			'receive_id': szRecvId,
			'receive_nickname': szRecvNick,
			'content': oFastReplyTextArea.value,
			'note_cate': 4,
			'save_fast_text_flag': document.getElementById("save_checkbox").checked
		}).then(function (response) {
			$scope.isDisabled = false;
			alert(gtext('전송이 완료되었습니다.'));
			
			document.getElementById("save_checkbox").checked = false;
			$scope.closeFastReplyPop();
			$scope.$digest();
		}).catch(function (response) {
			$scope.isDisabled = false;
			
			$scope.closeFastReplyPop();
			if (response.data.code === -7709) {
				alert(response.data.message);
			} 
			else if (response.data.code === '42S02' || response.data.code === '42S22' || response.data.code === '42000') {
				alert("fail");
			}
			else {
			 	alert(response.data.message);
			}
		});
	}

	getSaveFastReply = function () {
		NoteService.getFastReplyContent({
		}).then(function (response) {
			$scope.isDisabled = false;

			$scope.note.oFastReplyTextLength.text(Util.eacapeBackslash(response.data).length+'/500');
			oFastReplyTextArea.value = Util.eacapeBackslash(response.data);
		
			$scope.$digest();
		}).catch(function (response) {
			$scope.isDisabled = false;
			$scope.$digest();
		});
		
	}
	$scope.changeTextLength = function() {
		if (oSaveFastReplyCheckboxDiv.classList.contains('off')) {
			oFastReplySaveCheckbox.checked = false;
			oFastReplySaveCheckbox.disabled = false;
			oSaveFastReplyCheckboxDiv.classList.remove('off');
		}
		$scope.note.oFastReplyTextLength.text(this.FastReplyText.length+'/500');
	}

	/**
	 * 인앱 결제 여부 확인
	 * @returns {Promise<boolean>}
	 */
	checkInAppPurchase = async () => {
		const szOS = Util.getOS(),
			oVersion = Util.getOSVersion(),
			bPlayStore = Util.isPlayStore();

		// 인앱 체크
		if (!afreeca.link.isWebview()) {
			return false;
		}

		// 허용 기기 확인
		const aAllowOS = ['android'];
		if (!aAllowOS.includes(szOS)) {
			return false;
		}

		// 플레이 스토어 확인
		if (!bPlayStore) return false;

		// 인앱 활성화 여부 조회
		const szVersion = oVersion.version;
		try {
			const oResponse = await ItemService.itemInfoApi({
				work: 'IN_APP',
				type: 'subscription',
				os: 'android',
				version: szVersion
			});
			if (oResponse.RESULT !== 1) {
				throw new Error(oResponse.MESSAGE);
			}

			// 인앱 활성화 여부 확인
			if (!oResponse.DATA) {
				return false;
			}
		} catch (error) {
			return false;
		}

		return true;
	};

	subscribeTypeCheck = async () => {
		await $http.post(
			'${ITEM_M_AFREECATV}/api/subscription/a/info', $.param(data || '')
		).then(function (response) {
			if (response.result == 1) {
				
				$scope.subscribeType = response.data.subscription_type == null || response.data.subscription_type ? false : true;
			} 
		}).catch(function (error) {
			$scope.subscribeType = false;
		})

		return $scope.subscribeType;
	};

	goPlayStore = (szScheme) => {
		subscribeTypeCheck();
		var isPlayStore = Util.isPlayStore();
		var isActiveInApp = checkInAppPurchase();
		szScheme += '&activeInApp=' + (isActiveInApp && isPlayStore);
		szScheme += '&isSubscribed=' + $scope.subscribeType;
		
		document.location.href = szScheme;
		return false;
	}


}
module.exports = NoteReceiveViewController;

Number.prototype.addZero = function () {
	return ('0' + this).substr(-2);
}