var toastr = require('toastr');
LaterviewFactory.$inject = ['$rootScope', '$location', 'AfreecaStorageFactory', 'LoginManager', 'LaterviewService', 'Util', 'gtext'];

function LaterviewFactory($rootScope, $location, AfreecaStorageFactory, LoginManager, LaterviewService, Util, gtext) {


	var LaterviewFactory = this;
	var thenCallback;
	var storageKey = 'laterviewInfoLayer';

	/**
	 * 나중에보기 삭제
	 */
	return {
		ADD: 'add',
		DEL: 'del',
		storageKey: 'laterviewInfoLayer',
		starageVal: 'close',
		isLive: false,
		isShowMessage : true,
		isShowGuide : true,
		data: {

		},
		//after
		then: function (callback) {
			thenCallback = callback;
			return this;
		},
		sheme: function (value) {
			var aUrl = Util.parseURL(value);
			this.isSports = aUrl.params.type === 'PC_SPORTS' || aUrl.params.type === 'SPORTS';
			this.data = {};
			if (aUrl.file === 'live' || aUrl.params.type === 'live') {
				this.isLive = true;
				this.data.type = 'LIVE';
				this.data.broad_no = aUrl.params.broad_no;
			} else {
				this.isLive = false;
				this.data.type = 'VOD_' + aUrl.params.type;
				this.data.title_no = aUrl.params.title_no;
				this.data.user_id = aUrl.params.user_id;
			}
			return this;
		},
		setShowMessage: function(bShowMessage) {
			this.isShowMessage = bShowMessage;
			return this;
		},
		setShowGuide: function(bShowGuide) {
			this.isShowGuide = bShowGuide;
			return this;
		},
		setData: function (data) {
			this.data = data;
			return this;
		},

		//call
		add: function () {
			var that = this;
			return new Promise(function (resolve, reject) {
				//로그인체크
				if (LoginManager.isLogin() === false) {
					if (confirm(gtext('나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?'))) {
						$rootScope.login();
					}
					reject();
				}
				//스포츠이면 
				if (that.isSports) {
					var message = gettextCatalog.getString('나중에 보기를 지원하지 않는 영상입니다.');					
					if(that.isShowMessage){
						toastr.error(response);
						reject();
					} else {
						var response = {
							message : message
						}
						reject(response);
					}
				}

				that.collector();
				LaterviewService.add(that.data).then(function (response) {
					if(that.isShowGuide){
						that.showGuide();
					}
					
					if(that.isShowMessage){					
						toastr.success(response.message);
						resolve();
					} else {
						resolve(response);
					}					
				}, function (error) {
					if(that.isShowMessage){
						toastr.error(error.data.message);
						reject();
					} else {
						reject(error);
					}
				});
			});
		},

		//call
		delete: function (action) {
			if (action == undefined) {
				action = 'one';
			}
			this.data.action = action;
			this.collector(action);
			var that = this;
			return new Promise(function (resolve, reject) {
				LaterviewService.del(that.data).then(function (response) {
					if(that.isShowMessage){
						toastr.info(response.message);
						resolve();
					} else {
						resolve(response);
					}
				}, function (error) {
					if(that.isShowMessage){
						toastr.info(error.data.message);
						reject();
					} else {
						reject(error);
					}
				});
			});
		},
		deleteAll: function (action) {
			if (action == undefined) {
				action = 'all';
			}
			return this.delete(action);
		},
		collector: function (action) {
			//로깅을 위한 데이터 생성
			var log_action = 'register';
			switch (action) {
				case 'one':
					log_action = 'unregister';
					break;
				case 'view':
					log_action = 'viewedunregister';
					break;
				case 'all':
					log_action = 'allunregister';
					break;
				default:
					log_action = 'register';
					break;
			}
			var logdata = {
				src_type: angular.lowercase(this.data.type ? this.data.type : ''),
				action_type: log_action,
				location: this.data.location ? this.data.location : 'list',
				vno: this.data.title_no,
				broad_no: this.data.broad_no,
				bj_id: this.data.bj_id
			};
			//로깅 전송d
			collector.laterView(logdata);

		},
		showGuide: function () {
			if (AfreecaStorageFactory.getItem(this.storageKey) !== this.starageVal) {
				//다시보지않기 체크한적 없는경우
				$rootScope.szLaterMessage = this.isLive ? 'live' : 'vod';
				$rootScope.bLightBoxVisible = true;
				$rootScope.bLaterViewAfterLayer = true;
				$rootScope.szLaterViewAction = this.ADD;
				$rootScope.$apply();
			}

		},
		close: function (checked) {
			if (checked) {
				AfreecaStorageFactory.setItem(this.storageKey, this.starageVal);
			}

			$rootScope.bLightBoxVisible = false;
			$rootScope.bLaterViewAfterLayer = false;
		},

	}
};

module.exports = LaterviewFactory;
