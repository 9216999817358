

LiveService.$inject = ['$http'];
function LiveService($http) {
  this.list = function (data) {
    return new Promise(function (resolve, reject) {
      $http.post(API_M_AFREECATV + '/broad/a/items2', $.param(data || '')).then(function (response) {
        if (response.data.result == 1) {
          resolve(response.data)
        } else {
          reject(response.data);
        };
      }).catch(function (error) {
        reject(error);
      })
    });
  }
}


module.exports = LiveService;