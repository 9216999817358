
videoPlaylist.$inject = ['gtext'];

function videoPlaylist(gtext) {
    return {
        restrict: "E",
        scope: {
            playlist : "=",
            checkvalue : "=",
            bAddPlaylist : "=",
            clickDim : "&"
        },
        template : require('../templates/video.playlist.html'),
        link : function(scope, event, attrs){
            scope.$watch('bAddPlaylist', function(newVal, oldVal) {
                if(!newVal) scope.bCreatePlaylist = false;
            })
        },
        controller : ["$scope", "$rootScope", "Util", "LoginManager","VideoPlaylistService","ToastMessage", function($scope, $rootScope, Util,LoginManager,VideoPlaylistService,ToastMessage){
            $scope.bCreatePlaylist = false;
            $scope.displayTxt = '목록 공개';
            $scope.shareTxt = '목록 퍼가기 허용';

            $scope.insPlaylistView = function(){
                event.preventDefault();

                $scope.bCreatePlaylist = true;
                $scope.bTitleErr = false;

                $("#add_playlist_layer").removeClass("on");
                $(".select_warp").removeClass("on");

                // 공개/비공개, 담기 허용/비허용
                $scope.listDisplay = '';
                $scope.listShare = '';
                $scope.displayTxt = '목록 공개';
                $scope.shareTxt = '목록 퍼가기 허용';

                $('#ins_playlist_list div[name=mobile_display], #ins_playlist_list div[name=mobile_share_yn]').off().on('click',function(e){
                    e.preventDefault();

                    $(this).toggleClass("on");

                    if($(this).attr("name") == "mobile_display") {
						$("#ins_playlist_list div[name=mobile_share_yn]").removeClass("on")
					}
					else{
						$("#ins_playlist_list div[name=mobile_display]").removeClass("on")
                    }
                })
                
                $("#ins_playlist_list div[name=mobile_display] li a, #ins_playlist_list div[name=mobile_share_yn] li a").off().on("click",function(e){
                    e.preventDefault();

                    var $selectDiv = $(this).parent().parent().parent();
                    var selectVal = $(this).data("value");
                    var selectTxt = $(this).text();

                    $(this).parent(".select_warp").toggleClass("on");

                    if($selectDiv.attr("name") == "mobile_display") {
                        $scope.displayTxt = selectTxt;
                        $scope.listDisplay = selectVal;
                    }
                    else {
                        $scope.shareTxt = selectTxt;
                        $scope.listShare = selectVal;
                    }
                    $scope.$digest();
                });

                // 새 재생목록 만들기 > 제목
                var $playlistTitle = $("#ins_playlist_list input[name='playlist_title']");
                $scope.titleLength = 0;
                $playlistTitle.val('');

                setTimeout(function(){
                    $playlistTitle.focus();
                }, 1);

                $playlistTitle.off().on("keyup",function(){
                    if($(this).val().length > 30) {
                        $scope.titleLength = 30;
                        $scope.$digest();
                        return;
                    }

                    $scope.titleLength = $(this).val().length;
                    $scope.$digest();
                })

                $playlistTitle.on("click",function(){
                    $(".select_warp").removeClass("on");
                })
            }

            $scope.addPlaylist = function() {
                var aIdx = [];

                angular.forEach($scope.checkvalue, function(value,index){
                    if(value['check'] == "Y") {
                        aIdx.push(value['id']);
                    }
                });
                
                // check 액션 발생한 갯수 체크
                if(!aIdx.length) {
                    $scope.clickDim();
                    return;
                }

                var title_no = parseInt($('#addPlaylist').data('title-no'));

                var oAddData = {
                    title_no : title_no,
                    nPlaylistIdx : aIdx
                }

                var requestData= {
                    'szWork' : 'ins_playlist_info',
                    'title_no' : oAddData['title_no'],
                    'nPlaylistIdx[]' : oAddData['nPlaylistIdx']
                }

                VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
                   
                        ToastMessage(oRes.msg);
               
                    $scope.clickDim();
                    $("#add_playlist_layer").removeClass("on");
                });
            }

            // 새 재생목록 만들기 > 추가
            $scope.insPlaylist = function(){
                var $playlistTitle = $("#ins_playlist_list input[name='playlist_title']");

                if (!$playlistTitle.val().trim()) {
                    $scope.bTitleErr = true;
                    $scope.titleLength = 0;
                    $playlistTitle.val('');
                    return false;
                }

                var title_no = parseInt($('#addPlaylist').data('title-no'));

                var requestData= {
                    'szWork' : "ins_playlist_list",
                    'title' : $playlistTitle.val(),
                    'title_no' : title_no,
                    'display' : $scope.listDisplay ? $scope.listDisplay : 'Y',
                    'share_yn' : $scope.listShare ? $scope.listShare : 'Y'
                }

                VideoPlaylistService.callPlaylistApi(requestData).then(function(oRes){
                    ToastMessage(oRes.msg);
                    if(oRes.result != 1) return;//실패 케이스
                    $("#add_playlist_layer .PlayListBox").data("page",1);
                    $scope.clickDim();
                });
            }

            $scope.checkChange = function(index){
                $scope.checkvalue[index]['check']  =($scope.checkvalue[index]['check'] == "Y" ? "N" :"Y");
            }
        }]
    }
}

module.exports = videoPlaylist;