TokenFactory.$inject = ['LoginManager', 'CookieFactory', 'TokenService'];
function TokenFactory(LoginManager, CookieFactory, TokenService) {
    var _broadInfo = {};
    var aSendData = {};
    var aToken = {
        CHATTING : 'CHATTING', //라이브 시청 중 하나의 방송 당 최초 1회 채팅 입력할 때 토큰 적립
        LIVE_WATCH : 'LIVE_WATCH', //하나의 방송당 1분 시청 시작할 때 토큰 적립
        LIVE_WATCH_START : 'LIVE_WATCH_START', //시청 시작
        LIVE_WATCH_PAUSE : 'LIVE_WATCH_PAUSE', //시청 일시중지
        LIVE_WATCH_END : 'LIVE_WATCH_END', //시청 정지
        NEW_BJ_LIVE_WATCH : 'NEW_BJ_LIVE_WATCH', //10시간 ~ 500시간이하의 스트리머 방송 시청 시 토큰 추가 적립
        LIVE_WATCHING : 'LIVE_WATCHING', //하나의 방송 당 10분 단위로 1토큰씩 지급되고, 최대 60분까지 총 6토큰 적립
    }

    var otokenTimer = 0; //토큰 지급용 타이머
    var ntokenTimerInterval = 0; //토큰 타이머 시간
    var today = new Date().toISOString().slice(0,10).replace(/-/g,""); //오늘 날짜
    var nTokenCookieValue = null;

    return {
        setTokenInfo: function (type, broadInfo) {
            //로그인일 경우만 토큰 적립
            if(!LoginManager.isLogin()){
                //ct 쿠키 제거(로그아웃시 쿠키 제거해줘야함)
                CookieFactory.set('ct', null, -1);
                return;
            }

            _broadInfo = broadInfo;

            aSendData = {
                bj_id : _broadInfo.bj_id,
                bj_nick : _broadInfo.user_nick,
                common_no : _broadInfo.broad_no
            };

            nTokenCookieValue = today + _broadInfo.broad_no;

            switch(type){
                case aToken.CHATTING :
                    if(CookieFactory.get('ct') != nTokenCookieValue){
                        TokenService.sendTokenLog(LoginManager.getUserId(),type, aSendData);
                        CookieFactory.set('ct', nTokenCookieValue, 1); //최초 1회 체크용 쿠키 설정
                    }
                    break;
                case aToken.LIVE_WATCH_START :
                    if(otokenTimer != 0) return;

                    var types = null;
                    //토큰 적립을 위한 interval 시작
                    otokenTimer = setInterval(function(){
                        //인터벌 도는 중에 비로그인 상태로 바뀐걸 확인하면 인터벌 중지
                        if(!LoginManager.isLogin()){
                            clearInterval(otokenTimer);
                            ntokenTimerInterval = 0;
                            otokenTimer = 0;
                        }

                        ntokenTimerInterval++;
                        if(ntokenTimerInterval >= 1800 && ntokenTimerInterval <= 9000){
                            if(ntokenTimerInterval % 1800 == 0){    //30분마다 지급
                                //최대 150분 까지만 토큰 적립이라 150분 넘으면 타이머 중지
                                if(ntokenTimerInterval == 9000){
                                    clearInterval(otokenTimer);
                                }
                                types = aToken.LIVE_WATCHING;
                                aSendData['number'] = ntokenTimerInterval / 60;

                                TokenService.sendTokenLog(LoginManager.getUserId(), types, aSendData);
                            }
                        }
                        //console.log(ntokenTimerInterval);
                    },1000);
                    break;
                case aToken.LIVE_WATCH_END :
                case aToken.LIVE_WATCH_PAUSE : 
                    if(!otokenTimer) return;
                    clearInterval(otokenTimer);
                    ntokenTimerInterval = 0;
                    otokenTimer = 0;
                    break;
            }
        }
    }
};

module.exports = TokenFactory;