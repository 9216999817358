/**
 * 하위버전 안드로이드 단말에서 ng-click을 사용할경우 
 * 두번 클릭됨  touchstart 로 대치함
 */
ngContenteditable.$inject = ['$filter']
function ngContenteditable($filter) {
        return {
                require: 'ngModel',
                scope: {
                        ngModel: '=',
                        showLayer: '=?'
                },
                link: function (scope, elm, attrs, ctrl) {
                        // view -> model
                        elm.bind('keyup', function () {
                                scope.$apply(function () {
                                        ctrl.$setViewValue(elm.html());
                                });
                        });
                        elm.bind('click', function () {
                                scope.$apply(function () {
                                        scope.showLayer = false;
                                });
                        });

                        // model -> view
                        ctrl.$render = function () {
                                elm.html(ctrl.$viewValue);
                        };

                        // load init value from DOM
                        // ctrl.$setViewValue(elm.html());
                }
        }
}

module.exports = ngContenteditable;