
RecentService.$inject = ['$rootScope', '$location', 'AfreecaStorageFactory','$http'];
function RecentService($rootScope, $location, AfreecaStorageFactory, $http) {

        return {
                removeAll: function () {
                        return new Promise(function (resolve, reject) {
                            $http.post(API_M_AFREECATV + '/broad/a/delallwatchrecord').then(function (response) {
                                resolve(response.data);
                            }).catch(function (error) {
                                reject(error);
                            })
                        });
                },
                remove: function (data) {
                        return new Promise(function (resolve, reject) {
                            $http.post(API_M_AFREECATV + '/broad/a/delwatchrecord', $.param(data || '')).then(function (response) {
                                resolve(response.data);
                            }).catch(function (error) {
                                reject(error);
                            })
                        });
                },
                add: function (szUserId) {

                        var aRecentUsers = AfreecaStorageFactory.getItem('aRecentUsers') ? JSON.parse(AfreecaStorageFactory.getItem('aRecentUsers')) : [];
                        // 100개 이상일때 가정 먼저 추가한 user제거
                        if (aRecentUsers.length >= 100) {
                                aRecentUsers.splice(0, 1);
                        }

                        // 중복체크
                        if (aRecentUsers.indexOf(szUserId) < 0) {
                                aRecentUsers.push(szUserId);
                                AfreecaStorageFactory.setItem('aRecentUsers', JSON.stringify(aRecentUsers));
                        }
                },
                getAll: function (data) {
                        return new Promise(function (resolve, reject) {
                            $http.post(API_M_AFREECATV + '/broad/a/getwatchrecord', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                    resolve(response.data);
                                } else {
                                    reject(response.data);
                                };
                            }).catch(function (error) {
                                reject(error);
                            })
                        });
                }
        }
};

module.exports = RecentService;