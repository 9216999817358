module.exports = {
	'홈': 'Home',
	'핫이슈': 'Hot Issues',
	'나중에보기': 'Watch Later',
	'전체': 'All',
	'로그인': 'Log In',
	'최신순': 'Recent',
	'참여인원순': 'Viewer',
	'즐겨찾기 추가': 'Add to Favorites',
	'방송국 보러가기': 'Go to Profile',
	'나중에보기 등록': 'Add to Watch Later',
	'목록편집': 'Edit Playlist',
	'전체삭제': 'Delete All',
	'재생한 VOD 삭제': 'Delete Recently Watched',
	'시청한 VOD': 'Recently Watched',
	'다시보기': 'Replay',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': 'You can watch your Watch Later<br> videos after logging in.',
	'나중에 보기에 등록한 VOD가 없습니다.': 'No VOD added to Watch Later list',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': 'Add VOD you wish to watch later',
	'나중에보기 삭제': 'Delete from list',
	'나중에 보기 서비스란?': 'What is \Watch Later\?',
	'나중에 보기에 등록되었습니다.': 'Added to Watch Later',
	'나중에 보기가 삭제되었습니다.': 'Removed from Watch Later',
	'라이브 방송을 시청할 수 없는 상황일 때': 'Unable to watch Live streams?',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': 'Add it to Watch Later list and watch it at your convenience!',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': 'You can watch added VOD using<br><span class="blue">our Watch Later</span> function',
	'보고 싶은 VOD나, 아직 보지 않은': 'Conveniently watch videos,',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': 'by saving it to your Watch Later list!,',
	'다시 보지 않기': 'Don’t show again',
	'닫기': 'Close',
	'나중에 보기를 지원하지 않는 영상입니다.': 'The video does not support Watch later',
	'쪽지함': 'Message',
	'새 쪽지 <span>%s</span>개': '<span>%s</span> New Messages',
	'새 쪽지가 없습니다': 'No New Messages',
	'쪽지 보내기': 'Send Message',
	'쪽지설정': 'Message Settings',
	'받은 쪽지함': 'Inbox',
	'보낸 쪽지함': 'Sent',
	'쪽지 보관함': 'Saved Messages',
	'전체 쪽지': 'All Messages',
	'안읽은 쪽지': 'Unread',
	'보관한 쪽지':'Saved Messages',
	'보관한 쪽지가 없습니다.': 'There are no saved messages.',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': 'Saved messages will be permanently stored. (Max 1,000 messages)',

	'보관': 'Save',
	'신고': 'Report',
	'삭제': 'Delete',
	'받은 쪽지가 없습니다.': 'You have yet to receive a message.',
	'읽지않음': 'Unread',
	'읽음': 'Read',
	'더 보기': 'See More',
	'받은 쪽지': 'Received',
	'보낸 사람': 'Sender',
	'받은 시각': 'Received',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': 'Messages will be deleted 90 days after reading.',
	'답장': 'Reply',
	'수신거부': 'Block',
	'보낸 쪽지가 없습니다.': 'You have yet to send a message.',
	'보냄': 'Sent',
	'보낸 쪽지': 'Sent',
	'받는 사람': 'Recipient',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': 'Sent messages are automatically deleted in 90days.',
	'쪽지 수신 설정': 'Message Settings',
	'전체 수신거부': 'Block All Messages',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': 'Block messages from all users.<br>(Excluding admin ID)',
	'수신거부 ID 목록': 'Block List',
	'총 %s명': 'Total of %s',
	'아이디': 'Username',
	'추가 날짜': 'Date Added',
	'수신거부 목록이 없습니다.': 'No list created',
	'수신거부 해제': 'Unblock',
	'추가': 'Add',
	'수신거부 추가': 'Add to Block List',
	'쪽지 보내기': 'Send Message',
	'보내기': 'SEND',
	'받는 아이디': 'Username',
	'다중 전송은 엔터키로 구분해 주세요.': 'Please separate multiple entries by enter key',
	'최대 5,000자까지 작성 가능합니다.': 'Enter up to 5,000 characters.',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': 'The function  has been blocked due to use of forbidden word. Please contact CS for mere information',
	'신고가 접수되었습니다.': 'Your report has been registered.',
	'선택한 쪽지를\n신고하시겠습니까?': 'Would you like to report this message?',
	'쪽지를 선택해 주세요.': 'Please select message.',
	'선택한 쪽지를\n삭제하시겠습니까?': 'Would you like to delete this message?',
	'쪽지를 선택해 주세요.': 'Please select message.',
	'삭제하시겠습니까?': 'Would you like to delete?',
	'신고하시겠습니까?': 'Would you like to report?',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': 'Successfully blocked. You can unblock users through settings.',
	'아이디를 입력해 주세요.': 'Enter Username',
	'아이디는 영문,숫자만 입력 가능합니다.': 'Please enter only english and numerical characters.',
	'아이디를 선택해 주세요': 'Please select username.',
	'해제되었습니다.': 'Released',
	'한 번에 최대 30명까지만 전송 가능합니다.': 'You can enter up to 30 recipients.',
	'본인에게는 쪽지를 보낼 수 없습니다.': 'You cannot add your own username to the list of recipients.',
	'이미 받는사람 목록에 포함 되어 있습니다.': 'Already included on the recipient list.',
	'%s은(는) 존재하지 않는 닉네임/아이디입니다.': 'User %s does not exist',
	'받는 아이디/닉네임을 입력해 주세요.': 'Please fill in ID/Nickname to send.',
	'쪽지 내용을 입력해 주세요.': 'Please enter your details.',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': 'You can enter up to 30 recipients.',
	'전송이 완료되었습니다.': 'Successfully Sent.',
	'로그인이 되어 있지 않습니다.': 'Not logged in',
	'아이디는 12자 이내로 입력해 주세요.': 'ID is limited to 12 characters',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': 'Reported! (Exclude admin username)\nWould you like to delete the message you reported?',
	'잠시 후 이용해 주세요.': 'Please try again later.',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': 'Blocked! (Excluding Admin ID)\nWould you like to check this at your Block Settings?',
	'이미지 첨부는 3개까지 가능합니다.': 'You can attach up to 3 images',
	'이미지 첨부는 8MB까지 가능합니다.': 'You can attach images up to 8MB.',
	'이미지가 저장되었습니다.': 'Image saved.',
	'확인': 'Confirm',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(Saved for <em>90</em> days from the recieved date)',
	'이미지 첨부': 'Attach Image',
	'첨부 이미지': 'Image',
	'이미지 저장 기간': 'Image saved period',
	'이미지 저장 기간 만료': 'Image saved period expired',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(Saved for <em>90</em> days from the sent date)',
	'이미지 파일은 1개씩 첨부 가능합니다.': 'Image files can be individually uploaded',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': 'Only JPG, JPEG, GIF, PNG, and BMP images are supported.',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': 'Saved messages will be permanently stored.',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)':'Saved messages will be permanently stored.(Max 1,000 messages)',
	'이미지 첨부 중입니다.': 'Attaching image.',
	'해당 쪽지가 삭제되었습니다.' : 'You have deleted the message.',
	'해당 쪽지가 신고되었습니다.' : 'You have reported the message.',
	'수신거부가 완료되었습니다.' : 'Blocked!',
	'해당 쪽지가 발송 취소되었습니다.' : 'You have unsent the message.',
	'이미 신고된 쪽지입니다.':'You have already reported this message.',
	'수신거부 ID 추가' : 'Add ID to block',


	'즐겨찾기': 'Favorites',
	'즐찾': 'Favorites',
	'방송중': 'Streaming',
	'방송 시작 전': 'Offline',
	'BJ 랭킹순': 'BJ Rank',
	'방송 중인 즐겨찾기 BJ가 없습니다.': 'No Stream from Favorites',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': 'No Streamer from Favorite is online',
	'즐겨찾기한 BJ가 없거나': 'No Favorite Streamer or',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': ' 생방송 중인 즐겨찾기 BJ가 없습니다.',
	'상세정보': 'Details',
	'추천 받지 않기': 'No Recommendation',
	'즐겨찾기 삭제': 'Delete Favorite',
	'명 시청중': 'Viewing',
	'BJ랭킹순': 'Streamer Rank',
	'회원가입': 'Sign Up',
	'고객센터': 'Help',
	'최근 본 방송': 'History',
	'지상파/케이블': 'Cable TV',
	'보이는라디오': 'Radio',
	'스포츠': 'Sports',
	'게임': 'Games',
	'애니': 'Anime',
	'콘텐츠발전소': 'Content Refinery',
	'별풍선': 'Star Balloons',
	'장애/버그신고': 'Report a bug',
	'공지사항': 'Announcement',
	'게임센터': 'Game Center',
	'더보기': 'See more',
	'신인': 'Rookie',
	'시청자순': 'Viewer',
	'참여자순': 'Viewer',
	'1일': '1 Day',
	'1주': '1 Week',
	'1개월': '1 Month',
	'즐겨찾기할만한방송': 'Add to Favorites!',
	'아프리카TV앱': 'AfreecaTV App',
	'바로가기': 'Download',
	'정보': 'Information',
	'댓글': 'Comments',
	'승인': 'Approval',
	'인기방송': 'Top VOD added by viewers ',
	'내가 보유한 별풍선': 'My Purchased Star Balloons',
	'확인': 'Confirm',
	'취소': 'Cancel',
	'스티커': 'Sticker',
	'구매하기': 'Purchase',
	'직접입력': 'Other',
	'초콜릿': 'Chocolate',
	'로그인상태유지': 'Keep me logged in',
	'개': 'EA',
	'비밀번호': 'Password',
	'최근 본 방송 내역 전체 삭제하기': 'Entirely delete your recent view list',
	'전체방송': 'All broadcasts',
	'모바일게임': 'Mobile Games',
	'애니메이션': 'Animation',
	'먹방/쿡방': 'Mukbang',
	'음악': 'Music',
	'시사/현장': 'Current Events',
	'주식/금융': 'Stocks / Finance',
	'금융': 'Finance',
	'생활/정보': 'Lifestyle',
	'공부방': 'Study Room',
	'보이는쇼핑': 'Virtual Shopping',
	'케이블': 'Cable',
	'1:1문의': '1:1 Questions',
	'엔터테인먼트': 'Entertainment',
	'창업방': 'Start-up Zone',
	'VOD': 'Video',
	'개인방송국': 'Profile',
	'핫이슈동영상': 'Hot VODs',
	'로그아웃': 'Log Out',
	'방송시작전': 'Offline',
	'아이디찾기': 'Find username',
	'방송국보러가기': 'Go to Profile',
	'연령제한': 'Age Restricted',
	'청소년유해신고': 'Report foul content',
	'국내야구': 'South Korean Baseball',
	'일본야구': 'Japanese Baseball',
	'프로농구': 'Professional Basketball',
	'프로배구': 'Professional Volleyball',
	'UFC/일반': 'UFC / General',
	'프로골프': 'Professional Golf',
	'특집VOD': 'Special VOD',
	'스포츠특집': 'Sports Features',
	'게임특집': 'Game Features',
	'명시청중': 'Viewers',
	'내가 보유한 골드': 'My Purchased Gold',
	'내가 보유한 초콜릿': 'My Purchased Chocolate',
	'아프리카TV': 'AfreecaTV',
	'아프리카TV,': 'AfreecaTV',
	'랭킹순': 'Streamer Rank',
	'님이': '-',
	'비밀번호찾기': 'Find Password',
	'최근본방송 삭제': 'Delete',
	'최근본방송추가': 'Add Recently Watched Broadcasts',
	'시청한 방송이 없습니다': 'You have yet to view any Streams',
	'지금 바로 아프리카TV의 재미있고': 'Get ready to come across variety of fun at AfreecaTV!',
	'다양한 방송을 만나보세요!': 'Watch various Streams',
	'[생]': 'Live',
	'[재]': 'Replay',
	'[중계방]': 'Relay',
	'아이디비번찾기': 'Find Username / Password',
	'명시청': 'Viewers',
	'레이디스배틀': 'Ladies Battle',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': 'Incorrect username or password.',
	'아이디/비밀번호찾기': 'Find Username / Password',
	'님이빠져들만한방송': 'Recommended Broadcasts',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': 'Currently under maintenance. You can access this feature soon!',
	'구매': 'Purchase',
	'즐겨찾기추가': 'Add to Favorites',
	'베스트BJ방송': 'Best Broadcaster Broadcasts',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': 'Would you like to entirely delete your recently watched broadcast list? *Please note that you can delete each broadcasts separately by selecting see more',
	'자신에게는선물하실수없습니다.': 'You cannot send a gift to yourself.',
	'현재시청자수': 'Watching now',
	'모바일': 'Mobile',
	'누적 시청자 수': 'Total Views',
	'유머/패러디': 'Comedy',
	'베스트BJ': 'Best Broadcasters',
	'신인BJ': 'New Broadcasters',
	'파트너BJ': 'Partner Broadcasters',
	'뷰티/패션': 'Beauty / Fashion',
	'샵프리카': 'Shopfreeca',
	'MY쇼핑': 'MY Shopping',
	'마인크래프트': 'Minecraft',
	'히어로즈': 'Heroes',
	'메이플스토리2': 'Maple Story 2',
	'피파온라인3': 'FIFA ONLINE 3',
	'검은사막': 'Black Desert',
	'블랙스쿼드': 'Black Squad',
	'하스스톤': 'Hearthstone',
	'문명': 'Civilization',
	'버블파이터': 'Bubble Fighter',
	'마비노기': 'Mabinogi',
	'사이퍼즈': 'Cyphers',
	'메이플스토리': 'Maple Story',
	'테일즈런너': 'Tales Runner',
	'서든어택': 'Sudden Attack',
	'마영전': 'Vindictus',
	'리니지': 'Lineage',
	'스타': 'StarCraft',
	'스타2': 'StarCraft II',
	'아키에이지': 'ArcheAge',
	'카트라이더': 'Kart Rider',
	'모두의마블': 'Everybodys Marble',
	'디아블로3': 'Diablo III',
	'월드오브탱크': 'World of Tanks',
	'워크래프트': 'Warcraft',
	'아이온': 'Aion',
	'던파': 'Dungeon&Fighter',
	'스페셜포스': 'Special Force',
	'고전게임': 'Vintage Games',
	'비디오게임': 'Video Games',
	'기타': 'Other',
	'LOL': 'League of Legends',
	'BladeNSoul': 'Blade & Soul',
	'WOW': 'World of Warcraft',
	'종교': 'Religious',
	'요리/건강': 'Cooking & Health',
	'자습': 'Study',
	'강좌': 'Lecture',
	'멘토': 'Mentor',
	'프로야구': 'Major League Baseball',
	'농구': 'Basketball',
	'골프': 'Golf',
	'국내축구': 'Korea Soccer',
	'해외축구': 'Soccer',
	'스타리그': 'StarLeague',
	'어학': 'Language',
	'지식': 'Learning',
	'취미': 'Hobbies',
	'토크/캠방송': 'Social',
	'나루토': 'Naruto',
	'명탐정코난': 'Detective Conan',
	'교육': 'Educational',
	'토크/캠방': 'Social',
	'별풍선 &#183; 아이템': 'Star Balloon Item',
	'별풍선ㆍ아이템': 'Star Balloon Item',
	'보.라': 'Radio',
	'엔터': 'Entertainment',
	'파트너BJ방송': 'Partner Broadcasters',
	'게임전체': 'All Games',
	'스포츠전체': 'All Sports',
	'보이는라디오전체': 'All radio',
	'엔터전체': 'All Entertainment',
	'농구/NBA': 'Basketball / NBA',
	'시청자가밀어주는방송': 'Viewership Supported',
	'클랜이밀어주는방송': 'Clan Supported',
	'PC게임': 'PC Games',
	'멸망전': 'Elimination',
	'라이프': 'Life',
	'라이프전체': 'All Life',
	'더빙/리뷰': 'Dubbing / Review',
	'격투기/일반': 'Martial Arts / Standard',
	'샵프리카Beta': 'Shopfreeca Beta',
	'라이브': 'Live',
	'배구': 'Volleyball',
	'농구/배구': 'Basketball / Volleyball',
	'별풍선·아이템': 'Star Balloons / Items',
	'실시간핫이슈': 'Trending Hot Issues',
	'오버워치': 'Overwatch',
	'리니지LFC': 'Lineage LFC',
	'테라': 'Tera',
	'철권': 'Tekken',
	'스파5': 'Street Fighter V',
	'스팀게임': 'Steam Games',
	'뽐': 'FFOM',
	'가우스X아프리카TV': 'Gaus X AfreecaTV',
	'유로2016': 'Euro 2016',
	'LoL공식리그': 'LoL Leagues',
	'e스포츠': 'e-Sports',
	'세계정복2': 'World Conquest II',
	'클래시로얄': 'Clash Royale',
	'세븐나이츠': 'Seven Nights',
	'레이븐': 'Raven',
	'쿠키런': 'Cookie Run',
	'기타게임': 'Other',
	'두산': 'Doosan',
	'한화': 'Hanwha',
	'롯데': 'Lotte',
	'넥센': 'Nexon',
	'삼성': 'Samsung',
	'중립': 'Baseball',
	'채널A': 'Channel A',
	'TV조선': 'TV Chosun',
	'연합뉴스': 'Yonhap News',
	'몬길': 'Monster Tamer',
	'서든어택2': 'Sudden Attack II',
	'BJ리그': 'BJ League',
	'포켓몬GO': 'Pok?mon Go',
	'2016BJ대상': '2016 Broadcaster Award',
	'학습': 'Learning',
	'애니메이션관': 'Animation',
	'콘텐츠제작지원센터': 'Content Support Center',
	'알려드립니다': 'Notice',
	'개인정보처리방침': 'Privacy Guideline',
	'개인정보 수정': 'Edit Account Details',
	'내 정보': 'My Info',
	'로그인 기기관리': 'Manage Logged In Devices',
	'내 방송국': 'My Blog',
	'본방': 'Live Broadcast',
	'MY뽐': 'My FFOM',
	'2차비밀번호입력': 'Enter Secondary Password',
	'2차비밀번호': 'Secondary password',
	'입력내용삭제': 'Delete entered details',
	'2차비밀번호찾기': 'Search for secondary password',
	'MOBILE': 'MOBILE',
	'나중에설정': 'Do it later',
	'닉네임': 'Nickname',
	'고화질로시청': 'Watch in HD',
	'멀티방송': 'Split Screen',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'KakaoTalk',
	'참여중인중계방': 'Currently already in this relay broadcast',
	'멀티방송화면리스트': 'Split Screen List',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': 'You can purchase products showcased in the broadcast by clicking the View Product button.',
	'앱에서시청하기': 'Watch on App',
	'상품보기': 'View Product',
	'선물하기': 'Gift',
	'전송': 'Send',
	'비밀번호 변경': 'Change Password',
	'2차 비밀번호 설정': 'Set Secondary Password',
	'건강': 'Health',
	'이슈방송스케줄': 'Hot Issue Schedule',
	'PC버전으로보기': 'Switch to Desktop Mode',
	'LoL챌린저스': 'LoL challengers',
	'오버워치BJ리그': 'Overwatch League',
	'격투게임': 'Fighting game',
	'별풍선/아이템 정보': 'Item Balance',

	'VOD가 없습니다.': 'No VOD',
	'UP순': 'UPs',
	'댓글순': 'Replies',
	'조회순': 'Viewers',
	'1주일': '1 Week',
	'방송국': 'Profile',
	'인기순' : 'Popular',

	'사용 중인 퀵뷰가 없습니다.': 'No QuickView in use',
	'새로고침': 'Refresh',
	'사용중': 'In use',
	'무료사용중': 'In Free Trial',
	'자동결제': 'Auto payment',
	'사용기간이': 'Duration date',
	'일': 'Days',
	'시간': 'Hours',
    '남았습니다.': 'left',
	'다음 결제일': 'Next payment day',
	'보유 중인 퀵뷰': 'My QuickView',
	'보유중인 퀵뷰가 없습니다.': 'No Quickview',
	'보유 중인 퀵뷰 선물권': 'My QuickView Gifts Vouchers',
	'전체 방송': 'All Stream',
	'아이템효과 닫기': 'Close item effect',
	'채팅토글': 'Toggle Chat',
	'앱에서 참여하기': 'View from App',
	'아프리카TV 앱에서 참여하기': 'View from AfreecaTV app',
	'이모티콘': 'Emoticon',

	'광고 Skip, 본방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': 'Skip Ads, Enter main stream, Live Rewind function, etc. <br>Try using the <strong>QuickView item</strong> with diverse benefits.',
	'아프리카TV 상품권 등록 하세요!': 'Register  AfreecaTV  Certificate! ',

	'즐겨찾기에 추가됐습니다.': 'Added to Favorite',
	'바람의나라': 'World of Wind',
	'닉네임 변경': 'Change Nick Name',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': 'Nick Name can be changed upto 12 English characters',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': 'NIck Name can be changed again after 24 hours.',
	'닉네임 적용하기': 'Apply Nick Name',
	'중복확인': 'Check',
	'내정보': 'My Info',
	'퀵뷰 정보': 'Quickview info.',
	'아이템': 'Item',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': 'Login is needed to add to Favorite. Go to login page?',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': 'Delete stream from \n Favorite?',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': 'Delete stream from Favorite?',

	'사용하기': 'Use',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': 'Once blinded, you are unable to see the broadcast or chat in it. If you exit the broadcast while blinded, re-entry will not be possible as you will be kicked out automatically.',
	'고객센터 문의하기': 'Contact Customer Service',
	'비밀번호 설정 방송': 'Password-Protected Broadcast',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'This is a password-protected broadcast. <br>You can only watch it from the AfreecaTV app.',
	'연령제한 방송': 'Age Restricted Stream',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'This broadcast is restricted to mature audiences only. <br>You can only watch it from the AfreecaTV mobile app.',
	'연령제한 / 비밀번호 설정 방송': 'Age Restricted / Password Protected Streams',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': 'This broadcast is restricted to mature audiences only. <br>You can only watch it from the AfreecaTV mobile app.',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': 'Currently not broadcasting. <br>See more through the AfreecaTV App!',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 참여가 가능합니다.': 'Currently not broadcasting.<br> See more through the AfreecaTV App!',
	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': 'The broadcaster has paused the stream.',
	'다시 시작될 때까지 잠시만 기다려주세요.': 'Please wait a moment.',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': 'Send your questions regarding banning and suspension to Customer Service',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 있습니다.': 'Check valid QuickView items and press [Use] to apply QuickView item',

	'즐겨찾기가 삭제되었습니다.': 'Removed from Favorites',
	'알람': 'Alarm',
	'유료': 'Paid',
	'알람 닫기': 'Close notice',
	'알림 예약': 'Reserve notice',
	'알림 취소': 'Cancel notice',
	'현재 진행 중인 방송이 없습니다.': 'No streams',
	'곧,  BJ들의 재미있고 다양한 방송이': 'Fun and various Stream will',
	'시작됩니다.': 'start soon',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>No streams</strong><br>Fun and various Stream will<br>start soon',
	'방송이 시작되면': 'Send notice',
	'알려 드릴까요?': ' when stream starts?',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>Notification has already been <br> scheduled',
	'운영자에 의해<br ng-show="newLine">': ' ',
	'되었습니다': ' by Admin',
	'운영자 강제퇴장 안내': 'Admin ban notice',
	'강제퇴장': 'Ban',
	'회차': 'times',
	'(채팅금지 24시간)': '(Chat prohibition 24hr)',
	'강제퇴장 및 3일 이용정지': 'Ban & 3 Day suspension',
	'강제퇴장 및 7일 이용정지': 'Ban & 7 Day suspension',
	'강제퇴장 되어 방송을 보실 수 없습니다.': 'Unable to watch due to suspension',
	'앱을 설치하시면 구매가 가능합니다.': 'Purchase can be made when app is installed',
	'[안내]': '[Notice]',
	'방송에 입장하였습니다.': 'You have entered the stream.',
	'채널 퇴장하셨습니다.': 'You have left Stream',
	'로그아웃 하시겠습니까?': 'Would you like to log out?',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'Restricted to viewers under age of 19. It can only be watched in AfreecaTV app. \n\nWould you like to view on AfreecaTV app?',
	'알림 예약이 완료되었습니다.': 'Alert has been registered',
	'알림 예약이 취소되었습니다.': 'Alert has been cancelled',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': 'Restricted to viewers under age of 19. It can only be watched in AfreecaTV app. \n\ nWould you like to view on AfreecaTV app?',
	'유효기간': 'Valid date',
	'등록되었습니다.': 'Registered',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': 'Unable to register while using Quickview.',
	'오류가 발생하여 이용하실수 없습니다.': 'Unavailable due to an error',

	'검색결과': 'Search Result',
	'검색결과가 없습니다.': 'No Search Result',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': 'Nickname has been changed. You can change it again after 24 hours',
	'사용할 수 없는 닉네임입니다.': 'Unavailable nickname.',
	'닉네임 중복 확인을 해주세요.': 'Please check duplicate nickname.',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': 'Chat input is not supported. Please use AfreecaTV app',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'This is a password-protected broadcast. You can only watch it from the AfreecaTV app.',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'This is a password-protected broadcast. You can only watch it from the AfreecaTV app.',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': 'Due to copyright issues, this broadcast is not available outside of South Korea.',
	'강제퇴장 되어 방송을 볼 수 없습니다.': 'You have been banned from this broadcast.',
	'로그인 되었습니다.': '',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': 'Unstable connectivity. Would you like to try again?',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': 'Unstable connectivity. \n\nWould you like to try again?',
	'채팅 메세지를 입력하세요.': 'Write a message in the chat box!',
	'1개 이상 선물이 가능합니다.': 'You do not have enough Star Balloons!',
	'보유하신 별풍선이 부족합니다.': 'You do not have enough Star Balloons!',
	'보유하신 골드가 부족합니다.': 'You do not have enough Sticker.',
	'보유하신 초콜릿이 부족합니다.': 'You do not have enough Chocolate.',
	'선물하기 서버 문제로 이용하실 수 없습니다.': 'Our gift option is temporarily unavailable due to server issues.',
	'로그인 하셔야 선물 하실 수 있습니다.': 'You must log in to send gifts.',

	'즐겨찾기 바로가기': 'Favorite Shortcut',
	'멀티방송 화면리스트': 'Multi Stream Screen List',
	'현재 유저수': 'Current Viewer',
	'중계방': 'Relay Room',
	'PC버전으로 보기': 'View PC version',
	'앱에서 보기': 'View in app',
	'명': ' ',

	'본방입장': 'Enter Main room',
	'방송보기': 'View Stream',
	'잠시 쉬어갑니다.': 'is taking a short break.',
	'점검시간': 'Time',
	'점검영향': 'Details',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': 'Sorry for the inconvenience. ',
	'이용에 불편을 드려 죄송합니다.': 'We are currently under maintenance to offer<br>a more stable and reliable service.',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': 'We\'re working hard to improve our service.',
	'채팅안보기 해제': 'Unhide Chat',
	'채팅안보기': 'Hide Chat',

	'즐겨찾기에 추가됐습니다.': 'Added to Favorite',
	'[아프리카TV 안내]': '[Afreeca TV Notice]',
	'운영자 안내': 'Admin Notice',
	'운영자': 'Admin',
	'BJ': 'Streamer',
	'매니저': 'Manager',
	'클린아티': 'Clean Arti',
	'%s에 의해 강제퇴장 되었습니다.': '%s has banned you.',
	'%s님은 %s에 의해 채팅금지 %s회가 되셨습니다.': '%s has been banned by %s  %s times.',

	'로그인 서버오류가 발생하였습니다.': 'Login server error has occurred.',
	'지원하지 않는 OS버전입니다.': 'OS version is not supported',
	'아프리카TV 앱으로 시청해주세요.': 'Please watch using AfreecaTV app.',
	'버퍼링 중': 'Buffering',
	'애청자': 'Favorite',
	'팬클럽': 'Fan Club',
	'통합검색': 'Search',
	'생방송': 'Live',
	'정확도순': 'Order of accuracy',
	'방송시간순': 'Order stream',
	'누적참여자순': 'Cumulative viewer',
	'최신방송순': 'Order of recent stream',
	'적용 되었습니다.': 'Applied',
	'채팅창을 얼렸습니다.': 'Chat Window has been frozen',
	'채팅 참여 등급이 변경되었습니다.': 'Chat participation rank has been changed',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': 'Only Streamer and the manager can participate in the chat.',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': 'Only Streamer, manager and Fan club can participate in the chat.',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': 'Only Streamer, managers and supporters can participate in the chat.',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': 'Only Streamer, manager, fan and supporters can participate in the chat.',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': 'Chat window has been unfrozen, you can join the chat',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "You cannot enter \'%s\', as it contains a prohibited word",
	'검색시스템에 문제가 발생했습니다.': 'A problem with our search system has occured.',
	'검색어를 입력하세요.': 'Enter search word',
	'|중계방송|': '|Relay room|',

	'BJ가 방송을 종료하였습니다.': 'Streamer has ended stream',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": "\'%s\' Would you like to refuse UP for your broadcast?",
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': 'Quickview item is needed to enter Main room. Go to Quickview purchase page now?',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': 'This is a mobile live cam broadcast. If streamer pauses, the broadcast may stop or black screen may appear. (When pause is canceled, the broadcast will show again.',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'UP is available in AfreecaTV app.\n\nWould you like to watch it on the AfreecaTV app?',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'HD view is available in AfreecaTV app.\n\nWould you like to watch it in AfreecaTV app?',
	' 이상~': ' or more',

	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%s has been silenced by%s. Chat is prohibited for 30 seconds.',
	'%s님이 %s에 의해 채팅금지 %s회가 되셨습니다.': '%s has been silenced by %s %s times.',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%s has been silenced by%s. Chat is prohibited for 60 seconds.',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리되었습니다.': '%s has been blinded for 2minutes by %s due to exceeded chat ban count.',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%s has been blinded for 2minutes by %s due to exceeded chat ban count. When blinded, the screen and chat are not visible. If you leave the broadcat in the blind state, it will be automatically ban user and the user will not be able to enter stream',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제퇴장 처리되었습니다.': '%s(%s) was banned due to attemption to escape during blind condition.',
	'%s(%s)님이 강제퇴장 처리되었습니다.': '%s(%s) has been banned.',
	'%s(%s)님이 강제퇴장 취소 처리되었습니다.': '%s(%s) has been canceled from ban.',
    '%s님이 별풍선 %s개를 선물했습니다!': '%s has donated %s star balloon(s)!',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': '%s has donated %s star balloon(s) in the relay stream!',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': '%s has donated %s star balloon(s) in VOD!',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%s has donated %s video balloon(s)!',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': '%s has donated %s video balloon(s) in the relay stream!',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%s has become %s Fan club.',
	'%s님이 스티커 %s개를 선물했습니다!': '%s has donated %s sticker(s)!',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%s has became %s supporters.',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': '%s has donated %s sticker(s) in the relay stream!',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%s donated %s chocolate(s)!',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': '%s has donated %s chocolate(s) in the relay stream!',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s has sent %s Quickview%s! day Voucher',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': 'You can not watch on mobile web. \nPlease watch it on PC or mobile app (IOS, AOS)',

	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                      \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                          \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': "Current broadcast may be harmful for teenagers by the by the provisions of the Act on Promotion of Information Network Usage and Information Protection, etc. and the Juvenile Protection Act \n\
                                                        \n\The stream rank has been changed so that tennagers under 19 can't use the service. \n\
                                                        \n\ To continue watching, you need to verify your identity using the app.",

	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': 'I am watching %s on AfreecaTV. Would you like to see it together?',
	'채팅입력이 지원되지 않는 브라우져입니다.': 'Chat input is not supported.',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>Register <strong> AfreecaTV </strong> Certificate! </span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': 'This version of OS is not supported. <br> Please watch it on <span> AfreecaTV app </span>',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': '%s chat<br>has appeard again.',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': '%s chat<br>will not show from now on.',

	'랭킹 홈': 'Ranking Home',
	'최고 유저 평균': 'AVG. Max Viewer',
	'애청자 증가수': 'Increase of Fans',
	'UP 수': 'UP Count',
	'펫방': 'Pet',
	'더빙/라디오': 'Dubbing/Radio',
	'VOD BJ': 'VOD Streamer',
	'VOD FAN': 'VOD Fan',
	'누적 애청자수': 'Cumulatived number of Fans',
	'누적 팬클럽수': 'Cumulatived memebers in your Fan Club',
	'랭킹 소통방 바로가기': 'Go to Talk Rankings',
	'BJ랭킹 산정 방식 안내': 'Streamer rank calculation method guide',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': 'Streamer rank reflects from broadcasting activities from Monday to Sunday at the following ratios',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '(However, in the case of Fan, it is reflected as the cumulative number of Fan)',
	'VOD BJ 랭킹 산정 방식 안내': 'VOD Streamer rank calculation method guide',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': 'The VOD Streamer rank is ranked according to the VOD uploaded to Profile.',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': 'Total VOD search increase from last Monday ~ Sunday',
	'VOD FAN 랭킹 산정 방식 안내': 'VOD FAN rank calculation method guide',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': 'VOD FAN Ranking is the rank of the fans who uploaded favorite broadcasts of Streamer.',

	'변경': 'Change',
	'아프리카TV 앱 실행하기': 'Run AfreecaTV app ',
	'최고 유저': 'Peak Viewer',
	'BJ랭킹': 'Ranking',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': 'If you install the <span>AfreecaTV</span> App,<br>you can <span>watch in Source quality</span> and participate in chats.',
	'고화질로 시청하기': 'Watch in HD',

	'년 전': ' Year(s) Ago',
	'개월 전': ' Month(s) Ago',
	'주 전': ' Week(s) Ago',
	'일 전': ' Day(s) Ago',
	'시간 전': ' Hour(s) Ago',
	'분 전': ' Minute(s) Ago',
	'초 전': ' Second(s) Ago',
	'보낸 시각': 'Sent time',
	'공유하기': 'Share',
	'검색': 'Search',
	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '<strong>AfreecaTV App</strong><span>Download</span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'Login to use Watch Later function.\nLog in?',
	'누적 애청자 수': '',
	'누적 팬클럽 수': '',
	'BJ 랭킹': '',

	'즐겨찾기 전체': 'All Favorites',
	'최신등록순': 'By Upload Date',
	'참여인원순': 'By Viewers',
	'닉네임순': 'By Nickname',
	'아이디순': 'By ID',
	'최신방송순': 'By Stream Date',
	'LIVE 시청': 'Watch Live',
	'목록 상단 고정 완료': 'Pinned to top of the list',
	'라이브&amp;VOD': 'Live&amp;VOD',
	'방송국 개설일': 'Joined Date',
	'최근방송': 'Last Stream',
	'LIVE 시청하기': 'Watch Live',
	'방송국가기': 'Go to Blog',
	'목록 상단 고정': 'Pin Favorite',
	'즐겨찾기한 BJ가 없습니다.': 'There are no Favorite streamers.',
	'목록 상단 고정 해제': 'Unpin from list.',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': 'There are no Favorites LIVE now.',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': 'There are no VODs <br>from your favorite streamer.',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': 'If you add your favorite streamer, you can easily watch the streamer\'s live broadcasts and VODs.',
	'상단고정 완료되었습니다': 'Pinned to top of the list',
	'상단고정 해제 되었습니다': 'Unpin from list.',
	'지원하지 않는 메뉴입니다.': 'This menu is not available.',
	'수신거부 해제되었습니다.' : 'Unblocked!',
	'필터': 'Filter',
	'정렬기준': 'Sort by',
	'구분': 'Category',
	'날짜': 'Date',
	'취소': 'Cancel',
	'적용': 'Apply',
	'생방송': 'Live',
	'업로드VOD': 'Uploaded Videos',
	'유저클립': 'User Clip',
	'다시보기': 'Replay Videos',
	'하이라이트': 'Highlight Videos',
	'구독전용': 'Subscription-Only',
	'1년': '1 Year',
	'고화질순': 'High Definition',
	'고해상도순': 'High Resolution',
	'애청자순': 'No. of Favorites',
	'팬클럽순': 'No. of Fans',
	'전체알림': 'Alert All',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': 'Receive notifications from all favorite streamers.',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': 'Do not receive notifications from all favorite streamers.',
	'%s님에게 알림을 받습니다.': 'Receive notification from %s.',
	'%s님에게 알림을 받지 않습니다.': 'Do not receive notifications from %s.',

	'방송제목 변경이력': 'Stream Title History',
	'조회순': 'Views',
	'관련된 VOD': 'Related VODs',
	'검색어와 연관된 추천 영상입니다.': 'Recommended VOD related to the search word.',
	'게시글': 'Post',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': 'No search results returned.<br> Change the criteria from one month up to a year<br> to see more results.',
	'조회순': 'Views',

	'최근 검색어': 'Recent',
	'실시간 인기 검색어': 'Popular',
	'개인화 추천검색어': 'Personalized',
	'자동저장 <span>끄기</span>': '<span>Turn-off</span> Autosave',
	'자동저장 <span>켜기</span>': '<span>Turn-on</span> Autosave',
	'최근 검색어가 없습니다.': 'There is no recent search word.',
	'최근 검색어 자동저장이 꺼져 있습니다.': 'Autosave for recent search words is currently turned off.',
	'자동저장 켜기': 'Turn-on Autosave',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': 'If you log in, you can use <strong>personalized search word service</strong>.',
	'최근검색어를\n모두 삭제하시겠습니까?': 'Would you like to delete\nall recent search words?',
	'최근검색어 자동저장을 사용합니다.': 'Using autosave for most recent search words.',
	'최근검색어 자동저장 사용을 중지합니다.': 'Stop using autosave for most recent search words.',
	'개인화 추천 검색어가 없습니다.': 'There is no personalized search word.',
	'검색한 검색결과입니다.': 'Showing results for search term',
	'검색결과 보기': 'Show results for',

	'나중에 보기' : 'Watch Later',
	'즐겨찾기한 BJ의 VOD' : 'VODs from Favorited Streamers',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : 'No VODs from Favorite Streamers.',
	'즐겨찾기한 BJ의 방송이 없습니다.' : 'No Streams from Favorite Streamers.',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : 'Do you want to clear your recently viewed VOD history?',
	'재생한 VOD를 모두 삭제하시겠습니까?' : 'Do you want to clear your entire VOD history?',
	'UP 한 VOD가 없습니다.' : 'There are no UP VODs.',
	'즐찾 BJ': 'Favorites',
	'구독 BJ': 'Subscribed Streamers',
	'구독중인 BJ' : 'Subscribed Channels',
	'VOD 홈' : 'VOD Home',
	'인기 VOD' : 'Popular VOD',
	'인기' : 'Popular',
	'핫이슈 VOD' : 'HOT Issue VOD',
	'TV클립' : 'TV Clip',
	'인기 하이라이트' : 'Popular Highlights',
	'드라마 인기클립' : 'Popular Drama Clips',
	'예능 인기클립' : 'Popular Variety Show Clips',
	'스포츠 인기클립' : 'Popular Sports Clips',
	'아프리카TV X TV클립' : 'AfreecaTV X TV Clip',
	'예능' : 'Variety Show',
	'교양' : 'Culture',
	'시사' : 'News',
	'드라마' : 'Drama',
	'스포츠' : 'Sports',
	'패션/뷰티' : 'Fashion/Beauty',
	'어린이' : 'Children',
	'스페셜' : 'Special',
	'디지털' : 'Digital',
	'뮤직' : 'Music',
	'라디오' : 'Radio',
	'키즈' : 'Kids',
	'연예' : 'Entertainments',
	'시사보도' : 'Headlines',
	'라이프' : 'Life',
	'홈페이지' : 'Homepage',
	'주목할만한 클립' : 'Noticeable Clips',
	'인기 클립' : 'Popular Clips',
	'클립 전체' : 'All Clips',
	'프로그램 전체' : 'All Programs',
	'TV클립 목록이 없습니다.' : 'There is no TV Clip list.',
	'인기 프로그램' : 'Popular Program',

	'방송국 가기': 'Go to Blog',
	'기록' : 'History',
	'카테고리' : 'Category',
	'추천받지 않기' : 'Do not recommend',
	'이어서 보기' : 'Next',
	'최근 본 VOD' : 'Recent VOD',
	'UP 한 VOD' : 'UPed VOD',
	'나중에 보기 삭제' : 'Remove from Watch Later',
	'최근 본 VOD 삭제' : 'Delete from Recent VOD',
	'UP 취소' : 'Cancel UP',
	'나중에 보기 목록에서 삭제 되었습니다.' : 'Removed from Watch Later.',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : 'Recent VOD has been removed.',
	'선택하신 VOD UP이 취소 되었습니다.' : 'UP has been canceled',
	'추천 받지 않기가 반영되었습니다.' : 'No recommendation has been applied',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : 'This channel has already been added to Favorites.',
	'이미 나중에 보기 등록된 VOD 입니다.' : 'Already added to Watch Later',
	'전체 삭제' : 'Delete All',
	'나중에 보기 한 VOD가 없습니다.' : 'No VODs in your Watch Later list.',
	'최근 본 VOD가 없습니다.' : 'There are no recent VODs.',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : 'You can see recommended VODs<br>in your VOD page.',
	'VOD 홈으로 이동' : 'Go to VOD page.',
	'구독' : 'Subscribe',
	'카테고리 선택' : 'Select a category',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : 'You can see all VODs in History after logging in.',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : 'You can see all of your favorite<br>streamer\'s VODs after logging in.',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : 'Are you sure you want to clear your Watch Later list?',
	'UP이 취소되었습니다.' : 'UP has been canceled',
	'추천 받지 않기에 등록 되었습니다.' : 'No recommendation has been applied',
	'즐겨찾기에서 삭제됐습니다.': 'Removed from Favorites',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?' : 'Login is needed to add to Favorite.\nGo to login page?',
	'팬' : 'Fan',
	'구독한 BJ가 없습니다.' : 'There are no Subscribed Streamers.',
	'구독한 BJ의 VOD가 없습니다.' : 'No Subscribed Streamer VODs',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : 'Are you sure you want to remove %s from Favorites?',

	'추천' : 'Recommended',
	'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : 'Go to VOD Recommended menu<br>to receive customized recommendations for VODs.',
	'VOD 추천받기' : 'Receive VOD Recommendation.',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : 'Log in is required.\nWould you like to sign in?',
	'주소가 복사되었습니다.' : 'The address has been copied.',
	'본인은 즐겨찾기 할 수 없습니다.' : 'You are unable to add your own channel to Favorites.',

	'TAG_VOD_LIST_COUNT' : '<span class="count"><em class="point" text="@count"></em> total</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">\'s recommended VOD</span>',
	'TAG_VOD_RECENT_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">\'s frequently watched streamers</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name" text="@nickname"></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span>\'s VOD <span text="@count"></span></span>',

	'알림': 'Notification',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : 'You have no received notifications. <br><a ng-click="setting()"><span>In Notification Settings</span></a>, select the notification you want to receive.',
	'모두 읽음' : 'Mark All As Read',
	'모두 삭제' : 'Delete All',
	'읽은 알림 삭제' : 'Delete Read Notifications',
	'알림 설정' : 'Notification Settings',
	'설정' : 'Setting',
	'%d분 전' : '%d minutes ago',
	'%d시간 전' : '%d hours ago',
	'삭제' : 'Delete',
	'알림이 삭제되었습니다.' : 'Notification was deleted',
	'숨기기' : 'Hide',
	'알림 콘텐츠 설정' : 'Notification Contents Settings',
	'초기화' : 'Reset',
	'저장' : 'Save',
	'푸시 설정' : 'Push Notifications',
	'기록 설정' : 'Notification History',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : 'Push notifications can be changed in <a ng-click="setup_link()"><span>AfreecaTV Apps - Settings - Notification settings</span></a>.',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : 'Reset notification settings. \nReset all settings to default. Save to apply reset on Notification Settings.',
	'설정이 저장되었습니다.' : 'Your settings have been saved.',


	'발송취소' : 'Unsend',
	'해당 쪽지를 발송 취소하시겠습니까?' : 'Would you like to unsend this message?',
	'보관한 보낸 쪽지가 없습니다.' : 'There are no sent messages saved.',
	'보관한 받은 쪽지가 없습니다.' : 'There are no recieved messages saved.',
	'읽지 않음' : 'Unread',
	'방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : 'From Blog, %s has donated %s Ad Balloons.',
	'유효기간 만료순' : 'Expiration Date',


	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : 'Would you like to report this message?\n(Reported messages will be moved to Spam Box.)',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : 'Would you like to unspam this message?\n(Unspamed messages will be moved to Inbox.)',
	'자동스팸분류':'Auto Spam Filter',
	'스팸해제' : 'Unspam',
	'스팸신고': 'Report',
	'보관함':'Saved',
	'스팸함':'Spam',
	'스팸 쪽지가 없습니다.':'There are no spam messages.',
	'스팸 쪽지':'Spam Messages',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.':'Spam messages are deleted automatically after 90 days from the date you read.',

	'"%s" 검색 결과' : 'Search results for "%s"',
	'검색된 쪽지가 없습니다.' : 'There are no messages found.',
	'검색어를 입력해 주세요.' : 'Please enter a search word.',
	'내용' : 'Contents',
	'더 알아보기' : 'Learn More',
	'재생목록':'Playlist',
	'재생목록에 담기':'Add to Playlist',
	'재생목록 퍼가기':'Save Playlist',
	'생성한 재생목록':'Created Playlists',
	'재생목록 보관함':'Playlist Library',
	'재생목록이 없습니다.':'There is no playlist.',
	'새 재생목록 만들기':'Create New Playlist',
	'추가':'Add',
	'새 재생목록':'New Playlist',
	'확인' : 'Confirm',
	'VOD 추가순': 'Added Date',
	'오래된순': 'Oldest',
	'수정하기': 'Edit',
	'삭제하기': 'Delete',
	'업데이트' : 'Update',
	'공개': 'Public',
	'비공개' : 'Private',
	'전체보기' : 'See All',
	'목록 공개': 'Make List Public',
	'목록 비공개': 'Make List Private',
	'목록 퍼가기 허용': 'Allow Save',
	'목록 퍼가기 비허용': 'Do Not Allow Save',
	'제목을 입력해주세요.': 'Please enter a title.',
	'VOD가 없습니다.' : 'There is no VOD.',
	'재생목록을 삭제하시겠습니까?' : 'Would you like to delete the playlist?',
	'저화질' : 'Medium',
    '일반화질' : 'High',
	'원본화질' : 'Source',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : 'Move the VOD in Apps to watch in 360° angle.',
    '유료 광고 포함' : 'Includes Paid Promotion',
    '참여를 위해<br>인코딩 중입니다.': 'Loading…',
    '잠깐만 기다려주세요!': 'Hold on!',
    '<span><em>초</em> 남았습니다.</span>': '<span>Remaining <em>seconds</em></span>',
    '보유 중인 선물 받은 구독권' : 'My Received Subscription Gift Card',
    '보유 중인 구독 선물권' : 'My Subscription Gift Card',
    '아이템 정보' : 'Item Information',
    '광고 Skip, Full방 입장, 고화질 방송참여가 가능한<br>퀵뷰아이템을 만나보세요.' : 'Check out QuickView to skip ads,<br>enter full chat rooms, and watch<br>in HD quality.',
    '선물/후원 정보' : 'Gift/Donation Information',

	'사용하러 바로가기': 'Use Now',
	'구독 구매하기':'Purchase Subscription',
	'구매하러 바로가기':'Purchase Now',

    '연속선물' : 'Auto +1',
    '연속선물이란?' : 'Auto +1?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : 'If you turn on the Auto +1 function, the gift<br>window will be maintained, and the number of<br>Star Balloons will be automatically entered as +1<br>after it is sent.',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : 'You cannot register while using QuickView Plus.',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : 'You cannot register while using QuickView.',
    '교환' : 'Exchange',
	'플러스로 전환' : 'Switch to Plus',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : 'Only paid QuickView and paid QuickView Gift Card items\ncan be switched to QuickView Plus.',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : 'QuickView has been used.\nYou have an opportunity to upgrade your QuickView ticket to QuickView Plus.\nWould you like to upgrade?',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "Free QuickView has been used.<br>Free QuickView items cannot be switched to QuickView Plus.",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "You have used your free QuickView Plus.",
	"퀵뷰가 사용되었습니다.": "QuickView has been used.",
	"퀵뷰 플러스가 사용되었습니다.": "QuickView Plus has been used.",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : 'Upgrade Opportunity<br><strong>To QuickView Plus!</strong>',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : 'Try upgrading your current QuickView Ticket to QuickView Plus.',
	'이용기간' : 'Usage Period',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : 'The usage period is re-calculated from the time of upgrade, and the total usage period is extended.',
	'퀵뷰 플러스의 더 커진 혜택' : 'Bigger Benefits of QuickView Plus',
	'VOD 광고 NO!' : 'NO VOD Ads!',
	'LIVE/VOD 광고 NO' : 'NO LIVE/VOD Ads',
	'FULL방 입장' : 'Entering full rooms',
	'방송 광고 NO!' : 'NO Ads!',
	'멀티뷰' : 'Multi-view',
	'방송 미리보기(모바일)' : 'Stream Preview (Mobile)',
	'퀵뷰 사용 기간 별 전환 금액' : 'Upgrade Payment Amount Per Used Date',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : 'Once you pay the amount depending on your used date, the usage period is re-calculated from the time of upgrade, and <strong>the total usage period is extended</strong>.',
	'사용' : 'Used for',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'플러스로 전환하기' : 'Switch to Plus',
	'기존 퀵뷰로 사용하기' : 'Continue with Current QuickView',
	'※부가세 미포함' : '※VAT not included',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : 'You have used this item for %s days, therefore you can switch to <strong>%s Days QuickView Plus</strong> by paying <span>%s</span>.',
	'더 저렴하고! 기간도 길게!<br>(최초 1회 제공)' : 'Much Affordable with Longer Benefits! <br>(First-time-only)',
	'선물 ・ 후원 정보' : 'Gift ・Donation Information',
	'무료체험중' : 'Under Free Trial',
	'(iOS자동결제)' : '(iOS Auto Payment)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'As you are using Apple Subscription(Auto Payment),\npayment methods cannot be changed\non the current device.\nPlease use your iPhone(or iPad) App Store > Apple ID > Subscriptions.',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'As you are using Apple Subscription(Auto Payment),\nit is impossible to cancel the subscription\non the current device.\nPlease use your iPhone(or iPad) App Store > Apple ID > Subscriptions.',
	'해지 신청중' : 'Cancelling',
	'2개의 방송을 동시에! 모바일 멀티뷰' : '2 Streams At Once! Mobile Multi-View',

	'구매한 스티커' : 'Purchased Stickers',
	'선물받은 스티커' : 'Received Stickers',
	'스티커 아이템' : 'Sticker Item',
	'스티커 교환하기' : 'Exchange Sticker',
	'아이템 정보' : 'Item Information',

	'내가 보유한 스티커' : 'Possessing Stickers',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : 'Leave a clip for the gift of more than 100 Signature Balloons.',
	'소개글' : 'Introduction',
	'공지' : 'Notice',
	'나이' : 'Age',
	'경력/수상' : 'Career/Awards',
	'수상 메달' : 'Medals',
	'앗 혹시 찾으시는 분이...' : "Is this the one you're looking for...?",
	'지금 이 콘텐츠에 주목!' : 'Pay attention to this content!',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '<span class="normal">Recommended Contents for those who searched</span> \'{{ query }}\'',
	'누적 참여자' : 'Total Number of Viewers',
	'방송 시간' : 'Stream Time',
	
	'쉬는시간 후 방송이 이어집니다.' : 'The stream will continue after the break time.',
	'방송에 후원되는 광고입니다.' : 'The ad helps support the live stream.',
	
	'URL 복사' : 'Copy URL',
	'구독하러 바로가기':'Purchase Now',
	
	'빠른 답장' : 'Quick Reply',
	'기본 메시지로 저장' : 'Save as Default Message',
	'보내기' : 'Reply',
	'후원 쪽지' : 'Support Message',

	'선물 메세지' : 'Gift Message',
	'목소리 선택' : 'Select Voice',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : 'Try sending stickers to the<br>streamer for Alert Voice items!',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : 'If the streamer does not use E-Assistant or if there is a separate setting, the alert might not be played.',

	'영상 광고 없는 VOD 재생!' : 'Watch VODs without the video ads!',
	'캐치 광고 제외' : 'Excluding Catch Ads',
	'방송 입장 영상광고 NO!' : 'No Ads when entering LIVE!',
	'배너형, 로딩광고, LIVE 중간광고 제외' : 'Excluding Banner Ads, Loading Ads, LIVE Mid-roll Ads',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':"For youth protection,<br>this content is age-restricted,<br>and only users <strong>over 19 years old</strong> can watch it.<br>You can only watch this on AfreecaTV App.",
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':"For youth protection, this content is age-restricted,\nand only users over 19 years old can watch it.\n\nWould you like to watch this on AfreecaTV App?",
	
	'대결미션' : 'Battle Mission', 
	'대결미션이란?': 'Mission?',
	'대결단계에서만 후원 가능합니다.': 'You can only donate during the battle step.',
	'보유 별풍선이 즉시 차감됩니다.': 'Deducted from your Star Balloons.',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': 'It is a function that the streamers team up to conduct a competition content and distribute donated star balloons according to the results of the competition.',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': 'You will not be donating to the streamer, but you will be donating to the Battle Mission.',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': 'Star Balloons donations will guarantee you to join the streamer\'s fan club and VIP count.',
	
	'도전미션' : 'Challenge Mission',
	'보유 별풍선 :' : 'Star Balloons :',
	'도전미션이란?' : 'Challenge Mission?',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : 'Request a challenge mission to the streamer',
	'새로운 미션 요청' : 'Mission Request',
	'후원할 별풍선' : 'Star Balloons to Sponsor',
	'후원하기' : 'Donate',
	'미션 내용을 입력해 주세요. (최대 30자)' : 'Please enter the mission description. (Max 30 letters)',
	'미션 제한 시간' : 'Mission Time Limit',
	'도전미션 일' : 'D',
	'도전미션 시간' : 'H',
	'도전미션 분' : 'M',
	'미션 상금' : 'Mission Prize',
	'미션 요청' : 'Request',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : 'It is a donation service that the streamers can achieve the prize money users raise depending on the mission result.',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : 'The challenge mission can be requested by the users or the streamer can register the mission directly.',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : 'The mission result will be decided by the streamer, while the requested user can only select ‘Success’.',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : 'The challenge mission is based on the trust between the streamer and the user, and the result of the mission cannot be reversed under any circumstances.',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : 'The mission will fail automatically if the time limit for the mission has elapsed.',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : 'If the mission is successful, the prize money raised will be delivered to the streamer.',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : 'In the cases of Mission Cancel, or Mission Failed, the prize money will be given back to the user.',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : 'The streamer can extend the mission time limit once. (Max 10 days)',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : 'You can additionally support Standby/Ongoing missions.',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : 'Joining Fan Club/VIP Fan count will happen at the moment the streamer receives the prize money after succeeding in the mission.',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : 'If the users cancel 5 times in the last 1 days, they are unable to request another mission for all streamers. Please request carefully.',
	
	'아이템은 여기서 구매하세요!' : 'Purchase the items here!',
	'1:1 문의 바로가기' : 'Go to Ask 1:1',
	'내 애드벌룬 확인하고 선물하기' : 'Check my ad balloon and donate',

	'내 별풍선' : 'My Donation',
	'내 별풍선ㆍ아이템' : 'My Donation · Item',
	
	'규칙에 동의합니다!' : 'I agree to the rules!',
	'드롭스 이벤트 진행 중' : 'Drops event in progress',

	'퀵뷰': 'QuickView',
	'퀵뷰 플러스': 'QuickView Plus',
	'만료': 'Expired',
	'예약': 'Schedule',
	'사용 예약': 'Schedule',
	'예약 취소': 'Cancel',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': 'You have scheduled %s usage.\n\nOnce your current QuickView Item expires, you will continue using the item as scheduled.',
	'%s 사용을 예약 취소하시겠습니까?': 'Would you like to cancel the scheduled %s?',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': 'Canceled the scheduled %s.\n\nIf you would like to schedule QuickView usage, please click \'Schedule\'.',

	'공식방송국':	'Official',
	'공식 방송국':'Official',
	'관련BJ':	'Related',
	'관련 BJ':	'Related',
	'이벤트':	'Event',
	'시리즈게임':	'Sequel',
	'시리즈 게임':	'Sequel',
	'전체':	'All',
	'명':	'Viewers',
	'관련BJ<br>더보기':	'See More<br>Related Streamers',
	'LIVE':	'LIVE',
	'다시보기':	'Replay',
	'경기없음':	'No Relay',
	'경기취소':	'Canceled',
	'경기예정':	'Scheduled',
	'결과<br>보기':	'See<br>Results',
	'참여인원순':	'Viewers',
	'시청인원순':	'Views',
	'최근방송':	'Latest Stream',
	'LIVE 참여하기':	'Watch LIVE',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'There are no recent streams.<br>Try streaming in this category!',
	'예정된 경기가 없습니다.':	'There is no game scheduled.',
	'경기종료':	'Ended',
	'최신방송순':	'Latest',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '<span class="normal">Recommended Streamers for those who searched</span> \'{{ query }}\'',

	// 모웹 메인 footer에서 사용
	'이용약관': 'Terms of Use',
	'개인정보 처리방침': 'Privacy Policy',
	'대표이사': 'CEO',
	'사업자번호': 'Business Registration Number',
	'사업자 정보 확인': 'Check business information',
	'호스팅 제공자': 'Hosting by',
	'주식회사 숲': 'SOOP Co., LTD.',
	'주소': 'Address',
	'경기도 성남시 분당구 판교로 228번길 15 판교세븐밴처밸리 1단지 2동 주식회사 숲(삼평동)': 'SOOP Co., LTD. 625 Sampyeong-dong, Bundang-gu, Seongnam-si, Gyeonggi-do',
	'대표 번호': 'Tel.',
	'이메일': 'Email',
	'사업자 정보': 'Business Information',
	'통신판매번호': 'Mail-order-sales Registration Number',
	'FAX': 'Fax',
	'\'{{ query }}\' 검색한 유저를 위한 추천 Catch' : 'Recommended Catch for those who searched \'{{ query }}\'',
	'계정연결': 'Link Account',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'본방 입장': 'Main Stream Entrance',
	'남자': 'Male',
	'여자': 'Female',
	'캐릭터': 'Chracter',

	'종료된 방송입니다.': 'The stream has already ended.',
	'지금 바로 아프리카TV의 다양한 생방송과<br>재미있는 VOD를 만나보세요!': 'Check out AfreecaTV`s diverse live streams and fun VODs!',
	'VOD 보러가기': 'Go to Watch VOD'
};
