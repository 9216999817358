config.$inject = ['$httpProvider', '$locationProvider'];
function config($httpProvider, $locationProvider) {

        //$http.get CORS 문제 해결 
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = true
        delete $httpProvider.defaults.headers.common['X-Requested-With'];

        $locationProvider.hashPrefix('');

        // apache rewrite rule  필요
        // $locationProvider.html5Mode({
        //         enabled: true,
        //         requireBase: false
        // });
}
module.exports = config; 
