RankService.$inject = ['$http'];
function RankService($http) {
        this.list = function (data) {

                return new Promise(function (resolve, reject) {
                        $http.post(API_M_AFREECATV + '/bj/a/rank', $.param(data || '')).then(function (response) {
                                if (response.data.result == 1) {
                                        resolve(response.data)
                                } else {
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        };

        this.monthBjRanking = function (param) {
                var data = {
                        'szWhich': 'getMonthBj'
                        ,'displayYn': param.displayYn
                };

                // PC API를 쓰도록 수정.
                // var url = API_M_AFREECATV + '/bj/a/monthRank';  // 모바일 API
                var url = AFEVENT2_AFREECATV + '/app/rank/index.php';   // PC API

                return new Promise(function (resolve, reject){
                        $http.post(url, $.param(data || '')).then(function (response) {
                                if (response.data.RESULT == 1) {
                                        resolve(response.data);
                                } else {
                                        reject(response.data);
                                }
                        }).catch(function (error){
                                reject(error);
                        })
                });
                
        }

}

module.exports = RankService;
