MypointController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'MypointService', 'HistoryState']

function MypointController($scope, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, MypointService, HistoryState) {

    $scope.myPoint = 0;
    $scope.availablePoint = 0;
    $scope.availableToken = 0;
    $scope.expirationPoint = 0;
    $scope.expirationToken = 0;

    // 사용가능 포인트 조회
    $rootScope.amount = function () {
        MypointService.amount(LoginManager.getUserId())
        .then(function (response) {
            $scope.myPoint = response.DATA.AVAILABLE_POINT;
            $scope.availablePoint = response.DATA.AVAILABLE_POINT.numberFormat();
            $scope.availableToken = response.DATA.AVAILABLE_TOKEN.numberFormat();
            $scope.expirationPoint = response.DATA.EXPIRATION_POINT.numberFormat();
            $scope.expirationToken = response.DATA.EXPIRATION_TOKEN.numberFormat();
            $scope.remainPoint = $scope.availablePoint;
            $scope.earnToken = 0;
            $scope.usePoint = 0;
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
    }

    // 내정보 페이지로 이동
    $scope.back = function () {
        if (afreeca.link.isWebview()) {
            afreeca.link.close();
        } else {
            $state.go('myinfo');
        }
    }

    // 포인트&크레딧 안내 페이지 이동
    $scope.goGuidePage = function () {
        $state.go('mypoint.guide');
    }

    // 포인트&크레딧 적립방법 페이지 이동
    $scope.goAccumulatePage = function () {
        $state.go("mypoint.accumulate");
    }

    $scope.goSubscribePage = function() {
        $state.go("mypoint.subscribe");
    }

    // 포인트 현황 페이지 이동
    $scope.goPointStatusPage = function () {
        $state.go("mypoint.pointstatus");
    }

    // 크레딧 현황 페이지 이동
    $scope.goTokenStatusPage = function () {
        $state.go("mypoint.tokenstatus");
    }

    // 크레딧 상품 페이지 이동
    $scope.goTokenItemPage = function () {
        $state.go("mypoint.item");
    }

    // 숲토어 페이지 이동
    $scope.goShopPage = function () {
        afreeca.link.browser(SHOPFREECA_AFREECATV);
    }

    // 포인트->크레딧 전환 페이지 이동
    $scope.goTokenExchangePage = function () {
        if (LoginManager.isLogin()) {
            if (LoginManager.isRealName() == false) {
                $scope.checkRealName();
                return false;
            }
            $state.go("mypoint.exchange");
        } else {
            if (afreeca.link.isWebview()) {
                location.href = SERVICE_APP_SCHEME + "login/requireLogin";
            } else {
                toastr.error("로그인이 되어 있지 않습니다.");
                setTimeout(function () {
                    $state.go("home");
                }, 500);
            }
        }
    }

    // 본인확인 페이지 호출
    $scope.checkRealName = function () {
        if (confirm("본인 확인이 필요한 서비스 입니다. 진행하시겠습니까?")) {
            window.open(AUTH_M_AFREECATV + '/realname?type=popup');
        }
    };

    if (typeof $rootScope.member == 'undefined') {
        $rootScope.$on('$loginChange', function (event, value) {
            if (value) {
                $rootScope.amount();
            }
        });
        LoginManager.check();
    } else {
        $rootScope.amount();
    }
}

module.exports = MypointController;
