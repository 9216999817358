module.exports = {
	'홈': '首頁',
	'핫이슈': 'HOT ISSUE',
	'나중에보기': '下次再看',
	'전체': '全部',
	'로그인': '登錄',
	'최신순': '最新排序',
	'참여인원순': '收看人數排序',
	'즐겨찾기 추가': '添加關注',
	'방송국 보러가기': '前往直播间',
	'나중에보기 등록': '添加到下次再看目录',
	'목록편집': '编辑目录',
	'전체삭제': '刪除全部',
	'재생한 VOD 삭제': '刪除已播放視頻',
	'시청한 VOD': '已观看视频',
	'다시보기': '重播',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': '登錄後，才能確認<br>[下次再看]列表內影片。',
	'나중에 보기에 등록한 VOD가 없습니다.': '您还没有添加视频。',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': '不能立即观看视频时，<br>添加视频到[下次再看]后，<br>随时可以观看！',
	'나중에보기 삭제': '删除下次再看。',
	'나중에 보기 서비스란?': '下次再看服务是？',
	'나중에 보기에 등록되었습니다.': '添加成功。',
	'나중에 보기가 삭제되었습니다.': '删除成功。',
	'라이브 방송을 시청할 수 없는 상황일 때': '不方便观看直播时，,',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': '可以添加到列表，有空时在观看！',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': '<span class="blue">添加视频到"下次再看"后，</span><br>在列表中可以确认。',
	'보고 싶은 VOD나, 아직 보지 않은': '添加感兴趣的或还未观看的，',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': '视频到列表，有空时再观看！',
	'다시 보지 않기': '不再显示',
	'닫기': '关闭',
	'나중에 보기를 지원하지 않는 영상입니다.': '此视频不支持[下次再看]功能。',
	'쪽지함': '私人信箱',
	'새 쪽지 <span>%s</span>개': '新短信 <span>%s</span>個',
	'새 쪽지가 없습니다': '沒有短信',
	'쪽지 보내기': '發送私信',
	'쪽지설정': '接收私信設定',
	'받은 쪽지함': '收件匣',
	'보낸 쪽지함': '寄件匣',
	'쪽지 보관함': '我的短信',
	'전체 쪽지': '所有短信',
	'안읽은 쪽지': '未讀',
	'보관한 쪽지':'保管短信',
	'보관한 쪽지가 없습니다.': '沒有保管短信',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': '已保存短信會永久保存(最多1,000個)。',
	'이미지 첨부는 3개까지 가능합니다.': '圖片文件不能超過3個。',
	'이미지 첨부는 8MB까지 가능합니다.': '圖片文件大小不能超過8MB。',
	'이미지가 저장되었습니다.': '下載成功',
	'확인': '確認',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(保管期間為自接收日起<em>90</em>天)',
	'이미지 첨부': '添加圖案',
	'첨부 이미지': '附件',
	'이미지 저장 기간': '圖片保存期間',
	'이미지 저장 기간 만료': '圖片保存期間已滿',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(自贈送日起<em>90</em>天)',
	'이미지 파일은 1개씩 첨부 가능합니다.': '圖案文件只能逐一添加。',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': '图片只持支JPG, JPEG, GIF, PNG, BMP格式。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': '保存處理的短信將永久保存。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)':'保存處理的短信將永久保存。(最多1,000個)',
	'이미지 첨부 중입니다.': '正在添加圖片中。',
	'해당 쪽지가 삭제되었습니다.' : '此短信已被刪除。',
	'해당 쪽지가 신고되었습니다.' : '此短信被舉報。',
	'수신거부가 완료되었습니다.' : '拒收設定成功',
	'해당 쪽지가 발송 취소되었습니다.' : '已取消發送此短信。',
	'이미 신고된 쪽지입니다.':'您已檢舉此私信。',

	'보관': '保存',
	'신고': '舉報',
	'삭제': '刪除',
	'받은 쪽지가 없습니다.': '沒有接收的私信。',
	'읽지않음': '未讀',
	'읽음': '已讀',
	'더 보기': '查看更多',
	'받은 쪽지': '收件箱',
	'보낸 사람': '發件人',
	'받은 시각': '時間',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': '確認私信90天後會被自動刪除。',
	'답장': '回覆',
	'수신거부': '拒收',
	'보낸 쪽지가 없습니다.': '沒有發送的私信。',
	'보냄': '發送私信',
	'보낸 쪽지': '發件箱',
	'받는 사람': '收件人',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': '私信保存限制90日。',
	'쪽지 수신 설정': '接收私信設定',
	'전체 수신거부': '拒收全部短信',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': '拒收所有短信。<br>(管理員賬號除外)',
	'수신거부 ID 목록': '拒收私信帳號',
	'총 %s명': '共%s名',
	'아이디': '帳號',
	'추가 날짜': '添加日期',
	'수신거부 목록이 없습니다.': '不能給自己發送私信。',
	'수신거부 해제': '解除拒收私信。',
	'추가': '添加',
	'수신거부 추가': '添加到拒收列表',
	'쪽지 보내기': '發送私信',
	'보내기': '發送',
	'받는 아이디': '收件人',
	'다중 전송은 엔터키로 구분해 주세요.': '發送多個短信時，請ENTER區分帳號。',
	'최대 5,000자까지 작성 가능합니다.': '內容不能超過5,000字。',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': '您使用了禁止詞語，不能使用此功能。請諮詢客服中心。',
	'신고가 접수되었습니다.': '舉報成功。',
	'선택한 쪽지를\n신고하시겠습니까?': '要舉報已選短信嗎？',
	'쪽지를 선택해 주세요.': '請選擇短信。',
	'선택한 쪽지를\n삭제하시겠습니까?': '要刪除已選短信嗎？',
	'쪽지를 선택해 주세요.': '請選擇短信。',
	'삭제하시겠습니까?': '要刪除嗎？',
	'신고하시겠습니까?': '要舉報嗎？',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': '設定成功。*解除拒絕接收短信可在[拒收設定]中更改。 ',
	'아이디를 입력해 주세요.': '請輸入帳號',
	'아이디는 영문,숫자만 입력 가능합니다.': '賬號必須由英文，數字組成。',
	'아이디를 선택해 주세요': '請選擇賬號。',
	'해제되었습니다.': '解除成功',
	'한 번에 최대 30명까지만 전송 가능합니다.': '一次最多可以給30人發送短信。',
	'본인에게는 쪽지를 보낼 수 없습니다.': '不能給自己發送短信。',
	'이미 받는사람 목록에 포함 되어 있습니다.': '收件人列表內已存在。',
	'%s는 존재하지 않는 아이디입니다.': '不存在此%s。',
	'받는 아이디/닉네임을 입력해 주세요.': '請輸入收件人ID/暱稱。',
	'쪽지 내용을 입력해 주세요.': '請輸入內容。',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': '一次最多可以給30人發送短信。',
	'전송이 완료되었습니다.': '發送成功',
	'로그인이 되어 있지 않습니다.': '尚未登錄 ',
	'아이디는 12자 이내로 입력해 주세요.': 'ID長度不能超過12個字符。',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': '舉報成功(管理員賬號除外)。\n確定刪除已舉報短信嗎？',
	'잠시 후 이용해 주세요.': '請稍後重新嘗試。',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': '拒收設定成功。(經紀人帳號除外)\n要確認詳細內容嗎？',
	'수신거부 ID 추가': '添加帳號',

	'즐겨찾기': '關注',
	'즐찾': '關注',
	'방송중': '直播中',
	'방송 시작 전': '準備直播中',
	'BJ 랭킹순': '主播排序',
	'방송 중인 즐겨찾기 BJ가 없습니다.': '目前沒有正在直播的關注主播。',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': '目前沒有準備直播中的關注主播。',
	'즐겨찾기한 BJ가 없거나': '沒有關注的主播，',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': '或沒有正在直播中的關注主播。',
	'상세정보': '詳細資訊',
	'추천 받지 않기': '不再推薦',
	'즐겨찾기 삭제': '刪除關注',
	'명 시청중': '人正在觀看',

	'BJ랭킹순': '主播排行排序',
	'회원가입': '註冊會員',
	'고객센터': '客服',
	'최근 본 방송': '最近觀看',
	'지상파/케이블': '有線電視',
	'보이는라디오': '聊天',
	'스포츠': '體育',
	'게임': '遊戲',
	'애니': '動漫',
	'콘텐츠발전소': '內容發電站',
	'별풍선': '星氣球',
	'장애/버그신고': 'BUG舉報',
	'공지사항': '公告',
	'게임센터': '遊戲中心',
	'더보기': '查看更多',
	'신인': '新人',
	'시청자순': '收看人數排序',
	'참여자순': '收看人數排序',
	'1일': '1天',
	'1주': '1週間',
	'1개월': '1個月',
	'즐겨찾기할만한방송': '推薦直播',
	'아프리카TV앱': 'Afreeca TV APP',
	'바로가기': '立即前往',
	'정보': '資訊',
	'댓글': '留言',
	'승인': '確認',
	'인기방송': '人氣直播',
	'내가 보유한 별풍선': '我的星氣球',
	'확인': '確認',
	'취소': '取消',
	'스티커': '貼圖',
	'구매하기': '購買',
	'직접입력': '直接輸入',
	'초콜릿': '巧克力',
	'로그인상태유지': '保持登錄狀態',
	'개': '個',
	'비밀번호': '密碼',
	'최근 본 방송 내역 전체 삭제하기': '刪除全部',
	'전체방송': '全部直播',
	'모바일게임': '手游',
	'애니메이션': '動漫',
	'먹방/쿡방': '吃播',
	'음악': '音樂',
	'시사/현장': '新聞/現場',
	'주식/금융': '股票/金融',
	'금융': '金融',
	'생활/정보': '生活/百科',
	'공부방': '學習',
	'보이는쇼핑': '購物',
	'케이블': '有線',
	'1:1문의': '1:1諮詢',
	'엔터테인먼트': '娛樂',
	'창업방': '創業',
	'VOD': '影片',
	'개인방송국': '個人直播間',
	'핫이슈동영상': '熱門影片',
	'로그아웃': '登出',
	'방송시작전': '準備直播中',
	'아이디찾기': '找回帳號',
	'방송국보러가기': '前往直播間',
	'연령제한': '限制年齡',
	'청소년유해신고': '青少年有害舉報',
	'국내야구': '韓國棒球',
	'일본야구': '日本棒球',
	'프로농구': '職業籃球',
	'프로배구': '職業排球',
	'UFC/일반': 'UFC/普通',
	'프로골프': '職業高爾夫',
	'특집VOD': '影片特輯',
	'스포츠특집': '體育特輯',
	'게임특집': '遊戲特輯',
	'명시청중': '人在觀看',
	'내가 보유한 골드': '我的金幣',
	'내가 보유한 초콜릿': '我的巧克力',
	'아프리카TV': 'AfreecaTV',
	'아프리카TV,': 'AfreecaTV',
	'랭킹순': '排行排序',
	'님이': '-',
	'비밀번호찾기': '找回密碼',
	'최근본방송 삭제': '刪除最近觀看的直播',
	'최근본방송추가': '添加最近觀看直播',
	'시청한 방송이 없습니다': '暫無觀看記錄',
	'지금 바로 아프리카TV의 재미있고': '馬上去體驗有趣的AfreecaTV',
	'다양한 방송을 만나보세요!': '且豐富的直播！',
	'[생]': '[Live]',
	'[재]': '[重播]',
	'[중계방]': '[轉播間]',
	'아이디비번찾기': '找回帳號/密碼',
	'명시청': '人觀看',
	'레이디스배틀': 'Ladies Battle',
	'레이디스배틀(중국)"': 'Ladies Battle',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': '此帳號尚未註冊，或輸入的帳號/密碼有誤。',
	'아이디/비밀번호찾기': '找回帳號/密碼',
	'님이빠져들만한방송': '(님)可能感興趣的直播',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': '',
	'구매': '購買',
	'즐겨찾기추가': '添加關注',
	'베스트BJ방송': 'BEST BJ直播',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': '確定要刪除最近觀看的全部記錄嗎？ (如要刪除特定記錄，請點擊直播目錄的[查看更多]按鈕)',
	'자신에게는선물하실수없습니다.': '不能贈送禮物給自己。',
	'현재시청자수': '目前觀眾人數',
	'모바일': 'Mobile',
	'누적 시청자 수': '累計觀看人數',
	'유머/패러디': '搞笑/模仿',
	'베스트BJ': 'BEST BJ',
	'신인BJ': '新人BJ',
	'파트너BJ': '合約BJ',
	'뷰티/패션': '美容/時裝',
	'샵프리카': '購物直播',
	'MY쇼핑': '我的購物',
	'마인크래프트': 'Minecraft',
	'히어로즈': '暴雪英霸',
	'메이플스토리2': '楓之谷2',
	'피파온라인3': 'FIFA ONLINE 3',
	'검은사막': '黑色沙漠',
	'블랙스쿼드': 'Black Squad',
	'하스스톤': '爐石戰記',
	'문명': '文明帝國',
	'버블파이터': '泡泡大亂鬥',
	'마비노기': '瑪奇',
	'사이퍼즈': '暴能特區',
	'메이플스토리': '新楓之谷',
	'테일즈런너': '新衝天跑',
	'서든어택': '突擊風暴',
	'마영전': '瑪奇英雄傳',
	'리니지': '天堂',
	'LIVE/VOD 광고 NO' : 'LIVE及VOD廣告NO',
	'스타': '星海爭霸',
	'스타2': '星海爭霸2',
	'아키에이지': '上古世紀',
	'카트라이더': '跑跑卡丁車',
	'모두의마블': '旅遊大亨',
	'디아블로3': '暗黑破壞神3',
	'월드오브탱크': '戰車世界',
	'워크래프트': '魔獸爭霸',
	'아이온': '永恆紀元',
	'던파': '地下城與勇士',
	'스페셜포스': 'New SFOnline',
	'고전게임': '經典遊戲',
	'비디오게임': 'Video Games',
	'기타': '其他',
	'LOL': '英雄聯盟',
	'BladeNSoul': 'Blade & Soul',
	'WOW': '魔獸世界',
	'종교': '宗教',
	'요리/건강': '料理/健康',
	'자습': '自習',
	'강좌': '講座',
	'멘토': '導師',
	'프로야구': '職業棒球',
	'농구': '籃球',
	'골프': '高爾夫',
	'국내축구': '韓國足球',
	'해외축구': '海外足球',
	'스타리그': 'StarLeague',
	'어학': '語言',
	'지식': '知識',
	'취미': '愛好',
	'토크/캠방송': '聊天',
	'나루토': '火影忍者',
	'명탐정코난': '名偵探柯南',
	'교육': '教育',
	'토크/캠방': '聊天',
	'별풍선 &#183; 아이템': '星氣球',
	'별풍선ㆍ아이템': '星氣球',
	'보.라': '聊天',
	'엔터': '娛樂',
	'파트너BJ방송': '合約BJ直播',
	'게임전체': '全部遊戲',
	'스포츠전체': '全部體育',
	'보이는라디오전체': '全部聊天',
	'엔터전체': '全部娛樂',
	'농구/NBA': '籃球/NBA',
	'시청자가밀어주는방송': '用戶推薦的直播',
	'클랜이밀어주는방송': '公會推薦的直播',
	'PC게임': 'PC Games',
	'멸망전': '滅亡戰',
	'라이프': '生活',
	'라이프전체': '全部生活',
	'더빙/리뷰': '配音/評論',
	'격투기/일반': '格鬥/普遍',
	'샵프리카Beta': '購物直播 Beta',
	'라이브': '直播',
	'배구': '排球',
	'농구/배구': '籃球/排球',
	'별풍선·아이템': '星氣球 ·道具',
	'실시간핫이슈': '熱門',
	'오버워치': '鬥陣特攻',
	'리니지LFC': '天堂LFC',
	'테라': 'TERA',
	'철권': '鐵拳',
	'스파5': '快打旋風V',
	'스팀게임': 'Steam Games',
	'뽐': 'FFOM',
	'가우스X아프리카TV': 'Gaus X AfreecaTV',
	'유로2016': 'EURO2016',
	'LoL공식리그': 'LoL Leagues',
	'e스포츠': 'e-Sports',
	'세계정복2': 'World Conquest II',
	'클래시로얄': '皇室戰爭',
	'세븐나이츠': '七騎士',
	'레이븐': 'Raven',
	'쿠키런': 'Cookierun',
	'기타게임': '其他遊戲',
	'두산': 'Doosan',
	'한화': 'Hanwha',
	'롯데': 'Lotte',
	'넥센': 'Nexen',
	'삼성': 'Samsung',
	'중립': '中立',
	'채널A': 'Channel A',
	'TV조선': 'TV Chosun',
	'연합뉴스': 'Yonhapnews',
	'몬길': 'Monster Tamer',
	'서든어택2': '突擊風暴2',
	'BJ리그': 'BJ League',
	'포켓몬GO': 'Pokemon GO',
	'2016BJ대상': '2016 BJ大賞',
	'학습': '學習',
	'애니메이션관': '動漫',
	'콘텐츠제작지원센터': '內容製作支援中心',
	'알려드립니다': '公告',
	'개인정보처리방침': '個人資訊處理方針',
	'개인정보 수정': '更改個人資訊',
	'내 정보': '我的資訊',
	'로그인 기기관리': '我的機器管理',
	'내 방송국': '我是直播間',
	'본방': '原廣播',
	'MY뽐': 'My FFOM',
	'2차비밀번호입력': '',
	'2차비밀번호': '',
	'입력내용삭제': '刪除內容',
	'2차비밀번호찾기': '',
	'MOBILE': '手機',
	'나중에설정': '下次設定',
	'닉네임': '暱稱',
	'고화질로시청': '高清觀看',
	'멀티방송': '多鏡頭直播',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'KakaoTalk',
	'QQspace': 'QQ空間',
	'weibo': '微博',
	'참여중인 중계방': '正在轉播的直播間',
	'멀티방송화면리스트': '多鏡頭畫面列表',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': '直播中的商品可以通過點擊[查看商品]購買。',
	'앱에서시청하기': '前往APP觀看',
	'상품보기': '查看商品',
	'선물하기': '贈送',
	'전송': '發送',
	'비밀번호 변경': '更改密碼',
	'2차 비밀번호 설정': '設定第二階段密碼',
	'건강': '健康',
	'이슈방송스케줄': '熱門直播日程',
	'PC버전으로보기': '使用PC版本Mode',
	'LoL챌린저스': 'LoL challengers',
	'오버워치BJ리그': 'Overwatch League',
	'격투게임': '格鬥遊戲',
	'별풍선/아이템 정보': '星氣球/道具資訊',

	'VOD가 없습니다.': '暫無影片',
	'UP순': '點讚排序',
	'댓글순': '留言排序',
	'조회순': '播放排序',
	'1주일': '1週',
	'방송국': '直播間',
	'인기순' : '人氣順序',

	'사용 중인 퀵뷰가 없습니다.': '您還沒有使用快速收看。',
	'새로고침': '刷新',
	'사용중': '使用中',
	'무료사용중': '免費使用中',
	'자동결제': '自動結算',
	'사용기간이': '使用期限還剩下',
	'일': '日',
	'시간': '小時',
	'남았습니다.': '',
	'다음 결제일': '下一個結算日',
	'보유 중인 퀵뷰': '我的快播道具',
	'보유중인 퀵뷰가 없습니다.': '您還沒有快速收看道具。',
	'보유 중인 퀵뷰 선물권': '我的快速收看',
	'전체 방송': '全部直播',
	'아이템효과 닫기': '關閉道具效果',
	'채팅토글': '聊天切換',
	'앱에서 참여하기': '在APP中觀看',
	'아프리카TV 앱에서 참여하기': '在AfreecaTV APP中觀看',
	'이모티콘': '表情',

	'광고 Skip, 본방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': '馬上就去體驗享有略過廣告、進入滿員直播間、時光機功能等<br>各種福利的<strong>快速收看道具</strong>吧。',
	'아프리카TV 상품권 등록 하세요!': '請先註冊AfreecaTV 商品券 吧！',
	'즐겨찾기에 추가됐습니다.': '添加成功',
	'바람의나라': '風之國',
	'닉네임 변경': '更改暱稱',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': '請輸入漢字6字(英語12字)以內的字符。',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': '更改暱稱後，經過24小時才能重新設定。',
	'닉네임 적용하기': '確認',
	'중복확인': '重複確認',
	'내정보': '我的資訊',
	'퀵뷰 정보': '快速收看資訊',
	'아이템': '道具',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': '登錄後，才能添加關注。要現在登錄嗎？',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': '確定刪除\n此直播嗎？',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': '確定刪除此直播嗎？',
	'사용하기': '使用',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': '在禁看狀態下試圖離開直播間，將被視為強制退場，不能重新進入。',
	'고객센터 문의하기': '諮詢客服',
	'비밀번호 설정 방송': '設置密碼的直播',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播設有密碼，<br>只能在AfreecaTV APP中觀看。',
	'연령제한 방송': '限制年齡直播',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播限制19歲以上用戶觀看，且設有密碼。<br>只能在AfreecaTV APP中打開。',
	'연령제한 / 비밀번호 설정 방송': '年齡限制/設定密碼直播',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播限制19歲以上用戶觀看，且設有密碼。<br>只能在AfreecaTV APP中打開。',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': '此主播尚未開播。<br>建議到AfreecaTV APP觀看更多直播。',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 시청이 가능합니다.': '此主播尚未開播。<br>建議到AfreecaTV APP觀看更多直播。',
	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': '主播暫停直播，',
	'다시 시작될 때까지 잠시만 기다려주세요.': '請稍等片刻。',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': '請通過客服獲取有關強制退場及限制使用服務的諮詢答覆。',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 있습니다.': '先確認您的快速收看道具及 使用期限後，點擊[使用] 就可以使用快速收看道具了。',
	'즐겨찾기가 삭제되었습니다.': '刪除成功',
	'알람': '提示',
	'유료': '付費',
	'알람 닫기': '關閉提示',
	'알림 예약': '預約提示',
	'알림 취소': '取消提示',
	'현재 진행 중인 방송이 없습니다.': '目前沒有進行中的直播。',
	'곧,  BJ들의 재미있고 다양한 방송이': '主播們有趣又豐富的直播',
	'시작됩니다.': '馬上就要開始了。',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>目前沒有進行中的直播。</strong><br>主播們有趣又豐富的直播<br>馬上就要開始了。',
	'방송이 시작되면': '主播開播時，',
	'알려 드릴까요?': ' 需要提示嗎？',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>您已預約',

	'운영자 강제퇴장 안내': '強制退場提示',
	'강제퇴장': '強制退場',
	'회차': '次',
	'(채팅금지 24시간)': '(禁言24小時)',
	'강제퇴장 및 3일 이용정지': '強制退場並停用服務3天',
	'강제퇴장 및 7일 이용정지': '強制退場並停用服務7天',
	'강제퇴장 되어 방송을 보실 수 없습니다.': '被強制退場，無法觀看直播。',
	'앱을 설치하시면 구매가 가능합니다.': '安裝APP，便可馬上購買。',
	'[안내]': '[提示]',
	'방송에 입장하였습니다.': '進入直播間。',
	'채널 퇴장하셨습니다.': '已退出',
	'로그아웃 하시겠습니까?': '要登出嗎？',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': '使用預約提示需先登錄。 \n要現在登錄嗎？',
	'알림 예약이 완료되었습니다.': '預約成功',
	'알림 예약이 취소되었습니다.': '取消成功',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': '不允許未滿19歲用戶觀看。此直播只能在AfreecaTV應用程序中觀看。 \n\n要現在前往嗎？',
	'유효기간': '有效期限',
	'등록되었습니다.': '完成',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': '不能重複使用快播道具。',
	'오류가 발생하여 이용하실수 없습니다.': '發生錯誤。',

	'검색결과': '搜索結果',
	'검색결과가 없습니다.': '沒有搜索結果',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': '更改成功。如要再次更改，請在24小時候操作。',
	'사용할 수 없는 닉네임입니다.': '不能使用此暱稱。',
	'닉네임 중복 확인을 해주세요.': '請確認暱稱是否重複。',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': '此瀏覽器不支持聊天功能。請使用AfreecaTV應用程序。',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '此直播設有密碼，只能在AfreecaTV APP中觀看。',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '此直播設有密碼，只能在AfreecaTV APP中觀看。',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': '由於版權問題，無法在韓國境外觀看此直播。',
	'강제퇴장 되어 방송을 볼 수 없습니다.': '被強制退出直播間的用戶，無法繼續觀看此直播。',
	'로그인 되었습니다.': '成功登錄。',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': '網路狀態不佳，無法讀取數據。要重新嘗試嗎？',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': '網路狀態不佳，無法讀取數據。\n\n要重新嘗試嗎？',
	'채팅 메세지를 입력하세요.': '請輸入聊天內容。',
	'1개 이상 선물이 가능합니다.': '您的星氣球不足。',
	'보유하신 별풍선이 부족합니다.': '您的星氣球不足。',
	'보유하신 골드가 부족합니다.': '您的金幣不足。',
	'보유하신 초콜릿이 부족합니다.': '您的巧克力不足。',
	'선물하기 서버 문제로 이용하실 수 없습니다.': '服務器出現問題，無法使用。',
	'로그인 하셔야 선물 하실 수 있습니다.': '登錄後，才能贈送禮物。',

	'즐겨찾기 바로가기': '前往關注頁面',
	'멀티방송 화면리스트': '多鏡頭畫面列表',
	'현재 유저수': '觀看人數',
	'중계방': '轉播',
	'PC버전으로 보기': 'PC版本觀看',
	'앱에서 보기': '在APP中觀看',
	'명': '人',

	'본방입장': '進入直播間',
	'방송보기': '查看直播',
	'잠시 쉬어갑니다.': '維護/更新中…',
	'점검시간': '維護時間',
	'점검영향': '維護範圍',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': '為了提高服務品質正在進行維護。',
	'이용에 불편을 드려 죄송합니다.': '很抱歉！給各位帶來不便。',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': '我們將盡力為您提供 更加穩定的服務。',
	'채팅안보기 해제': '解除隱藏聊天',
	'채팅안보기': '隱藏聊天',

	'즐겨찾기에 추가됐습니다.': '添加成功',
	'[아프리카TV 안내]': '[AfreecaTV提示]',
	'운영자 안내': '管理員提示',
	'운영자': '管理員',
	'BJ': '主播',
	'매니저': '經紀人',
	'클린아티': '',
	'%s에 의해 강제퇴장 되었습니다.': '被%s強制退場。',
	'%s님은 %s에 의해 채팅금지 %s회가 되셨습니다.': '%s被%s禁止聊天%s次。',
	'로그인 서버오류가 발생하였습니다.': '發生錯誤',
	'지원하지 않는 OS버전입니다.': '不支持此OS版本。',
	'아프리카TV 앱으로 시청해주세요.': '請使用AfreecaTV APP。',
	'버퍼링 중': '加載中...',
	'애청자': '熱心觀眾',
	'팬클럽': '粉絲',
	'통합검색': '搜尋',
	'생방송': '直播',
	'정확도순': '準確度排序',
	'방송시간순': '直播時間排序',
	'누적참여자순': '累計收看人排序',
	'최신방송순': '最新直播排序',
	'적용 되었습니다.': '保存成功',
	'채팅창을 얼렸습니다.': '聊天室被限制',
	'채팅 참여 등급이 변경되었습니다.': '參與聊天的等級發生變化',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': ' 限制了聊天室。 只允許主播和經紀人參與聊天',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': ' 限制了聊天室。只允許主播，經紀人和粉絲參與聊天',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': ' 限制了聊天室。 只允許主播和協助者參與聊天',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': ' 限制了聊天室。 只允許主播，經紀人，粉絲和協助者參與聊天',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': '聊天室解除限制',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "您輸入的\'%s\'包含限制搜索的禁語，無法使用。 ",
	'검색시스템에 문제가 발생했습니다.': '發生錯誤。',
	'검색어를 입력하세요.': '請輸入搜索詞。',
	'|중계방송|': '|轉播間|',

	'BJ가 방송을 종료하였습니다.': '主播結束了直播。',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": "\'%s\'不再接收UP嗎？",
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': '要進入主播的直播間，需要使用快速收看道具。要現在前往購買頁面嗎？',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': '此直播是主播使用手機進行的直播，主播暫停直播時，會中斷或出現黑屏畫面。 (主播重新開始直播時，會自動解除相關現象。)',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '只能在AfreecaTV APP點贊。 \n\n要用APP(iOS, Android)嗎？',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '只能在AfreecaTV應用程序中高清觀看直播。 \n\n 要用應用程序觀看嗎？',
	' 이상~': '以上~',

	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%s被%s禁止聊天。不能參與聊天30秒。',
	'%s 님이 채팅금지 %s회가 되었습니다.': '%s被禁止聊天%s次。',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%s被%s禁止聊天。不能參與聊天60秒。',
	'운영자에 의해 강제퇴장 되었습니다.': '被管理員強制退場。',
	'운영자에 의해<br ng-show="newLine">': '您被管理員',
	'되었습니다': ' ',



	'%s 님이 채팅 금지 횟수 초과로 블라인드 되었습니다. 2분 동안 채팅과 방송화면을 볼 수 없습니다.': '%s被禁止聊天。禁看狀態120秒。',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%s被%s禁止聊天。禁止狀態120秒。禁看狀態不顯示畫面和聊天室，禁看中退出直播會處理為強制退出，不得從新進入。',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제 퇴장되었습니다.': '%s被%s在禁看狀態下退出此直播頻道，被處理為強制退場。',
	'%s(%s)님이 강제퇴장 되었습니다.': '%s被%s強制退場。',
	'%s(%s)님의 강제퇴장이 취소되었습니다..': '%s被%s取消強制退場。',
    '%s님이 별풍선 %s개를 선물했습니다!': '%s贈送了 %s個星氣球！',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': '%s在轉播間贈送了%s個星氣球！',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': '%s在收看影片中贈送了%s個星氣球！',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%s贈送%s個影片風球了',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': '%s在轉直播間贈送%s個影片風球！',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%s已成為第%s位粉絲',
	'%s님이 스티커 %s개를 선물했습니다!': '%s贈送%s個貼圖。',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%s已成為第%s位贊助者。',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': '%s贈送%s個貼圖。',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%s贈送%s個巧克力。',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': '%s贈送%s個巧克力。',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s贈送%s快速收看%s天使用券。',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': '在手機網頁無法收看。 \n請使用電腦或APP(IOS, Andorid)進行收看。',

	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                                                                                        \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                                                                                        \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': '您正在收看的直播包含有害青少年成長的內容。根據促進信息網絡利用及信息保護等相關法律規定， \n\
                                                                                                                                                                                                                        \n\直播信息以更改成人用戶收看。\n\
                                                                                                                                                                                                                        \n\如要繼續收看，需進行本人認證。 ',

	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': '我正在AfreecaTV收看%s，要一起收看嗎？',
	'채팅입력이 지원되지 않는 브라우져입니다.': '此瀏覽器不支持聊天功能。',
	'BJ %s의 방송 함께 봐요!' : '快進來看 %s的影片！',
	'아프리카 TV 사이트(www.afreecatv.com)에서 더 많은 컨텐츠를 즐길 수 있습니다!' : '前往AfreecaTV官方網頁，可以享受更多有趣的直播及影片！',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>請先註冊AfreecaTV <strong>商品券</strong> 吧！ </span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': '不支持此OS版本。 <br>請使用<span>AfreecaTV APP</span>觀看直播。',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': '開始顯示<br>%s的聊天。',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': '開始隱藏<br>%s的聊天。',

	'랭킹 홈': '排行榜主頁',
	'최고 유저 평균': '最高平均觀看人數',
	'애청자 증가수': '熱心觀眾增加數',
	'UP 수': '點讚數',
	'펫방': '寵物直播',
	'더빙/라디오': '配音/廣播',
	'VOD BJ': '影片主播',
	'VOD FAN': '影片粉絲',
	'누적 애청자수': '累計熱心觀眾',
	'누적 팬클럽수': '累計粉絲數',
	'랭킹 소통방 바로가기': '前往排行榜交流區',
	'BJ랭킹 산정 방식 안내': '主播排行篩選方式說明',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': '主播排行榜每週(週一~週日)通過以下比率篩選並反映到網站上。',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '（熱心觀眾數據為累計人數）',
	'VOD BJ 랭킹 산정 방식 안내': '影片主播排行篩選方式說明',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': '影片主播排行榜是依據主播的重播影片及上傳到直播間內的影片而篩選的。',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': '上週(週一~週日)內主播所有影片的點擊增加數',
	'VOD FAN 랭킹 산정 방식 안내': '影片粉絲排行篩選方式說明',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': '影片主播排行榜是用戶編輯並上傳的主播影片而篩選的。',


	'변경': '更改',
	'아프리카TV 앱 실행하기': 'AfreecaTV APP立即前往',
	'최고 유저': '最高收看人數',
	'BJ랭킹': '主播排行',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': '使用<span>AfreecaTV APP</span><br>以<span>最高清晰度(原畫質)</span>參與聊天。',
	'고화질로 시청하기': '高清收看',

	'년 전': '年前',
	'개월 전': '個月前',
	'주 전': '週前',
	'일 전': '天前',
	'시간 전': '小時前',
	'분 전': '分前',
	'초 전': '秒前',
	'보낸 시각': '發送時間',
	'공유하기': '分享',
	'다음 VOD': '下個VOD',
	'검색': '搜尋',
	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '<strong>AfreecaTV APP</strong><span>立即前往</span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': '需要登入後才能設定下次再看功能。',
	'누적 애청자 수': '',
	'누적 팬클럽 수': '',
	'BJ 랭킹': '',
	'즐겨찾기 전체': '全部',
	'최신등록순': '最新上传排序',
	'참여인원순': '观看人数排序',
	'닉네임순': '昵称排序',
	'아이디순': '账号字母排序',
	'최신방송순': '最近直播排序',
	'LIVE 시청': '观看直播',
	'목록 상단 고정 완료': '置顶设置成功。',
	'라이브&amp;VOD': '直播&amp;影片',
	'방송국 개설일': '直播间开设日',
	'최근방송': '最近直播',
	'LIVE 시청하기': '观看直播',
	'방송국가기': '前往直播間',
	'목록 상단 고정': '关注置顶',
	'즐겨찾기한 BJ가 없습니다.': '没有关注的主播。',
	'목록 상단 고정 해제': '解除置顶设置',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': '現在沒有關注的主播們開播。',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': '您关注的主播还没有视频。',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': '關注喜歡的直播，即可隨時方便收看主播的直播和影片。',
	'상단고정 완료되었습니다': '置頂設置成功。',
	'상단고정 해제 되었습니다': '解除置顶设置',
	'지원하지 않는 메뉴입니다.': '不支持此功能。',
	'수신거부 해제되었습니다.' : '設定成功。',
	'필터': 'FILTER',
	'정렬기준': '排序',
	'구분': '區分',
	'날짜': '日期',
	'취소': '取消',
	'적용': '適用',
	'생방송': '直播',
	'업로드VOD': '一般視頻',
	'유저클립': '用戶剪輯',
	'다시보기': '重播',
	'하이라이트': '精華影片',
	'구독전용': '訂閱專屬',
	'1년': '1年',
	'고화질순': '高畫質排序',
	'고해상도순': '高解析度排序',
	'애청자순': '關注人數排序',
	'팬클럽순': '粉絲排行排序',
	'전체알림': '全部提示',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': '接收所有主播的提示音。',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': '不接收所有主播的提示音。',
	'%s님에게 알림을 받습니다.': '開啟來自於%s的提示。',
	'%s님에게 알림을 받지 않습니다.': '關閉來自於%s的提示。',

	'방송제목 변경이력': '直播標題變更記錄',
	'조회순': '播放人數排序',
	'관련된 VOD': '相關影片',
	'검색어와 연관된 추천 영상입니다.': '有關搜索詞的相關影片',
	'게시글': '文章',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': '沒有搜索到最近1個月的內容。<br> 把搜索期間從一個月改為一年，<br> 可以搜索到更多的內容。',
	'조회순': '點擊排序',

	'최근 검색어': '最近搜索詞',
	'실시간 인기 검색어': '實時人氣搜索詞',
	'개인화 추천검색어': '個性化推薦搜索詞',
	'자동저장 <span>끄기</span>': '<span>關閉</span>自動保存',
	'자동저장 <span>켜기</span>': '<span>開啟</span>自動保存',
	'최근 검색어가 없습니다.': '沒有最近搜索詞',
	'최근 검색어 자동저장이 꺼져 있습니다.': '自動保存最近搜索詞功能尚未激活。',
	'자동저장 켜기': '開啟自動保存',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': '登錄後，才能使用<br><strong>個性化推薦搜索詞功能</strong>。',
	'최근검색어를\n모두 삭제하시겠습니까?': '確定要刪除\n最近搜索詞嗎？',
	'최근검색어 자동저장을 사용합니다.': '開始自動保存最近搜索詞。',
	'최근검색어 자동저장 사용을 중지합니다.': '停止自動保存最近搜索詞。',
	'개인화 추천 검색어가 없습니다.': '沒有個性化推薦搜索詞。',
	'검색한 검색결과입니다.': '的搜尋結果。確認',
	'검색결과 보기': '的搜尋結果',

	'나중에 보기' : '下次再看',
	'즐겨찾기한 BJ의 VOD' : '已關注主播的影片',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : '您關注的主播還沒有影片。',
	'즐겨찾기한 BJ의 방송이 없습니다.' : '您關注的主播還沒有直播。',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : '確定刪除最近觀看的所有影片記錄嗎？',
	'재생한 VOD를 모두 삭제하시겠습니까?' : '確定刪除所有已播放的視頻嗎？',
	'UP 한 VOD가 없습니다.' : '沒有點讚的影片。',
	'즐찾 BJ': '關注主播',
	'구독 BJ': '訂閱主播',
	'구독중인 BJ' : '正在訂閱的主播',
	'VOD 홈' : '影片主頁',
	'인기 VOD' : '人氣影片',
	'인기' : '人氣影片',
	'핫이슈 VOD' : '熱門影片',
	'TV클립' : 'TV剪輯',
	'인기 하이라이트' : '人氣剪輯精華',
	'드라마 인기클립' : '戲劇人氣剪輯',
	'예능 인기클립' : '綜藝人氣剪輯',
	'스포츠 인기클립' : '體育人氣剪輯',
	'아프리카TV X TV클립' : 'AfreecaTV X TV剪輯',
	'예능' : '綜藝',
	'교양' : '教育',
	'시사' : '時事',
	'드라마' : '戲劇',
	'스포츠' : '體育',
	'패션/뷰티' : '時尚/美妝',
	'어린이' : '兒童',
	'스페셜' : '精品',
	'디지털' : '數位',
	'뮤직' : '音樂',
	'라디오' : '廣播',
	'키즈' : '兒童',
	'연예' : '演藝',
	'시사보도' : '時事報導',
	'라이프' : '生活',
	'홈페이지' : '網頁',
	'주목할만한 클립' : '值得關注的剪輯',
	'인기 클립' : '人氣剪輯',
	'클립 전체' : '全部剪輯',
	'프로그램 전체' : '全部節目',
	'TV클립 목록이 없습니다.' : '並無TV剪輯列表。',
	'인기 프로그램' : '人氣節目',

	'방송국 가기': '前往直播間',
	'기록' : '收看記錄',
	'카테고리' : '分類',
	'추천받지 않기' : '不再推薦',
	'이어서 보기' : '連續觀看',
	'최근 본 VOD' : '最近觀看影片',
	'UP 한 VOD' : '點贊影片',
	'나중에 보기 삭제' : '刪除',
	'최근 본 VOD 삭제' : '刪除最近觀看影片',
	'UP 취소' : '取消UP',
	'나중에 보기 목록에서 삭제 되었습니다.' : '刪除成功。',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : '刪除成功。',
	'선택하신 VOD UP이 취소 되었습니다.' : '取消成功。',
	'추천 받지 않기가 반영되었습니다.' : '設定成功。',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : '您已關注此直播間。',
	'이미 나중에 보기 등록된 VOD 입니다.' : '您已添加此影片到[下次再看]列表。',
	'전체 삭제' : '刪除全部',
	'나중에 보기 한 VOD가 없습니다.' : '[下次再看]列表為空。',
	'최근 본 VOD가 없습니다.' : '沒有最近觀看的影片。',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : '前往影片首頁，<br>即可確認個性化推薦影片。',
	'VOD 홈으로 이동' : '前往影片主頁',
	'구독' : '訂閱',
	'카테고리 선택' : '選擇分類',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : '登入後才收看影片。',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : '登錄後，即可收看<br>關注主播的影片。',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : '確定刪除所有[下次再看]列表內影片嗎？',
	'UP이 취소되었습니다.' : '取消成功。',
	'추천 받지 않기에 등록 되었습니다.' : '設定成功。',
	'즐겨찾기에서 삭제됐습니다.': '刪除成功',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?': '登錄後，才能添加關注。\n要現在登錄嗎？',
	'팬' : '粉絲',
	'구독한 BJ가 없습니다.' : '暫無訂閱的主播。',
	'구독한 BJ의 VOD가 없습니다.' : '您訂閱的主播沒有影片。',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : '確定從列表刪除%s嗎？',

	'추천' : '推薦',
	'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : '前往影片推薦頁面，<br>便可確認個人化推薦影片。',
	'VOD 추천받기' : '影片推薦',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : '需要登錄。\n要登錄帳號嗎？',
	'주소가 복사되었습니다.' : '複製成功。',
	'본인은 즐겨찾기 할 수 없습니다.' : '不能關注自己。',

	'TAG_VOD_LIST_COUNT' : '<span class="count">共 <em class="point" text="@count"></em>個</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">常看主播的影片</span>',
	'TAG_VOD_RECENT_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">經常確認的主播</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name" text="@nickname"></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span>的影片 <span text="@count"></span>個</span>',

	'알림': '提示',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : '沒有提示。<br>請<a ng-click="setting()"><span>在提示設定</span></a>中選擇同意接收的提示。 ',
	'모두 읽음' : '全部已讀',
	'모두 삭제' : '刪除全部',
	'읽은 알림 삭제' : '刪除已讀短信',
	'알림 설정' : '提示設定',
	'설정' : '設定',
	'%d분 전' : '%d分鐘前',
	'%d시간 전' : '%d小時前',
	'삭제' : '刪除',
	'알림이 삭제되었습니다.' : '刪除成功。',
	'숨기기' : '隱藏',
	'알림 콘텐츠 설정' : '提示內容設定',
	'초기화' : '初始化',
	'저장' : '保存',
	'푸시 설정' : '推送提示',
	'기록 설정' : '記錄設定',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : '推送提示請在<a ng-click="setup_link()"><span>AfreecaTV應用-設定-提示</span></a>設定中更改。',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : '正在初始化。 \n所有已變更的設定值都將初始化。 初始化的提示設定會在保存後生效。',
	'설정이 저장되었습니다.' : '保存成功。',

	'발송취소' : '取消發送',
	'해당 쪽지를 발송 취소하시겠습니까?' : '確定取消發送此短信嗎？',
	'보관한 보낸 쪽지가 없습니다.' : '沒有保管的已發送短信。',
	'보관한 받은 쪽지가 없습니다.' : '沒有保管的已收短信。',
	'읽지 않음' : '未讀',
	'방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : '在直播間, %s贈送 %s個廣告氣球。',
	'유효기간 만료순' :'按有效日期排序',

	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : '確定要舉報此郵件嗎？\n(舉報的郵件將被移至垃圾郵件箱。)',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : '要解除此垃圾郵件嗎？\n(解除的郵件被移至收件箱。)',
	'자동스팸분류':'自動分類垃圾郵件',
	'스팸해제' : '解除垃圾短信',
	'스팸신고': '舉報垃圾短信',
	'보관함':'保管箱',
	'스팸함':'垃圾郵件箱',
	'스팸 쪽지가 없습니다.':'暫無垃圾郵件。',
	'스팸 쪽지':'垃圾郵件',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.':'讀取後垃圾郵件將90日後自定刪除。',

	'"%s" 검색 결과' : '"%s" 搜索結果',
	'검색된 쪽지가 없습니다.' : '沒有有關搜索的結果。',
	'검색어를 입력해 주세요.' : '請輸入內容。',
	'내용' : '內容',
	'검색' : '搜索',
	'더 알아보기' : '查看更多',
	'재생목록':'播放列表已刪除',
	'재생목록에 담기':'添加到播放列表',
	'재생목록 퍼가기':'轉載播放列表',
	'생성한 재생목록':'	已創建的播放列表',
	'재생목록 보관함':'	播放列表保管箱',
	'재생목록이 없습니다.':'暫無播放列表。',
	'새 재생목록 만들기':'創建新播放列表',
	'추가':'添加',
	'새 재생목록':'新播放列表',
	'확인' : '確認',
	'VOD 추가순': '視頻添加排序',
	'오래된순': '時間排序',
	'수정하기': '修改',
	'삭제하기': '刪除',
	'업데이트' : '更新',
	'공개': '公開',
	'비공개' : '非公開',
	'전체보기' : '查看全部',
	'목록 공개': '公開目錄',
	'목록 비공개': '隱藏目錄',
	'목록 퍼가기 허용': '允許轉載列表',
	'목록 퍼가기 비허용': '不允許轉載列表',
	'제목을 입력해주세요.': '請輸入標題。',
	'VOD가 없습니다.' : '暫無視頻。',
	'재생목록을 삭제하시겠습니까?' : '確定要刪除播放列表嗎？',
    '저화질' : '低清晰度',
    '일반화질' : '一般清晰度',
	'원본화질' : '原始清晰度',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : '請在APP中移動，以360°來觀賞影片！',
    '유료 광고 포함' : '包含付費廣告',
    '참여를 위해<br>인코딩 중입니다.': '正在編碼中。',
    '잠깐만 기다려주세요!': '請稍等！',
    '<span><em>초</em> 남았습니다.</span>': '<span>剩餘 <em>seconds</em></span>',
	'보유 중인 선물 받은 구독권' : '持有中的收到的訂閱券',
    '보유 중인 구독 선물권' : '持有中的訂閱贈送券',
    '아이템 정보' : '道具資訊',
    '광고 Skip, Full방 입장, 고화질 방송참여가 가능한<br>퀵뷰아이템을 만나보세요.' : '馬上就去體驗支持跳過廣告、<br>進入滿員直播間、<br>高清觀看直播的快速收看道具。',
    '선물/후원 정보' : '贈送/贊助資訊',
	'교환' : '交換',

	'사용하러 바로가기':'立即前往使用',
	'구독 구매하기':'購買訂閱',
	'구매하러 바로가기':'立即前往購買',
    '연속선물' : '遞增贈送',
    '연속선물이란?' : '遞增贈送?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : '激活遞增贈送功能時，其贈送窗口將會維持，<br> 每次贈送完畢後系統將自動+1。',

	'구매한 스티커' : '已購買貼圖',
	'선물받은 스티커' : '收到的貼圖',
	'스티커 아이템' : '貼圖道具',
	'스티커 교환하기' : '兌換貼圖',
	'아이템 정보' : '道具資訊',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : '正在使用快速收看道具Plus時，無法登錄。',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : '正在使用快速	收看道具時，無法登錄。',
	'플러스로 전환' : '轉換為 Plus',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : '僅有付費快速收看道具、付費快速收看道具贈送券，\n可以轉換為快速收看道具Plus。',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : '已開始使用快速收看道具。\n目前有可以轉換為快速收看道具Plus的機會,\n請問要轉換成快速收看道具Plus嗎?',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "已使用免費快速收看道具，<br>免費快速收看道具無法轉換為快速收看道具Plus。",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "已開始使用免費快速收看道具Plus。",
	"퀵뷰가 사용되었습니다.": "已使用快速收看道具。",
	"퀵뷰 플러스가 사용되었습니다.": "已使用快速收看道具Plus。",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : '轉換為<br><strong>快速收看道具Plus<br>的機會！</strong>',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : '將快速收看道具使用券 轉換為快速收看道具Plus看看吧。',
	'이용기간' : '使用期間',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : '使用時間將會從轉換時間開始重新計算, 使用時間將會延長。',
	'퀵뷰 플러스의 더 커진 혜택' : '更多的快速收看道具Plus優惠',
	'VOD 광고 NO!' : 'VOD廣告NO!',
	'FULL방 입장' : '進入滿員直播間',
	'방송 광고 NO!' : '略過直播廣告!',
	'멀티뷰' : '多視框收看',
	'방송 미리보기(모바일)' : '預覽（手機）',
	'퀵뷰 사용 기간 별 전환 금액' : '依照快速收看道具使用期間轉換的金額',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : '根據使用期間支付轉換金額時，將會重新計算使用期間，提供<strong>增加使用期間的福利!</strong>',
	'사용' : '使用',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'플러스로 전환하기' : '轉換為 Plus',
	'기존 퀵뷰로 사용하기' : '使用 現有快速收看道具',
	'※부가세 미포함' : '※VAT別計',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : '使用期間 %s日結算<span>%s</span>時，可轉換為<strong>快速收看道具Plus%s日券</strong>。',
	'더 저렴하고! 기간도 길게!<br>(최초 1회 제공)' : '更便宜！使用時間更長！<br>（提供首次1次）',
	'선물 ・ 후원 정보' : '贈送・贊助資訊',
	'무료체험중' : '免費體驗中',
	'(iOS자동결제)' : '(iOS自動結帳)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Apple定期訂閱（自動結帳）\n在目前的設備中\n無法更改結帳方式。\n在iPhone（或iPad）的App Store > Apple ID > 進行訂閱。',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Apple定期訂閱（自動結帳）\n在目前設備中\n無法進行取消。在iPhone（或iPad）的App Store > Apple ID > 進行訂閱。',
	'해지 신청중' : '正在處理中',
	'2개의 방송을 동시에! 모바일 멀티뷰' : '同時收看2個直播！Mobile多視角顯示',

	'내가 보유한 스티커' : '我的貼圖',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : '贈送100個以上的專屬氣球時，可留下影片剪輯',
	'소개글' : '簡介',
	'공지' : '公告',
	'나이' : '年齡',
	'경력/수상' : '經歷/得獎紀錄',
	'수상 메달' : '得獎紀錄',
	'앗 혹시 찾으시는 분이...' : "您是在找...",
	'지금 이 콘텐츠에 주목!' : '現在關注這個項目！',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '<span class="normal">為了搜尋</span>\'{{ query }}\'<span class="normal">的用戶所推薦的項目</span>',
	'누적 참여자' : '累計觀看人數',
	'방송 시간' : '直播時間',

	'쉬는시간 후 방송이 이어집니다.' : '直播將會在休息時間結束後繼續。',
	'방송에 후원되는 광고입니다.' : '此為可以贊助直播的廣告。',
	
	'URL 복사' : '複製URL地址',
	'구독하러 바로가기':'立即前往購買',

	'빠른 답장' : '快速回覆',
	'기본 메시지로 저장' : '儲存為基本訊息',
	'보내기' : '回覆',
	'후원 쪽지' : '贊助短信',

	'선물 메세지' : '贈送訊息',
	'목소리 선택' : '選擇提示語音',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : '為了讓BJ可以使用提示語音，<br>請贈送BJ貼圖看看吧！',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : '若BJ沒有設定電子祕書，或贈送的個數未達設定值，將不會播放提示。',

	'영상 광고 없는 VOD 재생!' : '收看無廣告VOD！',
	'캐치 광고 제외' : 'Catch廣告除外',
	'방송 입장 영상광고 NO!' : '直播入場廣告NO',
	'배너형, 로딩광고, LIVE 중간광고 제외' : 'Banner、Loading廣告、Live中間廣告除外',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':'為了保護未成年用戶，<br><strong>須滿19歲以上的用戶</strong>才能觀看<br>設有年齡限制的內容。<br>只能在AfreecaTV APP中參與。',
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':'為了保護未成年用戶，須滿19歲以上的用戶才能觀看\n設有年齡限制的內容。\n\n要前往AfreecaTV APP中參與嗎？',	

	'대결미션' : '對決任務', 
	'대결미션이란?': '對決任務是？',
	'대결단계에서만 후원 가능합니다.': '只能在對決任務進行時贊助。',
	'보유 별풍선이 즉시 차감됩니다.': '使用的星氣球會立即從剩餘星氣球中扣除。',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': '此為BJ組成小組進行對決，並根據對決結果分配贊助星氣球的功能。',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': '',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': '贊助Cash時，可以加入粉絲團，並計算超級粉絲的排名。',
	
	'도전미션' : '挑戰任務',
	'보유 별풍선 :' : '我的星氣球 :',
	'도전미션이란?' : '挑戰任務是？',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : '向BJ邀請挑戰任務，<br />贊助星氣球獎金吧！',
	'새로운 미션 요청' : '新任務邀請',
	'후원할 별풍선' : '要贊助的星氣球',
	'후원하기' : '贊助',
	'미션 내용을 입력해 주세요. (최대 30자)' : '請輸入任務內容。（最多30字）',
	'미션 제한 시간' : '任務限制時間',
	'도전미션 일': '日',
	'도전미션 시간': '小時',
	'도전미션 분' : '分',
	'미션 상금' : '任務獎金',
	'미션 요청' : '任務邀請',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : 'BJ進行任務，並根據成功與否來獲得用戶募集獎金的贊助服務。',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : '用戶可以邀請挑戰任務，或BJ可直接登錄任務。',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : '任務結果由BJ決定，邀請任務的用戶僅能決定「成功」。',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : '挑戰任務是在BJ和用戶的信任為前提進行，任何情況下都無法推翻任務結果。',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : '超過任務限制時間時，任務將自動失敗。',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : '任務成功時，將會把募集的獎金頒發給BJ。',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : '取消任務、任務失敗時，獎金將返還給用戶。',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : 'BJ可以延長一次任務限制時間。（最多10天）',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : '您可以為等待／進行中的任務追加贊助獎金。',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : '加入粉絲團／超級粉絲的統計將在任務成功後頒發獎金給BJ時進行。',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : '用戶在最近1天內累計取消5次任務時，24小時內將無法向所有BJ邀請任務。 請慎重地邀請任務。',
	
	'아이템은 여기서 구매하세요!' : '請在此購買道具！',
	'1:1 문의 바로가기' : '前往1:1客服諮詢',
	'내 애드벌룬 확인하고 선물하기' : '確認並贈送我的廣告氣球',

	'규칙에 동의합니다!' : '我同意規則！',
	
	'내 별풍선' : '我的星氣球',
	'내 별풍선ㆍ아이템' : '我的星氣球 · 道具',
	'드롭스 이벤트 진행 중': 'Drops活動進行中',

	'퀵뷰': '快速收看',
	'퀵뷰 플러스': '快速收看道具Plus',
	'만료': '到期',
	'예약': '預約',
	'사용 예약': '預約',
	'예약 취소': '取消',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': '已預訂使用%s 。\n\n目前正在使用的快速收看道具使用期限到期時，將繼續使用已預訂使用的道具 。',
	'%s 사용을 예약 취소하시겠습니까?': '您確定要取消%s道具的使用預約嗎？',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': '%s 使用預約已被取消，\n\n如需預約使用，請點擊「預約使用」。',

	'공식방송국':	'直播間',
	'공식 방송국':'直播間',
	'관련BJ':	'相關BJ',
	'관련 BJ':	'相關BJ',
	'이벤트':	'活動',
	'시리즈게임':	'系列遊戲',
	'시리즈 게임':	'系列遊戲',
	'전체':	'全部',
	'명':	'人',
	'관련BJ<br>더보기':	'查看更多<br>相關BJ',
	'LIVE':	'LIVE',
	'다시보기':	'重播',
	'경기없음':	'無轉播',
	'경기취소':	'比賽取消',
	'경기예정':	'預定比賽',
	'결과<br>보기':	'查看<br>結果',
	'참여인원순':	'人數排序',
	'시청인원순':	'觀看人數排序',
	'최근방송':	'最新直播',
	'LIVE 참여하기':	'參與LIVE',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'最近沒有直播的BJ，<br>以相應類別開始進行直播吧！',
	'예정된 경기가 없습니다.':	'沒有預定的比賽。',
	'경기종료':	'比賽結束',
	'최신방송순':	'播出排序',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '<span class="normal">為搜尋「</span>\'{{ query }}\'<span class="normal">」的用戶所推薦的BJ</span>',

	// 모웹 메인 footer에서 사용
	'이용약관': '使用條款',
	'개인정보 처리방침': '個人資訊處理方針',
	'대표이사': '代表理事',
	'사업자번호': '營業執照號',
	'사업자 정보 확인': '企業資訊確認',
	'호스팅 제공자': '託管提供者',
	'주식회사 숲': 'ⓒ SOOP Corp.',
	'주소': '地址',
	'경기도 성남시 분당구 판교로 228번길 15 판교세븐밴처밸리 1단지 2동 주식회사 숲(삼평동)': 'PanGuo 7 VentureValley 1-2, 15, PangGyo-ro 228beon-gil, Bundang-Gu, SeongNam-Si, GyeongGi-Do, Korea',
	'대표 번호': '代表號碼',
	'이메일': '電子郵件',
	'사업자 정보': '營業執照',
	'통신판매번호': '通信販賣業申報編號',
	'FAX': 'FAX',
	'\'{{ query }}\' 검색한 유저를 위한 추천 Catch' : '推薦給搜尋「\'{{ query }}\'」的用戶的推薦Catch',
	'계정연결': '帳號連結',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'본방 입장': '進入直播間',
	'남자': '男性',
	'여자': '女性',
	'캐릭터': '角色',

	'종료된 방송입니다.': '此直播已結束。',
	'지금 바로 아프리카TV의 다양한 생방송과<br>재미있는 VOD를 만나보세요!': '立即來看看AfreecaTV多樣的直播與有趣的影片吧！',
	'VOD 보러가기': '往前影片收看'
};
