module.exports = {
	'홈': '首页',
	'핫이슈': 'HOT ISSUE',
	'나중에보기': '下次再看',
	'전체': '全部',
	'로그인': '登录',
	'최신순': '最新排序',
	'참여인원순': '观看人数排序',
	'즐겨찾기 추가': '添加关注',
	'방송국 보러가기': '前往直播间',
	'나중에보기 등록': '添加到下次再看目录',
	'목록편집': '编辑目录',
	'전체삭제': '删除全部',
	'재생한 VOD 삭제': '删除已播放视频',
	'시청한 VOD': '已觀看視頻',
	'다시보기': '重播',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': '登录后，才能确认<br>[下次再看]列表内视频。',
	'나중에 보기에 등록한 VOD가 없습니다.': '找不到视频。',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': '当无法观看视频时，<br/>请按[下次再看]按钮，<br/>有空时随时观看视频！',
	'나중에보기 삭제': '从列表删除',
	'나중에 보기 서비스란?': '下次再看是？',
	'나중에 보기에 등록되었습니다.': '添加成功',
	'나중에 보기가 삭제되었습니다.': '刪除成功。',
	'라이브 방송을 시청할 수 없는 상황일 때': '不方便观看直播时',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': '可以添加到列表，有空时再观看！',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': '<span class="blue">添加视频到“下次再看”后,</span><br>在列表中可以确认。',
	'보고 싶은 VOD나, 아직 보지 않은': '添加感兴趣的或还未观看的视频到列表,',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': '有空时再观看！',
	'다시 보지 않기': '不再显示',
	'닫기': '关闭',
	'나중에 보기를 지원하지 않는 영상입니다.': '此影片不支持[下次再看]功能。',
	'쪽지함': '短信',
	'새 쪽지 <span>%s</span>개': '新短信<span>%s</span>个',
	'새 쪽지가 없습니다': '没有短信',
	'쪽지 보내기': '发送短信',
	'쪽지설정': '接收短信设定',
	'받은 쪽지함': '收件',
	'보낸 쪽지함': '发件',
	'쪽지 보관함': '短信存档',
	'전체 쪽지': '所有短信',
	'안읽은 쪽지': '未读',
	'보관한 쪽지':'保管箱',
	'보관한 쪽지가 없습니다.': '保管箱为空。',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': '已保存短信会永久保存(最多1,000个)。',
	'이미지 첨부는 3개까지 가능합니다.': '图片文件不能超过3个。',
	'이미지 첨부는 8MB까지 가능합니다.': '图片文件大小不能超过8MB。',
	'이미지가 저장되었습니다.': '保存成功。',
	'확인': '确认',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(保管期间为自接收日起<em>90</em>天)',
	'이미지 첨부': '添加图片',
	'첨부 이미지': '附件',
	'이미지 저장 기간': '图片保存期间',
	'이미지 저장 기간 만료': '图片保存日期已满',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(自赠送日起<em>90</em>天)',
	'이미지 파일은 1개씩 첨부 가능합니다.': '图片文件只能逐一添加。',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': '图片只持支JPG, JPEG, GIF, PNG, BMP格式。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': '移至保管箱的短信将永久保存。',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)':'移至保管箱的短信将永久保存。(最多1,000个)',
	'이미지 첨부 중입니다.': '在添加图片中。',

	'보관': '保存',
	'신고': '举报',
	'삭제': '删除',
	'받은 쪽지가 없습니다.': '没有接收的短信。',
	'읽지않음': '未读',
	'읽음': '已读',
	'더 보기': '查看更多',
	'받은 쪽지': '收件箱',
	'보낸 사람': '发件人',
	'받은 시각': '时间',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': '短信自确认时间起90天后自动删除。',
	'답장': '回复',
	'수신거부': '拒收',
	'보낸 쪽지가 없습니다.': '没有发送的短信。',
	'보냄': '发送 ',
	'보낸 쪽지': '发件箱',
	'받는 사람': '收件人',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': '已发送的短信从发送日起自动保存90日。',
	'쪽지 수신 설정': '接收短信设定',
	'전체 수신거부': '拒收全部短信',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': '拒收所有短信。<br>(管理员账号除外)',
	'수신거부 ID 목록': '拒收短信账号目录',
	'총 %s명': '共%s名',
	'아이디': '账号',
	'추가 날짜': '添加日期',
	'수신거부 목록이 없습니다.': '拒收短信账号目录为空。',
	'수신거부 해제': '解除',
	'추가': '添加',
	'수신거부 추가': '添加到拒收名单',
	'쪽지 보내기': '发送短信',
	'보내기': '发送',
	'받는 아이디': '收件人',
	'다중 전송은 엔터키로 구분해 주세요.': '一次发送多个短信时，请用Enter区分帐号。',
	'최대 5,000자까지 작성 가능합니다.': '内容不能超过5,000字。',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': '您使用了禁止词语，不能使用此功能。请咨询客服中心。',
	'신고가 접수되었습니다.': '举报成功。',
	'선택한 쪽지를\n신고하시겠습니까?': '要举报已选短信吗？',
	'쪽지를 선택해 주세요.': '请选择短信。',
	'선택한 쪽지를\n삭제하시겠습니까?': '要删除已选短信吗？',
	'LIVE/VOD 광고 NO' : 'LIVE及VOD广告NO',
	'쪽지를 선택해 주세요.': '请选择短信。',
	'삭제하시겠습니까?': '要删除吗？',
	'신고하시겠습니까?': '要举报吗？',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': '设定成功。*解除拒绝接收短信可在[拒收设定]中更改。',
	'아이디를 입력해 주세요.': '请输入账号',
	'아이디는 영문,숫자만 입력 가능합니다.': '账号必须由英文，数字组成。',
	'아이디를 선택해 주세요': '请选择账号。',
	'해제되었습니다.': '解除成功',
	'한 번에 최대 30명까지만 전송 가능합니다.': '一次最多可以给30人发送短信。',
	'본인에게는 쪽지를 보낼 수 없습니다.': '不能给自己发送短信。',
	'이미 받는사람 목록에 포함 되어 있습니다.': '收件人列表内已存在此用户。',
	'%s은(는) 존재하지 않는 닉네임/아이디입니다.': '%s不存在。',
	'받는 아이디/닉네임을 입력해 주세요.': '请输入收件人账号/昵称',
	'쪽지 내용을 입력해 주세요.': '请输入内容。',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': '一次最多可以给30人发送短信。',
	'전송이 완료되었습니다.': '发送成功',
	'로그인이 되어 있지 않습니다.': '尚未登录',
	'아이디는 12자 이내로 입력해 주세요.': '账号长度不能超过12个字符。',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': '举报成功(管理员账号除外)。\n确定删除已举报短信吗？',
	'잠시 후 이용해 주세요.': '请稍后重新尝试。',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': '拒收设定成功。(管理员账号除外)\n要确认详细内容吗？',
	'해당 쪽지가 삭제되었습니다.' : '此短信已被删除。',
	'해당 쪽지가 신고되었습니다.' : '此短信被举报。',
	'수신거부가 완료되었습니다.' : '拒收设定成功',
	'해당 쪽지가 발송 취소되었습니다.' : '已取消发送此短信。',
	'이미 신고된 쪽지입니다.':'您已举报此短信。',
	'수신거부 ID 추가' : '添加账号',

	'즐겨찾기': '关注',
	'즐찾': '关注',
	'방송중': '直播中',
	'방송 시작 전': '直播前',
	'BJ 랭킹순': '主播排行排序',
	'방송 중인 즐겨찾기 BJ가 없습니다.': '目前没有正在直播的关注主播。',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': '目前没有准备直播中的关注主播。',
	'즐겨찾기한 BJ가 없거나': '没有关注的主播，',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': '或没有正在直播中的关注主播。',
	'상세정보': '详细信息',
	'추천 받지 않기': '不再推荐',
	'즐겨찾기 삭제': '删除关注',
	'명 시청중': '人正在观看',

	'BJ랭킹순': '主播排行排序',
	'회원가입': '注册会员',
	'고객센터': '客服',
	'최근 본 방송': '最近观看',
	'지상파/케이블': '频道',
	'보이는라디오': '聊天',
	'스포츠': '体育',
	'게임': '游戏',
	'애니': '动漫',
	'콘텐츠발전소': '内容发电站',
	'별풍선': '星气球',
	'장애/버그신고': '障碍/故障举报',
	'공지사항': '公告',
	'게임센터': '游戏中心',
	'더보기': '查看更多',
	'신인': '新人',
	'시청자순': '观看人数排序',
	'참여자순': '观看人数排序',
	'1일': '1天',
	'1주': '1周',
	'1개월': '1个月',
	'즐겨찾기할만한방송': '推荐直播',
	'아프리카TV앱': 'Afreeca TV应用程序',
	'바로가기': '立即前往',
	'정보': '信息',
	'댓글': '留言',
	'승인': '確認',
	'인기방송': '人气直播',
	'내가 보유한 별풍선': '我的星气球',
	'확인': '確認',
	'취소': '取消',
	'스티커': '贴图',
	'구매하기': '购买',
	'직접입력': '直接输入',
	'초콜릿': '巧克力',
	'로그인상태유지': '保持登录状态',
	'개': '个',
	'비밀번호': '密码',
	'최근 본 방송 내역 전체 삭제하기': '删除全部',
	'전체방송': '全部直播',
	'모바일게임': '手游',
	'애니메이션': '动漫',
	'먹방/쿡방': '吃播',
	'음악': '音乐',
	'시사/현장': '新闻/现场',
	'주식/금융': '股票/金融',
	'금융': '金融',
	'생활/정보': '生活/百科',
	'공부방': '学习',
	'보이는쇼핑': '购物',
	'케이블': '有线',
	'1:1문의': '1:1咨询',
	'엔터테인먼트': '娱乐',
	'창업방': '创业',
	'VOD': '视频',
	'개인방송국': '个人直播间',
	'핫이슈동영상': '热门视频',
	'로그아웃': '登出',
	'방송시작전': '准备直播中',
	'아이디찾기': '找回账号',
	'방송국보러가기': '前往直播间',
	'연령제한': '观看年龄限制',
	'청소년유해신고': '青少年有害举报',
	'국내야구': '韩国棒球',
	'일본야구': '日本棒球',
	'프로농구': '职业篮球',
	'프로배구': '职业排球',
	'UFC/일반': 'UFC/普通',
	'프로골프': '职业高尔夫',
	'특집VOD': '视频特辑',
	'스포츠특집': '体育特辑',
	'게임특집': '游戏特辑',
	'명시청중': '人在观看',
	'내가 보유한 골드': '我的金币',
	'내가 보유한 초콜릿': '我的巧克力',
	'아프리카TV': 'AfreecaTV',
	'아프리카TV,': 'AfreecaTV',
	'랭킹순': '排行排序',
	'님이': '-',
	'비밀번호찾기': '找回密码',
	'최근본방송 삭제': '删除观看记录',
	'최근본방송추가': '添加最近观看直播',
	'시청한 방송이 없습니다': '暂无观看记录',
	'지금 바로 아프리카TV의 재미있고': '马上去体验Afreeca TV有趣、',
	'다양한 방송을 만나보세요!': '且丰富的直播！',
	'[생]': '[Live]',
	'[재]': '[重播]',
	'[중계방]': '[转播间]',
	'아이디비번찾기': '找回账号/密码',
	'명시청': '人观看',
	'레이디스배틀': 'Ladies Battle(中国)',
	'레이디스배틀(중국)': 'Ladies Battle(中国)',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': '此账号尚未注册，或输入的账号/密码有误。',
	'아이디/비밀번호찾기': '找回账号/密码',
	'님이빠져들만한방송': '(님)可能感兴趣的直播',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': '',
	'구매': '购买',
	'즐겨찾기추가': '添加关注',
	'베스트BJ방송': '最佳直播',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': '确定要删除最近观看的全部记录吗？(如要删除特定记录，请点击直播目录的[查看更多]按钮)',
	'자신에게는선물하실수없습니다.': '不能给自己赠送礼物。',
	'현재시청자수': '目前观看人数',
	'모바일': '移动端',
	'누적 시청자 수': '累计观看人数',
	'유머/패러디': '搞笑/戏仿',
	'베스트BJ': '最佳主播',
	'신인BJ': '新人主播',
	'파트너BJ': '签约主播',
	'뷰티/패션': '美容/时尚',
	'샵프리카': '购物直播',
	'MY쇼핑': '我的购物',
	'마인크래프트': '我的世界',
	'히어로즈': '英雄',
	'메이플스토리2': '新枫之谷2',
	'피파온라인3': 'FIFA ONLINE3',
	'검은사막': '黑沙漠',
	'블랙스쿼드': '暗影小组',
	'하스스톤': '炉石传说',
	'문명': '文明',
	'버블파이터': '泡泡战士',
	'마비노기': '洛奇',
	'사이퍼즈': '超能战联',
	'메이플스토리': '新枫之谷',
	'테일즈런너': '超级跑跑',
	'서든어택': '突击风暴',
	'마영전': '洛奇英雄传',
	'리니지': '天堂',
	'스타': '星际争霸',
	'스타2': '星际争霸2',
	'아키에이지': '上古世纪',
	'카트라이더': '跑跑卡丁车',
	'모두의마블': '天天富翁',
	'디아블로3': '暗黑破坏神3',
	'월드오브탱크': '坦克世界',
	'워크래프트': '魔兽争霸',
	'아이온': '永恒纪元',
	'던파': '地下城与勇士',
	'스페셜포스': '特种部队',
	'고전게임': '小游戏',
	'비디오게임': '电子游戏',
	'기타': '其他',
	'LOL': '英雄联盟',
	'BladeNSoul': '灵剑',
	'WOW': '魔兽世界',
	'종교': '宗教',
	'요리/건강': '料理/健康',
	'자습': '自习',
	'강좌': '讲座',
	'멘토': '导师',
	'프로야구': '职业棒球',
	'농구': '篮球',
	'골프': '高尔夫',
	'국내축구': '韩国足球',
	'해외축구': '海外足球',
	'스타리그': '星际争霸联赛',
	'어학': '语言',
	'지식': '知识',
	'취미': '爱好',
	'토크/캠방송': '聊天',
	'나루토': '火影忍者',
	'명탐정코난': '名侦探柯南',
	'교육': '教育',
	'토크/캠방': '聊天',
	'별풍선 &#183; 아이템': '星气球·道具',
	'별풍선ㆍ아이템': '星气球ㆍ道具',
	'보.라': '聊天',
	'엔터': '娱乐',
	'파트너BJ방송': '签约直播',
	'게임전체': '全部游戏',
	'스포츠전체': '全部体育',
	'보이는라디오전체': '全部聊天',
	'엔터전체': '全部娱乐',
	'농구/NBA': '篮球/NBA',
	'시청자가밀어주는방송': '用户推荐的直播',
	'클랜이밀어주는방송': '公会推荐的直播',
	'PC게임': '客户端游戏',
	'멸망전': '灭亡战',
	'라이프': '生活',
	'라이프전체': '全部生活',
	'더빙/리뷰': '配音/评论',
	'격투기/일반': '格斗/普遍',
	'샵프리카Beta': '购物直播 Beta',
	'라이브': '直播',
	'배구': '排球',
	'농구/배구': '篮球/排球',
	'별풍선·아이템': '星气球 ·道具',
	'실시간핫이슈': '热门',
	'오버워치': '守望先锋',
	'리니지LFC': '天堂LFC',
	'테라': 'TERA',
	'철권': '铁拳',
	'스파5': '街头霸王5',
	'스팀게임': 'Steam游戏',
	'뽐': 'FFOM',
	'가우스X아프리카TV': 'Gaus X AfreecaTV',
	'유로2016': 'EURO2016',
	'LoL공식리그': '英雄联盟正规联赛',
	'e스포츠': '电竞',
	'세계정복2': '世界征服2',
	'클래시로얄': '皇室战争',
	'세븐나이츠': '七骑士',
	'레이븐': '掠夺者',
	'쿠키런': 'Cookierun',
	'기타게임': '其他游戏',
	'두산': 'Doosan',
	'한화': 'Hanwha',
	'롯데': 'Lotte',
	'넥센': 'Nexen',
	'삼성': 'Samsung',
	'중립': '中立',
	'채널A': 'Channel A',
	'TV조선': 'TV Chosun',
	'연합뉴스': 'Yonhapnews',
	'몬길': '全民打怪兽',
	'서든어택2': '突击风暴2',
	'BJ리그': 'BJ联赛',
	'포켓몬GO': '精灵宝可梦GO',
	'2016BJ대상': '2016主播大奖',
	'학습': '学习',
	'애니메이션관': '动漫',
	'콘텐츠제작지원센터': '内容制作支援中心',
	'알려드립니다': '公告',
	'개인정보처리방침': '个人信息处理方针',
	'개인정보 수정': '更改个人信息',
	'내 정보': '我的信息',
	'로그인 기기관리': '登录设备管理',
	'내 방송국': '我的直播间',
	'본방': '直播间',
	'MY뽐': 'My FFOM',
	'2차비밀번호입력': '',
	'2차비밀번호': '',
	'입력내용삭제': '删除内容',
	'2차비밀번호찾기': '',
	'MOBILE': '手机',
	'나중에설정': '下次设定',
	'닉네임': '昵称',
	'고화질로시청': '高清观看',
	'멀티방송': '多镜头直播',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'KakaoTalk',
	'QQspace': 'QQ空间',
	'weibo': '微博',
	'참여중인 중계방': '正在转播的直播间',
	'멀티방송화면리스트': '多镜头画面列表',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': '直播中的商品可以通过点击[查看商品]购买。',
	'앱에서시청하기': '前往应用程序观看',
	'상품보기': '查看商品',
	'선물하기': '赠送',
	'전송': '发送',
	'비밀번호 변경': '更改密码',
	'2차 비밀번호 설정': '设定二阶段密码',
	'건강': '健康',
	'이슈방송스케줄': '热门直播日程',
	'PC버전으로보기': '使用PC版本视图',
	'LoL챌린저스': '英雄联盟挑战赛',
	'오버워치BJ리그': '守望先锋主播联赛',
	'격투게임': '格斗游戏',
	'별풍선/아이템 정보': '星气球/道具信息',

	'VOD가 없습니다.': '暂无视频',
	'UP순': '点赞排序',
	'댓글순': '留言排序',
	'조회순': '播放排序',
	'1주일': '1周',
	'방송국': '直播间',
	'인기순' : '人气度排序',

	'사용 중인 퀵뷰가 없습니다.': '您还没有使用快播。',
	'새로고침': '刷新',
	'사용중': '使用中',
	'무료사용중': '免费使用中',
	'자동결제': '自动结算',
	'사용기간이': '使用期限还剩下',
	'일': '日',
	'시간': '小时',
	'남았습니다.': '',
	'다음 결제일': '下一个结算日',
	'보유 중인 퀵뷰': '我的快播道具',
	'보유중인 퀵뷰가 없습니다.': '您还没有快播道具。',
	'보유 중인 퀵뷰 선물권': '我的快播赠送券',
	'전체 방송': '全部直播',
	'아이템효과 닫기': '关闭道具效果',
	'채팅토글': '聊天切换',
	'앱에서 참여하기': '在应用程序中观看',
	'아프리카TV 앱에서 참여하기': '在AfreecaTV应用程序中观看',
	'이모티콘': '表情',

	'광고 Skip, Full방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': '快来体验支持进入满员直播间, 跳过广告的<strong>快播道具</strong>吧!',
	'아프리카TV 상품권 등록 하세요!': '请先注册AfreecaTV 商品卷 吧！',
	'즐겨찾기에 추가됐습니다.': '添加成功',
	'바람의나라': '风之国',
	'닉네임 변경': '更改昵称',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': '请输入汉字6字(英语12字)以内的字符。',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': '更改昵称后，经过24小时才能重新设定。',
	'닉네임 적용하기': '确认',
	'중복확인': '重复确认',
	'내정보': '我的信息',
	'퀵뷰 정보': '快播信息',
	'아이템': '道具',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': '登录后，才能添加关注。要现在登录吗？',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': '确定删除此直播吗？',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': '确定删除此直播吗？',

	'사용하기': '使用',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': '在屏障状态下试图离开直播间，将被视为强制退场，不能重新进入。',
	'고객센터 문의하기': '咨询客服',
	'비밀번호 설정 방송': '设置密码的直播',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播设有密码，<br>只能在Afreeca TV应用程序中观看。',
	'연령제한 방송': '观看年龄受限直播',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播限制19岁以下用户观看，且设有密码。<br>只能在Afreeca TV应用程序中打开。',
	'연령제한 / 비밀번호 설정 방송': '年龄限制/设定密码直播',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.': '此直播限制19岁以下用户观看，且设有密码。<br>只能在Afreeca TV应用程序中打开。',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': '此主播尚未开播。<br>建议到Afreeca TV应用程序观看更多直播。',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 시청이 가능합니다.': '此主播尚未开播。<br>建议到Afreeca TV应用程序观看更多直播。',
	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': '请稍等片刻。',
	'다시 시작될 때까지 잠시만 기다려주세요.': '请稍等片刻。',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': '请通过客服获取有关强制退场及限制使用服务的咨询答复。',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 있습니다.': '确认您的快播道具及有效期限后，点击 [使用]，就能在相应期间内使用快播道具。',
	'알람': '提示',
	'유료': '付费',
	'알람 닫기': '关闭提示',
	'알림 예약': '预约提示',
	'알림 취소': '取消提示',
	'현재 진행 중인 방송이 없습니다.': '目前没有进行中的直播。',
	'곧,  BJ들의 재미있고 다양한 방송이': '主播们有趣又丰富的直播',
	'시작됩니다.': '马上就要开始了。',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>目前没有进行中的直播。</strong><br>主播们有趣又丰富的直播<br>马上就要开始了。',
	'방송이 시작되면': '主播开播时，',
	'알려 드릴까요?': '需要提示吗？',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>您已预约',

	'운영자 강제퇴장 안내': '强制退场提示',
	'강제퇴장': '强制退场',
	'회차': '次',
	'(채팅금지 24시간)': '(禁言24小时)',
	'강제퇴장 및 3일 이용정지': '强制退场并停用服务3天',
	'강제퇴장 및 7일 이용정지': '强制退场并停用服务7天',
	'강제퇴장 되어 방송을 보실 수 없습니다.': '被强制退场，无法观看直播。',
	'앱을 설치하시면 구매가 가능합니다.': '安装应用程序，便可马上购买。',
	'[안내]': '[提示]',
	'방송에 입장하였습니다.': '进入直播间。',
	'채널 퇴장하셨습니다.': '已退出',
	'로그아웃 하시겠습니까?': '要登出吗？',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': '使用预约提示需先登录。\n要现在登录吗？',
	'알림 예약이 완료되었습니다.': '预约成功',
	'알림 예약이 취소되었습니다.': '取消成功',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': '不允许未满19岁的用户观看。此直播只能在AfreecaTV应用程序中观看。\n\n要现在前往吗？',
	'유효기간': '有效期限',
	'등록되었습니다.': '完成',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': '不能重复使用快播道具。',
	'오류가 발생하여 이용하실수 없습니다.': '发生错误。',

	'검색결과': '搜索结果',
	'검색결과가 없습니다.': '没有搜索结果',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': '更改成功。如要再次更改，请在24小时后操作。',
	'사용할 수 없는 닉네임입니다.': '不能使用此昵称。',
	'닉네임 중복 확인을 해주세요.': '请确认昵称是否重复。',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': '此浏览器不支持聊天功能。请使用AfreecaTV应用程序。',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '此直播设有密码，只能在Afreeca TV应用程序中观看。',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '此直播设有密码，只能在Afreeca TV应用程序中观看。',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': '由于版权问题，无法在韩国境外观看此直播。',
	'강제퇴장 되어 방송을 볼 수 없습니다.': '被强制退出直播间的用户，无法继续观看此直播。',
	'로그인 되었습니다.': '成功登录。',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': '网络状态不佳，无法读取数据。要重新尝试吗？',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': '网络状态不佳，无法读取数据。\n\n要重新尝试吗？',
	'채팅 메세지를 입력하세요.': '请输入聊天内容。',
	'1개 이상 선물이 가능합니다.': '您的星气球不足。',
	'보유하신 별풍선이 부족합니다.': '您的星气球不足。',
	'보유하신 골드가 부족합니다.': '您的金币不足。',
	'보유하신 초콜릿이 부족합니다.': '您的巧克力不足。',
	'선물하기 서버 문제로 이용하실 수 없습니다.': '服务器出现问题，无法使用。',
	'로그인 하셔야 선물 하실 수 있습니다.': '登录后，才能赠送礼物。',

	'즐겨찾기 바로가기': '前往关注页面',
	'멀티방송 화면리스트': '多镜头画面列表',
	'현재 유저수': '观看人数',
	'중계방': '转播间',
	'PC버전으로 보기': 'PC版本视图',
	'앱에서 보기': '在应用程序中观看',
	'명': '人',

	'본방입장': '进入直播间',
	'방송보기': '查看直播',
	'잠시 쉬어갑니다.': '维护/更新中…',
	'점검시간': '维护时间',
	'점검영향': '维护范围',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': '为了提高服务品质正在进行维护。',
	'이용에 불편을 드려 죄송합니다.': '非常抱歉给您带来不便。',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': '我们将尽力为您提供 更加稳定的服务。',
	'채팅안보기 해제': '解除隐藏聊天',
	'채팅안보기': '隐藏聊天',

	'즐겨찾기에 추가됐습니다.': '添加成功',
	'즐겨찾기가 삭제되었습니다.': '删除成功',
	'[아프리카TV 안내]': '[AfreecaTV提示]',
	'운영자 안내': '管理员提示',
	'운영자': '管理员',
	'BJ': '主播',
	'매니저': '房管',
	'클린아티': '阿飞',
	'%s에 의해 강제퇴장 되었습니다.': '被%s强制退场。',
	'%s 님이 채팅금지 %s회가 되었습니다.': '%s被禁止聊天%s次。',
	'로그인 서버오류가 발생하였습니다.': '发生错误',
	'지원하지 않는 OS버전입니다.': '不支持此OS版本。',
	'아프리카TV 앱으로 시청해주세요.': '请使用AfreecaTV应用程序。',
	'버퍼링 중': '加载中...',
	'애청자': '热心观众',
	'팬클럽': '粉丝',
	'통합검색': '综合结果',
	'생방송': '直播',
	'정확도순': '准确度排序',
	'방송시간순': '直播时间排序',
	'누적참여자순': '累计观看人数排序',
	'최신방송순': '最新直播排序',
	'적용 되었습니다.': '保存成功',
	'채팅창을 얼렸습니다.': '聊天栏被冷却。',
	'채팅 참여 등급이 변경되었습니다.': '参与聊天等级有变动。',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': ' 只允许主播和房管参与聊天。',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': ' 只允许主播、房管和粉丝参与聊天。',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': ' 只允许主播、房管和赞助者参与聊天。',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': ' 只允许主播、房管、粉丝和赞助者参与聊天。',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': '聊天栏解除冷却。您可以参与聊天。',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "您输入的\'%s\'包含限制搜索的禁语，无法使用。",
	'검색시스템에 문제가 발생했습니다.': '发生错误。',
	'검색어를 입력하세요.': '请输入搜索词。',
	'|중계방송|': '|转播间|',

	'BJ가 방송을 종료하였습니다.': '主播结束了直播。',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": '确定不再推荐主播(%s)吗？',
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': '要进入主播的直播间，需要使用快播道具。要现在前往购买页面吗？',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': '此直播是主播使用手机进行的直播，主播暂停直播时，会中断或出现黑屏画面。(主播重新开始直播时，会自动解除相关现象。)',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '只能在AfreecaTV应用程序内点赞。\n\n要用应用程序观看吗？',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': '只能在AfreecaTV应用程序中高清观看直播。\n\n 要用应用程序观看吗？',
	' 이상~': '以上~',

	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%s被%s禁止聊天。不能参与聊天30秒。',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%s被%s禁止聊天。不能参与聊天60秒。',
	'%s 님이 채팅 금지 횟수 초과로 블라인드 되었습니다. 2분 동안 채팅과 방송화면을 볼 수 없습니다.': '%s被禁止聊天次数过多，屏障2分钟',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%s被%s禁止聊天次数过多，屏障2分钟。此期间不能观看画面及聊天栏，若在屏障状态下离开直播间，则将被视为自动强制退场，无法重新进入直播间。',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제 퇴장되었습니다.': '%s(%s)在屏障状态下尝试离开直播间，被强制退场。',
	'%s(%s)님이 강제퇴장 되었습니다.': '%s(%s)被强制退场。',
	'%s(%s)님의 강제퇴장이 취소되었습니다..': '%s(%s)的强制退场处分被取消。',
    '%s님이 별풍선 %s개를 선물했습니다!': '%s赠送了 %s个星气球！',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': '%s在转播间赠送了%s个星气球！',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': '%s在观看视频时赠送了%s个星气球！',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%s赠送了%s个视频气球！',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': '%s在转直播间赠送了%s个视频气球！',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%s成为第%s个粉丝。',
	'%s님이 스티커 %s개를 선물했습니다!': '%s赠送%s个贴图。',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%s成为%s个赞助者。',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': '%s赠送转播间%s个贴图。',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%s赠送%s个巧克力。',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': '%s赠送转播间%s个巧克力。',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s 赠送%s快播道具%s天使用券。',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': '在手机网页无法观看。\n请使用电脑或应用程序(IOS, AOS)进行观看。',

	'운영자에 의해 강제퇴장 되었습니다.': '被管理员强制退场。',
	'운영자에 의해<br ng-show="newLine">': '您被管理员',
	'되었습니다': ' ',


	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                                                                                        \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                                                                                        \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': '您正在观看的直播包含有害青少年成长的内容。根据促进信息网络利用及信息保护等相关法律规定， \n\
                                                                                                                                                                                                                        \n\直播信息以更改未满19岁的用户观看。\n\
                                                                                                                                                                                                                        \n\如要继续观看，需进行本人认证。',


	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': '我正在AfreecaTV观看%s，要一起观看吗？',
	'채팅입력이 지원되지 않는 브라우져입니다.': '此浏览器不支持聊天功能。',
	'BJ %s의 방송 함께 봐요!' : '现在就去观看主播 %s的精彩直播！',
	'아프리카 TV 사이트(www.afreecatv.com)에서 더 많은 컨텐츠를 즐길 수 있습니다!' : '前往AfreecaTV官方网页，可以享受更多有趣的直播及视频！',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>请先注册AfreecaTV <strong>商品券</strong> 吧！</span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': '不支持此OS版本。<br>请使用<span>AfreecaTV应用程序</span>观看直播。',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': '开始显示<br>%s的聊天。',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': '开始隐藏<br>%s的聊天。',

	'랭킹 홈': '排行榜主页',
	'최고 유저 평균': '最高平均观看人数',
	'애청자 증가수': '热心观众增加数',
	'UP 수': '点赞数',
	'펫방': '宠物直播',
	'더빙/라디오': '配音/广播',
	'VOD BJ': '视频主播',
	'VOD FAN': '视频粉丝',
	'누적 애청자수': '累计热心观众',
	'누적 팬클럽수': '累计粉丝数',
	'랭킹 소통방 바로가기': '前往排行榜交流区',
	'BJ랭킹 산정 방식 안내': '主播排行筛选方式说明',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': '主播排行榜每周(周一~周日)通过以下比率筛选并反映到网站上。',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '（热心观众数据为累计人数）',
	'VOD BJ 랭킹 산정 방식 안내': '视频主播排行筛选方式说明',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': '视频主播排行榜是依据主播的重播视频及上传到直播间内的视频而筛选的。',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': '上周(周一~周日)内主播所有视频的点击增加数',
	'VOD FAN 랭킹 산정 방식 안내': '视频粉丝排行筛选方式说明',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': '视频主播排行榜是用户编辑并上传的主播视频而筛选的。',

	'변경': '更改',
	'아프리카TV 앱 실행하기': '实行AfreecaTV应用程序',
	'최고 유저': '最高观看人数',
	'BJ랭킹': '主播排行',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': '使用<span>AfreecaTV应用</span>，<br>可用<span>最高清画质(原画质)</span>参与聊天。',
	'고화질로 시청하기': '高清观看',

	'년 전': '年前',
	'개월 전': '个月前',
	'주 전': '周前',
	'일 전': '天前',
	'시간 전': '小时前',
	'분 전': '分前',
	'초 전': '秒前',

	'보낸 시각': '发送时间',
	'공유하기': '分享',
	'검색': '综合结果',

	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '前往<strong>AfreecaTV</strong><span>应用程序</span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': '登录后，才能使用此功能，要现在登录吗？',
	'누적 애청자 수': '累计热心观众人数',
	'누적 팬클럽 수': '累计粉丝数',
	'BJ 랭킹': '主播排行',

	'즐겨찾기 전체': '全部',
	'최신등록순': '最新上传排序',
	'참여인원순': '觀看人數排序',
	'닉네임순': '昵称排序',
	'아이디순': '賬號字母排序',
	'최신방송순': '最近直播排序',
	'LIVE 시청': '觀看直播',
	'목록 상단 고정 완료': '置頂設置成功。',
	'라이브&amp;VOD': '直播&amp;视频',
	'방송국 개설일': '直播间开设日',
	'최근방송': '最近直播',
	'LIVE 시청하기': '收看直播',
	'방송국가기': '前往直播间',
	'목록 상단 고정': '关注置顶',
	'즐겨찾기한 BJ가 없습니다.': '还没有关注的主播。',
	'목록 상단 고정 해제': '解除置顶',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': '您关注的主播还没开播。',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': '您關注的主播還沒有影片',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': '关注喜欢的直播，即可随时方便观看主播的直播和视频。',
	'상단고정 완료되었습니다': '置顶设置成功。',
	'상단고정 해제 되었습니다': '变更成功',
	'지원하지 않는 메뉴입니다.': '不支持此功能。',
	'수신거부 해제되었습니다.' : '设定成功。',
	'필터': '筛选',
	'정렬기준': '排序',
	'구분': '区分',
	'날짜': '日期',
	'취소': '取消',
	'적용': '适用',
	'생방송': '直播',
	'업로드VOD': '一般视频',
	'유저클립': '用户剪辑',
	'다시보기': '重播',
	'하이라이트': '经典视频',
	'구독전용': '订阅专用',
	'1년': '1年',
	'고화질순': '高画质排序',
	'고해상도순': '高解析度排序',
	'애청자순': '关注人数排序',
	'팬클럽순': '粉丝排行排序',
	'전체알림': '全部提示',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': '接收所有主播的提示音。',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': '不接收所有主播的提示音。',
	'%s님에게 알림을 받습니다.': '开始接收%s发送的提示。',
	'%s님에게 알림을 받지 않습니다.': '开始关闭%s发送的提示。',

	'방송제목 변경이력': '直播标题变更历史',
	'조회순': '播放人数排序',
	'관련된 VOD': '相关视频',
	'검색어와 연관된 추천 영상입니다.': '有关搜索词的相关视频',
	'게시글': '文章',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': '没有搜索到最近1个月的内容。<br> 把搜索期间从一个月改为一年，<br> 可以搜索到更多的内容。',
	'조회순': '点击数排序',

	'최근 검색어': '最近搜索词',
	'실시간 인기 검색어': '实时人气搜索词',
	'개인화 추천검색어': '个性化推荐搜索词',
	'자동저장 <span>끄기</span>': '<span>关闭</span>自动保存',
	'자동저장 <span>켜기</span>': '<span>开启</span>自动保存',
	'최근 검색어가 없습니다.': '没有最近搜索词',
	'최근 검색어 자동저장이 꺼져 있습니다.': '自动保存最近搜索词功能尚未激活。',
	'자동저장 켜기': '开启自动保存',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': '登录后，才能使用<br><strong>个性化推荐搜索词功能</strong>。',
	'최근검색어를\n모두 삭제하시겠습니까?': '确定要删除\n最近搜索词吗？',
	'최근검색어 자동저장을 사용합니다.': '开始自动保存最近搜索词。',
	'최근검색어 자동저장 사용을 중지합니다.': '停止自动保存最近搜索词。',
	'개인화 추천 검색어가 없습니다.': '没有个性化推荐搜索词。',
	'검색한 검색결과입니다.': '的搜索结果。确认',
	'검색결과 보기': '的搜索结果',

	'나중에 보기' : '下次再看',
	'즐겨찾기한 BJ의 VOD' : '已关注主播的视频',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : '您关注的主播还没有视频。',
	'즐겨찾기한 BJ의 방송이 없습니다.' : '您关注的主播还没有直播。',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : '确定删除最近观看的所有视频记录吗？',
	'재생한 VOD를 모두 삭제하시겠습니까?' : '确定删除所有已播放的视频吗？',
	'UP 한 VOD가 없습니다.' : '没有点赞的视频。',
	'즐찾 BJ': '关注主播',
	'구독 BJ': '订阅主播',
	'구독중인 BJ' : '正在订阅的主播',
	'VOD 홈' : '视频主页',
	'인기 VOD' : '人气视频',
	'인기' : '人气视频',
	'핫이슈 VOD' : '热门视频',
	'TV클립' : 'TV剪辑',
	'인기 하이라이트' : '人气精剪视频',
	'드라마 인기클립' : '电视剧人气剪辑',
	'예능 인기클립' : '综艺人气剪辑',
	'스포츠 인기클립' : '体育人气剪辑',
	'아프리카TV X TV클립' : 'AfreecaTV X TV剪辑',
	'예능' : '综艺',
	'교양' : '教养',
	'시사' : '时事',
	'드라마' : '电视剧',
	'스포츠' : '体育',
	'패션/뷰티' : '时尚/美妆',
	'어린이' : '儿童',
	'스페셜' : '精品',
	'디지털' : '数码',
	'뮤직' : '音乐',
	'라디오' : '广播',
	'키즈' : '儿童',
	'연예' : '演艺',
	'시사보도' : '时事报道',
	'라이프' : '生活',
	'홈페이지' : '网页',
	'주목할만한 클립' : '值得关注的剪辑',
	'인기 클립' : '人气剪辑',
	'클립 전체' : '全部剪辑',
	'프로그램 전체' : '全部节目',
	'TV클립 목록이 없습니다.' : '暂无列表。',
	'인기 프로그램' : '人气节目',

	'방송국 가기': '前往直播间',
	'기록' : '观看记录',
	'카테고리' : '分类',
	'추천받지 않기' : '不再推荐',
	'이어서 보기' : '连续观看',
	'최근 본 VOD' : '最近观看视频',
	'UP 한 VOD' : '点赞视频',
	'나중에 보기 삭제' : '删除',
	'최근 본 VOD 삭제' : '删除最近观看视频',
	'UP 취소' : '取消UP',
	'나중에 보기 목록에서 삭제 되었습니다.' : '删除成功。',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : '删除成功。',
	'선택하신 VOD UP이 취소 되었습니다.' : '取消成功。',
	'추천 받지 않기가 반영되었습니다.' : '设定成功。',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : '您已关注此直播间。',
	'이미 나중에 보기 등록된 VOD 입니다.' : '您已添加此视频到[下次再看]列表。',
	'전체 삭제' : '删除全部',
	'나중에 보기 한 VOD가 없습니다.' : '[下次再看]列表为空。',
	'최근 본 VOD가 없습니다.' : '没有最近观看的视频。',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : '前往视频首页，<br>即可确认个性化推荐视频。',
	'VOD 홈으로 이동' : '前往视频主页',
	'구독' : '订阅',
	'카테고리 선택' : '选择分类',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : '登录后，才能确认观看记录。',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : '登录后，即可观看<br>关注主播的视频。',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : '确定删除所有[下次再看]列表内视频吗？',
	'UP이 취소되었습니다.' : '取消成功。',
	'추천 받지 않기에 등록 되었습니다.' : '设定成功。',
	'즐겨찾기에서 삭제됐습니다.': '删除成功',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?': '登录后，才能添加关注。\n要现在登录吗？',
	'팬' : '粉丝',
	'구독한 BJ가 없습니다.' : '暂无订阅主播。',
	'구독한 BJ의 VOD가 없습니다.' : '您订阅的主播没有视频。',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : '确定从列表删除%s吗？',

	'추천' : '推荐',
	'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : '前往视频推荐页面，<br>便可确认个人化推荐视频。',
	'VOD 추천받기' : '视频推荐',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : '需要登录。\n要现在登录吗？',
	'주소가 복사되었습니다.' : '复制成功。',
	'본인은 즐겨찾기 할 수 없습니다.' : '不能关注自己。',

	'TAG_VOD_LIST_COUNT' : '<span class="count">共 <em class="point" text="@count"></em>个</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">常看主播的视频</span>',
	'TAG_VOD_RECENT_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">经常确认的主播</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name" text="@nickname"></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span>的视频 <span text="@count"></span>个</span>',

	'알림': '提示',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : '没有提示。<br>请在<a ng-click="setting()"><span>提示设定</span></a>中选择同意接收的提示。',
	'모두 읽음' : '全部已读',
	'모두 삭제' : '删除全部',
	'읽은 알림 삭제' : '删除已读短信',
	'알림 설정' : '提示设定',
	'설정' : '设定',
	'%d분 전' : '%d分钟前',
	'%d시간 전' : '%d小时前',
	'삭제' : '删除',
	'알림이 삭제되었습니다.' : '删除成功。',
	'숨기기' : '隐藏',
	'알림 콘텐츠 설정' : '提示内容设定',
	'초기화' : '初始化',
	'저장' : '保存',
	'푸시 설정' : '推送提示',
	'기록 설정' : '记录设定',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : '推送提示请在<a ng-click="setup_link()"><span>AfreecaTV应用-设定-提示</span></a>设定中更改。',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : '正在初始化。 \n所有已变更的设定值都将初始化。 初始化的提示设定会在保存后生效。',
	'설정이 저장되었습니다.' : '保存成功。',

	'발송취소' : '取消发送',
	'해당 쪽지를 발송 취소하시겠습니까?' : '确定取消发送此短信吗？',
	'보관한 보낸 쪽지가 없습니다.' : '没有保管的已发送短信。',
	'보관한 받은 쪽지가 없습니다.' : '没有保管的已收短信。',
	'읽지 않음' : '未读',
	'방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : '在直播间, %s赠送了%s个广告气球。',
	'유효기간 만료순' : '按有效日期排序',


	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : '确定要举报此邮件吗？\n(举报的邮件将被移至垃圾邮件箱。)',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : '要解除此垃圾邮件吗？\n(解除的邮件被移至收件箱。)',
	'자동스팸분류':'自动分类垃圾邮件',
	'스팸해제' : '解除垃圾短信',
	'스팸신고': '举报垃圾短信',
	'보관함':'保管箱',
	'스팸함':'垃圾邮件箱',
	'스팸 쪽지가 없습니다.':'暂无垃圾邮件。',
	'스팸 쪽지':'垃圾邮件',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.':'确认的垃圾邮件经过90日后将自动删除。',

	'"%s" 검색 결과' : '"%s" 搜索结果',
	'검색된 쪽지가 없습니다.' : '没有有关搜索的结果。',
	'검색어를 입력해 주세요.' : '请输入内容。',
	'내용' : '内容',
	'검색' : '搜索',
	'더 알아보기' : '查看更多',
	'재생목록':'播放列表',
	'재생목록에 담기':'添加到播放列表',
	'재생목록 퍼가기':'转载播放列表',
	'생성한 재생목록':'已创建的播放列表',
	'재생목록 보관함':'播放列表保管箱',
	'재생목록이 없습니다.':'暂无播放列表。',
	'새 재생목록 만들기':'创建新播放列表',
	'추가':'添加',
	'새 재생목록':'新播放列表',
	'확인' : '确认',
	'VOD 추가순': '视频添加排序',
	'오래된순': '时间排序',
	'수정하기': '修改',
	'삭제하기': '删除',
	'업데이트' : '更新',
	'공개': '公开',
	'비공개' : '非公开',
	'전체보기' : '查看全部',
	'목록 공개': '公开目录',
	'목록 비공개': '隐藏目录',
	'목록 퍼가기 허용': '允许转载列表',
	'목록 퍼가기 비허용': '不允许转载列表',
	'제목을 입력해주세요.': '请输入标题。',
	'VOD가 없습니다.' : '暂无视频。',
	'재생목록을 삭제하시겠습니까?' : '确定要删除播放列表吗？',
    '저화질' : '低清晰度',
    '일반화질' : '普通清晰度',
	'원본화질' : '原清晰度',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : '请在 App中移动视频，以360°角度欣赏。',
    '유료 광고 포함' : '包含付费广告',
    '참여를 위해<br>인코딩 중입니다.': '正在编码中。 ',
    '잠깐만 기다려주세요!': '请稍等！',
    '<span><em>초</em> 남았습니다.</span>': '<span>还剩 <em>seconds</em></span>',
    '보유 중인 선물 받은 구독권' : '收到的订阅券',
    '보유 중인 구독 선물권' : '我的订阅券',
    '아이템 정보' : '道具信息',
    '광고 Skip, 본방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': '马上就去体验享有略过广告、进入满员直播间、时光机功能等<br>各种福利的<strong>快播道具</strong>吧。',
    '선물/후원 정보' : '赠送/赞助信息',
	'교환' : '交换',

	'사용하러 바로가기':'立即使用',
	'구독 구매하기':'购买订阅',
	'구매하러 바로가기':'立即购买',
    '연속선물' : '递增赠送',
    '연속선물이란?' : '递增赠送?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : '激活递增赠送功能时，其赠送窗口将会维持，<br>每次赠送完毕后系统将自动+1。',

	'구매한 스티커' : '已购买贴图',
	'선물받은 스티커' : '收到的贴图',
	'스티커 아이템' : '贴图道具',
	'스티커 교환하기' : '兑换贴图',
	'아이템 정보' : '道具信息',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : '在使用快播+道具时，不能添加。',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : '在使用快播道具时，不能添加。',
	'플러스로 전환' : '转换至 快播+',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : '购买的快播道具以及快播赠送券才能转换至快播+道具。',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : '成功使用快播道具 。\n您可以转换道具至快播+。\n确定转换至快播+吗？',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "成功使用免费的快播道具。免费的快播道具不能转换至快播+道具。",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "成功使用免费快播+道具。",
	"퀵뷰가 사용되었습니다.": "成功使用快播道具。",
	"퀵뷰 플러스가 사용되었습니다.": "成功使用快播+道具。",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : '转换至<br><strong>快播+的机会！</strong>',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : '试试转换快播至快播+道具吧。',
	'이용기간' : '使用期间',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : '系统会自动计算您转换后的使用期限，并相应延长使用期间。',
	'퀵뷰 플러스의 더 커진 혜택' : '快播+的更多优惠',
	'VOD 광고 NO!' : '视频广告NO!',
	'FULL방 입장' : '进入满员直播间',
	'방송 광고 NO!' : '跳过广告！!',
	'멀티뷰' : '多视角观看',
	'방송 미리보기(모바일)' : '预览直播(手机)',
	'퀵뷰 사용 기간 별 전환 금액' : '按使用快播快播时长而不同的转换金额',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : '按使用时长结算相应的金额时，系统会重新计算您的使用期限，提供相应<strong>延长其使用期限的优惠！</strong>',
	'사용' : '使用',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'플러스로 전환하기' : '转换至 快播+',
	'기존 퀵뷰로 사용하기' : '继续使用 现有快播道具',
	'※부가세 미포함' : '※附加税另计',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : '您已使用快播 <strong>%s天</strong>，额外结算 <span>%s</span>即可转换至 <strong>快播+%s天券</strong>道具。',
	'더 저렴하고! 기간도 길게!<br>(최초 1회 제공)' : '更便宜！使用时间更长！<br>（提供首次1次）',
	'선물 ・ 후원 정보' : '赠送・赞助信息',
	'무료체험중' : '免费体验中',
	'(iOS자동결제)' : '(iOS自动结算)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : '您目前正在使用苹果\n定期订阅(自动结算)，因此，\n在此设备无法更改结算方式。\n请通过苹果手机(或iPad)的App Store > Apple ID > 订阅使用服务。',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : '目前您正在使用苹果\n定期订阅(自动结算)，\n因此在此设备无法取消。请通过苹果手机(或iPad)的App Store > Apple ID > 订阅使用服务。',
	'해지 신청중' : '正在处理中',
	'2개의 방송을 동시에! 모바일 멀티뷰' : '同时观看两个直播！移动设备多视角观看',

	'내가 보유한 스티커' : '我的贴图',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : '赠送100个以上的个性星气球时留下视频剪辑',
	'소개글' : '简介',
	'공지' : '公告',
	'나이' : '年龄',
	'경력/수상' : '经历/获奖',
	'수상 메달' : '获奖',
	'앗 혹시 찾으시는 분이...' : "您是在找.....",
	'지금 이 콘텐츠에 주목!' : '此刻要关注的内容！',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '<span class="normal">推荐搜索</span>\'{{ query }}\'<span class="normal">的用户观看</span>',
	'누적 참여자' : '累计观看人数',
	'방송 시간' : '直播时间',

	'쉬는시간 후 방송이 이어집니다.' : '直播会在休息时间结束后继续。',
	'방송에 후원되는 광고입니다.' : '此为可以赞助直播的广告。',
	
	'URL 복사' : '复制网址',
	'구독하러 바로가기':'立即购买',

	'빠른 답장' : '快速回复',
	'기본 메시지로 저장' : '保存为基本短信',
	'보내기' : '回复',
	'후원 쪽지' : '赞助短信',

	'선물 메세지' : '赠送信息',
	'목소리 선택' : '选择声音',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : '您可赠送主播贴图，让TA使用提示音道具！',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : '如果直播设定里没有电子秘书或已设定赠送个数时将无法收到播放提示音。',

	'영상 광고 없는 VOD 재생!' : '观看无广告视频！',
	'캐치 광고 제외' : 'Catch广告除外',
	'방송 입장 영상광고 NO!' : '跳过视频广告直接进入直播间！',
	'배너형, 로딩광고, LIVE 중간광고 제외' : '条型，加载广告，直播插播广告除外',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':'为保护未成年人的健康成长，<br>主播已设定并<strong>限制19岁以上用户</strong>观看直播。<br>只能在AfreecaTV应用程序参与。',
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':'为保护未成年人的健康成长，主播已设定并限制19岁以上用户观看直播。\n\n要前往AfreecaTV应用程序参与到其中吗？',
	
	'대결미션' : '对决任务', 
	'대결미션이란?': '对决任务是？',
	'대결단계에서만 후원 가능합니다.': '只能在对决任务进行时赞助。',
	'보유 별풍선이 즉시 차감됩니다.': '使用的星气球会实时从剩余星气球中扣除。',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': '此服务是根据参与对决内容的结果，将赞助的星气球自动分配给各组的功能。',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': '您的星气球不是直接赞助给主播，而是会赞助给对决任务。',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': '赞助现金时，可以加入粉丝团，并计算超级粉丝的排名。',
	
	'도전미션' : '挑战任务',
	'보유 별풍선 :' : '我的星气球 :',
	'도전미션이란?' : '挑战任务是？',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : '向主播邀请挑战任务，<br />赞助星气球奖金吧！',
	'새로운 미션 요청' : '新任务邀请',
	'후원할 별풍선' : '要赞助的星气球',
	'후원하기' : '赞助',
	'미션 내용을 입력해 주세요. (최대 30자)' : '请输入任务内容。（最多30字）',
	'미션 제한 시간' : '任务限制时间',
	'도전미션 일': '日',
	'도전미션 시간': '小时',
	'도전미션 분' : '分',
	'미션 상금' : '任务奖金',
	'미션 요청' : '任务邀请',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : '主播进行任务，并根据成功与否来获得用户募集奖金的赞助服务。',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : '用户可以邀请挑战任务，或主播可直接登录任务。',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : '任务结果由主播决定，邀请任务的用户仅能决定「成功」。',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : '挑战任务是在主播和用户的信任为前提进行，任何情况下都无法推翻任务结果。',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : '超过任务限制时间时，任务将自动失败。',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : '任务成功时，将会把募集的奖金颁发给主播。',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : '取消任务、任务失败时，奖金将返还给用户。',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : '主播可以延长一次任务限制时间。（最多10天）',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : '您可以为等待／进行中的任务追加赞助奖金。',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : '加入粉丝团／超级粉丝的统计将在任务成功后颁发奖金给主播时进行。',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : '用户在最近1天内累计取消5次任务时，24小时内将无法向所有主播邀请任务。 请慎重地邀请任务。',
	
	'아이템은 여기서 구매하세요!' : '道具请在此购买！',
	'1:1 문의 바로가기' : '立即前往1:1客服',
	'내 애드벌룬 확인하고 선물하기' : '确认并赠送我的广告气球',

	'규칙에 동의합니다!' : '我同意规则！',
	
	'내 별풍선' : '我的星气球',
	'내 별풍선ㆍ아이템' : '我的星气球 · 道具',
	'드롭스 이벤트 진행 중' : 'Drops活动进行中',

	'퀵뷰': '快播',
	'퀵뷰 플러스': '快播+',
	'만료': '到期',
	'예약': '预约',
	'사용 예약': '预约',
	'예약 취소': '取消',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': '已预订使用%s 。\n\n目前正在使用的快播使用期限到期时，将继续使用已预订使用的道具 。',
	'%s 사용을 예약 취소하시겠습니까?': '您确定要取消%s的使用预约吗？',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': '%s 使用预约已被取消，\n\n如需预约使用，请点击「预约使用」。',

	'공식방송국':	'直播间',
	'공식 방송국':'直播间',
	'관련BJ':	'相关主播',
	'관련 BJ':	'相关主播',
	'이벤트':	'活动',
	'시리즈게임':	'系列游戏',
	'시리즈 게임':	'系列游戏',
	'전체':	'全部',
	'명':	'人',
	'관련BJ<br>더보기':	'查看更多<br>相关主播',
	'LIVE':	'LIVE',
	'다시보기':	'重播',
	'경기없음':	'无转播',
	'경기취소':	'比赛取消',
	'경기예정':	'预定比赛',
	'결과<br>보기':	'查看<br>结果',
	'참여인원순':	'人数排序',
	'시청인원순':	'观看人数排序',
	'최근방송':	'最新直播',
	'LIVE 참여하기':	'参与LIVE',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'最近没有直播的主播，<br>以相应类别开始进行直播吧!',
	'예정된 경기가 없습니다.':	'没有预定的比赛。',
	'경기종료':	'比赛结束',
	'최신방송순':	'直播排序',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '<span class="normal">为搜寻</span>\'{{ query }}\'<span class="normal">的用户所推荐的主播</span>',

	// 모웹 메인 footer에서 사용
	'이용약관': '使用条款',
	'개인정보 처리방침': '个人资料处理方针',
	'대표이사': '代表理事',
	'사업자번호': '营业执照号',
	'사업자 정보 확인': '企业信息确认',
	'호스팅 제공자': '托管提供者',
	'주식회사 숲': 'ⓒ SOOP Corp.',
	'주소': '地址',
	'경기도 성남시 분당구 판교로 228번길 15 판교세븐밴처밸리 1단지 2동 주식회사 숲(삼평동)': 'PanGuo 7 VentureValley 1-2, 15, PangGyo-ro 228beon-gil, Bundang-Gu, SeongNam-Si, GyeongGi-Do, Korea',
	'대표 번호': '代表号码',
	'이메일': '邮箱',
	'사업자 정보': '营业执照',
	'통신판매번호': '通信贩卖业申报编号',
	'FAX': '传真',
	'\'{{ query }}\' 검색한 유저를 위한 추천 Catch' : '推荐给搜寻「\'{{ query }}\'」的用户的推荐Catch',
	'계정연결': '账号连接',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'본방 입장': '进入直播间',
	'남자': '男性',
	'여자': '女性',
	'캐릭터': '角色',

	'종료된 방송입니다.': '此直播已结束。',
	'지금 바로 아프리카TV의 다양한 생방송과<br>재미있는 VOD를 만나보세요!': '立即来看看AfreecaTV多样的直播与有趣的视频吧！',
	'VOD 보러가기': '前往视频页面'
};
