Number.prototype.numberFormat = function() {
	if (this == 0) return 0;

	var reg = /(^[+-]?\d+)(\d{3})/;
	var n = this + "";

	while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

	return n;
};

// 문자열 타입에서 쓸 수 있도록 format() 함수 추가
String.prototype.numberFormat = function() {
	var num = parseFloat(this);
	if (isNaN(num)) return "0";
	return num.numberFormat();
};

//입력 bytes 카운트
String.prototype.getBytes = function() {
	if (this === window) {
		return 0;
	}
	var nBytes = 0;
	for (var i = 0; i < this.length; i++) nBytes += this.charCodeAt(i) > 128 ? 2 : 1;
	return nBytes;
};

//자르기
String.prototype.cut = function(len) {
	if (this === window) {
		return "";
	}
	var l = 0;
	for (var i = 0; i < this.length; i++) {
		l += this.charCodeAt(i) > 128 ? 2 : 1;
		if (l > len) return this.substring(0, i);
	}
	return this;
};


RegExp.escape = function(value)	{
	return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};