IteminfoStickerController.$inject = ['$scope', '$state', 'ItemService', 'gtext'];

function IteminfoStickerController($scope, $state, ItemService, gtext) {

	// 배너 날짜 체크
	var d = new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;
	$scope.today = [year, month, day].join('-');

	/**
	 * 스티커 티켓 아이템 리스트
	 
	 */
	$scope.loadStickerTicketList = function () {
		if (!/^ko/ig.test(navigator.language)) {
			alert(gtext('지원하지 않는 메뉴입니다.'));
			if (afreeca.link.isWebview()) {
				afreeca.link.close();
			} else {
				$state.go("iteminfo");
			}
		}
		ItemService.stickerTicketList().then(function (response) {
			$scope.TOTAL = response.data.TOTAL;
			$scope.ticket = response.data;
			$scope.$digest();
		}).catch(function (error) {
			$scope.TOTAL = 0;
			$scope.$digest();
		});
	}

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		if (afreeca.link.isWebview()) {
			afreeca.link.close();
		} else {
			$state.go("iteminfo");
		}
	}

	/**
	 * 티켓 교환하기 페이지로 이동
	 */
	$scope.exchangeTicketView = function (ticket) {
		$state.go("iteminfo.exchangeticket", {
			ticket_type: ticket.ticket_type
		});
	}

	/**
	 * 티켓 정보(프로모션, 이벤트 등) 페이지로 이동
	 */
	$scope.goTicketInfoLink = function (ticket_url) {
		afreeca.link.browser(ticket_url);
	}

	/**
	 *  내 스티커 정보 페이지로 이동
	 */
	$scope.goMyStickerPage = function () {
		$state.go("iteminfo.mysticker");
	}

	$scope.loadStickerTicketList();

}
module.exports = IteminfoStickerController;