IteminfoTicketEndController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$http', '$timeout', 'Util', 'LoginManager', 'ItemService', 'gtext'];

function IteminfoTicketEndController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, ItemService, gtext) {

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$window.history.back();
	}

	/**
	 * 티켓 선물하기 페이지로 이동
	 */
	$scope.giftTicket = function (ticket) {
		$state.go("iteminfo.giftticket", {
			ticket_type: ticket.ticket_type,
			period: 1
		});
	}


	/**
	 * 교환할 티켓 정보 불러오기 (티켓 교환하기 페이지)
	 */
	ItemService.stickerTicketExchangeView({
		ticket_type: $stateParams.ticket_type
	}).then(function (response) {
		$scope.total = 1;
		$scope.ticket = response.data;
		$scope.$digest();
	}).catch(function (error) {
		$scope.total = 0;
		$scope.$digest();
	});

}
module.exports = IteminfoTicketEndController;