
WebviewController.$inject = ['$scope', '$rootScope', '$location', '$stateParams', 'AfreecaStorageFactory', 'Util'];
function WebviewController($scope, $rootScope, $location, $stateParams, AfreecaStorageFactory, Util) {


	$('body').removeClass('liveArea fullscreen');

	/**
	 * 메뉴호출
	 * @param {type} id
	 * @returns {undefined}
	 */
	var scanMenu = function (id) {
		var i, j, group, menu;
		for (i = $scope.groups.length - 1; i >= 0; i--) {
			group = $scope.groups[i];
			for (j = group.menus.length - 1; j >= 0; j--) {
				menu = group.menus[j];
				if (menu.id == id) {
					var aUrl = Util.parseURL(menu.scheme);
					var url = aUrl.params['url'];
					$rootScope.title = menu.title;
					$rootScope.iframeurl = url;
					return;
				}
			}
		}
		$location.path('home');
	};



	//처음로딩시 메뉴데이터를 읽고 iframe 호출
	if (!$rootScope.$$listeners['menuCompleteEvent']) {
		$rootScope.$on('menuCompleteEvent', function (event) {
			scanMenu($stateParams.id);
		});
	}
	//					var iframe = $("#iframe");
	// 기본 height값 적용
	$('#iframe').css({ height: $(document).height() - 51 });


	//메뉴검색해서 페이지 띄움
	if ($scope.groups)
		scanMenu($stateParams.id);
}
module.exports = WebviewController; 