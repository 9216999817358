


Run.$inject = ['$window', 'gettextCatalog'];
function Run($window, gettextCatalog) {

        function getPreferredLocale() {
                var supportLanguage = ['en_US', 'ko_KR', 'zh_CN', 'zh_TW', 'ja_JP', 'th_TH'];
                var lang = $window.navigator.language || $window.navigator.userLanguage;
                var lang = lang.replace('-', '_');
                var aLang = lang.split('_');
                var nationCode = aLang[1] ? aLang[1].toUpperCase() : null;

                if (nationCode == 'HANS') {
                        nationCode = 'CN';
                } else if (nationCode == 'HANT' || nationCode == 'HK') {
                        nationCode = 'TW';
                }

                //4자 매칭
                var code = aLang[0] + (nationCode ? ('_' + nationCode) : '');
                if ($.inArray(code, supportLanguage) > 0) {
                        return code;
                }

                //앞 2자 매칭(언어매칭)
                for (var i = 0; i < supportLanguage.length; i++) {
                        var patt = new RegExp(aLang[0]);
                        if (patt.test(supportLanguage[i])) {
                                return supportLanguage[i];
                        }
                }

                return supportLanguage[0];

        }
        //언어셋팅
        gettextCatalog.currentLanguage = getPreferredLocale();
        console.log(gettextCatalog.currentLanguage)
        switch (gettextCatalog.currentLanguage) {
                case 'ko_KR':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('ko_KR', require('./ko_KR'));
                        }, 'ko_KR');
                        break;
                case 'en_US':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('en_US', require('./ko_KR'));
                        }, 'en_US');
                        break;
                case 'zh_TW':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('zh_TW', require('./ko_KR'));
                        }, 'zh_TW');
                        break;
                case 'zh_CN':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('zh_CN', require('./ko_KR'));
                        }, 'zh_CN');
                        break;
                case 'ja_JP':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('ja_JP', require('./ko_KR'));
                        }, 'ja_JP');
                        break;
                case 'th_TH':
                        require.ensure([], function () {
                                gettextCatalog.setStrings('th_TH', require('./ko_KR'));
                        }, 'th_TH');
                        break;                      
        }
};

module.exports = Run;