

NicknameService.$inject = ['$http'];
function NicknameService($http) {
        this.check = function (data) {
                var newData = {
                        szNick: data.szNick
                };
                return new Promise(function (resolve, reject) {
                        $http.post(ST_AFREECATV + '/api/nickname_api_utf8.php?szWork=check', $.param(newData || '')).then(function (response) {
                                if (response.data.RESULT == 1) {
                                        resolve(response.data)
                                } else {
                                        if (response.data.RESULT == '-50014') {
                                                alert(response.data.MESSAGE);
                                                return;
                                        }
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }


        this.update = function (data) {
                var newData = angular.merge({}, {
                }, data);
                return new Promise(function (resolve, reject) {
                        $http.post(ST_AFREECATV + '/api/nickname_api_utf8.php?szWork=update', $.param(newData || '')).then(function (response) {
                                if (response.data.RESULT == 1) {
                                        resolve(response.data)
                                } else {
                                        if (response.data.RESULT=='-50014') {
                                                alert(response.data.MESSAGE);
                                                return;
                                        }
                                        reject(response.data);
                                };
                        }).catch(function (error) {
                                reject(error);
                        })
                });
        }
}


module.exports = NicknameService;