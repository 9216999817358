function MultiviewFactory() {
        var _videoElem;
        var _elem;
        var _canvas, _context;
        var _index;
        var _selectPostion;
        var _info;
        var _index;
        var _requestId;
        var _videoWidth;
        var _videoHeight;
        var that = this;
        return {

                create: function (elem, video) {
                        _elem = elem;
                        _context = _elem.get(0).getContext('2d');
                        _videoElem = video.get(0);
                },

		/**
		 * 멀티방송정보
		 * @param {type} info  멀티방송정보
		 * @param {type} videoWidth 실제영상가로
		 * @param {type} videoHeight	실제영상세로
		 */
                setInfo: function (info, videoWidth, videoHeight) {
                        _info = info;
                        _videoWidth = videoWidth;
                        _videoHeight = videoHeight;
                },
                //멀티방송 선택
                setChannel: function (index) {
                        _index = index;
                        if (_info.points.length < _index) {
                                _index = 0;
                        }

                        if (_index) {

                                $(_videoElem).hide();
                                $(_elem).show();
                                _selectPostion = _info.points[_index - 1];
                                _context.fillRect(0, 0, _context.canvas.width, _context.canvas.height);
                                draw();
                        } else {
                                $(_videoElem).show();
                                $(_elem).hide();
                                if (_requestId)
                                        window.cancelAFrame(_requestId);
                        }
                },
                reload: function () {
                        setChannel(_index);
                },
                draw: function () {
                        if (_index) {
                                var w = _elem.width();
                                var h = _elem.height();

                                //화면위치
                                var rate = (_selectPostion[2] / _selectPostion[3]);	//가로세로 비율
                                var realWidth = _elem.height() * rate;
                                var realHeight = h;
                                var realX = (w - realWidth) / 2; //가운데 정렬

                                //원본영상위치
                                var videoRate = _videoWidth / _info.width;	//영상크기와 실제크키 비율
                                var srcX = _selectPostion[0] * videoRate;
                                var srcY = _selectPostion[1] * videoRate;
                                var srcWidth = _selectPostion[2] * videoRate;
                                var srcHeight = _selectPostion[3] * videoRate;

                                _context.drawImage(_videoElem, srcX, srcY, srcWidth, srcHeight, realX, 0, realWidth, realHeight);
                                _requestId = window.requestAFrame(that.draw);
                        }
                },

                remove: function () {
                        $(_videoElem).show();
                        $(_elem).hide();

                        if (_requestId)
                                window.cancelAFrame(_requestId);
                        _requestId = null;
                }
        }
}
module.exports = MultiviewFactory