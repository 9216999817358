IteminfoGiftTicketEndController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$window', '$http', '$timeout', 'Util', 'LoginManager', 'ItemService', 'gtext'];

function IteminfoGiftTicketEndController($scope, $rootScope, $state, $stateParams, $window, $http, $timeout, Util, LoginManager, ItemService, gtext) {

	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$state.go("iteminfo.mysticker");
	}

	/**
	 *  내 스티커 정보 페이지로 이동
	 */
	$scope.goMyStickerPage = function () {
		$state.go("iteminfo.mysticker");
	}

	/**
	 * 교환 티켓 정보 불러오기 (티켓 선물 완료 페이지)
	 */
	ItemService.stickerGiftTicketEndView({
		ticket_type: $stateParams.ticket_type
	}).then(function (response) {
		$scope.total = 1;
		$scope.ticket = response.data;
		ticket_cnt = $scope.ticket.buy_cnt;
		$('.box_w p').hide();
		$scope.$digest();
	}).catch(function (error) {
		$scope.total = 0;
		$scope.$digest();
	});
}
module.exports = IteminfoGiftTicketEndController;