


Run.$inject = ['$http', '$rootScope', '$state'];
function Run($http, $rootScope, $state) {
        $rootScope.$state = $state;
        $http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

};

module.exports = Run;