RecentVodService.$inject = ["$rootScope", "$location", "AfreecaStorageFactory"];
function RecentVodService($rootScope, $location, AfreecaStorageFactory) {
	return {
		key: "aRecentTitles",
		maxCnt: 20,
		/**
		 * 전체삭제
		 * @returns {undefined}
		 */
		removeAll: function() {
			AfreecaStorageFactory.removeItem(this.key);
		},

		/**
		 * 추가
		 * @param {type} oTitleInfo
		 * @returns {Boolean}
		 */
		add: function(oTitleInfo) {
			var aRecentTitles = this.getAll();

			// 이미 등록된 정보가 있을경우 Skip
			for (var i = 0; i < aRecentTitles.length; i++) {
				var oItem = aRecentTitles[i];
				if (oItem.type == oTitleInfo.type && oItem.title_no == oTitleInfo.title_no) {
					return false;
				}
			}

			// 최대 20개
			if (aRecentTitles.length >= this.maxCnt) {
				aRecentTitles.splice(0, 1);
			}

			aRecentTitles.push(oTitleInfo);
			AfreecaStorageFactory.setItem(this.key, JSON.stringify(aRecentTitles));
		},

		/**
		 * 전체호출
		 * @returns {Array|Object}
		 */
		getAll: function(bUseString) {
			var szRecentTitles = AfreecaStorageFactory.getItem(this.key);

			if (bUseString == true) {
				return szRecentTitles ? szRecentTitles : "";
			}
			//
			else {
				return szRecentTitles ? JSON.parse(szRecentTitles) : [];
			}
		},
	};
}

module.exports = RecentVodService;
