var toastr = require('toastr');
MyinfoNicknameController.$inject = ['$scope', '$rootScope', '$state', '$http', '$timeout', 'Util', 'LoginManager', 'NicknameService', 'gtext']
function MyinfoNicknameController($scope, $rootScope, $state, $http, $timeout, Util, LoginManager, NicknameService, gtext) {

	$scope.nickname = LoginManager.getNickname();
	$scope.disabled = true;
	/**
	 * 뒤로가기 버튼
	 */
	$scope.backHandler = function () {
		$state.go("myinfo");
	}


	$scope.$watch('nickname', function (newNames, oldNames) {
		$scope.disabled = true;
	});

	$scope.check = function () {
		if ($scope.nickname.trim() == "") {
			$scope.message = gtext("사용할 수 없는 닉네임입니다.");
			return;
		}

		NicknameService.check({
			szNick: $scope.nickname,
			szUId: LoginManager.getUserId(),
		}).then(function (response) {
			$scope.message = response.MESSAGE;
			$scope.disabled = false;
			$scope.$digest();
		}).catch(function (response) {
			$scope.message = response.MESSAGE;
			$scope.disabled = true;
			$scope.$digest();
		}).catch(function (error) {
			$scope.disabled = true;

		})

	}

	$scope.update = function (coupon) {
		if ($scope.disabled == true) {
			$scope.message = gtext("닉네임 중복 확인을 해주세요.");
			return;
		}

		NicknameService.update({
			szNick: $scope.nickname,
			szUId: LoginManager.getUserId()
		}).then(function (response) {
			LoginManager.changeNickName($scope.nickname);
			toastr.success(gtext('닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.'));
			$state.go("myinfo");
			$scope.$digest();
		}).catch(function (response) {
			$scope.message = response.MESSAGE;
			$scope.$digest();
		})

	}

	//로그인이벤트
	$scope.$on('$loginChange', function (event, value) {

		if (LoginManager.isLogin()) {
			$scope.nickname = LoginManager.getNickname();
		}
		else {
			toastr.error(gtext("로그인이 되어 있지 않습니다."))
			setTimeout(function () {
				$state.go("home");
			}, 500);

		}
	});

	LoginManager.check();
}
module.exports = MyinfoNicknameController; 