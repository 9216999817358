module.exports = function CheckImage() {
        return {
                restrict: 'A',
                scope: {
                        ngModel: '='
                },
                link: function ($scope, $element, $attrs) {
                        var imgtype = $attrs.imgtype;
                        $scope.$watch(function () {
                                return $attrs.ngSrc;
                        }, function () {});
                        // 디폴트이미지가 넘겨받았으면 그 이미지를 사용하고 없으면 img_default_b 이미지 사용
                        var szDefaultImg = $attrs.checkImg ? $attrs.checkImg : RES_AFREECATV + '/images/mobile/afweb/img_default_b.jpg';

                        // src값이 없을때
                        if (!$attrs.ngSrc) {
                                $element.attr('src', szDefaultImg);
                        }
                        // src값이 error일때
                        $element.bind('error', function () {
                                if ($scope.ngModel) {
                                        try {
                                                var model = $scope.ngModel;
                                                var isChange = false;
                                                var class_name = "";
                                                if(imgtype =="search_vod"){
                                                        if(model.auth =="HIDDEN" && model.grade == 19){
                                                                class_name = "thumb-lock_adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.grade == 19){
                                                                class_name = "thumb-adult";
                                                                isChange=true;
                                                        }
                                                        else if(model.auth=="HIDDEN"){
                                                                class_name = "thumb-lock";
                                                                isChange= true;
                                                        }
                                                        else{
                                                                class_name = "thumb-default";
                                                                isChange =true;
                                                        }
                                                        if(isChange){
                                                                $element[0].parentElement.classList.add(class_name); 
                                                                $element.remove()
                                                        }
                                                
                                                }
                                                else if(imgtype =="vod"){
                                                        if(model.auth_no =="102" && model.grade ==19){
                                                                class_name = "thumb-lock_adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.grade == 19){
                                                                class_name = "thumb-adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.auth_no=="102"){
                                                                class_name = "thumb-lock";
                                                                isChange =true;
                                                        }
                                                        else{
                                                                class_name = "thumb-default";
                                                                isChange =true;
                                                        }
                                                        if(isChange){
                                                                $element[0].parentElement.classList.add(class_name); 
                                                                $element.remove()
                                                        }
                                                }
                                                else{
                                                // 성인이고 비밀방이면
                                                        if ((19 == $scope.ngModel.grade) && '00030000' == $scope.ngModel.category && ($scope.ngModel.is_password && $scope.ngModel.is_password !== '0')) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_adultpass.gif');
                                                        }
                                                        // 성인이면
                                                        else if (19 == $scope.ngModel.grade && '00030000' == $scope.ngModel.category && !$scope.ngModel.is_password) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_adult.gif');
                                                        }
                                                        // 비밀방이면
                                                        else if ($scope.ngModel.is_password && $scope.ngModel.is_password != 0 && 19 != $scope.ngModel.grade) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_pw.gif');
                                                        }
                                                }
                                        }       
                                        catch (e) {}
                                }
                                        
                        });

                        // 유효썸네일체크할때만
                        setTimeout(function () {
                                if ($scope.ngModel) {
                                        var model = $scope.ngModel;
                                        try {
                                                var class_name = "";
                                                var isChange = false;
                                                if(imgtype =="search_vod"){
                                                        
                                                        if(model.auth =="HIDDEN" && model.grade == 19){
                                                                class_name = "thumb-lock_adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.grade == 19){
                                                                class_name = "thumb-adult";
                                                                isChange=true;
                                                        }
                                                        else if(model.auth=="HIDDEN"){
                                                                class_name = "thumb-lock";
                                                                isChange= true;
                                                        }
                                                        if(isChange){
                                                                $element[0].parentElement.classList.add(class_name); 
                                                                $element.remove();
                                                        }
                                                
                                                }
                                                else if(imgtype =="vod"){
                                                        if(model.auth_no =="102" && model.grade ==19){
                                                                class_name = "thumb-lock_adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.grade == 19){
                                                                class_name = "thumb-adult";
                                                                isChange =true;
                                                        }
                                                        else if(model.auth_no=="102"){
                                                                class_name = "thumb-lock";
                                                                isChange =true;
                                                        }
                                                       
                                                        
                                                        if(isChange){
                                                                $element[0].parentElement.classList.add(class_name); 
                                                                $element.remove();
                                                        }
                                                }
                                                else{
                                                // 성인이고 비밀방이면
                                                        if ((19 == $scope.ngModel.grade) && '00030000' == $scope.ngModel.category && ($scope.ngModel.is_password && $scope.ngModel.is_password !== '0')) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_adultpass.gif');
                                                        }
                                                        // 성인이면
                                                        else if (19 == $scope.ngModel.grade && '00030000' == $scope.ngModel.category && !$scope.ngModel.is_password) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_adult.gif');
                                                        }
                                                        // 비밀방이면
                                                        else if ($scope.ngModel.is_password && $scope.ngModel.is_password != 0 && 19 != $scope.ngModel.grade) {
                                                                $element.attr('src', RES_AFREECATV + '/images/mobile/afweb/img_thumb_pw.gif');
                                                        }
                                                }
                                        } catch (e) {}
                                }
                        }, 1);
                }
        }
}