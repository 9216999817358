module.exports = function InputKorean() {
    // 양방향 바인딩에서 한글이 짤리는 문제를 해결하기 위해 추가
    return {
        priority: 2,
        restrict: 'A',
        compile: function (element) {
            element.on('compositionstart', function (e) {
                e.stopImmediatePropagation();
            });
        }
    };
}