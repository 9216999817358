module.exports = function SearchProfileImage() {
	return {
		restrict: "A",
		scope: {
			ngModel: "=",
		},
		link: function($scope, $element, $attrs) {
			$scope.$watch(
				function() {
					return $attrs.ngSrc;
				},
				function() {}
			);

			// 디폴트이미지가 넘겨받았으면 그 이미지를 사용하고 없으면 img_default_b 이미지 사용
			var szDefaultImg = $attrs.searchImage ? $attrs.searchImage : RES_AFREECATV + "/images/svg/thumb_profile.svg";

			// src값이 없을때
			if (!$attrs.ngSrc) {
				$element.attr("src", szDefaultImg);
			}
			// src값이 error일때
			$element.bind("error", function() {
				$element.attr("src", szDefaultImg);
			});
		},
	};
};
